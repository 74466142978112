import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTimeISO: { input: any; output: any; }
  JSON: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
};

export type AddToCartInput = {
  product: Product;
  type: Scalars['String']['input'];
  userEmail: Scalars['String']['input'];
};

export type AddToCartResponse = {
  __typename?: 'AddToCartResponse';
  message?: Maybe<Scalars['String']['output']>;
  status: Scalars['Boolean']['output'];
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int']['output'];
};

export type AggregateCameraConfig = {
  __typename?: 'AggregateCameraConfig';
  _avg?: Maybe<CameraConfigAvgAggregate>;
  _count?: Maybe<CameraConfigCountAggregate>;
  _max?: Maybe<CameraConfigMaxAggregate>;
  _min?: Maybe<CameraConfigMinAggregate>;
  _sum?: Maybe<CameraConfigSumAggregate>;
};

export type AggregateDomainScene = {
  __typename?: 'AggregateDomainScene';
  _count?: Maybe<DomainSceneCountAggregate>;
  _max?: Maybe<DomainSceneMaxAggregate>;
  _min?: Maybe<DomainSceneMinAggregate>;
};

export type AggregateGraphicsSettings = {
  __typename?: 'AggregateGraphicsSettings';
  _count?: Maybe<GraphicsSettingsCountAggregate>;
  _max?: Maybe<GraphicsSettingsMaxAggregate>;
  _min?: Maybe<GraphicsSettingsMinAggregate>;
};

export type AggregateHideAndSeek = {
  __typename?: 'AggregateHideAndSeek';
  _avg?: Maybe<HideAndSeekAvgAggregate>;
  _count?: Maybe<HideAndSeekCountAggregate>;
  _max?: Maybe<HideAndSeekMaxAggregate>;
  _min?: Maybe<HideAndSeekMinAggregate>;
  _sum?: Maybe<HideAndSeekSumAggregate>;
};

export type AggregateHideAndSeekNpc = {
  __typename?: 'AggregateHideAndSeekNPC';
  _count?: Maybe<HideAndSeekNpcCountAggregate>;
  _max?: Maybe<HideAndSeekNpcMaxAggregate>;
  _min?: Maybe<HideAndSeekNpcMinAggregate>;
};

export type AggregateLightProps = {
  __typename?: 'AggregateLightProps';
  _avg?: Maybe<LightPropsAvgAggregate>;
  _count?: Maybe<LightPropsCountAggregate>;
  _max?: Maybe<LightPropsMaxAggregate>;
  _min?: Maybe<LightPropsMinAggregate>;
  _sum?: Maybe<LightPropsSumAggregate>;
};

export type AggregateLightTemplate = {
  __typename?: 'AggregateLightTemplate';
  _avg?: Maybe<LightTemplateAvgAggregate>;
  _count?: Maybe<LightTemplateCountAggregate>;
  _max?: Maybe<LightTemplateMaxAggregate>;
  _min?: Maybe<LightTemplateMinAggregate>;
  _sum?: Maybe<LightTemplateSumAggregate>;
};

export type AggregateMfesTemplate = {
  __typename?: 'AggregateMFESTemplate';
  _count?: Maybe<MfesTemplateCountAggregate>;
  _max?: Maybe<MfesTemplateMaxAggregate>;
  _min?: Maybe<MfesTemplateMinAggregate>;
};

export type AggregateMfeSceneTemplate = {
  __typename?: 'AggregateMFESceneTemplate';
  _count?: Maybe<MfeSceneTemplateCountAggregate>;
  _max?: Maybe<MfeSceneTemplateMaxAggregate>;
  _min?: Maybe<MfeSceneTemplateMinAggregate>;
};

export type AggregatePostProcessingSettings = {
  __typename?: 'AggregatePostProcessingSettings';
  _avg?: Maybe<PostProcessingSettingsAvgAggregate>;
  _count?: Maybe<PostProcessingSettingsCountAggregate>;
  _max?: Maybe<PostProcessingSettingsMaxAggregate>;
  _min?: Maybe<PostProcessingSettingsMinAggregate>;
  _sum?: Maybe<PostProcessingSettingsSumAggregate>;
};

export type AggregateQuest = {
  __typename?: 'AggregateQuest';
  _avg?: Maybe<QuestAvgAggregate>;
  _count?: Maybe<QuestCountAggregate>;
  _max?: Maybe<QuestMaxAggregate>;
  _min?: Maybe<QuestMinAggregate>;
  _sum?: Maybe<QuestSumAggregate>;
};

export type AggregateSceneTemplate = {
  __typename?: 'AggregateSceneTemplate';
  _count?: Maybe<SceneTemplateCountAggregate>;
  _max?: Maybe<SceneTemplateMaxAggregate>;
  _min?: Maybe<SceneTemplateMinAggregate>;
};

export type AggregateShopifyStore = {
  __typename?: 'AggregateShopifyStore';
  _count?: Maybe<ShopifyStoreCountAggregate>;
  _max?: Maybe<ShopifyStoreMaxAggregate>;
  _min?: Maybe<ShopifyStoreMinAggregate>;
};

export type AggregateSubScenesDomain = {
  __typename?: 'AggregateSubScenesDomain';
  _count?: Maybe<SubScenesDomainCountAggregate>;
  _max?: Maybe<SubScenesDomainMaxAggregate>;
  _min?: Maybe<SubScenesDomainMinAggregate>;
};

export type AggregateSubscriptionPlan = {
  __typename?: 'AggregateSubscriptionPlan';
  _avg?: Maybe<SubscriptionPlanAvgAggregate>;
  _count?: Maybe<SubscriptionPlanCountAggregate>;
  _max?: Maybe<SubscriptionPlanMaxAggregate>;
  _min?: Maybe<SubscriptionPlanMinAggregate>;
  _sum?: Maybe<SubscriptionPlanSumAggregate>;
};

export type AggregateTemplates = {
  __typename?: 'AggregateTemplates';
  _count?: Maybe<TemplatesCountAggregate>;
  _max?: Maybe<TemplatesMaxAggregate>;
  _min?: Maybe<TemplatesMinAggregate>;
};

export type AggregateTourItems = {
  __typename?: 'AggregateTourItems';
  _avg?: Maybe<TourItemsAvgAggregate>;
  _count?: Maybe<TourItemsCountAggregate>;
  _max?: Maybe<TourItemsMaxAggregate>;
  _min?: Maybe<TourItemsMinAggregate>;
  _sum?: Maybe<TourItemsSumAggregate>;
};

export type AggregateUserScene = {
  __typename?: 'AggregateUserScene';
  _avg?: Maybe<UserSceneAvgAggregate>;
  _count?: Maybe<UserSceneCountAggregate>;
  _max?: Maybe<UserSceneMaxAggregate>;
  _min?: Maybe<UserSceneMinAggregate>;
  _sum?: Maybe<UserSceneSumAggregate>;
};

export type AggregateUserSceneAssets = {
  __typename?: 'AggregateUserSceneAssets';
  _avg?: Maybe<UserSceneAssetsAvgAggregate>;
  _count?: Maybe<UserSceneAssetsCountAggregate>;
  _max?: Maybe<UserSceneAssetsMaxAggregate>;
  _min?: Maybe<UserSceneAssetsMinAggregate>;
  _sum?: Maybe<UserSceneAssetsSumAggregate>;
};

export type AggregateUserSubscription = {
  __typename?: 'AggregateUserSubscription';
  _count?: Maybe<UserSubscriptionCountAggregate>;
  _max?: Maybe<UserSubscriptionMaxAggregate>;
  _min?: Maybe<UserSubscriptionMinAggregate>;
};

export type AggregateUsersQuests = {
  __typename?: 'AggregateUsersQuests';
  _count?: Maybe<UsersQuestsCountAggregate>;
  _max?: Maybe<UsersQuestsMaxAggregate>;
  _min?: Maybe<UsersQuestsMinAggregate>;
};

export type Asset = {
  __typename?: 'Asset';
  _count?: Maybe<AssetCount>;
  assetURL: Scalars['String']['output'];
  collectionId: Scalars['String']['output'];
  countCopies: Scalars['Int']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  creatorEmail: Scalars['String']['output'];
  currency: Currency;
  description: Scalars['String']['output'];
  featured?: Maybe<Scalars['Boolean']['output']>;
  fileType: FileType;
  id: Scalars['Int']['output'];
  maxSupply: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  previewImage: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  viewsCount: Scalars['Int']['output'];
};

export type AssetCount = {
  __typename?: 'AssetCount';
  likesUsers: Scalars['Int']['output'];
};


export type AssetCountLikesUsersArgs = {
  where?: InputMaybe<LikeAssetByUserWhereInput>;
};

export type AssetCreateInput = {
  assetURL: Scalars['String']['input'];
  collectionId: Scalars['String']['input'];
  countCopies: Scalars['Float']['input'];
  creatorEmail: Scalars['String']['input'];
  description: Scalars['String']['input'];
  fileType: Scalars['String']['input'];
  id: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  previewImage: Scalars['String']['input'];
  price: Scalars['Float']['input'];
};

export type AssetCreateResponse = {
  __typename?: 'AssetCreateResponse';
  assetCreateStatus: Scalars['Boolean']['output'];
  assetId?: Maybe<Scalars['Float']['output']>;
  categoryKey?: Maybe<Scalars['String']['output']>;
};

export type AssetGetByCollectionInput = {
  assetCurrentName?: InputMaybe<Scalars['String']['input']>;
  assetSearchName?: InputMaybe<Scalars['String']['input']>;
  collectionId: Scalars['String']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
};

export type AssetGetByCollectionPaginationInput = {
  assetCurrentName?: InputMaybe<Scalars['String']['input']>;
  assetSearchName?: InputMaybe<Scalars['String']['input']>;
  collectionId: Scalars['String']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Float']['input'];
  offset: Scalars['Float']['input'];
};

export type AssetGetByCreatorInput = {
  creatorEmail: Scalars['String']['input'];
  filter?: InputMaybe<AssetsByCreatorFilter>;
};

export type AssetInfo = {
  __typename?: 'AssetInfo';
  assetURL: Scalars['String']['output'];
  category: Scalars['String']['output'];
  collection?: Maybe<Scalars['String']['output']>;
  collectionId?: Maybe<Scalars['String']['output']>;
  countCopies: Scalars['Float']['output'];
  creator: Scalars['String']['output'];
  creatorId: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  description: Scalars['String']['output'];
  fileType: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  likesCount: Scalars['Float']['output'];
  maxSupply: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  previewImage: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  viewsCount: Scalars['Float']['output'];
};

export type AssetListRelationFilter = {
  every?: InputMaybe<AssetWhereInput>;
  none?: InputMaybe<AssetWhereInput>;
  some?: InputMaybe<AssetWhereInput>;
};

export type AssetPaginationInput = {
  filter?: InputMaybe<AssetsPaginationFilter>;
  limit: Scalars['Float']['input'];
  offset: Scalars['Float']['input'];
};

export type AssetRelationFilter = {
  is?: InputMaybe<AssetWhereInput>;
  isNot?: InputMaybe<AssetWhereInput>;
};

export type AssetWeb2 = {
  __typename?: 'AssetWeb2';
  _count?: Maybe<AssetWeb2Count>;
  assetURL: Scalars['String']['output'];
  collectionId: Scalars['String']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  creatorEmail: Scalars['String']['output'];
  currency: Currency;
  description: Scalars['String']['output'];
  fileType: FileType;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  previewImage: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  skinId?: Maybe<Scalars['String']['output']>;
  tag: Array<Scalars['String']['output']>;
  viewsCount: Scalars['Int']['output'];
};

export type AssetWeb2BoughtListRelationFilter = {
  every?: InputMaybe<AssetWeb2BoughtWhereInput>;
  none?: InputMaybe<AssetWeb2BoughtWhereInput>;
  some?: InputMaybe<AssetWeb2BoughtWhereInput>;
};

export type AssetWeb2BoughtWhereInput = {
  AND?: InputMaybe<Array<AssetWeb2BoughtWhereInput>>;
  NOT?: InputMaybe<Array<AssetWeb2BoughtWhereInput>>;
  OR?: InputMaybe<Array<AssetWeb2BoughtWhereInput>>;
  asset?: InputMaybe<AssetWeb2RelationFilter>;
  assetId?: InputMaybe<UuidFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  order?: InputMaybe<OrderNullableRelationFilter>;
  orderId?: InputMaybe<UuidNullableFilter>;
  paymentIntentId?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type AssetWeb2Count = {
  __typename?: 'AssetWeb2Count';
  boughtBy: Scalars['Int']['output'];
  likesUsers: Scalars['Int']['output'];
};


export type AssetWeb2CountBoughtByArgs = {
  where?: InputMaybe<AssetWeb2BoughtWhereInput>;
};


export type AssetWeb2CountLikesUsersArgs = {
  where?: InputMaybe<LikeAssetByUserWhereInput>;
};

export type AssetWeb2CreateInput = {
  assetURL: Scalars['String']['input'];
  collectionId: Scalars['String']['input'];
  creatorEmail: Scalars['String']['input'];
  description: Scalars['String']['input'];
  fileType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  previewImage: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  tag?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AssetWeb2CreateResponse = {
  __typename?: 'AssetWeb2CreateResponse';
  assetCreateStatus: Scalars['Boolean']['output'];
  assetId?: Maybe<Scalars['String']['output']>;
  categoryKey?: Maybe<Scalars['String']['output']>;
};

export type AssetWeb2Info = {
  __typename?: 'AssetWeb2Info';
  assetURL: Scalars['String']['output'];
  category: Scalars['String']['output'];
  collection?: Maybe<Scalars['String']['output']>;
  collectionId?: Maybe<Scalars['String']['output']>;
  creator: Scalars['String']['output'];
  creatorId: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  description: Scalars['String']['output'];
  fileType: Scalars['String']['output'];
  id: Scalars['String']['output'];
  likesCount: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  previewImage: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  viewsCount: Scalars['Float']['output'];
};

export type AssetWeb2ListRelationFilter = {
  every?: InputMaybe<AssetWeb2WhereInput>;
  none?: InputMaybe<AssetWeb2WhereInput>;
  some?: InputMaybe<AssetWeb2WhereInput>;
};

export type AssetWeb2RelationFilter = {
  is?: InputMaybe<AssetWeb2WhereInput>;
  isNot?: InputMaybe<AssetWeb2WhereInput>;
};

export type AssetWeb2WhereInput = {
  AND?: InputMaybe<Array<AssetWeb2WhereInput>>;
  NOT?: InputMaybe<Array<AssetWeb2WhereInput>>;
  OR?: InputMaybe<Array<AssetWeb2WhereInput>>;
  assetURL?: InputMaybe<StringFilter>;
  boughtBy?: InputMaybe<AssetWeb2BoughtListRelationFilter>;
  collection?: InputMaybe<CollectionRelationFilter>;
  collectionId?: InputMaybe<UuidFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creator?: InputMaybe<CreatorRelationFilter>;
  creatorEmail?: InputMaybe<StringFilter>;
  currency?: InputMaybe<EnumCurrencyFilter>;
  description?: InputMaybe<StringFilter>;
  fileType?: InputMaybe<EnumFileTypeFilter>;
  id?: InputMaybe<UuidFilter>;
  likesUsers?: InputMaybe<LikeAssetByUserListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  previewImage?: InputMaybe<StringFilter>;
  price?: InputMaybe<FloatFilter>;
  skinId?: InputMaybe<StringNullableFilter>;
  tag?: InputMaybe<StringNullableListFilter>;
  viewsCount?: InputMaybe<IntFilter>;
};

export type AssetWhereInput = {
  AND?: InputMaybe<Array<AssetWhereInput>>;
  NOT?: InputMaybe<Array<AssetWhereInput>>;
  OR?: InputMaybe<Array<AssetWhereInput>>;
  assetURL?: InputMaybe<StringFilter>;
  collection?: InputMaybe<CollectionRelationFilter>;
  collectionId?: InputMaybe<UuidFilter>;
  countCopies?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creator?: InputMaybe<CreatorRelationFilter>;
  creatorEmail?: InputMaybe<StringFilter>;
  currency?: InputMaybe<EnumCurrencyFilter>;
  description?: InputMaybe<StringFilter>;
  featured?: InputMaybe<BoolNullableFilter>;
  featuredRecord?: InputMaybe<FeaturedAssetNullableRelationFilter>;
  fileType?: InputMaybe<EnumFileTypeFilter>;
  id?: InputMaybe<IntFilter>;
  likesUsers?: InputMaybe<LikeAssetByUserListRelationFilter>;
  maxSupply?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  previewImage?: InputMaybe<StringFilter>;
  price?: InputMaybe<FloatFilter>;
  viewsCount?: InputMaybe<IntFilter>;
};

export type AssetWithFeatRecord = {
  __typename?: 'AssetWithFeatRecord';
  _count?: Maybe<AssetCount>;
  assetURL: Scalars['String']['output'];
  collectionId: Scalars['String']['output'];
  countCopies: Scalars['Int']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  creatorEmail: Scalars['String']['output'];
  currency: Currency;
  description: Scalars['String']['output'];
  featured?: Maybe<Scalars['Boolean']['output']>;
  featuredRecord?: Maybe<FeaturedAsset>;
  fileType: FileType;
  id: Scalars['Int']['output'];
  maxSupply: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  previewImage: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  viewsCount: Scalars['Int']['output'];
};

export type AssetsByCreator = {
  __typename?: 'AssetsByCreator';
  assets?: Maybe<Array<Asset>>;
  collections?: Maybe<Array<TCollection>>;
};

export type AssetsByCreatorFilter = {
  assetName: Scalars['String']['input'];
  categoryKey: Scalars['String']['input'];
  collectionName: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type AssetsPagination = {
  __typename?: 'AssetsPagination';
  assets?: Maybe<Array<Asset>>;
  totalCount: Scalars['Float']['output'];
};

export type AssetsPaginationFilter = {
  categoryKey?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<PriceFilter>;
};

export type AssetsSearch = {
  __typename?: 'AssetsSearch';
  assets?: Maybe<Array<Asset>>;
};

export type BatchUploadInput = {
  adminEmail: Scalars['String']['input'];
  filePath: Scalars['String']['input'];
};

export type BatchUploadResponse = {
  __typename?: 'BatchUploadResponse';
  status: Scalars['String']['output'];
};

export type BeCreator = {
  __typename?: 'BeCreator';
  beCreatorStatus: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['String']['output']>;
};

export type BeCreatorInput = {
  alias: Scalars['String']['input'];
  bio: Scalars['String']['input'];
  creatorWalletType: Scalars['String']['input'];
  email: Scalars['String']['input'];
  id: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  walletId: Scalars['String']['input'];
};

export enum BnbPaymentStatus {
  Fulfilled = 'FULFILLED',
  Pending = 'PENDING'
}

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type BoolNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedBoolNullableFilter>;
  _min?: InputMaybe<NestedBoolNullableFilter>;
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolNullableWithAggregatesFilter>;
};

export type BoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export enum Brand {
  Studio = 'STUDIO',
  Zepeto = 'ZEPETO'
}

export type BulkEmailAddResponse = {
  __typename?: 'BulkEmailAddResponse';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type BulkSendInput = {
  recipients: Scalars['String']['input'];
  subject: Scalars['String']['input'];
  templateId: Scalars['String']['input'];
};

export type BuyAssetWeb2FcInput = {
  assetId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type BuyAssetWeb2FcResponse = {
  __typename?: 'BuyAssetWeb2FCResponse';
  message?: Maybe<Scalars['String']['output']>;
  status: Scalars['Boolean']['output'];
};

export type BuyAssetWeb2Input = {
  assetId: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
  paymentId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type BuyAssetWeb2Response = {
  __typename?: 'BuyAssetWeb2Response';
  message?: Maybe<Scalars['String']['output']>;
  status: Scalars['Boolean']['output'];
};

export type CameraConfig = {
  __typename?: 'CameraConfig';
  _count?: Maybe<CameraConfigCount>;
  far: Scalars['Float']['output'];
  fov: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  maxSpeed: Scalars['Float']['output'];
  minSpeed: Scalars['Float']['output'];
  near: Scalars['Float']['output'];
};

export type CameraConfigAvgAggregate = {
  __typename?: 'CameraConfigAvgAggregate';
  far?: Maybe<Scalars['Float']['output']>;
  fov?: Maybe<Scalars['Float']['output']>;
  maxSpeed?: Maybe<Scalars['Float']['output']>;
  minSpeed?: Maybe<Scalars['Float']['output']>;
  near?: Maybe<Scalars['Float']['output']>;
};

export type CameraConfigAvgOrderByAggregateInput = {
  far?: InputMaybe<SortOrder>;
  fov?: InputMaybe<SortOrder>;
  maxSpeed?: InputMaybe<SortOrder>;
  minSpeed?: InputMaybe<SortOrder>;
  near?: InputMaybe<SortOrder>;
};

export type CameraConfigCount = {
  __typename?: 'CameraConfigCount';
  UserScene: Scalars['Int']['output'];
};


export type CameraConfigCountUserSceneArgs = {
  where?: InputMaybe<UserSceneWhereInput>;
};

export type CameraConfigCountAggregate = {
  __typename?: 'CameraConfigCountAggregate';
  _all: Scalars['Int']['output'];
  far: Scalars['Int']['output'];
  fov: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  maxSpeed: Scalars['Int']['output'];
  minSpeed: Scalars['Int']['output'];
  near: Scalars['Int']['output'];
};

export type CameraConfigCountOrderByAggregateInput = {
  far?: InputMaybe<SortOrder>;
  fov?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  maxSpeed?: InputMaybe<SortOrder>;
  minSpeed?: InputMaybe<SortOrder>;
  near?: InputMaybe<SortOrder>;
};

export type CameraConfigCreateInput = {
  UserScene?: InputMaybe<UserSceneCreateNestedManyWithoutCameraConfigInput>;
  far?: InputMaybe<Scalars['Float']['input']>;
  fov?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxSpeed?: InputMaybe<Scalars['Float']['input']>;
  minSpeed?: InputMaybe<Scalars['Float']['input']>;
  near?: InputMaybe<Scalars['Float']['input']>;
};

export type CameraConfigCreateManyInput = {
  far?: InputMaybe<Scalars['Float']['input']>;
  fov?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxSpeed?: InputMaybe<Scalars['Float']['input']>;
  minSpeed?: InputMaybe<Scalars['Float']['input']>;
  near?: InputMaybe<Scalars['Float']['input']>;
};

export type CameraConfigCreateNestedOneWithoutUserSceneInput = {
  connect?: InputMaybe<CameraConfigWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CameraConfigCreateOrConnectWithoutUserSceneInput>;
  create?: InputMaybe<CameraConfigCreateWithoutUserSceneInput>;
};

export type CameraConfigCreateOrConnectWithoutUserSceneInput = {
  create: CameraConfigCreateWithoutUserSceneInput;
  where: CameraConfigWhereUniqueInput;
};

export type CameraConfigCreateWithoutUserSceneInput = {
  far?: InputMaybe<Scalars['Float']['input']>;
  fov?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxSpeed?: InputMaybe<Scalars['Float']['input']>;
  minSpeed?: InputMaybe<Scalars['Float']['input']>;
  near?: InputMaybe<Scalars['Float']['input']>;
};

export type CameraConfigGroupBy = {
  __typename?: 'CameraConfigGroupBy';
  _avg?: Maybe<CameraConfigAvgAggregate>;
  _count?: Maybe<CameraConfigCountAggregate>;
  _max?: Maybe<CameraConfigMaxAggregate>;
  _min?: Maybe<CameraConfigMinAggregate>;
  _sum?: Maybe<CameraConfigSumAggregate>;
  far: Scalars['Float']['output'];
  fov: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  maxSpeed: Scalars['Float']['output'];
  minSpeed: Scalars['Float']['output'];
  near: Scalars['Float']['output'];
};

export type CameraConfigMaxAggregate = {
  __typename?: 'CameraConfigMaxAggregate';
  far?: Maybe<Scalars['Float']['output']>;
  fov?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  maxSpeed?: Maybe<Scalars['Float']['output']>;
  minSpeed?: Maybe<Scalars['Float']['output']>;
  near?: Maybe<Scalars['Float']['output']>;
};

export type CameraConfigMaxOrderByAggregateInput = {
  far?: InputMaybe<SortOrder>;
  fov?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  maxSpeed?: InputMaybe<SortOrder>;
  minSpeed?: InputMaybe<SortOrder>;
  near?: InputMaybe<SortOrder>;
};

export type CameraConfigMinAggregate = {
  __typename?: 'CameraConfigMinAggregate';
  far?: Maybe<Scalars['Float']['output']>;
  fov?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  maxSpeed?: Maybe<Scalars['Float']['output']>;
  minSpeed?: Maybe<Scalars['Float']['output']>;
  near?: Maybe<Scalars['Float']['output']>;
};

export type CameraConfigMinOrderByAggregateInput = {
  far?: InputMaybe<SortOrder>;
  fov?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  maxSpeed?: InputMaybe<SortOrder>;
  minSpeed?: InputMaybe<SortOrder>;
  near?: InputMaybe<SortOrder>;
};

export type CameraConfigNullableRelationFilter = {
  is?: InputMaybe<CameraConfigWhereInput>;
  isNot?: InputMaybe<CameraConfigWhereInput>;
};

export type CameraConfigOrderByWithAggregationInput = {
  _avg?: InputMaybe<CameraConfigAvgOrderByAggregateInput>;
  _count?: InputMaybe<CameraConfigCountOrderByAggregateInput>;
  _max?: InputMaybe<CameraConfigMaxOrderByAggregateInput>;
  _min?: InputMaybe<CameraConfigMinOrderByAggregateInput>;
  _sum?: InputMaybe<CameraConfigSumOrderByAggregateInput>;
  far?: InputMaybe<SortOrder>;
  fov?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  maxSpeed?: InputMaybe<SortOrder>;
  minSpeed?: InputMaybe<SortOrder>;
  near?: InputMaybe<SortOrder>;
};

export type CameraConfigOrderByWithRelationInput = {
  UserScene?: InputMaybe<UserSceneOrderByRelationAggregateInput>;
  far?: InputMaybe<SortOrder>;
  fov?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  maxSpeed?: InputMaybe<SortOrder>;
  minSpeed?: InputMaybe<SortOrder>;
  near?: InputMaybe<SortOrder>;
};

export enum CameraConfigScalarFieldEnum {
  Far = 'far',
  Fov = 'fov',
  Id = 'id',
  MaxSpeed = 'maxSpeed',
  MinSpeed = 'minSpeed',
  Near = 'near'
}

export type CameraConfigScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CameraConfigScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CameraConfigScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CameraConfigScalarWhereWithAggregatesInput>>;
  far?: InputMaybe<FloatWithAggregatesFilter>;
  fov?: InputMaybe<FloatWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  maxSpeed?: InputMaybe<FloatWithAggregatesFilter>;
  minSpeed?: InputMaybe<FloatWithAggregatesFilter>;
  near?: InputMaybe<FloatWithAggregatesFilter>;
};

export type CameraConfigSumAggregate = {
  __typename?: 'CameraConfigSumAggregate';
  far?: Maybe<Scalars['Float']['output']>;
  fov?: Maybe<Scalars['Float']['output']>;
  maxSpeed?: Maybe<Scalars['Float']['output']>;
  minSpeed?: Maybe<Scalars['Float']['output']>;
  near?: Maybe<Scalars['Float']['output']>;
};

export type CameraConfigSumOrderByAggregateInput = {
  far?: InputMaybe<SortOrder>;
  fov?: InputMaybe<SortOrder>;
  maxSpeed?: InputMaybe<SortOrder>;
  minSpeed?: InputMaybe<SortOrder>;
  near?: InputMaybe<SortOrder>;
};

export type CameraConfigUpdateInput = {
  UserScene?: InputMaybe<UserSceneUpdateManyWithoutCameraConfigNestedInput>;
  far?: InputMaybe<FloatFieldUpdateOperationsInput>;
  fov?: InputMaybe<FloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  maxSpeed?: InputMaybe<FloatFieldUpdateOperationsInput>;
  minSpeed?: InputMaybe<FloatFieldUpdateOperationsInput>;
  near?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type CameraConfigUpdateManyMutationInput = {
  far?: InputMaybe<FloatFieldUpdateOperationsInput>;
  fov?: InputMaybe<FloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  maxSpeed?: InputMaybe<FloatFieldUpdateOperationsInput>;
  minSpeed?: InputMaybe<FloatFieldUpdateOperationsInput>;
  near?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type CameraConfigUpdateOneWithoutUserSceneNestedInput = {
  connect?: InputMaybe<CameraConfigWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CameraConfigCreateOrConnectWithoutUserSceneInput>;
  create?: InputMaybe<CameraConfigCreateWithoutUserSceneInput>;
  delete?: InputMaybe<CameraConfigWhereInput>;
  disconnect?: InputMaybe<CameraConfigWhereInput>;
  update?: InputMaybe<CameraConfigUpdateToOneWithWhereWithoutUserSceneInput>;
  upsert?: InputMaybe<CameraConfigUpsertWithoutUserSceneInput>;
};

export type CameraConfigUpdateToOneWithWhereWithoutUserSceneInput = {
  data: CameraConfigUpdateWithoutUserSceneInput;
  where?: InputMaybe<CameraConfigWhereInput>;
};

export type CameraConfigUpdateWithoutUserSceneInput = {
  far?: InputMaybe<FloatFieldUpdateOperationsInput>;
  fov?: InputMaybe<FloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  maxSpeed?: InputMaybe<FloatFieldUpdateOperationsInput>;
  minSpeed?: InputMaybe<FloatFieldUpdateOperationsInput>;
  near?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type CameraConfigUpsertWithoutUserSceneInput = {
  create: CameraConfigCreateWithoutUserSceneInput;
  update: CameraConfigUpdateWithoutUserSceneInput;
  where?: InputMaybe<CameraConfigWhereInput>;
};

export type CameraConfigWhereInput = {
  AND?: InputMaybe<Array<CameraConfigWhereInput>>;
  NOT?: InputMaybe<Array<CameraConfigWhereInput>>;
  OR?: InputMaybe<Array<CameraConfigWhereInput>>;
  UserScene?: InputMaybe<UserSceneListRelationFilter>;
  far?: InputMaybe<FloatFilter>;
  fov?: InputMaybe<FloatFilter>;
  id?: InputMaybe<UuidFilter>;
  maxSpeed?: InputMaybe<FloatFilter>;
  minSpeed?: InputMaybe<FloatFilter>;
  near?: InputMaybe<FloatFilter>;
};

export type CameraConfigWhereUniqueInput = {
  AND?: InputMaybe<Array<CameraConfigWhereInput>>;
  NOT?: InputMaybe<Array<CameraConfigWhereInput>>;
  OR?: InputMaybe<Array<CameraConfigWhereInput>>;
  UserScene?: InputMaybe<UserSceneListRelationFilter>;
  far?: InputMaybe<FloatFilter>;
  fov?: InputMaybe<FloatFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxSpeed?: InputMaybe<FloatFilter>;
  minSpeed?: InputMaybe<FloatFilter>;
  near?: InputMaybe<FloatFilter>;
};

export type CategoriesSearch = {
  __typename?: 'CategoriesSearch';
  Collection?: Maybe<Array<Collection>>;
  bannerURL: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  imageURL: Scalars['String']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Category = {
  __typename?: 'Category';
  _count?: Maybe<CategoryCount>;
  bannerURL?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  imageURL?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CategoryCount = {
  __typename?: 'CategoryCount';
  Collection: Scalars['Int']['output'];
};


export type CategoryCountCollectionArgs = {
  where?: InputMaybe<CollectionWhereInput>;
};

export type CategoryCreateInput = {
  id: Scalars['Float']['input'];
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CategoryCreateResponse = {
  __typename?: 'CategoryCreateResponse';
  categoryCreateStatus: Scalars['Boolean']['output'];
};

export type CategoryRelationFilter = {
  is?: InputMaybe<CategoryWhereInput>;
  isNot?: InputMaybe<CategoryWhereInput>;
};

export type CategoryWhereInput = {
  AND?: InputMaybe<Array<CategoryWhereInput>>;
  Collection?: InputMaybe<CollectionListRelationFilter>;
  NOT?: InputMaybe<Array<CategoryWhereInput>>;
  OR?: InputMaybe<Array<CategoryWhereInput>>;
  bannerURL?: InputMaybe<StringNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  imageURL?: InputMaybe<StringNullableFilter>;
  key?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
};

export type ChangeAssetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  previewImage?: InputMaybe<Scalars['String']['input']>;
};

export type ChangeAssetResponse = {
  __typename?: 'ChangeAssetResponse';
  status: Scalars['Boolean']['output'];
};

export type ChangeOrderFeatAssetRes = {
  __typename?: 'ChangeOrderFeatAssetRes';
  status: Scalars['Boolean']['output'];
};

export type ChangeOrderFeatAssetResInput = {
  selectedId: Scalars['Float']['input'];
  targetId: Scalars['Float']['input'];
};

export type ChangePriceInput = {
  assetId: Scalars['Float']['input'];
  price: Scalars['Float']['input'];
};

export type ChangePriceResponse = {
  __typename?: 'ChangePriceResponse';
  status: Scalars['Boolean']['output'];
};

export type ChangeUserPassword = {
  __typename?: 'ChangeUserPassword';
  changePassword: Scalars['Boolean']['output'];
};

export type ChangeUserPasswordInput = {
  changePasswordToken: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type ChangeUsernameInput = {
  userId: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type ChangeUsernameResponse = {
  __typename?: 'ChangeUsernameResponse';
  message?: Maybe<Scalars['String']['output']>;
  status: Scalars['Boolean']['output'];
};

export type ClearCartInput = {
  userEmail: Scalars['String']['input'];
};

export type ClearCartResponse = {
  __typename?: 'ClearCartResponse';
  message: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
};

export type Collection = {
  __typename?: 'Collection';
  _count?: Maybe<CollectionCount>;
  bannerImageURL: Scalars['String']['output'];
  categoryKey: Scalars['String']['output'];
  creatorEmail: Scalars['String']['output'];
  description: Scalars['String']['output'];
  featureImageURL: Scalars['String']['output'];
  featured?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  logoImageURL: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CollectionCount = {
  __typename?: 'CollectionCount';
  assets: Scalars['Int']['output'];
  assetsWeb2: Scalars['Int']['output'];
};


export type CollectionCountAssetsArgs = {
  where?: InputMaybe<AssetWhereInput>;
};


export type CollectionCountAssetsWeb2Args = {
  where?: InputMaybe<AssetWeb2WhereInput>;
};

export type CollectionCreateInput = {
  bannerImageUrl: Scalars['String']['input'];
  categoryKey: Scalars['String']['input'];
  creatorEmail: Scalars['String']['input'];
  description: Scalars['String']['input'];
  featureImageUrl: Scalars['String']['input'];
  logoImageUrl: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CollectionCreateResponse = {
  __typename?: 'CollectionCreateResponse';
  message: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
};

export type CollectionInfo = {
  __typename?: 'CollectionInfo';
  bannerImageURL: Scalars['String']['output'];
  categoryKey: Scalars['String']['output'];
  categoryName: Scalars['String']['output'];
  creatorAlias: Scalars['String']['output'];
  creatorEmail: Scalars['String']['output'];
  creatorId: Scalars['String']['output'];
  description: Scalars['String']['output'];
  featureImageURL: Scalars['String']['output'];
  id: Scalars['String']['output'];
  logoImageURL: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CollectionListRelationFilter = {
  every?: InputMaybe<CollectionWhereInput>;
  none?: InputMaybe<CollectionWhereInput>;
  some?: InputMaybe<CollectionWhereInput>;
};

export type CollectionRelationFilter = {
  is?: InputMaybe<CollectionWhereInput>;
  isNot?: InputMaybe<CollectionWhereInput>;
};

export type CollectionSearch = {
  __typename?: 'CollectionSearch';
  collections?: Maybe<Array<Collection>>;
};

export type CollectionWhereInput = {
  AND?: InputMaybe<Array<CollectionWhereInput>>;
  NOT?: InputMaybe<Array<CollectionWhereInput>>;
  OR?: InputMaybe<Array<CollectionWhereInput>>;
  assets?: InputMaybe<AssetListRelationFilter>;
  assetsWeb2?: InputMaybe<AssetWeb2ListRelationFilter>;
  bannerImageURL?: InputMaybe<StringFilter>;
  category?: InputMaybe<CategoryRelationFilter>;
  categoryKey?: InputMaybe<StringFilter>;
  creator?: InputMaybe<CreatorRelationFilter>;
  creatorEmail?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringFilter>;
  featureImageURL?: InputMaybe<StringFilter>;
  featured?: InputMaybe<BoolNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  logoImageURL?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
};

export type ConvertFlowToOzoneInput = {
  price: Scalars['Float']['input'];
};

export type ConvertFlowToOzoneResponse = {
  __typename?: 'ConvertFlowToOzoneResponse';
  convertedPrice?: Maybe<Scalars['Float']['output']>;
  status: Scalars['Boolean']['output'];
};

export type ConvertOzoneToFcInput = {
  price: Scalars['Float']['input'];
};

export type ConvertOzoneToFcResponse = {
  __typename?: 'ConvertOzoneToFCResponse';
  convertedPrice?: Maybe<Scalars['Float']['output']>;
  status: Scalars['Boolean']['output'];
};

export type ConvertTokenToDollarInput = {
  price: Scalars['Float']['input'];
  token: Currency;
};

export type ConvertTokenToDollarResponse = {
  __typename?: 'ConvertTokenToDollarResponse';
  convertedPrice?: Maybe<Scalars['Float']['output']>;
  status: Scalars['Boolean']['output'];
};

export type CreateCartInput = {
  userEmail: Scalars['String']['input'];
};

export type CreateCartResponse = {
  __typename?: 'CreateCartResponse';
  message: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
};

export type CreateDomainSceneInput = {
  domain: Scalars['String']['input'];
  sceneId: Scalars['String']['input'];
};

export type CreateDomainSceneResponse = {
  __typename?: 'CreateDomainSceneResponse';
  status: Scalars['String']['output'];
};

export type CreateDomainSubSceneInput = {
  domain: Scalars['String']['input'];
  route: Scalars['String']['input'];
  sceneId: Scalars['String']['input'];
};

export type CreateDomainSubSceneResponse = {
  __typename?: 'CreateDomainSubSceneResponse';
  status: Scalars['String']['output'];
};

export type CreateMatchInput = {
  player1: Scalars['String']['input'];
  player2: Scalars['String']['input'];
  winner?: InputMaybe<Scalars['String']['input']>;
};

export type CreateMatchResponse = {
  __typename?: 'CreateMatchResponse';
  matchId?: Maybe<Scalars['String']['output']>;
  status: Scalars['Boolean']['output'];
};

export type CreateShopifyStoreInput = {
  apiVersion: Scalars['String']['input'];
  domain: Scalars['String']['input'];
  sceneId: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type CreateShopifyStoreResponse = {
  __typename?: 'CreateShopifyStoreResponse';
  status: Scalars['String']['output'];
};

export type CreateUserProfileInput = {
  userId: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type CreateUserProfileResponse = {
  __typename?: 'CreateUserProfileResponse';
  message?: Maybe<Scalars['String']['output']>;
  status: Scalars['Boolean']['output'];
};

export type Creator = {
  __typename?: 'Creator';
  _count?: Maybe<CreatorCount>;
  alias?: Maybe<Scalars['String']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  bnbWallet?: Maybe<Scalars['String']['output']>;
  creatorWalletType: CreatorWalletType;
  email: Scalars['String']['output'];
  featured?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  isSetupedOzoneToken?: Maybe<Scalars['Boolean']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  walletId?: Maybe<Scalars['String']['output']>;
};

export type CreatorByWalletIdInput = {
  walletId: Scalars['String']['input'];
};

export type CreatorByWalletIdResponse = {
  __typename?: 'CreatorByWalletIdResponse';
  alias?: Maybe<Scalars['String']['output']>;
  creatorId?: Maybe<Scalars['String']['output']>;
};

export type CreatorC = {
  __typename?: 'CreatorC';
  alias?: Maybe<Scalars['String']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  createdAssets?: Maybe<Array<Asset>>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  walletId?: Maybe<Scalars['String']['output']>;
};

export type CreatorCount = {
  __typename?: 'CreatorCount';
  collections: Scalars['Int']['output'];
  createdAssets: Scalars['Int']['output'];
  createdAssetsWeb2: Scalars['Int']['output'];
};


export type CreatorCountCollectionsArgs = {
  where?: InputMaybe<CollectionWhereInput>;
};


export type CreatorCountCreatedAssetsArgs = {
  where?: InputMaybe<AssetWhereInput>;
};


export type CreatorCountCreatedAssetsWeb2Args = {
  where?: InputMaybe<AssetWeb2WhereInput>;
};

export type CreatorF = {
  __typename?: 'CreatorF';
  alias?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
};

export type CreatorInfo = {
  __typename?: 'CreatorInfo';
  creator?: Maybe<CreatorInfoObject>;
};

export type CreatorInfoObject = {
  __typename?: 'CreatorInfoObject';
  alias?: Maybe<Scalars['String']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  creatorWalletType?: Maybe<CreatorWalletType>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isSetupedOzoneToken?: Maybe<Scalars['Boolean']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  walletId?: Maybe<Scalars['String']['output']>;
};

export type CreatorRelationFilter = {
  is?: InputMaybe<CreatorWhereInput>;
  isNot?: InputMaybe<CreatorWhereInput>;
};

export type CreatorSearch = {
  __typename?: 'CreatorSearch';
  creators?: Maybe<Array<Creator>>;
};

export type CreatorT = {
  __typename?: 'CreatorT';
  alias?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

export enum CreatorWalletType {
  Blocto = 'BLOCTO',
  Dapper = 'DAPPER'
}

export type CreatorWhereInput = {
  AND?: InputMaybe<Array<CreatorWhereInput>>;
  NOT?: InputMaybe<Array<CreatorWhereInput>>;
  OR?: InputMaybe<Array<CreatorWhereInput>>;
  alias?: InputMaybe<StringNullableFilter>;
  bio?: InputMaybe<StringNullableFilter>;
  bnbWallet?: InputMaybe<StringNullableFilter>;
  collections?: InputMaybe<CollectionListRelationFilter>;
  createdAssets?: InputMaybe<AssetListRelationFilter>;
  createdAssetsWeb2?: InputMaybe<AssetWeb2ListRelationFilter>;
  creatorWalletType?: InputMaybe<EnumCreatorWalletTypeFilter>;
  email?: InputMaybe<StringFilter>;
  featured?: InputMaybe<BoolNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  isSetupedOzoneToken?: InputMaybe<BoolNullableFilter>;
  lastname?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  walletId?: InputMaybe<StringNullableFilter>;
};

export type CreatorWhereUniqueInput = {
  AND?: InputMaybe<Array<CreatorWhereInput>>;
  NOT?: InputMaybe<Array<CreatorWhereInput>>;
  OR?: InputMaybe<Array<CreatorWhereInput>>;
  alias?: InputMaybe<StringNullableFilter>;
  bio?: InputMaybe<StringNullableFilter>;
  bnbWallet?: InputMaybe<StringNullableFilter>;
  collections?: InputMaybe<CollectionListRelationFilter>;
  createdAssets?: InputMaybe<AssetListRelationFilter>;
  createdAssetsWeb2?: InputMaybe<AssetWeb2ListRelationFilter>;
  creatorWalletType?: InputMaybe<EnumCreatorWalletTypeFilter>;
  email?: InputMaybe<Scalars['String']['input']>;
  featured?: InputMaybe<BoolNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isSetupedOzoneToken?: InputMaybe<BoolNullableFilter>;
  lastname?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  walletId?: InputMaybe<StringNullableFilter>;
};

export enum Currency {
  Fc = 'FC',
  Flow = 'FLOW',
  Ozone = 'OZONE',
  Usd = 'USD'
}

export type CurrencyPrice = {
  __typename?: 'CurrencyPrice';
  currency: Currency;
  id: Scalars['String']['output'];
  name: Currency;
  price: Scalars['Float']['output'];
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type DateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type DeleteAssetInput = {
  assetId: Scalars['Float']['input'];
};

export type DeleteAssetResponse = {
  __typename?: 'DeleteAssetResponse';
  status: Scalars['Boolean']['output'];
};

export type DeleteCollectionInput = {
  id: Scalars['String']['input'];
};

export type DeleteCollectionResponse = {
  __typename?: 'DeleteCollectionResponse';
  message?: Maybe<Scalars['String']['output']>;
  status: Scalars['Boolean']['output'];
};

export type DeleteDomainSceneInput = {
  id: Scalars['String']['input'];
};

export type DeleteDomainSceneResponse = {
  __typename?: 'DeleteDomainSceneResponse';
  status: Scalars['String']['output'];
};

export type DeleteDomainSubSceneInput = {
  id: Scalars['String']['input'];
};

export type DeleteDomainSubSceneResponse = {
  __typename?: 'DeleteDomainSubSceneResponse';
  status: Scalars['String']['output'];
};

export type DepositFcInput = {
  amount: Scalars['Float']['input'];
  userId: Scalars['String']['input'];
};

export type DepositFcResponse = {
  __typename?: 'DepositFCResponse';
  message?: Maybe<Scalars['String']['output']>;
  status: Scalars['Boolean']['output'];
};

export type DistributeTokensToWinnerInput = {
  matchId: Scalars['String']['input'];
};

export type DistributeTokensToWinnerResponse = {
  __typename?: 'DistributeTokensToWinnerResponse';
  message?: Maybe<Scalars['String']['output']>;
  status: Scalars['Boolean']['output'];
};

export type DomainScene = {
  __typename?: 'DomainScene';
  _count?: Maybe<DomainSceneCount>;
  domain: Scalars['String']['output'];
  id: Scalars['String']['output'];
  sceneId: Scalars['String']['output'];
};

export type DomainSceneCount = {
  __typename?: 'DomainSceneCount';
  subScenes: Scalars['Int']['output'];
};


export type DomainSceneCountSubScenesArgs = {
  where?: InputMaybe<SubScenesDomainWhereInput>;
};

export type DomainSceneCountAggregate = {
  __typename?: 'DomainSceneCountAggregate';
  _all: Scalars['Int']['output'];
  domain: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  sceneId: Scalars['Int']['output'];
};

export type DomainSceneCountOrderByAggregateInput = {
  domain?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  sceneId?: InputMaybe<SortOrder>;
};

export type DomainSceneCreateInput = {
  domain: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  scene?: InputMaybe<UserSceneCreateNestedOneWithoutDomainInput>;
  subScenes?: InputMaybe<SubScenesDomainCreateNestedManyWithoutDomainRecInput>;
};

export type DomainSceneCreateManyInput = {
  domain: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  sceneId: Scalars['String']['input'];
};

export type DomainSceneCreateManySceneInput = {
  domain: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
};

export type DomainSceneCreateManySceneInputEnvelope = {
  data: Array<DomainSceneCreateManySceneInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DomainSceneCreateNestedManyWithoutSceneInput = {
  connect?: InputMaybe<Array<DomainSceneWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DomainSceneCreateOrConnectWithoutSceneInput>>;
  create?: InputMaybe<Array<DomainSceneCreateWithoutSceneInput>>;
  createMany?: InputMaybe<DomainSceneCreateManySceneInputEnvelope>;
};

export type DomainSceneCreateNestedOneWithoutSubScenesInput = {
  connect?: InputMaybe<DomainSceneWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DomainSceneCreateOrConnectWithoutSubScenesInput>;
  create?: InputMaybe<DomainSceneCreateWithoutSubScenesInput>;
};

export type DomainSceneCreateOrConnectWithoutSceneInput = {
  create: DomainSceneCreateWithoutSceneInput;
  where: DomainSceneWhereUniqueInput;
};

export type DomainSceneCreateOrConnectWithoutSubScenesInput = {
  create: DomainSceneCreateWithoutSubScenesInput;
  where: DomainSceneWhereUniqueInput;
};

export type DomainSceneCreateWithoutSceneInput = {
  domain: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  subScenes?: InputMaybe<SubScenesDomainCreateNestedManyWithoutDomainRecInput>;
};

export type DomainSceneCreateWithoutSubScenesInput = {
  domain: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  scene?: InputMaybe<UserSceneCreateNestedOneWithoutDomainInput>;
};

export type DomainSceneGroupBy = {
  __typename?: 'DomainSceneGroupBy';
  _count?: Maybe<DomainSceneCountAggregate>;
  _max?: Maybe<DomainSceneMaxAggregate>;
  _min?: Maybe<DomainSceneMinAggregate>;
  domain: Scalars['String']['output'];
  id: Scalars['String']['output'];
  sceneId: Scalars['String']['output'];
};

export type DomainSceneListRelationFilter = {
  every?: InputMaybe<DomainSceneWhereInput>;
  none?: InputMaybe<DomainSceneWhereInput>;
  some?: InputMaybe<DomainSceneWhereInput>;
};

export type DomainSceneMaxAggregate = {
  __typename?: 'DomainSceneMaxAggregate';
  domain?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  sceneId?: Maybe<Scalars['String']['output']>;
};

export type DomainSceneMaxOrderByAggregateInput = {
  domain?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  sceneId?: InputMaybe<SortOrder>;
};

export type DomainSceneMinAggregate = {
  __typename?: 'DomainSceneMinAggregate';
  domain?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  sceneId?: Maybe<Scalars['String']['output']>;
};

export type DomainSceneMinOrderByAggregateInput = {
  domain?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  sceneId?: InputMaybe<SortOrder>;
};

export type DomainSceneNullableRelationFilter = {
  is?: InputMaybe<DomainSceneWhereInput>;
  isNot?: InputMaybe<DomainSceneWhereInput>;
};

export type DomainSceneOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type DomainSceneOrderByWithAggregationInput = {
  _count?: InputMaybe<DomainSceneCountOrderByAggregateInput>;
  _max?: InputMaybe<DomainSceneMaxOrderByAggregateInput>;
  _min?: InputMaybe<DomainSceneMinOrderByAggregateInput>;
  domain?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  sceneId?: InputMaybe<SortOrder>;
};

export type DomainSceneOrderByWithRelationInput = {
  domain?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  scene?: InputMaybe<UserSceneOrderByWithRelationInput>;
  sceneId?: InputMaybe<SortOrder>;
  subScenes?: InputMaybe<SubScenesDomainOrderByRelationAggregateInput>;
};

export enum DomainSceneScalarFieldEnum {
  Domain = 'domain',
  Id = 'id',
  SceneId = 'sceneId'
}

export type DomainSceneScalarWhereInput = {
  AND?: InputMaybe<Array<DomainSceneScalarWhereInput>>;
  NOT?: InputMaybe<Array<DomainSceneScalarWhereInput>>;
  OR?: InputMaybe<Array<DomainSceneScalarWhereInput>>;
  domain?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  sceneId?: InputMaybe<UuidFilter>;
};

export type DomainSceneScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<DomainSceneScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<DomainSceneScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<DomainSceneScalarWhereWithAggregatesInput>>;
  domain?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  sceneId?: InputMaybe<UuidWithAggregatesFilter>;
};

export type DomainSceneUpdateInput = {
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  scene?: InputMaybe<UserSceneUpdateOneWithoutDomainNestedInput>;
  subScenes?: InputMaybe<SubScenesDomainUpdateManyWithoutDomainRecNestedInput>;
};

export type DomainSceneUpdateManyMutationInput = {
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DomainSceneUpdateManyWithWhereWithoutSceneInput = {
  data: DomainSceneUpdateManyMutationInput;
  where: DomainSceneScalarWhereInput;
};

export type DomainSceneUpdateManyWithoutSceneNestedInput = {
  connect?: InputMaybe<Array<DomainSceneWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DomainSceneCreateOrConnectWithoutSceneInput>>;
  create?: InputMaybe<Array<DomainSceneCreateWithoutSceneInput>>;
  createMany?: InputMaybe<DomainSceneCreateManySceneInputEnvelope>;
  delete?: InputMaybe<Array<DomainSceneWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DomainSceneScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DomainSceneWhereUniqueInput>>;
  set?: InputMaybe<Array<DomainSceneWhereUniqueInput>>;
  update?: InputMaybe<Array<DomainSceneUpdateWithWhereUniqueWithoutSceneInput>>;
  updateMany?: InputMaybe<Array<DomainSceneUpdateManyWithWhereWithoutSceneInput>>;
  upsert?: InputMaybe<Array<DomainSceneUpsertWithWhereUniqueWithoutSceneInput>>;
};

export type DomainSceneUpdateOneWithoutSubScenesNestedInput = {
  connect?: InputMaybe<DomainSceneWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DomainSceneCreateOrConnectWithoutSubScenesInput>;
  create?: InputMaybe<DomainSceneCreateWithoutSubScenesInput>;
  delete?: InputMaybe<DomainSceneWhereInput>;
  disconnect?: InputMaybe<DomainSceneWhereInput>;
  update?: InputMaybe<DomainSceneUpdateToOneWithWhereWithoutSubScenesInput>;
  upsert?: InputMaybe<DomainSceneUpsertWithoutSubScenesInput>;
};

export type DomainSceneUpdateToOneWithWhereWithoutSubScenesInput = {
  data: DomainSceneUpdateWithoutSubScenesInput;
  where?: InputMaybe<DomainSceneWhereInput>;
};

export type DomainSceneUpdateWithWhereUniqueWithoutSceneInput = {
  data: DomainSceneUpdateWithoutSceneInput;
  where: DomainSceneWhereUniqueInput;
};

export type DomainSceneUpdateWithoutSceneInput = {
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  subScenes?: InputMaybe<SubScenesDomainUpdateManyWithoutDomainRecNestedInput>;
};

export type DomainSceneUpdateWithoutSubScenesInput = {
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  scene?: InputMaybe<UserSceneUpdateOneWithoutDomainNestedInput>;
};

export type DomainSceneUpsertWithWhereUniqueWithoutSceneInput = {
  create: DomainSceneCreateWithoutSceneInput;
  update: DomainSceneUpdateWithoutSceneInput;
  where: DomainSceneWhereUniqueInput;
};

export type DomainSceneUpsertWithoutSubScenesInput = {
  create: DomainSceneCreateWithoutSubScenesInput;
  update: DomainSceneUpdateWithoutSubScenesInput;
  where?: InputMaybe<DomainSceneWhereInput>;
};

export type DomainSceneWhereInput = {
  AND?: InputMaybe<Array<DomainSceneWhereInput>>;
  NOT?: InputMaybe<Array<DomainSceneWhereInput>>;
  OR?: InputMaybe<Array<DomainSceneWhereInput>>;
  domain?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  scene?: InputMaybe<UserSceneNullableRelationFilter>;
  sceneId?: InputMaybe<UuidFilter>;
  subScenes?: InputMaybe<SubScenesDomainListRelationFilter>;
};

export type DomainSceneWhereUniqueInput = {
  AND?: InputMaybe<Array<DomainSceneWhereInput>>;
  NOT?: InputMaybe<Array<DomainSceneWhereInput>>;
  OR?: InputMaybe<Array<DomainSceneWhereInput>>;
  domain?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  scene?: InputMaybe<UserSceneNullableRelationFilter>;
  sceneId?: InputMaybe<UuidFilter>;
  subScenes?: InputMaybe<SubScenesDomainListRelationFilter>;
};

export type EnumBnbPaymentStatusNullableFilter = {
  equals?: InputMaybe<BnbPaymentStatus>;
  in?: InputMaybe<Array<BnbPaymentStatus>>;
  not?: InputMaybe<NestedEnumBnbPaymentStatusNullableFilter>;
  notIn?: InputMaybe<Array<BnbPaymentStatus>>;
};

export type EnumBrandFieldUpdateOperationsInput = {
  set?: InputMaybe<Brand>;
};

export type EnumBrandFilter = {
  equals?: InputMaybe<Brand>;
  in?: InputMaybe<Array<Brand>>;
  not?: InputMaybe<NestedEnumBrandFilter>;
  notIn?: InputMaybe<Array<Brand>>;
};

export type EnumBrandWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumBrandFilter>;
  _min?: InputMaybe<NestedEnumBrandFilter>;
  equals?: InputMaybe<Brand>;
  in?: InputMaybe<Array<Brand>>;
  not?: InputMaybe<NestedEnumBrandWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Brand>>;
};

export type EnumCreatorWalletTypeFilter = {
  equals?: InputMaybe<CreatorWalletType>;
  in?: InputMaybe<Array<CreatorWalletType>>;
  not?: InputMaybe<NestedEnumCreatorWalletTypeFilter>;
  notIn?: InputMaybe<Array<CreatorWalletType>>;
};

export type EnumCurrencyFilter = {
  equals?: InputMaybe<Currency>;
  in?: InputMaybe<Array<Currency>>;
  not?: InputMaybe<NestedEnumCurrencyFilter>;
  notIn?: InputMaybe<Array<Currency>>;
};

export type EnumFileTypeFilter = {
  equals?: InputMaybe<FileType>;
  in?: InputMaybe<Array<FileType>>;
  not?: InputMaybe<NestedEnumFileTypeFilter>;
  notIn?: InputMaybe<Array<FileType>>;
};

export type EnumGameTypeNullableFilter = {
  equals?: InputMaybe<GameType>;
  in?: InputMaybe<Array<GameType>>;
  not?: InputMaybe<NestedEnumGameTypeNullableFilter>;
  notIn?: InputMaybe<Array<GameType>>;
};

export type EnumGameTypeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumGameTypeNullableFilter>;
  _min?: InputMaybe<NestedEnumGameTypeNullableFilter>;
  equals?: InputMaybe<GameType>;
  in?: InputMaybe<Array<GameType>>;
  not?: InputMaybe<NestedEnumGameTypeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<GameType>>;
};

export type EnumOrderStatusFilter = {
  equals?: InputMaybe<OrderStatus>;
  in?: InputMaybe<Array<OrderStatus>>;
  not?: InputMaybe<NestedEnumOrderStatusFilter>;
  notIn?: InputMaybe<Array<OrderStatus>>;
};

export type EnumQuestStateFieldUpdateOperationsInput = {
  set?: InputMaybe<QuestState>;
};

export type EnumQuestStateFilter = {
  equals?: InputMaybe<QuestState>;
  in?: InputMaybe<Array<QuestState>>;
  not?: InputMaybe<NestedEnumQuestStateFilter>;
  notIn?: InputMaybe<Array<QuestState>>;
};

export type EnumQuestStateWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumQuestStateFilter>;
  _min?: InputMaybe<NestedEnumQuestStateFilter>;
  equals?: InputMaybe<QuestState>;
  in?: InputMaybe<Array<QuestState>>;
  not?: InputMaybe<NestedEnumQuestStateWithAggregatesFilter>;
  notIn?: InputMaybe<Array<QuestState>>;
};

export type EnumRewardStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<RewardStatus>;
};

export type EnumRewardStatusFilter = {
  equals?: InputMaybe<RewardStatus>;
  in?: InputMaybe<Array<RewardStatus>>;
  not?: InputMaybe<NestedEnumRewardStatusFilter>;
  notIn?: InputMaybe<Array<RewardStatus>>;
};

export type EnumRewardStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumRewardStatusFilter>;
  _min?: InputMaybe<NestedEnumRewardStatusFilter>;
  equals?: InputMaybe<RewardStatus>;
  in?: InputMaybe<Array<RewardStatus>>;
  not?: InputMaybe<NestedEnumRewardStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<RewardStatus>>;
};

export type EnumRewardTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<RewardType>;
};

export type EnumRewardTypeFilter = {
  equals?: InputMaybe<RewardType>;
  in?: InputMaybe<Array<RewardType>>;
  not?: InputMaybe<NestedEnumRewardTypeFilter>;
  notIn?: InputMaybe<Array<RewardType>>;
};

export type EnumRewardTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumRewardTypeFilter>;
  _min?: InputMaybe<NestedEnumRewardTypeFilter>;
  equals?: InputMaybe<RewardType>;
  in?: InputMaybe<Array<RewardType>>;
  not?: InputMaybe<NestedEnumRewardTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<RewardType>>;
};

export type EnumTypeOrderFilter = {
  equals?: InputMaybe<TypeOrder>;
  in?: InputMaybe<Array<TypeOrder>>;
  not?: InputMaybe<NestedEnumTypeOrderFilter>;
  notIn?: InputMaybe<Array<TypeOrder>>;
};

export type FeatAsset = {
  __typename?: 'FeatAsset';
  asset: Asset;
  assetId: Scalars['Float']['output'];
  order: Scalars['Float']['output'];
};

export type FeaturedAsset = {
  __typename?: 'FeaturedAsset';
  assetId: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
};

export type FeaturedAssetNullableRelationFilter = {
  is?: InputMaybe<FeaturedAssetWhereInput>;
  isNot?: InputMaybe<FeaturedAssetWhereInput>;
};

export type FeaturedAssetWhereInput = {
  AND?: InputMaybe<Array<FeaturedAssetWhereInput>>;
  NOT?: InputMaybe<Array<FeaturedAssetWhereInput>>;
  OR?: InputMaybe<Array<FeaturedAssetWhereInput>>;
  asset?: InputMaybe<AssetRelationFilter>;
  assetId?: InputMaybe<IntFilter>;
  order?: InputMaybe<IntFilter>;
};

export type FeaturedNft = {
  __typename?: 'FeaturedNFT';
  assetURL: Scalars['String']['output'];
  collection?: Maybe<Collection>;
  countCopies: Scalars['Float']['output'];
  creator?: Maybe<CreatorF>;
  creatorEmail: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  description: Scalars['String']['output'];
  fileType: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  likesUsers?: Maybe<Array<LikeAssetByUser>>;
  maxSupply: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  previewImage: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  viewsCount: Scalars['Float']['output'];
};

export type FightMatches = {
  __typename?: 'FightMatches';
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  player1: Scalars['String']['output'];
  player2: Scalars['String']['output'];
  reward?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<MatchStatus>;
  winner?: Maybe<Scalars['String']['output']>;
};

export enum FileType {
  Audio = 'AUDIO',
  Image = 'IMAGE',
  Model = 'MODEL',
  Video = 'VIDEO'
}

export type Filter = {
  arrayTagsSearch?: InputMaybe<Array<Scalars['String']['input']>>;
  excludeTag?: InputMaybe<Scalars['String']['input']>;
  searchInput?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type FindAliasSceneInput = {
  alias: Scalars['String']['input'];
};

export type FindAliasSceneResponse = {
  __typename?: 'FindAliasSceneResponse';
  exists: Scalars['Boolean']['output'];
  sceneId?: Maybe<Scalars['String']['output']>;
};

export type FindMatchesByUserIdInput = {
  userId: Scalars['String']['input'];
};

export type FindMatchesByUserIdResponse = {
  __typename?: 'FindMatchesByUserIdResponse';
  matches?: Maybe<Array<FightMatches>>;
};

export type FloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']['input']>;
  divide?: InputMaybe<Scalars['Float']['input']>;
  increment?: InputMaybe<Scalars['Float']['input']>;
  multiply?: InputMaybe<Scalars['Float']['input']>;
  set?: InputMaybe<Scalars['Float']['input']>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type FloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type FloatNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['Float']['input']>>;
  has?: InputMaybe<Scalars['Float']['input']>;
  hasEvery?: InputMaybe<Array<Scalars['Float']['input']>>;
  hasSome?: InputMaybe<Array<Scalars['Float']['input']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FloatNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedFloatNullableFilter>;
  _min?: InputMaybe<NestedFloatNullableFilter>;
  _sum?: InputMaybe<NestedFloatNullableFilter>;
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type FloatWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedFloatFilter>;
  _min?: InputMaybe<NestedFloatFilter>;
  _sum?: InputMaybe<NestedFloatFilter>;
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type ForgotPassword = {
  __typename?: 'ForgotPassword';
  forgotPassword: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
};

export type ForgotPasswordInput = {
  email: Scalars['String']['input'];
};

export enum GameType {
  FightGame_2D = 'FIGHT_GAME_2D'
}

export type GetAllDomainSceneInput = {
  limit: Scalars['Float']['input'];
  offset: Scalars['Float']['input'];
};

export type GetAllDomainSceneResponse = {
  __typename?: 'GetAllDomainSceneResponse';
  domainScene?: Maybe<Array<DomainScene>>;
  total: Scalars['Float']['output'];
};

export type GetAllUsersQuestsInput = {
  limit: Scalars['Float']['input'];
  offset: Scalars['Float']['input'];
};

export type GetAllUsersQuestsResponse = {
  __typename?: 'GetAllUsersQuestsResponse';
  total: Scalars['Float']['output'];
  usersQuests?: Maybe<Array<UsersQuests>>;
};

export type GetAssetCreatorBnbWalletResponse = {
  __typename?: 'GetAssetCreatorBnbWalletResponse';
  bnbWallet?: Maybe<Scalars['String']['output']>;
};

export type GetAssetCreatorWalletResponse = {
  __typename?: 'GetAssetCreatorWalletResponse';
  wallet?: Maybe<Scalars['String']['output']>;
};

export type GetAssetInfoInput = {
  assetId: Scalars['Float']['input'];
};

export type GetAssetInfoResponse = {
  __typename?: 'GetAssetInfoResponse';
  asset?: Maybe<AssetInfo>;
};

export type GetAssetVideoUrlByMetaOgResponse = {
  __typename?: 'GetAssetVideoUrlByMetaOGResponse';
  status: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type GetAssetWeb2InfoInput = {
  assetId: Scalars['String']['input'];
};

export type GetAssetWeb2InfoResponse = {
  __typename?: 'GetAssetWeb2InfoResponse';
  asset?: Maybe<AssetWeb2Info>;
};

export type GetAssetsByCollectionPaginationResponse = {
  __typename?: 'GetAssetsByCollectionPaginationResponse';
  assets?: Maybe<Array<Asset>>;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type GetAssetsByCollectionResponse = {
  __typename?: 'GetAssetsByCollectionResponse';
  assets?: Maybe<Array<Asset>>;
};

export type GetAssetsInput = {
  limit: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  offset: Scalars['Float']['input'];
};

export type GetAssetsResponse = {
  __typename?: 'GetAssetsResponse';
  assets?: Maybe<Array<AssetWithFeatRecord>>;
  totalCount: Scalars['Float']['output'];
};

export type GetAssetsWeb2Input = {
  filter?: InputMaybe<Filter>;
  limit: Scalars['Float']['input'];
  offset: Scalars['Float']['input'];
};

export type GetAssetsWeb2Response = {
  __typename?: 'GetAssetsWeb2Response';
  assets?: Maybe<Array<AssetWeb2>>;
  totalCount: Scalars['Float']['output'];
};

export type GetBoughtAssetsByUserInput = {
  filter?: InputMaybe<Filter>;
  limit: Scalars['Float']['input'];
  offset: Scalars['Float']['input'];
  userId: Scalars['String']['input'];
};

export type GetBoughtAssetsByUserResponse = {
  __typename?: 'GetBoughtAssetsByUserResponse';
  assets?: Maybe<Array<AssetWeb2>>;
  totalCount: Scalars['Float']['output'];
};

export type GetCartInput = {
  userEmail: Scalars['String']['input'];
};

export type GetCartResponse = {
  __typename?: 'GetCartResponse';
  cart?: Maybe<Array<ProductOutput>>;
  message?: Maybe<Scalars['String']['output']>;
  status: Scalars['Boolean']['output'];
};

export type GetCategoriesResponse = {
  __typename?: 'GetCategoriesResponse';
  categories?: Maybe<Array<Category>>;
};

export type GetCategoryByIdByResponse = {
  __typename?: 'GetCategoryByIdByResponse';
  category?: Maybe<Category>;
};

export type GetCategoryByIdInput = {
  id: Scalars['Float']['input'];
};

export type GetCategoryIdByKey = {
  __typename?: 'GetCategoryIdByKey';
  categoryId?: Maybe<Scalars['Float']['output']>;
};

export type GetCategoryIdByKeyInput = {
  key: Scalars['String']['input'];
};

export type GetCollectionInfoInput = {
  collectionId: Scalars['String']['input'];
};

export type GetCollectionInfoResponse = {
  __typename?: 'GetCollectionInfoResponse';
  collection?: Maybe<CollectionInfo>;
};

export type GetCollectionsByCreatorInput = {
  creatorEmail: Scalars['String']['input'];
};

export type GetCollectionsByCreatorResponse = {
  __typename?: 'GetCollectionsByCreatorResponse';
  collections?: Maybe<Array<Collection>>;
  message: Scalars['String']['output'];
};

export type GetCollectionsInput = {
  limit: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  offset: Scalars['Float']['input'];
};

export type GetCollectionsResponse = {
  __typename?: 'GetCollectionsResponse';
  collections?: Maybe<Array<Collection>>;
  totalCount: Scalars['Float']['output'];
};

export type GetConversationSidInput = {
  id: Scalars['String']['input'];
};

export type GetConversationSidResponse = {
  __typename?: 'GetConversationSidResponse';
  sid?: Maybe<Scalars['String']['output']>;
};

export type GetCreatorAssetsAndInfoInput = {
  filter?: InputMaybe<Scalars['String']['input']>;
  filterCategory?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  itemsType?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Float']['input'];
  offset: Scalars['Float']['input'];
  searchName?: InputMaybe<Scalars['String']['input']>;
};

export type GetCreatorAssetsAndInfoResponse = {
  __typename?: 'GetCreatorAssetsAndInfoResponse';
  creator?: Maybe<Creator>;
  items?: Maybe<Items>;
  totalItemsCount?: Maybe<Scalars['Float']['output']>;
};

export type GetCreatorsResponse = {
  __typename?: 'GetCreatorsResponse';
  creators?: Maybe<Array<Creator>>;
  totalCount: Scalars['Float']['output'];
};

export type GetCurrenciesPriceResponse = {
  __typename?: 'GetCurrenciesPriceResponse';
  currencies?: Maybe<Array<CurrencyPrice>>;
};

export type GetDefaultTemplate = {
  __typename?: 'GetDefaultTemplate';
  template?: Maybe<Templates>;
};

export type GetFeaturedAssetsAdminRes = {
  __typename?: 'GetFeaturedAssetsAdminRes';
  assets?: Maybe<Array<FeatAsset>>;
};

export type GetFeaturedAssetsResponse = {
  __typename?: 'GetFeaturedAssetsResponse';
  assets?: Maybe<Array<FeaturedNft>>;
};

export type GetFeaturedCollectionsResponse = {
  __typename?: 'GetFeaturedCollectionsResponse';
  collections?: Maybe<Array<Collection>>;
};

export type GetFeaturedCreatorsResponse = {
  __typename?: 'GetFeaturedCreatorsResponse';
  creators?: Maybe<Array<Creator>>;
};

export type GetLikesAssetsByUserInput = {
  userId: Scalars['String']['input'];
};

export type GetLikesAssetsByUserResponse = {
  __typename?: 'GetLikesAssetsByUserResponse';
  assets?: Maybe<Array<Asset>>;
};

export type GetOpenAiAnswerInput = {
  messages?: InputMaybe<Array<Scalars['JSONObject']['input']>>;
};

export type GetOpenAiAnswerResponse = {
  __typename?: 'GetOpenAIAnswerResponse';
  message?: Maybe<OpenAiMessageResponse>;
};

export type GetOrdersByIdInput = {
  orderId: Scalars['String']['input'];
};

export type GetOrdersByIdResponse = {
  __typename?: 'GetOrdersByIdResponse';
  order?: Maybe<Order>;
};

export type GetOrdersByPayerIdInput = {
  payerId: Scalars['String']['input'];
};

export type GetOrdersByPayerIdResponse = {
  __typename?: 'GetOrdersByPayerIdResponse';
  orders?: Maybe<Array<Order>>;
};

export type GetPollyLectorInput = {
  params?: InputMaybe<Params>;
};

export type GetPollyLectorResponse = {
  __typename?: 'GetPollyLectorResponse';
  audio?: Maybe<Array<Scalars['Float']['output']>>;
};

export type GetProfileByUserIdInput = {
  userId: Scalars['String']['input'];
};

export type GetProfileByUserIdResponse = {
  __typename?: 'GetProfileByUserIdResponse';
  message?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<Profile>;
  status: Scalars['Boolean']['output'];
};

export type GetSceneAliasInput = {
  id: Scalars['String']['input'];
};

export type GetSceneAliasResponse = {
  __typename?: 'GetSceneAliasResponse';
  alias?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type GetSceneIdByDomainResponse = {
  __typename?: 'GetSceneIdByDomainResponse';
  sceneId?: Maybe<Scalars['String']['output']>;
};

export type GetScenesCountResponse = {
  __typename?: 'GetScenesCountResponse';
  count?: Maybe<Scalars['Float']['output']>;
};

export type GetShopifyStoreBySceneInput = {
  sceneId: Scalars['String']['input'];
};

export type GetShopifyStoreBySceneResponse = {
  __typename?: 'GetShopifyStoreBySceneResponse';
  store?: Maybe<ShopifyStore>;
};

export type GetShopifyStoreInput = {
  id: Scalars['String']['input'];
};

export type GetShopifyStoreResponse = {
  __typename?: 'GetShopifyStoreResponse';
  store?: Maybe<ShopifyStore>;
};

export type GetSubSceneIdByDomainInput = {
  domain: Scalars['String']['input'];
  route: Scalars['String']['input'];
};

export type GetSubSceneIdByDomainResponse = {
  __typename?: 'GetSubSceneIdByDomainResponse';
  sceneId?: Maybe<Scalars['String']['output']>;
};

export type GetSubScenesDomainInput = {
  domain: Scalars['String']['input'];
  limit: Scalars['Float']['input'];
  offset: Scalars['Float']['input'];
};

export type GetSubScenesDomainResponse = {
  __typename?: 'GetSubScenesDomainResponse';
  domainSubScene?: Maybe<Array<SubScenesDomain>>;
  total: Scalars['Float']['output'];
};

export type GetTopCreatorInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Float']['input'];
  offset: Scalars['Float']['input'];
};

export type GetTopCreatorsResponse = {
  __typename?: 'GetTopCreatorsResponse';
  creators?: Maybe<Array<CreatorC>>;
};

export type GetTrendingCollectionsResponse = {
  __typename?: 'GetTrendingCollectionsResponse';
  collections?: Maybe<Array<TrendCollection>>;
};

export type GetUnityPackageInput = {
  fileName: Scalars['String']['input'];
  fileURL: Scalars['String']['input'];
};

export type GetUnityPackageResponse = {
  __typename?: 'GetUnityPackageResponse';
  fileURL: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
};

export type GetUserWardrobeInput = {
  userId: Scalars['String']['input'];
};

export type GetUserWardrobeResponse = {
  __typename?: 'GetUserWardrobeResponse';
  wardrobe?: Maybe<SkinsWardrobe>;
};

export type GetWalletBalanceInput = {
  userId: Scalars['String']['input'];
};

export type GetWalletBalanceResponse = {
  __typename?: 'GetWalletBalanceResponse';
  balance: Scalars['Float']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type GoogleLoginRes = {
  __typename?: 'GoogleLoginRes';
  status: Scalars['String']['output'];
  user?: Maybe<Users>;
};

export type GraphicsSettings = {
  __typename?: 'GraphicsSettings';
  _count?: Maybe<GraphicsSettingsCount>;
  id: Scalars['String']['output'];
  physicsEngine?: Maybe<Scalars['String']['output']>;
  postProcessingSettingsId?: Maybe<Scalars['String']['output']>;
  preset?: Maybe<Scalars['String']['output']>;
};

export type GraphicsSettingsCount = {
  __typename?: 'GraphicsSettingsCount';
  UserScene: Scalars['Int']['output'];
};


export type GraphicsSettingsCountUserSceneArgs = {
  where?: InputMaybe<UserSceneWhereInput>;
};

export type GraphicsSettingsCountAggregate = {
  __typename?: 'GraphicsSettingsCountAggregate';
  _all: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  physicsEngine: Scalars['Int']['output'];
  postProcessingSettingsId: Scalars['Int']['output'];
  preset: Scalars['Int']['output'];
};

export type GraphicsSettingsCountOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  physicsEngine?: InputMaybe<SortOrder>;
  postProcessingSettingsId?: InputMaybe<SortOrder>;
  preset?: InputMaybe<SortOrder>;
};

export type GraphicsSettingsCreateInput = {
  UserScene?: InputMaybe<UserSceneCreateNestedManyWithoutGraphicsSettingsInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  physicsEngine?: InputMaybe<Scalars['String']['input']>;
  postProcessingSettings?: InputMaybe<PostProcessingSettingsCreateNestedOneWithoutGraphicsSettingsInput>;
  preset?: InputMaybe<Scalars['String']['input']>;
};

export type GraphicsSettingsCreateManyInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  physicsEngine?: InputMaybe<Scalars['String']['input']>;
  postProcessingSettingsId?: InputMaybe<Scalars['String']['input']>;
  preset?: InputMaybe<Scalars['String']['input']>;
};

export type GraphicsSettingsCreateManyPostProcessingSettingsInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  physicsEngine?: InputMaybe<Scalars['String']['input']>;
  preset?: InputMaybe<Scalars['String']['input']>;
};

export type GraphicsSettingsCreateManyPostProcessingSettingsInputEnvelope = {
  data: Array<GraphicsSettingsCreateManyPostProcessingSettingsInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GraphicsSettingsCreateNestedManyWithoutPostProcessingSettingsInput = {
  connect?: InputMaybe<Array<GraphicsSettingsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GraphicsSettingsCreateOrConnectWithoutPostProcessingSettingsInput>>;
  create?: InputMaybe<Array<GraphicsSettingsCreateWithoutPostProcessingSettingsInput>>;
  createMany?: InputMaybe<GraphicsSettingsCreateManyPostProcessingSettingsInputEnvelope>;
};

export type GraphicsSettingsCreateNestedOneWithoutUserSceneInput = {
  connect?: InputMaybe<GraphicsSettingsWhereUniqueInput>;
  connectOrCreate?: InputMaybe<GraphicsSettingsCreateOrConnectWithoutUserSceneInput>;
  create?: InputMaybe<GraphicsSettingsCreateWithoutUserSceneInput>;
};

export type GraphicsSettingsCreateOrConnectWithoutPostProcessingSettingsInput = {
  create: GraphicsSettingsCreateWithoutPostProcessingSettingsInput;
  where: GraphicsSettingsWhereUniqueInput;
};

export type GraphicsSettingsCreateOrConnectWithoutUserSceneInput = {
  create: GraphicsSettingsCreateWithoutUserSceneInput;
  where: GraphicsSettingsWhereUniqueInput;
};

export type GraphicsSettingsCreateWithoutPostProcessingSettingsInput = {
  UserScene?: InputMaybe<UserSceneCreateNestedManyWithoutGraphicsSettingsInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  physicsEngine?: InputMaybe<Scalars['String']['input']>;
  preset?: InputMaybe<Scalars['String']['input']>;
};

export type GraphicsSettingsCreateWithoutUserSceneInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  physicsEngine?: InputMaybe<Scalars['String']['input']>;
  postProcessingSettings?: InputMaybe<PostProcessingSettingsCreateNestedOneWithoutGraphicsSettingsInput>;
  preset?: InputMaybe<Scalars['String']['input']>;
};

export type GraphicsSettingsGroupBy = {
  __typename?: 'GraphicsSettingsGroupBy';
  _count?: Maybe<GraphicsSettingsCountAggregate>;
  _max?: Maybe<GraphicsSettingsMaxAggregate>;
  _min?: Maybe<GraphicsSettingsMinAggregate>;
  id: Scalars['String']['output'];
  physicsEngine?: Maybe<Scalars['String']['output']>;
  postProcessingSettingsId?: Maybe<Scalars['String']['output']>;
  preset?: Maybe<Scalars['String']['output']>;
};

export type GraphicsSettingsListRelationFilter = {
  every?: InputMaybe<GraphicsSettingsWhereInput>;
  none?: InputMaybe<GraphicsSettingsWhereInput>;
  some?: InputMaybe<GraphicsSettingsWhereInput>;
};

export type GraphicsSettingsMaxAggregate = {
  __typename?: 'GraphicsSettingsMaxAggregate';
  id?: Maybe<Scalars['String']['output']>;
  physicsEngine?: Maybe<Scalars['String']['output']>;
  postProcessingSettingsId?: Maybe<Scalars['String']['output']>;
  preset?: Maybe<Scalars['String']['output']>;
};

export type GraphicsSettingsMaxOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  physicsEngine?: InputMaybe<SortOrder>;
  postProcessingSettingsId?: InputMaybe<SortOrder>;
  preset?: InputMaybe<SortOrder>;
};

export type GraphicsSettingsMinAggregate = {
  __typename?: 'GraphicsSettingsMinAggregate';
  id?: Maybe<Scalars['String']['output']>;
  physicsEngine?: Maybe<Scalars['String']['output']>;
  postProcessingSettingsId?: Maybe<Scalars['String']['output']>;
  preset?: Maybe<Scalars['String']['output']>;
};

export type GraphicsSettingsMinOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  physicsEngine?: InputMaybe<SortOrder>;
  postProcessingSettingsId?: InputMaybe<SortOrder>;
  preset?: InputMaybe<SortOrder>;
};

export type GraphicsSettingsNullableRelationFilter = {
  is?: InputMaybe<GraphicsSettingsWhereInput>;
  isNot?: InputMaybe<GraphicsSettingsWhereInput>;
};

export type GraphicsSettingsOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type GraphicsSettingsOrderByWithAggregationInput = {
  _count?: InputMaybe<GraphicsSettingsCountOrderByAggregateInput>;
  _max?: InputMaybe<GraphicsSettingsMaxOrderByAggregateInput>;
  _min?: InputMaybe<GraphicsSettingsMinOrderByAggregateInput>;
  id?: InputMaybe<SortOrder>;
  physicsEngine?: InputMaybe<SortOrderInput>;
  postProcessingSettingsId?: InputMaybe<SortOrderInput>;
  preset?: InputMaybe<SortOrderInput>;
};

export type GraphicsSettingsOrderByWithRelationInput = {
  UserScene?: InputMaybe<UserSceneOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  physicsEngine?: InputMaybe<SortOrderInput>;
  postProcessingSettings?: InputMaybe<PostProcessingSettingsOrderByWithRelationInput>;
  postProcessingSettingsId?: InputMaybe<SortOrderInput>;
  preset?: InputMaybe<SortOrderInput>;
};

export enum GraphicsSettingsScalarFieldEnum {
  Id = 'id',
  PhysicsEngine = 'physicsEngine',
  PostProcessingSettingsId = 'postProcessingSettingsId',
  Preset = 'preset'
}

export type GraphicsSettingsScalarWhereInput = {
  AND?: InputMaybe<Array<GraphicsSettingsScalarWhereInput>>;
  NOT?: InputMaybe<Array<GraphicsSettingsScalarWhereInput>>;
  OR?: InputMaybe<Array<GraphicsSettingsScalarWhereInput>>;
  id?: InputMaybe<UuidFilter>;
  physicsEngine?: InputMaybe<StringNullableFilter>;
  postProcessingSettingsId?: InputMaybe<UuidNullableFilter>;
  preset?: InputMaybe<StringNullableFilter>;
};

export type GraphicsSettingsScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<GraphicsSettingsScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<GraphicsSettingsScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<GraphicsSettingsScalarWhereWithAggregatesInput>>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  physicsEngine?: InputMaybe<StringNullableWithAggregatesFilter>;
  postProcessingSettingsId?: InputMaybe<UuidNullableWithAggregatesFilter>;
  preset?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type GraphicsSettingsUpdateInput = {
  UserScene?: InputMaybe<UserSceneUpdateManyWithoutGraphicsSettingsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  physicsEngine?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postProcessingSettings?: InputMaybe<PostProcessingSettingsUpdateOneWithoutGraphicsSettingsNestedInput>;
  preset?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type GraphicsSettingsUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  physicsEngine?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  preset?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type GraphicsSettingsUpdateManyWithWhereWithoutPostProcessingSettingsInput = {
  data: GraphicsSettingsUpdateManyMutationInput;
  where: GraphicsSettingsScalarWhereInput;
};

export type GraphicsSettingsUpdateManyWithoutPostProcessingSettingsNestedInput = {
  connect?: InputMaybe<Array<GraphicsSettingsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GraphicsSettingsCreateOrConnectWithoutPostProcessingSettingsInput>>;
  create?: InputMaybe<Array<GraphicsSettingsCreateWithoutPostProcessingSettingsInput>>;
  createMany?: InputMaybe<GraphicsSettingsCreateManyPostProcessingSettingsInputEnvelope>;
  delete?: InputMaybe<Array<GraphicsSettingsWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<GraphicsSettingsScalarWhereInput>>;
  disconnect?: InputMaybe<Array<GraphicsSettingsWhereUniqueInput>>;
  set?: InputMaybe<Array<GraphicsSettingsWhereUniqueInput>>;
  update?: InputMaybe<Array<GraphicsSettingsUpdateWithWhereUniqueWithoutPostProcessingSettingsInput>>;
  updateMany?: InputMaybe<Array<GraphicsSettingsUpdateManyWithWhereWithoutPostProcessingSettingsInput>>;
  upsert?: InputMaybe<Array<GraphicsSettingsUpsertWithWhereUniqueWithoutPostProcessingSettingsInput>>;
};

export type GraphicsSettingsUpdateOneWithoutUserSceneNestedInput = {
  connect?: InputMaybe<GraphicsSettingsWhereUniqueInput>;
  connectOrCreate?: InputMaybe<GraphicsSettingsCreateOrConnectWithoutUserSceneInput>;
  create?: InputMaybe<GraphicsSettingsCreateWithoutUserSceneInput>;
  delete?: InputMaybe<GraphicsSettingsWhereInput>;
  disconnect?: InputMaybe<GraphicsSettingsWhereInput>;
  update?: InputMaybe<GraphicsSettingsUpdateToOneWithWhereWithoutUserSceneInput>;
  upsert?: InputMaybe<GraphicsSettingsUpsertWithoutUserSceneInput>;
};

export type GraphicsSettingsUpdateToOneWithWhereWithoutUserSceneInput = {
  data: GraphicsSettingsUpdateWithoutUserSceneInput;
  where?: InputMaybe<GraphicsSettingsWhereInput>;
};

export type GraphicsSettingsUpdateWithWhereUniqueWithoutPostProcessingSettingsInput = {
  data: GraphicsSettingsUpdateWithoutPostProcessingSettingsInput;
  where: GraphicsSettingsWhereUniqueInput;
};

export type GraphicsSettingsUpdateWithoutPostProcessingSettingsInput = {
  UserScene?: InputMaybe<UserSceneUpdateManyWithoutGraphicsSettingsNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  physicsEngine?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  preset?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type GraphicsSettingsUpdateWithoutUserSceneInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  physicsEngine?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postProcessingSettings?: InputMaybe<PostProcessingSettingsUpdateOneWithoutGraphicsSettingsNestedInput>;
  preset?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type GraphicsSettingsUpsertWithWhereUniqueWithoutPostProcessingSettingsInput = {
  create: GraphicsSettingsCreateWithoutPostProcessingSettingsInput;
  update: GraphicsSettingsUpdateWithoutPostProcessingSettingsInput;
  where: GraphicsSettingsWhereUniqueInput;
};

export type GraphicsSettingsUpsertWithoutUserSceneInput = {
  create: GraphicsSettingsCreateWithoutUserSceneInput;
  update: GraphicsSettingsUpdateWithoutUserSceneInput;
  where?: InputMaybe<GraphicsSettingsWhereInput>;
};

export type GraphicsSettingsWhereInput = {
  AND?: InputMaybe<Array<GraphicsSettingsWhereInput>>;
  NOT?: InputMaybe<Array<GraphicsSettingsWhereInput>>;
  OR?: InputMaybe<Array<GraphicsSettingsWhereInput>>;
  UserScene?: InputMaybe<UserSceneListRelationFilter>;
  id?: InputMaybe<UuidFilter>;
  physicsEngine?: InputMaybe<StringNullableFilter>;
  postProcessingSettings?: InputMaybe<PostProcessingSettingsNullableRelationFilter>;
  postProcessingSettingsId?: InputMaybe<UuidNullableFilter>;
  preset?: InputMaybe<StringNullableFilter>;
};

export type GraphicsSettingsWhereUniqueInput = {
  AND?: InputMaybe<Array<GraphicsSettingsWhereInput>>;
  NOT?: InputMaybe<Array<GraphicsSettingsWhereInput>>;
  OR?: InputMaybe<Array<GraphicsSettingsWhereInput>>;
  UserScene?: InputMaybe<UserSceneListRelationFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  physicsEngine?: InputMaybe<StringNullableFilter>;
  postProcessingSettings?: InputMaybe<PostProcessingSettingsNullableRelationFilter>;
  postProcessingSettingsId?: InputMaybe<UuidNullableFilter>;
  preset?: InputMaybe<StringNullableFilter>;
};

export type HideAndSeek = {
  __typename?: 'HideAndSeek';
  _count?: Maybe<HideAndSeekCount>;
  finishPrompt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  searchTime: Scalars['Int']['output'];
  startPrompt: Scalars['String']['output'];
};

export type HideAndSeekAvgAggregate = {
  __typename?: 'HideAndSeekAvgAggregate';
  searchTime?: Maybe<Scalars['Float']['output']>;
};

export type HideAndSeekAvgOrderByAggregateInput = {
  searchTime?: InputMaybe<SortOrder>;
};

export type HideAndSeekCount = {
  __typename?: 'HideAndSeekCount';
  UserScene: Scalars['Int']['output'];
  hideAndSeekNPCs: Scalars['Int']['output'];
};


export type HideAndSeekCountUserSceneArgs = {
  where?: InputMaybe<UserSceneWhereInput>;
};


export type HideAndSeekCountHideAndSeekNpCsArgs = {
  where?: InputMaybe<HideAndSeekNpcWhereInput>;
};

export type HideAndSeekCountAggregate = {
  __typename?: 'HideAndSeekCountAggregate';
  _all: Scalars['Int']['output'];
  finishPrompt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  searchTime: Scalars['Int']['output'];
  startPrompt: Scalars['Int']['output'];
};

export type HideAndSeekCountOrderByAggregateInput = {
  finishPrompt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  searchTime?: InputMaybe<SortOrder>;
  startPrompt?: InputMaybe<SortOrder>;
};

export type HideAndSeekCreateInput = {
  UserScene?: InputMaybe<UserSceneCreateNestedManyWithoutHideAndSeekInput>;
  finishPrompt?: InputMaybe<Scalars['String']['input']>;
  hideAndSeekNPCs?: InputMaybe<HideAndSeekNpcCreateNestedManyWithoutHideAndSeekInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  searchTime?: InputMaybe<Scalars['Int']['input']>;
  startPrompt: Scalars['String']['input'];
};

export type HideAndSeekCreateManyInput = {
  finishPrompt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  searchTime?: InputMaybe<Scalars['Int']['input']>;
  startPrompt: Scalars['String']['input'];
};

export type HideAndSeekCreateNestedOneWithoutHideAndSeekNpCsInput = {
  connect?: InputMaybe<HideAndSeekWhereUniqueInput>;
  connectOrCreate?: InputMaybe<HideAndSeekCreateOrConnectWithoutHideAndSeekNpCsInput>;
  create?: InputMaybe<HideAndSeekCreateWithoutHideAndSeekNpCsInput>;
};

export type HideAndSeekCreateNestedOneWithoutUserSceneInput = {
  connect?: InputMaybe<HideAndSeekWhereUniqueInput>;
  connectOrCreate?: InputMaybe<HideAndSeekCreateOrConnectWithoutUserSceneInput>;
  create?: InputMaybe<HideAndSeekCreateWithoutUserSceneInput>;
};

export type HideAndSeekCreateOrConnectWithoutHideAndSeekNpCsInput = {
  create: HideAndSeekCreateWithoutHideAndSeekNpCsInput;
  where: HideAndSeekWhereUniqueInput;
};

export type HideAndSeekCreateOrConnectWithoutUserSceneInput = {
  create: HideAndSeekCreateWithoutUserSceneInput;
  where: HideAndSeekWhereUniqueInput;
};

export type HideAndSeekCreateWithoutHideAndSeekNpCsInput = {
  UserScene?: InputMaybe<UserSceneCreateNestedManyWithoutHideAndSeekInput>;
  finishPrompt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  searchTime?: InputMaybe<Scalars['Int']['input']>;
  startPrompt: Scalars['String']['input'];
};

export type HideAndSeekCreateWithoutUserSceneInput = {
  finishPrompt?: InputMaybe<Scalars['String']['input']>;
  hideAndSeekNPCs?: InputMaybe<HideAndSeekNpcCreateNestedManyWithoutHideAndSeekInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  searchTime?: InputMaybe<Scalars['Int']['input']>;
  startPrompt: Scalars['String']['input'];
};

export type HideAndSeekGroupBy = {
  __typename?: 'HideAndSeekGroupBy';
  _avg?: Maybe<HideAndSeekAvgAggregate>;
  _count?: Maybe<HideAndSeekCountAggregate>;
  _max?: Maybe<HideAndSeekMaxAggregate>;
  _min?: Maybe<HideAndSeekMinAggregate>;
  _sum?: Maybe<HideAndSeekSumAggregate>;
  finishPrompt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  searchTime: Scalars['Int']['output'];
  startPrompt: Scalars['String']['output'];
};

export type HideAndSeekMaxAggregate = {
  __typename?: 'HideAndSeekMaxAggregate';
  finishPrompt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  searchTime?: Maybe<Scalars['Int']['output']>;
  startPrompt?: Maybe<Scalars['String']['output']>;
};

export type HideAndSeekMaxOrderByAggregateInput = {
  finishPrompt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  searchTime?: InputMaybe<SortOrder>;
  startPrompt?: InputMaybe<SortOrder>;
};

export type HideAndSeekMinAggregate = {
  __typename?: 'HideAndSeekMinAggregate';
  finishPrompt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  searchTime?: Maybe<Scalars['Int']['output']>;
  startPrompt?: Maybe<Scalars['String']['output']>;
};

export type HideAndSeekMinOrderByAggregateInput = {
  finishPrompt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  searchTime?: InputMaybe<SortOrder>;
  startPrompt?: InputMaybe<SortOrder>;
};

export type HideAndSeekNpc = {
  __typename?: 'HideAndSeekNPC';
  assetUrl: Scalars['String']['output'];
  hideAndSeekId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  propUrl: Scalars['String']['output'];
};

export type HideAndSeekNpcCountAggregate = {
  __typename?: 'HideAndSeekNPCCountAggregate';
  _all: Scalars['Int']['output'];
  assetUrl: Scalars['Int']['output'];
  hideAndSeekId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  propUrl: Scalars['Int']['output'];
};

export type HideAndSeekNpcCountOrderByAggregateInput = {
  assetUrl?: InputMaybe<SortOrder>;
  hideAndSeekId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  propUrl?: InputMaybe<SortOrder>;
};

export type HideAndSeekNpcCreateInput = {
  HideAndSeek?: InputMaybe<HideAndSeekCreateNestedOneWithoutHideAndSeekNpCsInput>;
  assetUrl: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  propUrl: Scalars['String']['input'];
};

export type HideAndSeekNpcCreateManyHideAndSeekInput = {
  assetUrl: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  propUrl: Scalars['String']['input'];
};

export type HideAndSeekNpcCreateManyHideAndSeekInputEnvelope = {
  data: Array<HideAndSeekNpcCreateManyHideAndSeekInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HideAndSeekNpcCreateManyInput = {
  assetUrl: Scalars['String']['input'];
  hideAndSeekId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  propUrl: Scalars['String']['input'];
};

export type HideAndSeekNpcCreateNestedManyWithoutHideAndSeekInput = {
  connect?: InputMaybe<Array<HideAndSeekNpcWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<HideAndSeekNpcCreateOrConnectWithoutHideAndSeekInput>>;
  create?: InputMaybe<Array<HideAndSeekNpcCreateWithoutHideAndSeekInput>>;
  createMany?: InputMaybe<HideAndSeekNpcCreateManyHideAndSeekInputEnvelope>;
};

export type HideAndSeekNpcCreateOrConnectWithoutHideAndSeekInput = {
  create: HideAndSeekNpcCreateWithoutHideAndSeekInput;
  where: HideAndSeekNpcWhereUniqueInput;
};

export type HideAndSeekNpcCreateWithoutHideAndSeekInput = {
  assetUrl: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  propUrl: Scalars['String']['input'];
};

export type HideAndSeekNpcGroupBy = {
  __typename?: 'HideAndSeekNPCGroupBy';
  _count?: Maybe<HideAndSeekNpcCountAggregate>;
  _max?: Maybe<HideAndSeekNpcMaxAggregate>;
  _min?: Maybe<HideAndSeekNpcMinAggregate>;
  assetUrl: Scalars['String']['output'];
  hideAndSeekId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  propUrl: Scalars['String']['output'];
};

export type HideAndSeekNpcListRelationFilter = {
  every?: InputMaybe<HideAndSeekNpcWhereInput>;
  none?: InputMaybe<HideAndSeekNpcWhereInput>;
  some?: InputMaybe<HideAndSeekNpcWhereInput>;
};

export type HideAndSeekNpcMaxAggregate = {
  __typename?: 'HideAndSeekNPCMaxAggregate';
  assetUrl?: Maybe<Scalars['String']['output']>;
  hideAndSeekId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  propUrl?: Maybe<Scalars['String']['output']>;
};

export type HideAndSeekNpcMaxOrderByAggregateInput = {
  assetUrl?: InputMaybe<SortOrder>;
  hideAndSeekId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  propUrl?: InputMaybe<SortOrder>;
};

export type HideAndSeekNpcMinAggregate = {
  __typename?: 'HideAndSeekNPCMinAggregate';
  assetUrl?: Maybe<Scalars['String']['output']>;
  hideAndSeekId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  propUrl?: Maybe<Scalars['String']['output']>;
};

export type HideAndSeekNpcMinOrderByAggregateInput = {
  assetUrl?: InputMaybe<SortOrder>;
  hideAndSeekId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  propUrl?: InputMaybe<SortOrder>;
};

export type HideAndSeekNpcOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type HideAndSeekNpcOrderByWithAggregationInput = {
  _count?: InputMaybe<HideAndSeekNpcCountOrderByAggregateInput>;
  _max?: InputMaybe<HideAndSeekNpcMaxOrderByAggregateInput>;
  _min?: InputMaybe<HideAndSeekNpcMinOrderByAggregateInput>;
  assetUrl?: InputMaybe<SortOrder>;
  hideAndSeekId?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  propUrl?: InputMaybe<SortOrder>;
};

export type HideAndSeekNpcOrderByWithRelationInput = {
  HideAndSeek?: InputMaybe<HideAndSeekOrderByWithRelationInput>;
  assetUrl?: InputMaybe<SortOrder>;
  hideAndSeekId?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  propUrl?: InputMaybe<SortOrder>;
};

export enum HideAndSeekNpcScalarFieldEnum {
  AssetUrl = 'assetUrl',
  HideAndSeekId = 'hideAndSeekId',
  Id = 'id',
  PropUrl = 'propUrl'
}

export type HideAndSeekNpcScalarWhereInput = {
  AND?: InputMaybe<Array<HideAndSeekNpcScalarWhereInput>>;
  NOT?: InputMaybe<Array<HideAndSeekNpcScalarWhereInput>>;
  OR?: InputMaybe<Array<HideAndSeekNpcScalarWhereInput>>;
  assetUrl?: InputMaybe<StringFilter>;
  hideAndSeekId?: InputMaybe<UuidNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  propUrl?: InputMaybe<StringFilter>;
};

export type HideAndSeekNpcScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<HideAndSeekNpcScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<HideAndSeekNpcScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<HideAndSeekNpcScalarWhereWithAggregatesInput>>;
  assetUrl?: InputMaybe<StringWithAggregatesFilter>;
  hideAndSeekId?: InputMaybe<UuidNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  propUrl?: InputMaybe<StringWithAggregatesFilter>;
};

export type HideAndSeekNpcUpdateInput = {
  HideAndSeek?: InputMaybe<HideAndSeekUpdateOneWithoutHideAndSeekNpCsNestedInput>;
  assetUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  propUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type HideAndSeekNpcUpdateManyMutationInput = {
  assetUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  propUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type HideAndSeekNpcUpdateManyWithWhereWithoutHideAndSeekInput = {
  data: HideAndSeekNpcUpdateManyMutationInput;
  where: HideAndSeekNpcScalarWhereInput;
};

export type HideAndSeekNpcUpdateManyWithoutHideAndSeekNestedInput = {
  connect?: InputMaybe<Array<HideAndSeekNpcWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<HideAndSeekNpcCreateOrConnectWithoutHideAndSeekInput>>;
  create?: InputMaybe<Array<HideAndSeekNpcCreateWithoutHideAndSeekInput>>;
  createMany?: InputMaybe<HideAndSeekNpcCreateManyHideAndSeekInputEnvelope>;
  delete?: InputMaybe<Array<HideAndSeekNpcWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<HideAndSeekNpcScalarWhereInput>>;
  disconnect?: InputMaybe<Array<HideAndSeekNpcWhereUniqueInput>>;
  set?: InputMaybe<Array<HideAndSeekNpcWhereUniqueInput>>;
  update?: InputMaybe<Array<HideAndSeekNpcUpdateWithWhereUniqueWithoutHideAndSeekInput>>;
  updateMany?: InputMaybe<Array<HideAndSeekNpcUpdateManyWithWhereWithoutHideAndSeekInput>>;
  upsert?: InputMaybe<Array<HideAndSeekNpcUpsertWithWhereUniqueWithoutHideAndSeekInput>>;
};

export type HideAndSeekNpcUpdateWithWhereUniqueWithoutHideAndSeekInput = {
  data: HideAndSeekNpcUpdateWithoutHideAndSeekInput;
  where: HideAndSeekNpcWhereUniqueInput;
};

export type HideAndSeekNpcUpdateWithoutHideAndSeekInput = {
  assetUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  propUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type HideAndSeekNpcUpsertWithWhereUniqueWithoutHideAndSeekInput = {
  create: HideAndSeekNpcCreateWithoutHideAndSeekInput;
  update: HideAndSeekNpcUpdateWithoutHideAndSeekInput;
  where: HideAndSeekNpcWhereUniqueInput;
};

export type HideAndSeekNpcWhereInput = {
  AND?: InputMaybe<Array<HideAndSeekNpcWhereInput>>;
  HideAndSeek?: InputMaybe<HideAndSeekNullableRelationFilter>;
  NOT?: InputMaybe<Array<HideAndSeekNpcWhereInput>>;
  OR?: InputMaybe<Array<HideAndSeekNpcWhereInput>>;
  assetUrl?: InputMaybe<StringFilter>;
  hideAndSeekId?: InputMaybe<UuidNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  propUrl?: InputMaybe<StringFilter>;
};

export type HideAndSeekNpcWhereUniqueInput = {
  AND?: InputMaybe<Array<HideAndSeekNpcWhereInput>>;
  HideAndSeek?: InputMaybe<HideAndSeekNullableRelationFilter>;
  NOT?: InputMaybe<Array<HideAndSeekNpcWhereInput>>;
  OR?: InputMaybe<Array<HideAndSeekNpcWhereInput>>;
  assetUrl?: InputMaybe<StringFilter>;
  hideAndSeekId?: InputMaybe<UuidNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  propUrl?: InputMaybe<StringFilter>;
};

export type HideAndSeekNullableRelationFilter = {
  is?: InputMaybe<HideAndSeekWhereInput>;
  isNot?: InputMaybe<HideAndSeekWhereInput>;
};

export type HideAndSeekOrderByWithAggregationInput = {
  _avg?: InputMaybe<HideAndSeekAvgOrderByAggregateInput>;
  _count?: InputMaybe<HideAndSeekCountOrderByAggregateInput>;
  _max?: InputMaybe<HideAndSeekMaxOrderByAggregateInput>;
  _min?: InputMaybe<HideAndSeekMinOrderByAggregateInput>;
  _sum?: InputMaybe<HideAndSeekSumOrderByAggregateInput>;
  finishPrompt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  searchTime?: InputMaybe<SortOrder>;
  startPrompt?: InputMaybe<SortOrder>;
};

export type HideAndSeekOrderByWithRelationInput = {
  UserScene?: InputMaybe<UserSceneOrderByRelationAggregateInput>;
  finishPrompt?: InputMaybe<SortOrder>;
  hideAndSeekNPCs?: InputMaybe<HideAndSeekNpcOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  searchTime?: InputMaybe<SortOrder>;
  startPrompt?: InputMaybe<SortOrder>;
};

export enum HideAndSeekScalarFieldEnum {
  FinishPrompt = 'finishPrompt',
  Id = 'id',
  SearchTime = 'searchTime',
  StartPrompt = 'startPrompt'
}

export type HideAndSeekScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<HideAndSeekScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<HideAndSeekScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<HideAndSeekScalarWhereWithAggregatesInput>>;
  finishPrompt?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  searchTime?: InputMaybe<IntWithAggregatesFilter>;
  startPrompt?: InputMaybe<StringWithAggregatesFilter>;
};

export type HideAndSeekSumAggregate = {
  __typename?: 'HideAndSeekSumAggregate';
  searchTime?: Maybe<Scalars['Int']['output']>;
};

export type HideAndSeekSumOrderByAggregateInput = {
  searchTime?: InputMaybe<SortOrder>;
};

export type HideAndSeekUpdateInput = {
  UserScene?: InputMaybe<UserSceneUpdateManyWithoutHideAndSeekNestedInput>;
  finishPrompt?: InputMaybe<StringFieldUpdateOperationsInput>;
  hideAndSeekNPCs?: InputMaybe<HideAndSeekNpcUpdateManyWithoutHideAndSeekNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  searchTime?: InputMaybe<IntFieldUpdateOperationsInput>;
  startPrompt?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type HideAndSeekUpdateManyMutationInput = {
  finishPrompt?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  searchTime?: InputMaybe<IntFieldUpdateOperationsInput>;
  startPrompt?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type HideAndSeekUpdateOneWithoutHideAndSeekNpCsNestedInput = {
  connect?: InputMaybe<HideAndSeekWhereUniqueInput>;
  connectOrCreate?: InputMaybe<HideAndSeekCreateOrConnectWithoutHideAndSeekNpCsInput>;
  create?: InputMaybe<HideAndSeekCreateWithoutHideAndSeekNpCsInput>;
  delete?: InputMaybe<HideAndSeekWhereInput>;
  disconnect?: InputMaybe<HideAndSeekWhereInput>;
  update?: InputMaybe<HideAndSeekUpdateToOneWithWhereWithoutHideAndSeekNpCsInput>;
  upsert?: InputMaybe<HideAndSeekUpsertWithoutHideAndSeekNpCsInput>;
};

export type HideAndSeekUpdateOneWithoutUserSceneNestedInput = {
  connect?: InputMaybe<HideAndSeekWhereUniqueInput>;
  connectOrCreate?: InputMaybe<HideAndSeekCreateOrConnectWithoutUserSceneInput>;
  create?: InputMaybe<HideAndSeekCreateWithoutUserSceneInput>;
  delete?: InputMaybe<HideAndSeekWhereInput>;
  disconnect?: InputMaybe<HideAndSeekWhereInput>;
  update?: InputMaybe<HideAndSeekUpdateToOneWithWhereWithoutUserSceneInput>;
  upsert?: InputMaybe<HideAndSeekUpsertWithoutUserSceneInput>;
};

export type HideAndSeekUpdateToOneWithWhereWithoutHideAndSeekNpCsInput = {
  data: HideAndSeekUpdateWithoutHideAndSeekNpCsInput;
  where?: InputMaybe<HideAndSeekWhereInput>;
};

export type HideAndSeekUpdateToOneWithWhereWithoutUserSceneInput = {
  data: HideAndSeekUpdateWithoutUserSceneInput;
  where?: InputMaybe<HideAndSeekWhereInput>;
};

export type HideAndSeekUpdateWithoutHideAndSeekNpCsInput = {
  UserScene?: InputMaybe<UserSceneUpdateManyWithoutHideAndSeekNestedInput>;
  finishPrompt?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  searchTime?: InputMaybe<IntFieldUpdateOperationsInput>;
  startPrompt?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type HideAndSeekUpdateWithoutUserSceneInput = {
  finishPrompt?: InputMaybe<StringFieldUpdateOperationsInput>;
  hideAndSeekNPCs?: InputMaybe<HideAndSeekNpcUpdateManyWithoutHideAndSeekNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  searchTime?: InputMaybe<IntFieldUpdateOperationsInput>;
  startPrompt?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type HideAndSeekUpsertWithoutHideAndSeekNpCsInput = {
  create: HideAndSeekCreateWithoutHideAndSeekNpCsInput;
  update: HideAndSeekUpdateWithoutHideAndSeekNpCsInput;
  where?: InputMaybe<HideAndSeekWhereInput>;
};

export type HideAndSeekUpsertWithoutUserSceneInput = {
  create: HideAndSeekCreateWithoutUserSceneInput;
  update: HideAndSeekUpdateWithoutUserSceneInput;
  where?: InputMaybe<HideAndSeekWhereInput>;
};

export type HideAndSeekWhereInput = {
  AND?: InputMaybe<Array<HideAndSeekWhereInput>>;
  NOT?: InputMaybe<Array<HideAndSeekWhereInput>>;
  OR?: InputMaybe<Array<HideAndSeekWhereInput>>;
  UserScene?: InputMaybe<UserSceneListRelationFilter>;
  finishPrompt?: InputMaybe<StringFilter>;
  hideAndSeekNPCs?: InputMaybe<HideAndSeekNpcListRelationFilter>;
  id?: InputMaybe<UuidFilter>;
  searchTime?: InputMaybe<IntFilter>;
  startPrompt?: InputMaybe<StringFilter>;
};

export type HideAndSeekWhereUniqueInput = {
  AND?: InputMaybe<Array<HideAndSeekWhereInput>>;
  NOT?: InputMaybe<Array<HideAndSeekWhereInput>>;
  OR?: InputMaybe<Array<HideAndSeekWhereInput>>;
  UserScene?: InputMaybe<UserSceneListRelationFilter>;
  finishPrompt?: InputMaybe<StringFilter>;
  hideAndSeekNPCs?: InputMaybe<HideAndSeekNpcListRelationFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  searchTime?: InputMaybe<IntFilter>;
  startPrompt?: InputMaybe<StringFilter>;
};

export type IncrementViewAssetInput = {
  assetId?: InputMaybe<Scalars['Float']['input']>;
  assetIdWeb2?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type IncrementViewAssetResponse = {
  __typename?: 'IncrementViewAssetResponse';
  status: Scalars['String']['output'];
};

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']['input']>;
  divide?: InputMaybe<Scalars['Int']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  multiply?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedIntNullableFilter>;
  _min?: InputMaybe<NestedIntNullableFilter>;
  _sum?: InputMaybe<NestedIntNullableFilter>;
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IsChatEnabledInSceneInput = {
  id: Scalars['String']['input'];
};

export type IsChatEnabledInSceneResponse = {
  __typename?: 'IsChatEnabledInSceneResponse';
  enabled: Scalars['Boolean']['output'];
};

export type IsEmptyCollectionInput = {
  id: Scalars['String']['input'];
};

export type IsEmptyCollectionResponse = {
  __typename?: 'IsEmptyCollectionResponse';
  isEmpty: Scalars['Boolean']['output'];
};

export type IsLikedAssetInput = {
  assetId?: InputMaybe<Scalars['Float']['input']>;
  assetIdWeb2?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type IsLikedAssetResponse = {
  __typename?: 'IsLikedAssetResponse';
  error?: Maybe<Scalars['String']['output']>;
  isLikes: Scalars['Boolean']['output'];
};

export type IsScanEnabledInSceneInput = {
  id: Scalars['String']['input'];
};

export type IsScanEnabledInSceneResponse = {
  __typename?: 'IsScanEnabledInSceneResponse';
  enabled: Scalars['Boolean']['output'];
};

export type Items = {
  __typename?: 'Items';
  assets?: Maybe<Array<Asset>>;
  collections?: Maybe<Array<TrendCollection>>;
};

export type LightProps = {
  __typename?: 'LightProps';
  _count?: Maybe<LightPropsCount>;
  angle?: Maybe<Scalars['Float']['output']>;
  color?: Maybe<Scalars['Int']['output']>;
  decay?: Maybe<Scalars['Float']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  groundColor?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  intensity: Scalars['Float']['output'];
  penumbra?: Maybe<Scalars['Float']['output']>;
  shadow?: Maybe<Scalars['Boolean']['output']>;
  skyColor?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type LightPropsAvgAggregate = {
  __typename?: 'LightPropsAvgAggregate';
  angle?: Maybe<Scalars['Float']['output']>;
  color?: Maybe<Scalars['Float']['output']>;
  decay?: Maybe<Scalars['Float']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  groundColor?: Maybe<Scalars['Float']['output']>;
  intensity?: Maybe<Scalars['Float']['output']>;
  penumbra?: Maybe<Scalars['Float']['output']>;
  skyColor?: Maybe<Scalars['Float']['output']>;
};

export type LightPropsAvgOrderByAggregateInput = {
  angle?: InputMaybe<SortOrder>;
  color?: InputMaybe<SortOrder>;
  decay?: InputMaybe<SortOrder>;
  distance?: InputMaybe<SortOrder>;
  groundColor?: InputMaybe<SortOrder>;
  intensity?: InputMaybe<SortOrder>;
  penumbra?: InputMaybe<SortOrder>;
  skyColor?: InputMaybe<SortOrder>;
};

export type LightPropsCount = {
  __typename?: 'LightPropsCount';
  assets: Scalars['Int']['output'];
};


export type LightPropsCountAssetsArgs = {
  where?: InputMaybe<UserSceneAssetsWhereInput>;
};

export type LightPropsCountAggregate = {
  __typename?: 'LightPropsCountAggregate';
  _all: Scalars['Int']['output'];
  angle: Scalars['Int']['output'];
  color: Scalars['Int']['output'];
  decay: Scalars['Int']['output'];
  distance: Scalars['Int']['output'];
  groundColor: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  intensity: Scalars['Int']['output'];
  penumbra: Scalars['Int']['output'];
  shadow: Scalars['Int']['output'];
  skyColor: Scalars['Int']['output'];
  type: Scalars['Int']['output'];
  visible: Scalars['Int']['output'];
};

export type LightPropsCountOrderByAggregateInput = {
  angle?: InputMaybe<SortOrder>;
  color?: InputMaybe<SortOrder>;
  decay?: InputMaybe<SortOrder>;
  distance?: InputMaybe<SortOrder>;
  groundColor?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  intensity?: InputMaybe<SortOrder>;
  penumbra?: InputMaybe<SortOrder>;
  shadow?: InputMaybe<SortOrder>;
  skyColor?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  visible?: InputMaybe<SortOrder>;
};

export type LightPropsCreateInput = {
  angle?: InputMaybe<Scalars['Float']['input']>;
  assets?: InputMaybe<UserSceneAssetsCreateNestedManyWithoutLightInput>;
  color?: InputMaybe<Scalars['Int']['input']>;
  decay?: InputMaybe<Scalars['Float']['input']>;
  distance?: InputMaybe<Scalars['Float']['input']>;
  groundColor?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intensity?: InputMaybe<Scalars['Float']['input']>;
  penumbra?: InputMaybe<Scalars['Float']['input']>;
  shadow?: InputMaybe<Scalars['Boolean']['input']>;
  skyColor?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LightPropsCreateManyInput = {
  angle?: InputMaybe<Scalars['Float']['input']>;
  color?: InputMaybe<Scalars['Int']['input']>;
  decay?: InputMaybe<Scalars['Float']['input']>;
  distance?: InputMaybe<Scalars['Float']['input']>;
  groundColor?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intensity?: InputMaybe<Scalars['Float']['input']>;
  penumbra?: InputMaybe<Scalars['Float']['input']>;
  shadow?: InputMaybe<Scalars['Boolean']['input']>;
  skyColor?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LightPropsCreateNestedOneWithoutAssetsInput = {
  connect?: InputMaybe<LightPropsWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LightPropsCreateOrConnectWithoutAssetsInput>;
  create?: InputMaybe<LightPropsCreateWithoutAssetsInput>;
};

export type LightPropsCreateOrConnectWithoutAssetsInput = {
  create: LightPropsCreateWithoutAssetsInput;
  where: LightPropsWhereUniqueInput;
};

export type LightPropsCreateWithoutAssetsInput = {
  angle?: InputMaybe<Scalars['Float']['input']>;
  color?: InputMaybe<Scalars['Int']['input']>;
  decay?: InputMaybe<Scalars['Float']['input']>;
  distance?: InputMaybe<Scalars['Float']['input']>;
  groundColor?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intensity?: InputMaybe<Scalars['Float']['input']>;
  penumbra?: InputMaybe<Scalars['Float']['input']>;
  shadow?: InputMaybe<Scalars['Boolean']['input']>;
  skyColor?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LightPropsGroupBy = {
  __typename?: 'LightPropsGroupBy';
  _avg?: Maybe<LightPropsAvgAggregate>;
  _count?: Maybe<LightPropsCountAggregate>;
  _max?: Maybe<LightPropsMaxAggregate>;
  _min?: Maybe<LightPropsMinAggregate>;
  _sum?: Maybe<LightPropsSumAggregate>;
  angle?: Maybe<Scalars['Float']['output']>;
  color?: Maybe<Scalars['Int']['output']>;
  decay?: Maybe<Scalars['Float']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  groundColor?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  intensity: Scalars['Float']['output'];
  penumbra?: Maybe<Scalars['Float']['output']>;
  shadow?: Maybe<Scalars['Boolean']['output']>;
  skyColor?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type LightPropsMaxAggregate = {
  __typename?: 'LightPropsMaxAggregate';
  angle?: Maybe<Scalars['Float']['output']>;
  color?: Maybe<Scalars['Int']['output']>;
  decay?: Maybe<Scalars['Float']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  groundColor?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  intensity?: Maybe<Scalars['Float']['output']>;
  penumbra?: Maybe<Scalars['Float']['output']>;
  shadow?: Maybe<Scalars['Boolean']['output']>;
  skyColor?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type LightPropsMaxOrderByAggregateInput = {
  angle?: InputMaybe<SortOrder>;
  color?: InputMaybe<SortOrder>;
  decay?: InputMaybe<SortOrder>;
  distance?: InputMaybe<SortOrder>;
  groundColor?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  intensity?: InputMaybe<SortOrder>;
  penumbra?: InputMaybe<SortOrder>;
  shadow?: InputMaybe<SortOrder>;
  skyColor?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  visible?: InputMaybe<SortOrder>;
};

export type LightPropsMinAggregate = {
  __typename?: 'LightPropsMinAggregate';
  angle?: Maybe<Scalars['Float']['output']>;
  color?: Maybe<Scalars['Int']['output']>;
  decay?: Maybe<Scalars['Float']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  groundColor?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  intensity?: Maybe<Scalars['Float']['output']>;
  penumbra?: Maybe<Scalars['Float']['output']>;
  shadow?: Maybe<Scalars['Boolean']['output']>;
  skyColor?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type LightPropsMinOrderByAggregateInput = {
  angle?: InputMaybe<SortOrder>;
  color?: InputMaybe<SortOrder>;
  decay?: InputMaybe<SortOrder>;
  distance?: InputMaybe<SortOrder>;
  groundColor?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  intensity?: InputMaybe<SortOrder>;
  penumbra?: InputMaybe<SortOrder>;
  shadow?: InputMaybe<SortOrder>;
  skyColor?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  visible?: InputMaybe<SortOrder>;
};

export type LightPropsNullableRelationFilter = {
  is?: InputMaybe<LightPropsWhereInput>;
  isNot?: InputMaybe<LightPropsWhereInput>;
};

export type LightPropsOrderByWithAggregationInput = {
  _avg?: InputMaybe<LightPropsAvgOrderByAggregateInput>;
  _count?: InputMaybe<LightPropsCountOrderByAggregateInput>;
  _max?: InputMaybe<LightPropsMaxOrderByAggregateInput>;
  _min?: InputMaybe<LightPropsMinOrderByAggregateInput>;
  _sum?: InputMaybe<LightPropsSumOrderByAggregateInput>;
  angle?: InputMaybe<SortOrderInput>;
  color?: InputMaybe<SortOrderInput>;
  decay?: InputMaybe<SortOrderInput>;
  distance?: InputMaybe<SortOrderInput>;
  groundColor?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  intensity?: InputMaybe<SortOrder>;
  penumbra?: InputMaybe<SortOrderInput>;
  shadow?: InputMaybe<SortOrderInput>;
  skyColor?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrder>;
  visible?: InputMaybe<SortOrderInput>;
};

export type LightPropsOrderByWithRelationInput = {
  angle?: InputMaybe<SortOrderInput>;
  assets?: InputMaybe<UserSceneAssetsOrderByRelationAggregateInput>;
  color?: InputMaybe<SortOrderInput>;
  decay?: InputMaybe<SortOrderInput>;
  distance?: InputMaybe<SortOrderInput>;
  groundColor?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  intensity?: InputMaybe<SortOrder>;
  penumbra?: InputMaybe<SortOrderInput>;
  shadow?: InputMaybe<SortOrderInput>;
  skyColor?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrder>;
  visible?: InputMaybe<SortOrderInput>;
};

export enum LightPropsScalarFieldEnum {
  Angle = 'angle',
  Color = 'color',
  Decay = 'decay',
  Distance = 'distance',
  GroundColor = 'groundColor',
  Id = 'id',
  Intensity = 'intensity',
  Penumbra = 'penumbra',
  Shadow = 'shadow',
  SkyColor = 'skyColor',
  Type = 'type',
  Visible = 'visible'
}

export type LightPropsScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<LightPropsScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<LightPropsScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<LightPropsScalarWhereWithAggregatesInput>>;
  angle?: InputMaybe<FloatNullableWithAggregatesFilter>;
  color?: InputMaybe<IntNullableWithAggregatesFilter>;
  decay?: InputMaybe<FloatNullableWithAggregatesFilter>;
  distance?: InputMaybe<FloatNullableWithAggregatesFilter>;
  groundColor?: InputMaybe<IntNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  intensity?: InputMaybe<FloatWithAggregatesFilter>;
  penumbra?: InputMaybe<FloatNullableWithAggregatesFilter>;
  shadow?: InputMaybe<BoolNullableWithAggregatesFilter>;
  skyColor?: InputMaybe<IntNullableWithAggregatesFilter>;
  type?: InputMaybe<StringWithAggregatesFilter>;
  visible?: InputMaybe<BoolNullableWithAggregatesFilter>;
};

export type LightPropsSumAggregate = {
  __typename?: 'LightPropsSumAggregate';
  angle?: Maybe<Scalars['Float']['output']>;
  color?: Maybe<Scalars['Int']['output']>;
  decay?: Maybe<Scalars['Float']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  groundColor?: Maybe<Scalars['Int']['output']>;
  intensity?: Maybe<Scalars['Float']['output']>;
  penumbra?: Maybe<Scalars['Float']['output']>;
  skyColor?: Maybe<Scalars['Int']['output']>;
};

export type LightPropsSumOrderByAggregateInput = {
  angle?: InputMaybe<SortOrder>;
  color?: InputMaybe<SortOrder>;
  decay?: InputMaybe<SortOrder>;
  distance?: InputMaybe<SortOrder>;
  groundColor?: InputMaybe<SortOrder>;
  intensity?: InputMaybe<SortOrder>;
  penumbra?: InputMaybe<SortOrder>;
  skyColor?: InputMaybe<SortOrder>;
};

export type LightPropsUpdateInput = {
  angle?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  assets?: InputMaybe<UserSceneAssetsUpdateManyWithoutLightNestedInput>;
  color?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  decay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  distance?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  groundColor?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  intensity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  penumbra?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  shadow?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  skyColor?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  visible?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
};

export type LightPropsUpdateManyMutationInput = {
  angle?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  color?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  decay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  distance?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  groundColor?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  intensity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  penumbra?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  shadow?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  skyColor?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  visible?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
};

export type LightPropsUpdateOneWithoutAssetsNestedInput = {
  connect?: InputMaybe<LightPropsWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LightPropsCreateOrConnectWithoutAssetsInput>;
  create?: InputMaybe<LightPropsCreateWithoutAssetsInput>;
  delete?: InputMaybe<LightPropsWhereInput>;
  disconnect?: InputMaybe<LightPropsWhereInput>;
  update?: InputMaybe<LightPropsUpdateToOneWithWhereWithoutAssetsInput>;
  upsert?: InputMaybe<LightPropsUpsertWithoutAssetsInput>;
};

export type LightPropsUpdateToOneWithWhereWithoutAssetsInput = {
  data: LightPropsUpdateWithoutAssetsInput;
  where?: InputMaybe<LightPropsWhereInput>;
};

export type LightPropsUpdateWithoutAssetsInput = {
  angle?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  color?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  decay?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  distance?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  groundColor?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  intensity?: InputMaybe<FloatFieldUpdateOperationsInput>;
  penumbra?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  shadow?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  skyColor?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  visible?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
};

export type LightPropsUpsertWithoutAssetsInput = {
  create: LightPropsCreateWithoutAssetsInput;
  update: LightPropsUpdateWithoutAssetsInput;
  where?: InputMaybe<LightPropsWhereInput>;
};

export type LightPropsWhereInput = {
  AND?: InputMaybe<Array<LightPropsWhereInput>>;
  NOT?: InputMaybe<Array<LightPropsWhereInput>>;
  OR?: InputMaybe<Array<LightPropsWhereInput>>;
  angle?: InputMaybe<FloatNullableFilter>;
  assets?: InputMaybe<UserSceneAssetsListRelationFilter>;
  color?: InputMaybe<IntNullableFilter>;
  decay?: InputMaybe<FloatNullableFilter>;
  distance?: InputMaybe<FloatNullableFilter>;
  groundColor?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  intensity?: InputMaybe<FloatFilter>;
  penumbra?: InputMaybe<FloatNullableFilter>;
  shadow?: InputMaybe<BoolNullableFilter>;
  skyColor?: InputMaybe<IntNullableFilter>;
  type?: InputMaybe<StringFilter>;
  visible?: InputMaybe<BoolNullableFilter>;
};

export type LightPropsWhereUniqueInput = {
  AND?: InputMaybe<Array<LightPropsWhereInput>>;
  NOT?: InputMaybe<Array<LightPropsWhereInput>>;
  OR?: InputMaybe<Array<LightPropsWhereInput>>;
  angle?: InputMaybe<FloatNullableFilter>;
  assets?: InputMaybe<UserSceneAssetsListRelationFilter>;
  color?: InputMaybe<IntNullableFilter>;
  decay?: InputMaybe<FloatNullableFilter>;
  distance?: InputMaybe<FloatNullableFilter>;
  groundColor?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  intensity?: InputMaybe<FloatFilter>;
  penumbra?: InputMaybe<FloatNullableFilter>;
  shadow?: InputMaybe<BoolNullableFilter>;
  skyColor?: InputMaybe<IntNullableFilter>;
  type?: InputMaybe<StringFilter>;
  visible?: InputMaybe<BoolNullableFilter>;
};

export type LightTemplate = {
  __typename?: 'LightTemplate';
  id: Scalars['String']['output'];
  subscriptionPlanId: Scalars['String']['output'];
  templateIndex: Scalars['Int']['output'];
};

export type LightTemplateAvgAggregate = {
  __typename?: 'LightTemplateAvgAggregate';
  templateIndex?: Maybe<Scalars['Float']['output']>;
};

export type LightTemplateAvgOrderByAggregateInput = {
  templateIndex?: InputMaybe<SortOrder>;
};

export type LightTemplateCountAggregate = {
  __typename?: 'LightTemplateCountAggregate';
  _all: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  subscriptionPlanId: Scalars['Int']['output'];
  templateIndex: Scalars['Int']['output'];
};

export type LightTemplateCountOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  subscriptionPlanId?: InputMaybe<SortOrder>;
  templateIndex?: InputMaybe<SortOrder>;
};

export type LightTemplateCreateInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  subscription: SubscriptionPlanCreateNestedOneWithoutLightTemplateInput;
  templateIndex: Scalars['Int']['input'];
};

export type LightTemplateCreateManyInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  subscriptionPlanId: Scalars['String']['input'];
  templateIndex: Scalars['Int']['input'];
};

export type LightTemplateCreateManySubscriptionInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  templateIndex: Scalars['Int']['input'];
};

export type LightTemplateCreateManySubscriptionInputEnvelope = {
  data: Array<LightTemplateCreateManySubscriptionInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LightTemplateCreateNestedManyWithoutSubscriptionInput = {
  connect?: InputMaybe<Array<LightTemplateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LightTemplateCreateOrConnectWithoutSubscriptionInput>>;
  create?: InputMaybe<Array<LightTemplateCreateWithoutSubscriptionInput>>;
  createMany?: InputMaybe<LightTemplateCreateManySubscriptionInputEnvelope>;
};

export type LightTemplateCreateOrConnectWithoutSubscriptionInput = {
  create: LightTemplateCreateWithoutSubscriptionInput;
  where: LightTemplateWhereUniqueInput;
};

export type LightTemplateCreateWithoutSubscriptionInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  templateIndex: Scalars['Int']['input'];
};

export type LightTemplateGroupBy = {
  __typename?: 'LightTemplateGroupBy';
  _avg?: Maybe<LightTemplateAvgAggregate>;
  _count?: Maybe<LightTemplateCountAggregate>;
  _max?: Maybe<LightTemplateMaxAggregate>;
  _min?: Maybe<LightTemplateMinAggregate>;
  _sum?: Maybe<LightTemplateSumAggregate>;
  id: Scalars['String']['output'];
  subscriptionPlanId: Scalars['String']['output'];
  templateIndex: Scalars['Int']['output'];
};

export type LightTemplateListRelationFilter = {
  every?: InputMaybe<LightTemplateWhereInput>;
  none?: InputMaybe<LightTemplateWhereInput>;
  some?: InputMaybe<LightTemplateWhereInput>;
};

export type LightTemplateMaxAggregate = {
  __typename?: 'LightTemplateMaxAggregate';
  id?: Maybe<Scalars['String']['output']>;
  subscriptionPlanId?: Maybe<Scalars['String']['output']>;
  templateIndex?: Maybe<Scalars['Int']['output']>;
};

export type LightTemplateMaxOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  subscriptionPlanId?: InputMaybe<SortOrder>;
  templateIndex?: InputMaybe<SortOrder>;
};

export type LightTemplateMinAggregate = {
  __typename?: 'LightTemplateMinAggregate';
  id?: Maybe<Scalars['String']['output']>;
  subscriptionPlanId?: Maybe<Scalars['String']['output']>;
  templateIndex?: Maybe<Scalars['Int']['output']>;
};

export type LightTemplateMinOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  subscriptionPlanId?: InputMaybe<SortOrder>;
  templateIndex?: InputMaybe<SortOrder>;
};

export type LightTemplateOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type LightTemplateOrderByWithAggregationInput = {
  _avg?: InputMaybe<LightTemplateAvgOrderByAggregateInput>;
  _count?: InputMaybe<LightTemplateCountOrderByAggregateInput>;
  _max?: InputMaybe<LightTemplateMaxOrderByAggregateInput>;
  _min?: InputMaybe<LightTemplateMinOrderByAggregateInput>;
  _sum?: InputMaybe<LightTemplateSumOrderByAggregateInput>;
  id?: InputMaybe<SortOrder>;
  subscriptionPlanId?: InputMaybe<SortOrder>;
  templateIndex?: InputMaybe<SortOrder>;
};

export type LightTemplateOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  subscription?: InputMaybe<SubscriptionPlanOrderByWithRelationInput>;
  subscriptionPlanId?: InputMaybe<SortOrder>;
  templateIndex?: InputMaybe<SortOrder>;
};

export enum LightTemplateScalarFieldEnum {
  Id = 'id',
  SubscriptionPlanId = 'subscriptionPlanId',
  TemplateIndex = 'templateIndex'
}

export type LightTemplateScalarWhereInput = {
  AND?: InputMaybe<Array<LightTemplateScalarWhereInput>>;
  NOT?: InputMaybe<Array<LightTemplateScalarWhereInput>>;
  OR?: InputMaybe<Array<LightTemplateScalarWhereInput>>;
  id?: InputMaybe<UuidFilter>;
  subscriptionPlanId?: InputMaybe<UuidFilter>;
  templateIndex?: InputMaybe<IntFilter>;
};

export type LightTemplateScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<LightTemplateScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<LightTemplateScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<LightTemplateScalarWhereWithAggregatesInput>>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  subscriptionPlanId?: InputMaybe<UuidWithAggregatesFilter>;
  templateIndex?: InputMaybe<IntWithAggregatesFilter>;
};

export type LightTemplateSumAggregate = {
  __typename?: 'LightTemplateSumAggregate';
  templateIndex?: Maybe<Scalars['Int']['output']>;
};

export type LightTemplateSumOrderByAggregateInput = {
  templateIndex?: InputMaybe<SortOrder>;
};

export type LightTemplateUpdateInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  subscription?: InputMaybe<SubscriptionPlanUpdateOneRequiredWithoutLightTemplateNestedInput>;
  templateIndex?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type LightTemplateUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  templateIndex?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type LightTemplateUpdateManyWithWhereWithoutSubscriptionInput = {
  data: LightTemplateUpdateManyMutationInput;
  where: LightTemplateScalarWhereInput;
};

export type LightTemplateUpdateManyWithoutSubscriptionNestedInput = {
  connect?: InputMaybe<Array<LightTemplateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LightTemplateCreateOrConnectWithoutSubscriptionInput>>;
  create?: InputMaybe<Array<LightTemplateCreateWithoutSubscriptionInput>>;
  createMany?: InputMaybe<LightTemplateCreateManySubscriptionInputEnvelope>;
  delete?: InputMaybe<Array<LightTemplateWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LightTemplateScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LightTemplateWhereUniqueInput>>;
  set?: InputMaybe<Array<LightTemplateWhereUniqueInput>>;
  update?: InputMaybe<Array<LightTemplateUpdateWithWhereUniqueWithoutSubscriptionInput>>;
  updateMany?: InputMaybe<Array<LightTemplateUpdateManyWithWhereWithoutSubscriptionInput>>;
  upsert?: InputMaybe<Array<LightTemplateUpsertWithWhereUniqueWithoutSubscriptionInput>>;
};

export type LightTemplateUpdateWithWhereUniqueWithoutSubscriptionInput = {
  data: LightTemplateUpdateWithoutSubscriptionInput;
  where: LightTemplateWhereUniqueInput;
};

export type LightTemplateUpdateWithoutSubscriptionInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  templateIndex?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type LightTemplateUpsertWithWhereUniqueWithoutSubscriptionInput = {
  create: LightTemplateCreateWithoutSubscriptionInput;
  update: LightTemplateUpdateWithoutSubscriptionInput;
  where: LightTemplateWhereUniqueInput;
};

export type LightTemplateWhereInput = {
  AND?: InputMaybe<Array<LightTemplateWhereInput>>;
  NOT?: InputMaybe<Array<LightTemplateWhereInput>>;
  OR?: InputMaybe<Array<LightTemplateWhereInput>>;
  id?: InputMaybe<UuidFilter>;
  subscription?: InputMaybe<SubscriptionPlanRelationFilter>;
  subscriptionPlanId?: InputMaybe<UuidFilter>;
  templateIndex?: InputMaybe<IntFilter>;
};

export type LightTemplateWhereUniqueInput = {
  AND?: InputMaybe<Array<LightTemplateWhereInput>>;
  NOT?: InputMaybe<Array<LightTemplateWhereInput>>;
  OR?: InputMaybe<Array<LightTemplateWhereInput>>;
  id?: InputMaybe<Scalars['String']['input']>;
  subscription?: InputMaybe<SubscriptionPlanRelationFilter>;
  subscriptionPlanId?: InputMaybe<UuidFilter>;
  templateIndex?: InputMaybe<IntFilter>;
};

export type LikeAssetByUser = {
  __typename?: 'LikeAssetByUser';
  _count?: Maybe<LikeAssetByUserCount>;
  userId: Scalars['String']['output'];
};

export type LikeAssetByUserCount = {
  __typename?: 'LikeAssetByUserCount';
  likesAssets: Scalars['Int']['output'];
  likesAssetsWeb2: Scalars['Int']['output'];
};


export type LikeAssetByUserCountLikesAssetsArgs = {
  where?: InputMaybe<AssetWhereInput>;
};


export type LikeAssetByUserCountLikesAssetsWeb2Args = {
  where?: InputMaybe<AssetWeb2WhereInput>;
};

export type LikeAssetByUserListRelationFilter = {
  every?: InputMaybe<LikeAssetByUserWhereInput>;
  none?: InputMaybe<LikeAssetByUserWhereInput>;
  some?: InputMaybe<LikeAssetByUserWhereInput>;
};

export type LikeAssetByUserWhereInput = {
  AND?: InputMaybe<Array<LikeAssetByUserWhereInput>>;
  NOT?: InputMaybe<Array<LikeAssetByUserWhereInput>>;
  OR?: InputMaybe<Array<LikeAssetByUserWhereInput>>;
  likesAssets?: InputMaybe<AssetListRelationFilter>;
  likesAssetsWeb2?: InputMaybe<AssetWeb2ListRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type LikeAssetInput = {
  assetId?: InputMaybe<Scalars['Float']['input']>;
  assetIdWeb2?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type LikeAssetResponse = {
  __typename?: 'LikeAssetResponse';
  status: Scalars['String']['output'];
};

export type Login = {
  __typename?: 'Login';
  isLoggedIn: Scalars['Boolean']['output'];
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MfesTemplate = {
  __typename?: 'MFESTemplate';
  _count?: Maybe<MfesTemplateCount>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  subscriptionPlanId: Scalars['String']['output'];
};

export type MfesTemplateCount = {
  __typename?: 'MFESTemplateCount';
  scenes: Scalars['Int']['output'];
};


export type MfesTemplateCountScenesArgs = {
  where?: InputMaybe<MfeSceneTemplateWhereInput>;
};

export type MfesTemplateCountAggregate = {
  __typename?: 'MFESTemplateCountAggregate';
  _all: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  subscriptionPlanId: Scalars['Int']['output'];
};

export type MfesTemplateCountOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  subscriptionPlanId?: InputMaybe<SortOrder>;
};

export type MfesTemplateCreateInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  scenes?: InputMaybe<MfeSceneTemplateCreateNestedManyWithoutMfesTemplateInput>;
  subscription: SubscriptionPlanCreateNestedOneWithoutMfesTemplateInput;
};

export type MfesTemplateCreateManyInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  subscriptionPlanId: Scalars['String']['input'];
};

export type MfesTemplateCreateManySubscriptionInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type MfesTemplateCreateManySubscriptionInputEnvelope = {
  data: Array<MfesTemplateCreateManySubscriptionInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MfesTemplateCreateNestedManyWithoutSubscriptionInput = {
  connect?: InputMaybe<Array<MfesTemplateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MfesTemplateCreateOrConnectWithoutSubscriptionInput>>;
  create?: InputMaybe<Array<MfesTemplateCreateWithoutSubscriptionInput>>;
  createMany?: InputMaybe<MfesTemplateCreateManySubscriptionInputEnvelope>;
};

export type MfesTemplateCreateNestedOneWithoutScenesInput = {
  connect?: InputMaybe<MfesTemplateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MfesTemplateCreateOrConnectWithoutScenesInput>;
  create?: InputMaybe<MfesTemplateCreateWithoutScenesInput>;
};

export type MfesTemplateCreateOrConnectWithoutScenesInput = {
  create: MfesTemplateCreateWithoutScenesInput;
  where: MfesTemplateWhereUniqueInput;
};

export type MfesTemplateCreateOrConnectWithoutSubscriptionInput = {
  create: MfesTemplateCreateWithoutSubscriptionInput;
  where: MfesTemplateWhereUniqueInput;
};

export type MfesTemplateCreateWithoutScenesInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  subscription: SubscriptionPlanCreateNestedOneWithoutMfesTemplateInput;
};

export type MfesTemplateCreateWithoutSubscriptionInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  scenes?: InputMaybe<MfeSceneTemplateCreateNestedManyWithoutMfesTemplateInput>;
};

export type MfesTemplateGroupBy = {
  __typename?: 'MFESTemplateGroupBy';
  _count?: Maybe<MfesTemplateCountAggregate>;
  _max?: Maybe<MfesTemplateMaxAggregate>;
  _min?: Maybe<MfesTemplateMinAggregate>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  subscriptionPlanId: Scalars['String']['output'];
};

export type MfesTemplateListRelationFilter = {
  every?: InputMaybe<MfesTemplateWhereInput>;
  none?: InputMaybe<MfesTemplateWhereInput>;
  some?: InputMaybe<MfesTemplateWhereInput>;
};

export type MfesTemplateMaxAggregate = {
  __typename?: 'MFESTemplateMaxAggregate';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  subscriptionPlanId?: Maybe<Scalars['String']['output']>;
};

export type MfesTemplateMaxOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  subscriptionPlanId?: InputMaybe<SortOrder>;
};

export type MfesTemplateMinAggregate = {
  __typename?: 'MFESTemplateMinAggregate';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  subscriptionPlanId?: Maybe<Scalars['String']['output']>;
};

export type MfesTemplateMinOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  subscriptionPlanId?: InputMaybe<SortOrder>;
};

export type MfesTemplateOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type MfesTemplateOrderByWithAggregationInput = {
  _count?: InputMaybe<MfesTemplateCountOrderByAggregateInput>;
  _max?: InputMaybe<MfesTemplateMaxOrderByAggregateInput>;
  _min?: InputMaybe<MfesTemplateMinOrderByAggregateInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  subscriptionPlanId?: InputMaybe<SortOrder>;
};

export type MfesTemplateOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  scenes?: InputMaybe<MfeSceneTemplateOrderByRelationAggregateInput>;
  subscription?: InputMaybe<SubscriptionPlanOrderByWithRelationInput>;
  subscriptionPlanId?: InputMaybe<SortOrder>;
};

export type MfesTemplateRelationFilter = {
  is?: InputMaybe<MfesTemplateWhereInput>;
  isNot?: InputMaybe<MfesTemplateWhereInput>;
};

export enum MfesTemplateScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  SubscriptionPlanId = 'subscriptionPlanId'
}

export type MfesTemplateScalarWhereInput = {
  AND?: InputMaybe<Array<MfesTemplateScalarWhereInput>>;
  NOT?: InputMaybe<Array<MfesTemplateScalarWhereInput>>;
  OR?: InputMaybe<Array<MfesTemplateScalarWhereInput>>;
  id?: InputMaybe<UuidFilter>;
  name?: InputMaybe<StringFilter>;
  subscriptionPlanId?: InputMaybe<UuidFilter>;
};

export type MfesTemplateScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<MfesTemplateScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<MfesTemplateScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<MfesTemplateScalarWhereWithAggregatesInput>>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  subscriptionPlanId?: InputMaybe<UuidWithAggregatesFilter>;
};

export type MfesTemplateUpdateInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  scenes?: InputMaybe<MfeSceneTemplateUpdateManyWithoutMfesTemplateNestedInput>;
  subscription?: InputMaybe<SubscriptionPlanUpdateOneRequiredWithoutMfesTemplateNestedInput>;
};

export type MfesTemplateUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type MfesTemplateUpdateManyWithWhereWithoutSubscriptionInput = {
  data: MfesTemplateUpdateManyMutationInput;
  where: MfesTemplateScalarWhereInput;
};

export type MfesTemplateUpdateManyWithoutSubscriptionNestedInput = {
  connect?: InputMaybe<Array<MfesTemplateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MfesTemplateCreateOrConnectWithoutSubscriptionInput>>;
  create?: InputMaybe<Array<MfesTemplateCreateWithoutSubscriptionInput>>;
  createMany?: InputMaybe<MfesTemplateCreateManySubscriptionInputEnvelope>;
  delete?: InputMaybe<Array<MfesTemplateWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MfesTemplateScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MfesTemplateWhereUniqueInput>>;
  set?: InputMaybe<Array<MfesTemplateWhereUniqueInput>>;
  update?: InputMaybe<Array<MfesTemplateUpdateWithWhereUniqueWithoutSubscriptionInput>>;
  updateMany?: InputMaybe<Array<MfesTemplateUpdateManyWithWhereWithoutSubscriptionInput>>;
  upsert?: InputMaybe<Array<MfesTemplateUpsertWithWhereUniqueWithoutSubscriptionInput>>;
};

export type MfesTemplateUpdateOneRequiredWithoutScenesNestedInput = {
  connect?: InputMaybe<MfesTemplateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MfesTemplateCreateOrConnectWithoutScenesInput>;
  create?: InputMaybe<MfesTemplateCreateWithoutScenesInput>;
  update?: InputMaybe<MfesTemplateUpdateToOneWithWhereWithoutScenesInput>;
  upsert?: InputMaybe<MfesTemplateUpsertWithoutScenesInput>;
};

export type MfesTemplateUpdateToOneWithWhereWithoutScenesInput = {
  data: MfesTemplateUpdateWithoutScenesInput;
  where?: InputMaybe<MfesTemplateWhereInput>;
};

export type MfesTemplateUpdateWithWhereUniqueWithoutSubscriptionInput = {
  data: MfesTemplateUpdateWithoutSubscriptionInput;
  where: MfesTemplateWhereUniqueInput;
};

export type MfesTemplateUpdateWithoutScenesInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  subscription?: InputMaybe<SubscriptionPlanUpdateOneRequiredWithoutMfesTemplateNestedInput>;
};

export type MfesTemplateUpdateWithoutSubscriptionInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  scenes?: InputMaybe<MfeSceneTemplateUpdateManyWithoutMfesTemplateNestedInput>;
};

export type MfesTemplateUpsertWithWhereUniqueWithoutSubscriptionInput = {
  create: MfesTemplateCreateWithoutSubscriptionInput;
  update: MfesTemplateUpdateWithoutSubscriptionInput;
  where: MfesTemplateWhereUniqueInput;
};

export type MfesTemplateUpsertWithoutScenesInput = {
  create: MfesTemplateCreateWithoutScenesInput;
  update: MfesTemplateUpdateWithoutScenesInput;
  where?: InputMaybe<MfesTemplateWhereInput>;
};

export type MfesTemplateWhereInput = {
  AND?: InputMaybe<Array<MfesTemplateWhereInput>>;
  NOT?: InputMaybe<Array<MfesTemplateWhereInput>>;
  OR?: InputMaybe<Array<MfesTemplateWhereInput>>;
  id?: InputMaybe<UuidFilter>;
  name?: InputMaybe<StringFilter>;
  scenes?: InputMaybe<MfeSceneTemplateListRelationFilter>;
  subscription?: InputMaybe<SubscriptionPlanRelationFilter>;
  subscriptionPlanId?: InputMaybe<UuidFilter>;
};

export type MfesTemplateWhereUniqueInput = {
  AND?: InputMaybe<Array<MfesTemplateWhereInput>>;
  NOT?: InputMaybe<Array<MfesTemplateWhereInput>>;
  OR?: InputMaybe<Array<MfesTemplateWhereInput>>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<StringFilter>;
  scenes?: InputMaybe<MfeSceneTemplateListRelationFilter>;
  subscription?: InputMaybe<SubscriptionPlanRelationFilter>;
  subscriptionPlanId?: InputMaybe<UuidFilter>;
};

export type MfeSceneTemplate = {
  __typename?: 'MFESceneTemplate';
  MFESTemplateId: Scalars['String']['output'];
  baseModelURL: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  thumbnail?: Maybe<Scalars['String']['output']>;
};

export type MfeSceneTemplateCountAggregate = {
  __typename?: 'MFESceneTemplateCountAggregate';
  MFESTemplateId: Scalars['Int']['output'];
  _all: Scalars['Int']['output'];
  baseModelURL: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  thumbnail: Scalars['Int']['output'];
};

export type MfeSceneTemplateCountOrderByAggregateInput = {
  MFESTemplateId?: InputMaybe<SortOrder>;
  baseModelURL?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  thumbnail?: InputMaybe<SortOrder>;
};

export type MfeSceneTemplateCreateInput = {
  MFESTemplate: MfesTemplateCreateNestedOneWithoutScenesInput;
  baseModelURL: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  thumbnail?: InputMaybe<Scalars['String']['input']>;
};

export type MfeSceneTemplateCreateManyInput = {
  MFESTemplateId: Scalars['String']['input'];
  baseModelURL: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  thumbnail?: InputMaybe<Scalars['String']['input']>;
};

export type MfeSceneTemplateCreateManyMfesTemplateInput = {
  baseModelURL: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  thumbnail?: InputMaybe<Scalars['String']['input']>;
};

export type MfeSceneTemplateCreateManyMfesTemplateInputEnvelope = {
  data: Array<MfeSceneTemplateCreateManyMfesTemplateInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MfeSceneTemplateCreateNestedManyWithoutMfesTemplateInput = {
  connect?: InputMaybe<Array<MfeSceneTemplateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MfeSceneTemplateCreateOrConnectWithoutMfesTemplateInput>>;
  create?: InputMaybe<Array<MfeSceneTemplateCreateWithoutMfesTemplateInput>>;
  createMany?: InputMaybe<MfeSceneTemplateCreateManyMfesTemplateInputEnvelope>;
};

export type MfeSceneTemplateCreateOrConnectWithoutMfesTemplateInput = {
  create: MfeSceneTemplateCreateWithoutMfesTemplateInput;
  where: MfeSceneTemplateWhereUniqueInput;
};

export type MfeSceneTemplateCreateWithoutMfesTemplateInput = {
  baseModelURL: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  thumbnail?: InputMaybe<Scalars['String']['input']>;
};

export type MfeSceneTemplateGroupBy = {
  __typename?: 'MFESceneTemplateGroupBy';
  MFESTemplateId: Scalars['String']['output'];
  _count?: Maybe<MfeSceneTemplateCountAggregate>;
  _max?: Maybe<MfeSceneTemplateMaxAggregate>;
  _min?: Maybe<MfeSceneTemplateMinAggregate>;
  baseModelURL: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  thumbnail?: Maybe<Scalars['String']['output']>;
};

export type MfeSceneTemplateListRelationFilter = {
  every?: InputMaybe<MfeSceneTemplateWhereInput>;
  none?: InputMaybe<MfeSceneTemplateWhereInput>;
  some?: InputMaybe<MfeSceneTemplateWhereInput>;
};

export type MfeSceneTemplateMaxAggregate = {
  __typename?: 'MFESceneTemplateMaxAggregate';
  MFESTemplateId?: Maybe<Scalars['String']['output']>;
  baseModelURL?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
};

export type MfeSceneTemplateMaxOrderByAggregateInput = {
  MFESTemplateId?: InputMaybe<SortOrder>;
  baseModelURL?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  thumbnail?: InputMaybe<SortOrder>;
};

export type MfeSceneTemplateMinAggregate = {
  __typename?: 'MFESceneTemplateMinAggregate';
  MFESTemplateId?: Maybe<Scalars['String']['output']>;
  baseModelURL?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
};

export type MfeSceneTemplateMinOrderByAggregateInput = {
  MFESTemplateId?: InputMaybe<SortOrder>;
  baseModelURL?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  thumbnail?: InputMaybe<SortOrder>;
};

export type MfeSceneTemplateOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type MfeSceneTemplateOrderByWithAggregationInput = {
  MFESTemplateId?: InputMaybe<SortOrder>;
  _count?: InputMaybe<MfeSceneTemplateCountOrderByAggregateInput>;
  _max?: InputMaybe<MfeSceneTemplateMaxOrderByAggregateInput>;
  _min?: InputMaybe<MfeSceneTemplateMinOrderByAggregateInput>;
  baseModelURL?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  thumbnail?: InputMaybe<SortOrderInput>;
};

export type MfeSceneTemplateOrderByWithRelationInput = {
  MFESTemplate?: InputMaybe<MfesTemplateOrderByWithRelationInput>;
  MFESTemplateId?: InputMaybe<SortOrder>;
  baseModelURL?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  thumbnail?: InputMaybe<SortOrderInput>;
};

export enum MfeSceneTemplateScalarFieldEnum {
  MfesTemplateId = 'MFESTemplateId',
  BaseModelUrl = 'baseModelURL',
  Id = 'id',
  Name = 'name',
  Thumbnail = 'thumbnail'
}

export type MfeSceneTemplateScalarWhereInput = {
  AND?: InputMaybe<Array<MfeSceneTemplateScalarWhereInput>>;
  MFESTemplateId?: InputMaybe<UuidFilter>;
  NOT?: InputMaybe<Array<MfeSceneTemplateScalarWhereInput>>;
  OR?: InputMaybe<Array<MfeSceneTemplateScalarWhereInput>>;
  baseModelURL?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  name?: InputMaybe<StringFilter>;
  thumbnail?: InputMaybe<StringNullableFilter>;
};

export type MfeSceneTemplateScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<MfeSceneTemplateScalarWhereWithAggregatesInput>>;
  MFESTemplateId?: InputMaybe<UuidWithAggregatesFilter>;
  NOT?: InputMaybe<Array<MfeSceneTemplateScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<MfeSceneTemplateScalarWhereWithAggregatesInput>>;
  baseModelURL?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  thumbnail?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type MfeSceneTemplateUpdateInput = {
  MFESTemplate?: InputMaybe<MfesTemplateUpdateOneRequiredWithoutScenesNestedInput>;
  baseModelURL?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  thumbnail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type MfeSceneTemplateUpdateManyMutationInput = {
  baseModelURL?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  thumbnail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type MfeSceneTemplateUpdateManyWithWhereWithoutMfesTemplateInput = {
  data: MfeSceneTemplateUpdateManyMutationInput;
  where: MfeSceneTemplateScalarWhereInput;
};

export type MfeSceneTemplateUpdateManyWithoutMfesTemplateNestedInput = {
  connect?: InputMaybe<Array<MfeSceneTemplateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MfeSceneTemplateCreateOrConnectWithoutMfesTemplateInput>>;
  create?: InputMaybe<Array<MfeSceneTemplateCreateWithoutMfesTemplateInput>>;
  createMany?: InputMaybe<MfeSceneTemplateCreateManyMfesTemplateInputEnvelope>;
  delete?: InputMaybe<Array<MfeSceneTemplateWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MfeSceneTemplateScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MfeSceneTemplateWhereUniqueInput>>;
  set?: InputMaybe<Array<MfeSceneTemplateWhereUniqueInput>>;
  update?: InputMaybe<Array<MfeSceneTemplateUpdateWithWhereUniqueWithoutMfesTemplateInput>>;
  updateMany?: InputMaybe<Array<MfeSceneTemplateUpdateManyWithWhereWithoutMfesTemplateInput>>;
  upsert?: InputMaybe<Array<MfeSceneTemplateUpsertWithWhereUniqueWithoutMfesTemplateInput>>;
};

export type MfeSceneTemplateUpdateWithWhereUniqueWithoutMfesTemplateInput = {
  data: MfeSceneTemplateUpdateWithoutMfesTemplateInput;
  where: MfeSceneTemplateWhereUniqueInput;
};

export type MfeSceneTemplateUpdateWithoutMfesTemplateInput = {
  baseModelURL?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  thumbnail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type MfeSceneTemplateUpsertWithWhereUniqueWithoutMfesTemplateInput = {
  create: MfeSceneTemplateCreateWithoutMfesTemplateInput;
  update: MfeSceneTemplateUpdateWithoutMfesTemplateInput;
  where: MfeSceneTemplateWhereUniqueInput;
};

export type MfeSceneTemplateWhereInput = {
  AND?: InputMaybe<Array<MfeSceneTemplateWhereInput>>;
  MFESTemplate?: InputMaybe<MfesTemplateRelationFilter>;
  MFESTemplateId?: InputMaybe<UuidFilter>;
  NOT?: InputMaybe<Array<MfeSceneTemplateWhereInput>>;
  OR?: InputMaybe<Array<MfeSceneTemplateWhereInput>>;
  baseModelURL?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  name?: InputMaybe<StringFilter>;
  thumbnail?: InputMaybe<StringNullableFilter>;
};

export type MfeSceneTemplateWhereUniqueInput = {
  AND?: InputMaybe<Array<MfeSceneTemplateWhereInput>>;
  MFESTemplate?: InputMaybe<MfesTemplateRelationFilter>;
  MFESTemplateId?: InputMaybe<UuidFilter>;
  NOT?: InputMaybe<Array<MfeSceneTemplateWhereInput>>;
  OR?: InputMaybe<Array<MfeSceneTemplateWhereInput>>;
  baseModelURL?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<StringFilter>;
  thumbnail?: InputMaybe<StringNullableFilter>;
};

export type MarkAsDefaultTemplateInput = {
  templateId: Scalars['String']['input'];
};

export type MarkAsDefaultTemplateRes = {
  __typename?: 'MarkAsDefaultTemplateRes';
  success: Scalars['Boolean']['output'];
};

export type MarkAsFeaturedAssetInput = {
  id: Scalars['Float']['input'];
};

export type MarkAsFeaturedAssetResponse = {
  __typename?: 'MarkAsFeaturedAssetResponse';
  message: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
};

export type MarkAsFeaturedCollectionInput = {
  id: Scalars['String']['input'];
};

export type MarkAsFeaturedCollectionResponse = {
  __typename?: 'MarkAsFeaturedCollectionResponse';
  message: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
};

export type MarkAsFeaturedInput = {
  id: Scalars['String']['input'];
};

export type MarkAsFeaturedResponse = {
  __typename?: 'MarkAsFeaturedResponse';
  message: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
};

export enum MatchStatus {
  Created = 'CREATED',
  Finished = 'FINISHED',
  Started = 'STARTED'
}

export type Mutation = {
  __typename?: 'Mutation';
  addToCart: AddToCartResponse;
  batchUpload: BatchUploadResponse;
  beCreator: BeCreator;
  bulkSend: BulkEmailAddResponse;
  buyAssetWeb2: BuyAssetWeb2Response;
  buyAssetWeb2FC: BuyAssetWeb2FcResponse;
  changeAsset: ChangeAssetResponse;
  changeOrderFeatAsset: ChangeOrderFeatAssetRes;
  changePrice: ChangePriceResponse;
  changeUserPassword: ChangeUserPassword;
  changeUsername: ChangeUsernameResponse;
  clearCart: ClearCartResponse;
  convertFlowToOzone: ConvertFlowToOzoneResponse;
  convertOzoneToFC: ConvertOzoneToFcResponse;
  convertTokenToDollar: ConvertTokenToDollarResponse;
  createAsset: AssetCreateResponse;
  createAssetWeb2: AssetWeb2CreateResponse;
  createCart: CreateCartResponse;
  createCategory: CategoryCreateResponse;
  createCollection: CollectionCreateResponse;
  createDomainScene: CreateDomainSceneResponse;
  createDomainSubScene: CreateDomainSubSceneResponse;
  createManyCameraConfig: AffectedRowsOutput;
  createManyDomainScene: AffectedRowsOutput;
  createManyGraphicsSettings: AffectedRowsOutput;
  createManyHideAndSeek: AffectedRowsOutput;
  createManyHideAndSeekNPC: AffectedRowsOutput;
  createManyLightProps: AffectedRowsOutput;
  createManyLightTemplate: AffectedRowsOutput;
  createManyMFESTemplate: AffectedRowsOutput;
  createManyMFESceneTemplate: AffectedRowsOutput;
  createManyPostProcessingSettings: AffectedRowsOutput;
  createManyQuest: AffectedRowsOutput;
  createManySceneTemplate: AffectedRowsOutput;
  createManyShopifyStore: AffectedRowsOutput;
  createManySubScenesDomain: AffectedRowsOutput;
  createManySubscriptionPlan: AffectedRowsOutput;
  createManyTemplates: AffectedRowsOutput;
  createManyTourItems: AffectedRowsOutput;
  createManyUserScene: AffectedRowsOutput;
  createManyUserSceneAssets: AffectedRowsOutput;
  createManyUserSubscription: AffectedRowsOutput;
  createManyUsersQuests: AffectedRowsOutput;
  createMatch: CreateMatchResponse;
  createOneCameraConfig: CameraConfig;
  createOneDomainScene: DomainScene;
  createOneGraphicsSettings: GraphicsSettings;
  createOneHideAndSeek: HideAndSeek;
  createOneHideAndSeekNPC: HideAndSeekNpc;
  createOneLightProps: LightProps;
  createOneLightTemplate: LightTemplate;
  createOneMFESTemplate: MfesTemplate;
  createOneMFESceneTemplate: MfeSceneTemplate;
  createOnePostProcessingSettings: PostProcessingSettings;
  createOneQuest: Quest;
  createOneSceneTemplate: SceneTemplate;
  createOneShopifyStore: ShopifyStore;
  createOneSubScenesDomain: SubScenesDomain;
  createOneSubscriptionPlan: SubscriptionPlan;
  createOneTemplates: Templates;
  createOneTourItems: TourItems;
  createOneUserScene: UserScene;
  createOneUserSceneAssets: UserSceneAssets;
  createOneUserSubscription: UserSubscription;
  createOneUsersQuests: UsersQuests;
  createOrder: OrderCreateResponse;
  createShopifyStore: CreateShopifyStoreResponse;
  createUser: UserPublic;
  createUserProfile: CreateUserProfileResponse;
  deleteAsset: DeleteAssetResponse;
  deleteCollection: DeleteCollectionResponse;
  deleteDomainScene: DeleteDomainSceneResponse;
  deleteDomainSubScene: DeleteDomainSubSceneResponse;
  deleteManyCameraConfig: AffectedRowsOutput;
  deleteManyDomainScene: AffectedRowsOutput;
  deleteManyGraphicsSettings: AffectedRowsOutput;
  deleteManyHideAndSeek: AffectedRowsOutput;
  deleteManyHideAndSeekNPC: AffectedRowsOutput;
  deleteManyLightProps: AffectedRowsOutput;
  deleteManyLightTemplate: AffectedRowsOutput;
  deleteManyMFESTemplate: AffectedRowsOutput;
  deleteManyMFESceneTemplate: AffectedRowsOutput;
  deleteManyPostProcessingSettings: AffectedRowsOutput;
  deleteManyQuest: AffectedRowsOutput;
  deleteManySceneTemplate: AffectedRowsOutput;
  deleteManyShopifyStore: AffectedRowsOutput;
  deleteManySubScenesDomain: AffectedRowsOutput;
  deleteManySubscriptionPlan: AffectedRowsOutput;
  deleteManyTemplates: AffectedRowsOutput;
  deleteManyTourItems: AffectedRowsOutput;
  deleteManyUserScene: AffectedRowsOutput;
  deleteManyUserSceneAssets: AffectedRowsOutput;
  deleteManyUserSubscription: AffectedRowsOutput;
  deleteManyUsersQuests: AffectedRowsOutput;
  deleteOneCameraConfig?: Maybe<CameraConfig>;
  deleteOneDomainScene?: Maybe<DomainScene>;
  deleteOneGraphicsSettings?: Maybe<GraphicsSettings>;
  deleteOneHideAndSeek?: Maybe<HideAndSeek>;
  deleteOneHideAndSeekNPC?: Maybe<HideAndSeekNpc>;
  deleteOneLightProps?: Maybe<LightProps>;
  deleteOneLightTemplate?: Maybe<LightTemplate>;
  deleteOneMFESTemplate?: Maybe<MfesTemplate>;
  deleteOneMFESceneTemplate?: Maybe<MfeSceneTemplate>;
  deleteOnePostProcessingSettings?: Maybe<PostProcessingSettings>;
  deleteOneQuest?: Maybe<Quest>;
  deleteOneSceneTemplate?: Maybe<SceneTemplate>;
  deleteOneShopifyStore?: Maybe<ShopifyStore>;
  deleteOneSubScenesDomain?: Maybe<SubScenesDomain>;
  deleteOneSubscriptionPlan?: Maybe<SubscriptionPlan>;
  deleteOneTemplates?: Maybe<Templates>;
  deleteOneTourItems?: Maybe<TourItems>;
  deleteOneUserScene?: Maybe<UserScene>;
  deleteOneUserSceneAssets?: Maybe<UserSceneAssets>;
  deleteOneUserSubscription?: Maybe<UserSubscription>;
  deleteOneUsersQuests?: Maybe<UsersQuests>;
  depositFC: DepositFcResponse;
  distributeTokensToWinner: DistributeTokensToWinnerResponse;
  forgotPassword: ForgotPassword;
  getAssetVideoUrlByHtml: GetAssetVideoUrlByMetaOgResponse;
  getAssetVideoUrlByMetaOG: GetAssetVideoUrlByMetaOgResponse;
  getUnityPackage: GetUnityPackageResponse;
  incrementViewAsset: IncrementViewAssetResponse;
  likeAsset: LikeAssetResponse;
  markAsDefaultTemplate: MarkAsDefaultTemplateRes;
  markAsFeaturedAsset: MarkAsFeaturedAssetResponse;
  markAsFeaturedCollection: MarkAsFeaturedCollectionResponse;
  markAsFeaturedCreator: MarkAsFeaturedResponse;
  reduceCountCopies: ReduceCountCopiesResponse;
  removeFromCart: RemoveFromCartResponse;
  removeFromFeaturedAsset: MarkAsFeaturedAssetResponse;
  removeFromFeaturedCollection: MarkAsFeaturedCollectionResponse;
  removeFromFeaturedCreator: MarkAsFeaturedResponse;
  setConversationSid: SetConversationSidResponse;
  setIsCameraThirdPersonScene: SetIsCameraThirdPersonSceneResponse;
  setIsChatEnabledScene: SetIsChatEnabledSceneResponse;
  setIsScanEnabledScene: SetIsScanEnabledSceneResponse;
  signUp: SignUp;
  switchEnableQuests: SwitchEnableQuestsResponse;
  testSend: BulkEmailAddResponse;
  unLikeAsset: UnLikeAssetResponse;
  unmarkDefaultTemplate: MarkAsDefaultTemplateRes;
  updateAliasScene: UpdateSceneResponse;
  updateCollection: UpdateCollectionResponse;
  updateCreator: UpdateCreatorResponse;
  updateDomainScene: UpdateDomainSceneResponse;
  updateDomainSubScene: UpdateDomainSubSceneResponse;
  updateManyCameraConfig: AffectedRowsOutput;
  updateManyDomainScene: AffectedRowsOutput;
  updateManyGraphicsSettings: AffectedRowsOutput;
  updateManyHideAndSeek: AffectedRowsOutput;
  updateManyHideAndSeekNPC: AffectedRowsOutput;
  updateManyLightProps: AffectedRowsOutput;
  updateManyLightTemplate: AffectedRowsOutput;
  updateManyMFESTemplate: AffectedRowsOutput;
  updateManyMFESceneTemplate: AffectedRowsOutput;
  updateManyPostProcessingSettings: AffectedRowsOutput;
  updateManyQuest: AffectedRowsOutput;
  updateManySceneTemplate: AffectedRowsOutput;
  updateManyShopifyStore: AffectedRowsOutput;
  updateManySubScenesDomain: AffectedRowsOutput;
  updateManySubscriptionPlan: AffectedRowsOutput;
  updateManyTemplates: AffectedRowsOutput;
  updateManyTourItems: AffectedRowsOutput;
  updateManyUserScene: AffectedRowsOutput;
  updateManyUserSceneAssets: AffectedRowsOutput;
  updateManyUserSubscription: AffectedRowsOutput;
  updateManyUsersQuests: AffectedRowsOutput;
  updateMatch: UpdateMatchResponse;
  updateOneCameraConfig?: Maybe<CameraConfig>;
  updateOneDomainScene?: Maybe<DomainScene>;
  updateOneGraphicsSettings?: Maybe<GraphicsSettings>;
  updateOneHideAndSeek?: Maybe<HideAndSeek>;
  updateOneHideAndSeekNPC?: Maybe<HideAndSeekNpc>;
  updateOneLightProps?: Maybe<LightProps>;
  updateOneLightTemplate?: Maybe<LightTemplate>;
  updateOneMFESTemplate?: Maybe<MfesTemplate>;
  updateOneMFESceneTemplate?: Maybe<MfeSceneTemplate>;
  updateOnePostProcessingSettings?: Maybe<PostProcessingSettings>;
  updateOneQuest?: Maybe<Quest>;
  updateOneSceneTemplate?: Maybe<SceneTemplate>;
  updateOneShopifyStore?: Maybe<ShopifyStore>;
  updateOneSubScenesDomain?: Maybe<SubScenesDomain>;
  updateOneSubscriptionPlan?: Maybe<SubscriptionPlan>;
  updateOneTemplates?: Maybe<Templates>;
  updateOneTourItems?: Maybe<TourItems>;
  updateOneUserScene?: Maybe<UserScene>;
  updateOneUserSceneAssets?: Maybe<UserSceneAssets>;
  updateOneUserSubscription?: Maybe<UserSubscription>;
  updateOneUsersQuests?: Maybe<UsersQuests>;
  updateOrderStatus: UpdateOrderStatusResponse;
  updateQuest: UpdateQuestResponse;
  updateSceneAssets: UpdateSceneAssetsResponse;
  updateShopifyStore: UpdateShopifyStoreResponse;
  updateTimeInLobby: UpdateTimeInLobbyResponse;
  updateUser: UserPublic;
  upsertOneCameraConfig: CameraConfig;
  upsertOneDomainScene: DomainScene;
  upsertOneGraphicsSettings: GraphicsSettings;
  upsertOneHideAndSeek: HideAndSeek;
  upsertOneHideAndSeekNPC: HideAndSeekNpc;
  upsertOneLightProps: LightProps;
  upsertOneLightTemplate: LightTemplate;
  upsertOneMFESTemplate: MfesTemplate;
  upsertOneMFESceneTemplate: MfeSceneTemplate;
  upsertOnePostProcessingSettings: PostProcessingSettings;
  upsertOneQuest: Quest;
  upsertOneSceneTemplate: SceneTemplate;
  upsertOneShopifyStore: ShopifyStore;
  upsertOneSubScenesDomain: SubScenesDomain;
  upsertOneSubscriptionPlan: SubscriptionPlan;
  upsertOneTemplates: Templates;
  upsertOneTourItems: TourItems;
  upsertOneUserScene: UserScene;
  upsertOneUserSceneAssets: UserSceneAssets;
  upsertOneUserSubscription: UserSubscription;
  upsertOneUsersQuests: UsersQuests;
  userFinishedQuest: UserFinishedQuestResponse;
  verifyAccount: VerifyAccount;
  wearSkin: WearSkinResponse;
};


export type MutationAddToCartArgs = {
  data: AddToCartInput;
};


export type MutationBatchUploadArgs = {
  data: BatchUploadInput;
};


export type MutationBeCreatorArgs = {
  data: BeCreatorInput;
};


export type MutationBulkSendArgs = {
  data: BulkSendInput;
};


export type MutationBuyAssetWeb2Args = {
  data: BuyAssetWeb2Input;
};


export type MutationBuyAssetWeb2FcArgs = {
  data: BuyAssetWeb2FcInput;
};


export type MutationChangeAssetArgs = {
  data: ChangeAssetInput;
};


export type MutationChangeOrderFeatAssetArgs = {
  where: ChangeOrderFeatAssetResInput;
};


export type MutationChangePriceArgs = {
  data: ChangePriceInput;
};


export type MutationChangeUserPasswordArgs = {
  data: ChangeUserPasswordInput;
  where: UsersWhereUniqueInput;
};


export type MutationChangeUsernameArgs = {
  data: ChangeUsernameInput;
};


export type MutationClearCartArgs = {
  data: ClearCartInput;
};


export type MutationConvertFlowToOzoneArgs = {
  where: ConvertFlowToOzoneInput;
};


export type MutationConvertOzoneToFcArgs = {
  where: ConvertOzoneToFcInput;
};


export type MutationConvertTokenToDollarArgs = {
  where: ConvertTokenToDollarInput;
};


export type MutationCreateAssetArgs = {
  data: AssetCreateInput;
};


export type MutationCreateAssetWeb2Args = {
  data: AssetWeb2CreateInput;
};


export type MutationCreateCartArgs = {
  data: CreateCartInput;
};


export type MutationCreateCategoryArgs = {
  data: CategoryCreateInput;
};


export type MutationCreateCollectionArgs = {
  data: CollectionCreateInput;
};


export type MutationCreateDomainSceneArgs = {
  data: CreateDomainSceneInput;
};


export type MutationCreateDomainSubSceneArgs = {
  data: CreateDomainSubSceneInput;
};


export type MutationCreateManyCameraConfigArgs = {
  data: Array<CameraConfigCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyDomainSceneArgs = {
  data: Array<DomainSceneCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyGraphicsSettingsArgs = {
  data: Array<GraphicsSettingsCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyHideAndSeekArgs = {
  data: Array<HideAndSeekCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyHideAndSeekNpcArgs = {
  data: Array<HideAndSeekNpcCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyLightPropsArgs = {
  data: Array<LightPropsCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyLightTemplateArgs = {
  data: Array<LightTemplateCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyMfesTemplateArgs = {
  data: Array<MfesTemplateCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyMfeSceneTemplateArgs = {
  data: Array<MfeSceneTemplateCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyPostProcessingSettingsArgs = {
  data: Array<PostProcessingSettingsCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyQuestArgs = {
  data: Array<QuestCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManySceneTemplateArgs = {
  data: Array<SceneTemplateCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyShopifyStoreArgs = {
  data: Array<ShopifyStoreCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManySubScenesDomainArgs = {
  data: Array<SubScenesDomainCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManySubscriptionPlanArgs = {
  data: Array<SubscriptionPlanCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyTemplatesArgs = {
  data: Array<TemplatesCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyTourItemsArgs = {
  data: Array<TourItemsCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyUserSceneArgs = {
  data: Array<UserSceneCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyUserSceneAssetsArgs = {
  data: Array<UserSceneAssetsCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyUserSubscriptionArgs = {
  data: Array<UserSubscriptionCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyUsersQuestsArgs = {
  data: Array<UsersQuestsCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateMatchArgs = {
  data: CreateMatchInput;
};


export type MutationCreateOneCameraConfigArgs = {
  data?: InputMaybe<CameraConfigCreateInput>;
};


export type MutationCreateOneDomainSceneArgs = {
  data: DomainSceneCreateInput;
};


export type MutationCreateOneGraphicsSettingsArgs = {
  data?: InputMaybe<GraphicsSettingsCreateInput>;
};


export type MutationCreateOneHideAndSeekArgs = {
  data: HideAndSeekCreateInput;
};


export type MutationCreateOneHideAndSeekNpcArgs = {
  data: HideAndSeekNpcCreateInput;
};


export type MutationCreateOneLightPropsArgs = {
  data?: InputMaybe<LightPropsCreateInput>;
};


export type MutationCreateOneLightTemplateArgs = {
  data: LightTemplateCreateInput;
};


export type MutationCreateOneMfesTemplateArgs = {
  data: MfesTemplateCreateInput;
};


export type MutationCreateOneMfeSceneTemplateArgs = {
  data: MfeSceneTemplateCreateInput;
};


export type MutationCreateOnePostProcessingSettingsArgs = {
  data?: InputMaybe<PostProcessingSettingsCreateInput>;
};


export type MutationCreateOneQuestArgs = {
  data: QuestCreateInput;
};


export type MutationCreateOneSceneTemplateArgs = {
  data: SceneTemplateCreateInput;
};


export type MutationCreateOneShopifyStoreArgs = {
  data: ShopifyStoreCreateInput;
};


export type MutationCreateOneSubScenesDomainArgs = {
  data: SubScenesDomainCreateInput;
};


export type MutationCreateOneSubscriptionPlanArgs = {
  data: SubscriptionPlanCreateInput;
};


export type MutationCreateOneTemplatesArgs = {
  data: TemplatesCreateInput;
};


export type MutationCreateOneTourItemsArgs = {
  data: TourItemsCreateInput;
};


export type MutationCreateOneUserSceneArgs = {
  data: UserSceneCreateInput;
};


export type MutationCreateOneUserSceneAssetsArgs = {
  data: UserSceneAssetsCreateInput;
};


export type MutationCreateOneUserSubscriptionArgs = {
  data: UserSubscriptionCreateInput;
};


export type MutationCreateOneUsersQuestsArgs = {
  data: UsersQuestsCreateInput;
};


export type MutationCreateOrderArgs = {
  data: OrderCreateInput;
};


export type MutationCreateShopifyStoreArgs = {
  where: CreateShopifyStoreInput;
};


export type MutationCreateUserArgs = {
  data: UserCreateInput;
};


export type MutationCreateUserProfileArgs = {
  data: CreateUserProfileInput;
};


export type MutationDeleteAssetArgs = {
  data: DeleteAssetInput;
};


export type MutationDeleteCollectionArgs = {
  data: DeleteCollectionInput;
};


export type MutationDeleteDomainSceneArgs = {
  where: DeleteDomainSceneInput;
};


export type MutationDeleteDomainSubSceneArgs = {
  where: DeleteDomainSubSceneInput;
};


export type MutationDeleteManyCameraConfigArgs = {
  where?: InputMaybe<CameraConfigWhereInput>;
};


export type MutationDeleteManyDomainSceneArgs = {
  where?: InputMaybe<DomainSceneWhereInput>;
};


export type MutationDeleteManyGraphicsSettingsArgs = {
  where?: InputMaybe<GraphicsSettingsWhereInput>;
};


export type MutationDeleteManyHideAndSeekArgs = {
  where?: InputMaybe<HideAndSeekWhereInput>;
};


export type MutationDeleteManyHideAndSeekNpcArgs = {
  where?: InputMaybe<HideAndSeekNpcWhereInput>;
};


export type MutationDeleteManyLightPropsArgs = {
  where?: InputMaybe<LightPropsWhereInput>;
};


export type MutationDeleteManyLightTemplateArgs = {
  where?: InputMaybe<LightTemplateWhereInput>;
};


export type MutationDeleteManyMfesTemplateArgs = {
  where?: InputMaybe<MfesTemplateWhereInput>;
};


export type MutationDeleteManyMfeSceneTemplateArgs = {
  where?: InputMaybe<MfeSceneTemplateWhereInput>;
};


export type MutationDeleteManyPostProcessingSettingsArgs = {
  where?: InputMaybe<PostProcessingSettingsWhereInput>;
};


export type MutationDeleteManyQuestArgs = {
  where?: InputMaybe<QuestWhereInput>;
};


export type MutationDeleteManySceneTemplateArgs = {
  where?: InputMaybe<SceneTemplateWhereInput>;
};


export type MutationDeleteManyShopifyStoreArgs = {
  where?: InputMaybe<ShopifyStoreWhereInput>;
};


export type MutationDeleteManySubScenesDomainArgs = {
  where?: InputMaybe<SubScenesDomainWhereInput>;
};


export type MutationDeleteManySubscriptionPlanArgs = {
  where?: InputMaybe<SubscriptionPlanWhereInput>;
};


export type MutationDeleteManyTemplatesArgs = {
  where?: InputMaybe<TemplatesWhereInput>;
};


export type MutationDeleteManyTourItemsArgs = {
  where?: InputMaybe<TourItemsWhereInput>;
};


export type MutationDeleteManyUserSceneArgs = {
  where?: InputMaybe<UserSceneWhereInput>;
};


export type MutationDeleteManyUserSceneAssetsArgs = {
  where?: InputMaybe<UserSceneAssetsWhereInput>;
};


export type MutationDeleteManyUserSubscriptionArgs = {
  where?: InputMaybe<UserSubscriptionWhereInput>;
};


export type MutationDeleteManyUsersQuestsArgs = {
  where?: InputMaybe<UsersQuestsWhereInput>;
};


export type MutationDeleteOneCameraConfigArgs = {
  where: CameraConfigWhereUniqueInput;
};


export type MutationDeleteOneDomainSceneArgs = {
  where: DomainSceneWhereUniqueInput;
};


export type MutationDeleteOneGraphicsSettingsArgs = {
  where: GraphicsSettingsWhereUniqueInput;
};


export type MutationDeleteOneHideAndSeekArgs = {
  where: HideAndSeekWhereUniqueInput;
};


export type MutationDeleteOneHideAndSeekNpcArgs = {
  where: HideAndSeekNpcWhereUniqueInput;
};


export type MutationDeleteOneLightPropsArgs = {
  where: LightPropsWhereUniqueInput;
};


export type MutationDeleteOneLightTemplateArgs = {
  where: LightTemplateWhereUniqueInput;
};


export type MutationDeleteOneMfesTemplateArgs = {
  where: MfesTemplateWhereUniqueInput;
};


export type MutationDeleteOneMfeSceneTemplateArgs = {
  where: MfeSceneTemplateWhereUniqueInput;
};


export type MutationDeleteOnePostProcessingSettingsArgs = {
  where: PostProcessingSettingsWhereUniqueInput;
};


export type MutationDeleteOneQuestArgs = {
  where: QuestWhereUniqueInput;
};


export type MutationDeleteOneSceneTemplateArgs = {
  where: SceneTemplateWhereUniqueInput;
};


export type MutationDeleteOneShopifyStoreArgs = {
  where: ShopifyStoreWhereUniqueInput;
};


export type MutationDeleteOneSubScenesDomainArgs = {
  where: SubScenesDomainWhereUniqueInput;
};


export type MutationDeleteOneSubscriptionPlanArgs = {
  where: SubscriptionPlanWhereUniqueInput;
};


export type MutationDeleteOneTemplatesArgs = {
  where: TemplatesWhereUniqueInput;
};


export type MutationDeleteOneTourItemsArgs = {
  where: TourItemsWhereUniqueInput;
};


export type MutationDeleteOneUserSceneArgs = {
  where: UserSceneWhereUniqueInput;
};


export type MutationDeleteOneUserSceneAssetsArgs = {
  where: UserSceneAssetsWhereUniqueInput;
};


export type MutationDeleteOneUserSubscriptionArgs = {
  where: UserSubscriptionWhereUniqueInput;
};


export type MutationDeleteOneUsersQuestsArgs = {
  where: UsersQuestsWhereUniqueInput;
};


export type MutationDepositFcArgs = {
  data: DepositFcInput;
};


export type MutationDistributeTokensToWinnerArgs = {
  data: DistributeTokensToWinnerInput;
};


export type MutationForgotPasswordArgs = {
  data: ForgotPasswordInput;
};


export type MutationGetAssetVideoUrlByHtmlArgs = {
  type: Scalars['String']['input'];
  url: Scalars['String']['input'];
};


export type MutationGetAssetVideoUrlByMetaOgArgs = {
  url: Scalars['String']['input'];
};


export type MutationGetUnityPackageArgs = {
  data: GetUnityPackageInput;
};


export type MutationIncrementViewAssetArgs = {
  where: IncrementViewAssetInput;
};


export type MutationLikeAssetArgs = {
  where: LikeAssetInput;
};


export type MutationMarkAsDefaultTemplateArgs = {
  data: MarkAsDefaultTemplateInput;
};


export type MutationMarkAsFeaturedAssetArgs = {
  where: MarkAsFeaturedAssetInput;
};


export type MutationMarkAsFeaturedCollectionArgs = {
  where: MarkAsFeaturedCollectionInput;
};


export type MutationMarkAsFeaturedCreatorArgs = {
  where: MarkAsFeaturedInput;
};


export type MutationReduceCountCopiesArgs = {
  where: ReduceCountCopiesInput;
};


export type MutationRemoveFromCartArgs = {
  data: RemoveFromCartInput;
};


export type MutationRemoveFromFeaturedAssetArgs = {
  where: MarkAsFeaturedAssetInput;
};


export type MutationRemoveFromFeaturedCollectionArgs = {
  where: MarkAsFeaturedCollectionInput;
};


export type MutationRemoveFromFeaturedCreatorArgs = {
  where: MarkAsFeaturedInput;
};


export type MutationSetConversationSidArgs = {
  where: SetConversationSidInput;
};


export type MutationSetIsCameraThirdPersonSceneArgs = {
  where: SetIsCameraThirdPersonSceneInput;
};


export type MutationSetIsChatEnabledSceneArgs = {
  where: SetIsChatEnabledSceneInput;
};


export type MutationSetIsScanEnabledSceneArgs = {
  where: SetIsScanEnabledSceneInput;
};


export type MutationSignUpArgs = {
  data: SignUpInput;
};


export type MutationSwitchEnableQuestsArgs = {
  data: SwitchEnableQuestsInput;
};


export type MutationTestSendArgs = {
  data: TestSendInput;
};


export type MutationUnLikeAssetArgs = {
  where: UnLikeAssetInput;
};


export type MutationUnmarkDefaultTemplateArgs = {
  data: MarkAsDefaultTemplateInput;
};


export type MutationUpdateAliasSceneArgs = {
  where: UpdateAliasSceneInput;
};


export type MutationUpdateCollectionArgs = {
  data: UpdateCollectionInput;
};


export type MutationUpdateCreatorArgs = {
  data: UpdateCreatorInput;
};


export type MutationUpdateDomainSceneArgs = {
  data: UpdateDomainSceneInput;
};


export type MutationUpdateDomainSubSceneArgs = {
  data: UpdateDomainSubSceneInput;
};


export type MutationUpdateManyCameraConfigArgs = {
  data: CameraConfigUpdateManyMutationInput;
  where?: InputMaybe<CameraConfigWhereInput>;
};


export type MutationUpdateManyDomainSceneArgs = {
  data: DomainSceneUpdateManyMutationInput;
  where?: InputMaybe<DomainSceneWhereInput>;
};


export type MutationUpdateManyGraphicsSettingsArgs = {
  data: GraphicsSettingsUpdateManyMutationInput;
  where?: InputMaybe<GraphicsSettingsWhereInput>;
};


export type MutationUpdateManyHideAndSeekArgs = {
  data: HideAndSeekUpdateManyMutationInput;
  where?: InputMaybe<HideAndSeekWhereInput>;
};


export type MutationUpdateManyHideAndSeekNpcArgs = {
  data: HideAndSeekNpcUpdateManyMutationInput;
  where?: InputMaybe<HideAndSeekNpcWhereInput>;
};


export type MutationUpdateManyLightPropsArgs = {
  data: LightPropsUpdateManyMutationInput;
  where?: InputMaybe<LightPropsWhereInput>;
};


export type MutationUpdateManyLightTemplateArgs = {
  data: LightTemplateUpdateManyMutationInput;
  where?: InputMaybe<LightTemplateWhereInput>;
};


export type MutationUpdateManyMfesTemplateArgs = {
  data: MfesTemplateUpdateManyMutationInput;
  where?: InputMaybe<MfesTemplateWhereInput>;
};


export type MutationUpdateManyMfeSceneTemplateArgs = {
  data: MfeSceneTemplateUpdateManyMutationInput;
  where?: InputMaybe<MfeSceneTemplateWhereInput>;
};


export type MutationUpdateManyPostProcessingSettingsArgs = {
  data: PostProcessingSettingsUpdateManyMutationInput;
  where?: InputMaybe<PostProcessingSettingsWhereInput>;
};


export type MutationUpdateManyQuestArgs = {
  data: QuestUpdateManyMutationInput;
  where?: InputMaybe<QuestWhereInput>;
};


export type MutationUpdateManySceneTemplateArgs = {
  data: SceneTemplateUpdateManyMutationInput;
  where?: InputMaybe<SceneTemplateWhereInput>;
};


export type MutationUpdateManyShopifyStoreArgs = {
  data: ShopifyStoreUpdateManyMutationInput;
  where?: InputMaybe<ShopifyStoreWhereInput>;
};


export type MutationUpdateManySubScenesDomainArgs = {
  data: SubScenesDomainUpdateManyMutationInput;
  where?: InputMaybe<SubScenesDomainWhereInput>;
};


export type MutationUpdateManySubscriptionPlanArgs = {
  data: SubscriptionPlanUpdateManyMutationInput;
  where?: InputMaybe<SubscriptionPlanWhereInput>;
};


export type MutationUpdateManyTemplatesArgs = {
  data: TemplatesUpdateManyMutationInput;
  where?: InputMaybe<TemplatesWhereInput>;
};


export type MutationUpdateManyTourItemsArgs = {
  data: TourItemsUpdateManyMutationInput;
  where?: InputMaybe<TourItemsWhereInput>;
};


export type MutationUpdateManyUserSceneArgs = {
  data: UserSceneUpdateManyMutationInput;
  where?: InputMaybe<UserSceneWhereInput>;
};


export type MutationUpdateManyUserSceneAssetsArgs = {
  data: UserSceneAssetsUpdateManyMutationInput;
  where?: InputMaybe<UserSceneAssetsWhereInput>;
};


export type MutationUpdateManyUserSubscriptionArgs = {
  data: UserSubscriptionUpdateManyMutationInput;
  where?: InputMaybe<UserSubscriptionWhereInput>;
};


export type MutationUpdateManyUsersQuestsArgs = {
  data: UsersQuestsUpdateManyMutationInput;
  where?: InputMaybe<UsersQuestsWhereInput>;
};


export type MutationUpdateMatchArgs = {
  data: UpdateMatchInput;
};


export type MutationUpdateOneCameraConfigArgs = {
  data: CameraConfigUpdateInput;
  where: CameraConfigWhereUniqueInput;
};


export type MutationUpdateOneDomainSceneArgs = {
  data: DomainSceneUpdateInput;
  where: DomainSceneWhereUniqueInput;
};


export type MutationUpdateOneGraphicsSettingsArgs = {
  data: GraphicsSettingsUpdateInput;
  where: GraphicsSettingsWhereUniqueInput;
};


export type MutationUpdateOneHideAndSeekArgs = {
  data: HideAndSeekUpdateInput;
  where: HideAndSeekWhereUniqueInput;
};


export type MutationUpdateOneHideAndSeekNpcArgs = {
  data: HideAndSeekNpcUpdateInput;
  where: HideAndSeekNpcWhereUniqueInput;
};


export type MutationUpdateOneLightPropsArgs = {
  data: LightPropsUpdateInput;
  where: LightPropsWhereUniqueInput;
};


export type MutationUpdateOneLightTemplateArgs = {
  data: LightTemplateUpdateInput;
  where: LightTemplateWhereUniqueInput;
};


export type MutationUpdateOneMfesTemplateArgs = {
  data: MfesTemplateUpdateInput;
  where: MfesTemplateWhereUniqueInput;
};


export type MutationUpdateOneMfeSceneTemplateArgs = {
  data: MfeSceneTemplateUpdateInput;
  where: MfeSceneTemplateWhereUniqueInput;
};


export type MutationUpdateOnePostProcessingSettingsArgs = {
  data: PostProcessingSettingsUpdateInput;
  where: PostProcessingSettingsWhereUniqueInput;
};


export type MutationUpdateOneQuestArgs = {
  data: QuestUpdateInput;
  where: QuestWhereUniqueInput;
};


export type MutationUpdateOneSceneTemplateArgs = {
  data: SceneTemplateUpdateInput;
  where: SceneTemplateWhereUniqueInput;
};


export type MutationUpdateOneShopifyStoreArgs = {
  data: ShopifyStoreUpdateInput;
  where: ShopifyStoreWhereUniqueInput;
};


export type MutationUpdateOneSubScenesDomainArgs = {
  data: SubScenesDomainUpdateInput;
  where: SubScenesDomainWhereUniqueInput;
};


export type MutationUpdateOneSubscriptionPlanArgs = {
  data: SubscriptionPlanUpdateInput;
  where: SubscriptionPlanWhereUniqueInput;
};


export type MutationUpdateOneTemplatesArgs = {
  data: TemplatesUpdateInput;
  where: TemplatesWhereUniqueInput;
};


export type MutationUpdateOneTourItemsArgs = {
  data: TourItemsUpdateInput;
  where: TourItemsWhereUniqueInput;
};


export type MutationUpdateOneUserSceneArgs = {
  data: UserSceneUpdateInput;
  where: UserSceneWhereUniqueInput;
};


export type MutationUpdateOneUserSceneAssetsArgs = {
  data: UserSceneAssetsUpdateInput;
  where: UserSceneAssetsWhereUniqueInput;
};


export type MutationUpdateOneUserSubscriptionArgs = {
  data: UserSubscriptionUpdateInput;
  where: UserSubscriptionWhereUniqueInput;
};


export type MutationUpdateOneUsersQuestsArgs = {
  data: UsersQuestsUpdateInput;
  where: UsersQuestsWhereUniqueInput;
};


export type MutationUpdateOrderStatusArgs = {
  where: UpdateOrderStatusInput;
};


export type MutationUpdateQuestArgs = {
  data: UpdateQuestInput;
};


export type MutationUpdateSceneAssetsArgs = {
  data: UpdateSceneAssetsInput;
};


export type MutationUpdateShopifyStoreArgs = {
  data: UpdateShopifyStoreInput;
};


export type MutationUpdateTimeInLobbyArgs = {
  data: UpdateTimeInLobbyInput;
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  where: UsersWhereUniqueInput;
};


export type MutationUpsertOneCameraConfigArgs = {
  create: CameraConfigCreateInput;
  update: CameraConfigUpdateInput;
  where: CameraConfigWhereUniqueInput;
};


export type MutationUpsertOneDomainSceneArgs = {
  create: DomainSceneCreateInput;
  update: DomainSceneUpdateInput;
  where: DomainSceneWhereUniqueInput;
};


export type MutationUpsertOneGraphicsSettingsArgs = {
  create: GraphicsSettingsCreateInput;
  update: GraphicsSettingsUpdateInput;
  where: GraphicsSettingsWhereUniqueInput;
};


export type MutationUpsertOneHideAndSeekArgs = {
  create: HideAndSeekCreateInput;
  update: HideAndSeekUpdateInput;
  where: HideAndSeekWhereUniqueInput;
};


export type MutationUpsertOneHideAndSeekNpcArgs = {
  create: HideAndSeekNpcCreateInput;
  update: HideAndSeekNpcUpdateInput;
  where: HideAndSeekNpcWhereUniqueInput;
};


export type MutationUpsertOneLightPropsArgs = {
  create: LightPropsCreateInput;
  update: LightPropsUpdateInput;
  where: LightPropsWhereUniqueInput;
};


export type MutationUpsertOneLightTemplateArgs = {
  create: LightTemplateCreateInput;
  update: LightTemplateUpdateInput;
  where: LightTemplateWhereUniqueInput;
};


export type MutationUpsertOneMfesTemplateArgs = {
  create: MfesTemplateCreateInput;
  update: MfesTemplateUpdateInput;
  where: MfesTemplateWhereUniqueInput;
};


export type MutationUpsertOneMfeSceneTemplateArgs = {
  create: MfeSceneTemplateCreateInput;
  update: MfeSceneTemplateUpdateInput;
  where: MfeSceneTemplateWhereUniqueInput;
};


export type MutationUpsertOnePostProcessingSettingsArgs = {
  create: PostProcessingSettingsCreateInput;
  update: PostProcessingSettingsUpdateInput;
  where: PostProcessingSettingsWhereUniqueInput;
};


export type MutationUpsertOneQuestArgs = {
  create: QuestCreateInput;
  update: QuestUpdateInput;
  where: QuestWhereUniqueInput;
};


export type MutationUpsertOneSceneTemplateArgs = {
  create: SceneTemplateCreateInput;
  update: SceneTemplateUpdateInput;
  where: SceneTemplateWhereUniqueInput;
};


export type MutationUpsertOneShopifyStoreArgs = {
  create: ShopifyStoreCreateInput;
  update: ShopifyStoreUpdateInput;
  where: ShopifyStoreWhereUniqueInput;
};


export type MutationUpsertOneSubScenesDomainArgs = {
  create: SubScenesDomainCreateInput;
  update: SubScenesDomainUpdateInput;
  where: SubScenesDomainWhereUniqueInput;
};


export type MutationUpsertOneSubscriptionPlanArgs = {
  create: SubscriptionPlanCreateInput;
  update: SubscriptionPlanUpdateInput;
  where: SubscriptionPlanWhereUniqueInput;
};


export type MutationUpsertOneTemplatesArgs = {
  create: TemplatesCreateInput;
  update: TemplatesUpdateInput;
  where: TemplatesWhereUniqueInput;
};


export type MutationUpsertOneTourItemsArgs = {
  create: TourItemsCreateInput;
  update: TourItemsUpdateInput;
  where: TourItemsWhereUniqueInput;
};


export type MutationUpsertOneUserSceneArgs = {
  create: UserSceneCreateInput;
  update: UserSceneUpdateInput;
  where: UserSceneWhereUniqueInput;
};


export type MutationUpsertOneUserSceneAssetsArgs = {
  create: UserSceneAssetsCreateInput;
  update: UserSceneAssetsUpdateInput;
  where: UserSceneAssetsWhereUniqueInput;
};


export type MutationUpsertOneUserSubscriptionArgs = {
  create: UserSubscriptionCreateInput;
  update: UserSubscriptionUpdateInput;
  where: UserSubscriptionWhereUniqueInput;
};


export type MutationUpsertOneUsersQuestsArgs = {
  create: UsersQuestsCreateInput;
  update: UsersQuestsUpdateInput;
  where: UsersQuestsWhereUniqueInput;
};


export type MutationUserFinishedQuestArgs = {
  data: UserFinishedQuestInput;
};


export type MutationVerifyAccountArgs = {
  data: VerifyAccountInput;
};


export type MutationWearSkinArgs = {
  where: WearSkinInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type NestedBoolNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedBoolNullableFilter>;
  _min?: InputMaybe<NestedBoolNullableFilter>;
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolNullableWithAggregatesFilter>;
};

export type NestedBoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type NestedDateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type NestedEnumBnbPaymentStatusNullableFilter = {
  equals?: InputMaybe<BnbPaymentStatus>;
  in?: InputMaybe<Array<BnbPaymentStatus>>;
  not?: InputMaybe<NestedEnumBnbPaymentStatusNullableFilter>;
  notIn?: InputMaybe<Array<BnbPaymentStatus>>;
};

export type NestedEnumBrandFilter = {
  equals?: InputMaybe<Brand>;
  in?: InputMaybe<Array<Brand>>;
  not?: InputMaybe<NestedEnumBrandFilter>;
  notIn?: InputMaybe<Array<Brand>>;
};

export type NestedEnumBrandWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumBrandFilter>;
  _min?: InputMaybe<NestedEnumBrandFilter>;
  equals?: InputMaybe<Brand>;
  in?: InputMaybe<Array<Brand>>;
  not?: InputMaybe<NestedEnumBrandWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Brand>>;
};

export type NestedEnumCreatorWalletTypeFilter = {
  equals?: InputMaybe<CreatorWalletType>;
  in?: InputMaybe<Array<CreatorWalletType>>;
  not?: InputMaybe<NestedEnumCreatorWalletTypeFilter>;
  notIn?: InputMaybe<Array<CreatorWalletType>>;
};

export type NestedEnumCurrencyFilter = {
  equals?: InputMaybe<Currency>;
  in?: InputMaybe<Array<Currency>>;
  not?: InputMaybe<NestedEnumCurrencyFilter>;
  notIn?: InputMaybe<Array<Currency>>;
};

export type NestedEnumFileTypeFilter = {
  equals?: InputMaybe<FileType>;
  in?: InputMaybe<Array<FileType>>;
  not?: InputMaybe<NestedEnumFileTypeFilter>;
  notIn?: InputMaybe<Array<FileType>>;
};

export type NestedEnumGameTypeNullableFilter = {
  equals?: InputMaybe<GameType>;
  in?: InputMaybe<Array<GameType>>;
  not?: InputMaybe<NestedEnumGameTypeNullableFilter>;
  notIn?: InputMaybe<Array<GameType>>;
};

export type NestedEnumGameTypeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumGameTypeNullableFilter>;
  _min?: InputMaybe<NestedEnumGameTypeNullableFilter>;
  equals?: InputMaybe<GameType>;
  in?: InputMaybe<Array<GameType>>;
  not?: InputMaybe<NestedEnumGameTypeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<GameType>>;
};

export type NestedEnumOrderStatusFilter = {
  equals?: InputMaybe<OrderStatus>;
  in?: InputMaybe<Array<OrderStatus>>;
  not?: InputMaybe<NestedEnumOrderStatusFilter>;
  notIn?: InputMaybe<Array<OrderStatus>>;
};

export type NestedEnumQuestStateFilter = {
  equals?: InputMaybe<QuestState>;
  in?: InputMaybe<Array<QuestState>>;
  not?: InputMaybe<NestedEnumQuestStateFilter>;
  notIn?: InputMaybe<Array<QuestState>>;
};

export type NestedEnumQuestStateWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumQuestStateFilter>;
  _min?: InputMaybe<NestedEnumQuestStateFilter>;
  equals?: InputMaybe<QuestState>;
  in?: InputMaybe<Array<QuestState>>;
  not?: InputMaybe<NestedEnumQuestStateWithAggregatesFilter>;
  notIn?: InputMaybe<Array<QuestState>>;
};

export type NestedEnumRewardStatusFilter = {
  equals?: InputMaybe<RewardStatus>;
  in?: InputMaybe<Array<RewardStatus>>;
  not?: InputMaybe<NestedEnumRewardStatusFilter>;
  notIn?: InputMaybe<Array<RewardStatus>>;
};

export type NestedEnumRewardStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumRewardStatusFilter>;
  _min?: InputMaybe<NestedEnumRewardStatusFilter>;
  equals?: InputMaybe<RewardStatus>;
  in?: InputMaybe<Array<RewardStatus>>;
  not?: InputMaybe<NestedEnumRewardStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<RewardStatus>>;
};

export type NestedEnumRewardTypeFilter = {
  equals?: InputMaybe<RewardType>;
  in?: InputMaybe<Array<RewardType>>;
  not?: InputMaybe<NestedEnumRewardTypeFilter>;
  notIn?: InputMaybe<Array<RewardType>>;
};

export type NestedEnumRewardTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumRewardTypeFilter>;
  _min?: InputMaybe<NestedEnumRewardTypeFilter>;
  equals?: InputMaybe<RewardType>;
  in?: InputMaybe<Array<RewardType>>;
  not?: InputMaybe<NestedEnumRewardTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<RewardType>>;
};

export type NestedEnumTypeOrderFilter = {
  equals?: InputMaybe<TypeOrder>;
  in?: InputMaybe<Array<TypeOrder>>;
  not?: InputMaybe<NestedEnumTypeOrderFilter>;
  notIn?: InputMaybe<Array<TypeOrder>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NestedFloatNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedFloatNullableFilter>;
  _min?: InputMaybe<NestedFloatNullableFilter>;
  _sum?: InputMaybe<NestedFloatNullableFilter>;
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NestedFloatWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedFloatFilter>;
  _min?: InputMaybe<NestedFloatFilter>;
  _sum?: InputMaybe<NestedFloatFilter>;
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedIntNullableFilter>;
  _min?: InputMaybe<NestedIntNullableFilter>;
  _sum?: InputMaybe<NestedIntNullableFilter>;
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedUuidFilter = {
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedUuidFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type NestedUuidNullableFilter = {
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedUuidNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type NestedUuidNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedUuidNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type NestedUuidWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedUuidWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NullableEnumGameTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<GameType>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']['input']>;
  divide?: InputMaybe<Scalars['Float']['input']>;
  increment?: InputMaybe<Scalars['Float']['input']>;
  multiply?: InputMaybe<Scalars['Float']['input']>;
  set?: InputMaybe<Scalars['Float']['input']>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']['input']>;
  divide?: InputMaybe<Scalars['Int']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  multiply?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

export enum NullsOrder {
  First = 'first',
  Last = 'last'
}

export type OpenAiMessageResponse = {
  __typename?: 'OpenAIMessageResponse';
  content?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
};

export type Order = {
  __typename?: 'Order';
  _count?: Maybe<OrderCount>;
  assetId?: Maybe<Scalars['Int']['output']>;
  assetWeb2Id?: Maybe<Scalars['String']['output']>;
  bnbPaymentStatus?: Maybe<BnbPaymentStatus>;
  bnbTxId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  depositWalletId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  nftReceived?: Maybe<Scalars['Boolean']['output']>;
  payerId: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  sellerWalletId: Scalars['String']['output'];
  status: OrderStatus;
  stripeTxId?: Maybe<Scalars['String']['output']>;
  txId?: Maybe<Scalars['String']['output']>;
  type: TypeOrder;
};

export type OrderCount = {
  __typename?: 'OrderCount';
  assetWeb2Bought: Scalars['Int']['output'];
};


export type OrderCountAssetWeb2BoughtArgs = {
  where?: InputMaybe<AssetWeb2BoughtWhereInput>;
};

export type OrderCreateInput = {
  assetId?: InputMaybe<Scalars['Float']['input']>;
  assetWeb2Id?: InputMaybe<Scalars['String']['input']>;
  depositWalletId: Scalars['String']['input'];
  payerId: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  sellerWalletId: Scalars['String']['input'];
  type?: InputMaybe<TypeOrder>;
};

export type OrderCreateResponse = {
  __typename?: 'OrderCreateResponse';
  orderId?: Maybe<Scalars['String']['output']>;
};

export type OrderNullableRelationFilter = {
  is?: InputMaybe<OrderWhereInput>;
  isNot?: InputMaybe<OrderWhereInput>;
};

export enum OrderStatus {
  Failed = 'FAILED',
  Proccessed = 'PROCCESSED',
  Success = 'SUCCESS'
}

export type OrderWhereInput = {
  AND?: InputMaybe<Array<OrderWhereInput>>;
  NOT?: InputMaybe<Array<OrderWhereInput>>;
  OR?: InputMaybe<Array<OrderWhereInput>>;
  assetId?: InputMaybe<IntNullableFilter>;
  assetWeb2Bought?: InputMaybe<AssetWeb2BoughtListRelationFilter>;
  assetWeb2Id?: InputMaybe<UuidNullableFilter>;
  bnbPaymentStatus?: InputMaybe<EnumBnbPaymentStatusNullableFilter>;
  bnbTxId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  depositWalletId?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  nftReceived?: InputMaybe<BoolNullableFilter>;
  payerId?: InputMaybe<StringFilter>;
  price?: InputMaybe<FloatFilter>;
  sellerWalletId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumOrderStatusFilter>;
  stripeTxId?: InputMaybe<StringNullableFilter>;
  txId?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumTypeOrderFilter>;
};

export type Params = {
  OutputFormat: Scalars['String']['input'];
  OutputS3BucketName: Scalars['String']['input'];
  Text: Scalars['String']['input'];
  TextType: Scalars['String']['input'];
  VoiceId: Scalars['String']['input'];
};

export type PostProcessingSettings = {
  __typename?: 'PostProcessingSettings';
  _count?: Maybe<PostProcessingSettingsCount>;
  antialiasing?: Maybe<Scalars['String']['output']>;
  bloom?: Maybe<Scalars['String']['output']>;
  bloomExposure: Scalars['Float']['output'];
  bloomRadius: Scalars['Float']['output'];
  bloomStrength: Scalars['Float']['output'];
  bloomThreshold: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  ssao?: Maybe<Scalars['String']['output']>;
  ssr?: Maybe<Scalars['String']['output']>;
};

export type PostProcessingSettingsAvgAggregate = {
  __typename?: 'PostProcessingSettingsAvgAggregate';
  bloomExposure?: Maybe<Scalars['Float']['output']>;
  bloomRadius?: Maybe<Scalars['Float']['output']>;
  bloomStrength?: Maybe<Scalars['Float']['output']>;
  bloomThreshold?: Maybe<Scalars['Float']['output']>;
};

export type PostProcessingSettingsAvgOrderByAggregateInput = {
  bloomExposure?: InputMaybe<SortOrder>;
  bloomRadius?: InputMaybe<SortOrder>;
  bloomStrength?: InputMaybe<SortOrder>;
  bloomThreshold?: InputMaybe<SortOrder>;
};

export type PostProcessingSettingsCount = {
  __typename?: 'PostProcessingSettingsCount';
  GraphicsSettings: Scalars['Int']['output'];
};


export type PostProcessingSettingsCountGraphicsSettingsArgs = {
  where?: InputMaybe<GraphicsSettingsWhereInput>;
};

export type PostProcessingSettingsCountAggregate = {
  __typename?: 'PostProcessingSettingsCountAggregate';
  _all: Scalars['Int']['output'];
  antialiasing: Scalars['Int']['output'];
  bloom: Scalars['Int']['output'];
  bloomExposure: Scalars['Int']['output'];
  bloomRadius: Scalars['Int']['output'];
  bloomStrength: Scalars['Int']['output'];
  bloomThreshold: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  ssao: Scalars['Int']['output'];
  ssr: Scalars['Int']['output'];
};

export type PostProcessingSettingsCountOrderByAggregateInput = {
  antialiasing?: InputMaybe<SortOrder>;
  bloom?: InputMaybe<SortOrder>;
  bloomExposure?: InputMaybe<SortOrder>;
  bloomRadius?: InputMaybe<SortOrder>;
  bloomStrength?: InputMaybe<SortOrder>;
  bloomThreshold?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ssao?: InputMaybe<SortOrder>;
  ssr?: InputMaybe<SortOrder>;
};

export type PostProcessingSettingsCreateInput = {
  GraphicsSettings?: InputMaybe<GraphicsSettingsCreateNestedManyWithoutPostProcessingSettingsInput>;
  antialiasing?: InputMaybe<Scalars['String']['input']>;
  bloom?: InputMaybe<Scalars['String']['input']>;
  bloomExposure?: InputMaybe<Scalars['Float']['input']>;
  bloomRadius?: InputMaybe<Scalars['Float']['input']>;
  bloomStrength?: InputMaybe<Scalars['Float']['input']>;
  bloomThreshold?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ssao?: InputMaybe<Scalars['String']['input']>;
  ssr?: InputMaybe<Scalars['String']['input']>;
};

export type PostProcessingSettingsCreateManyInput = {
  antialiasing?: InputMaybe<Scalars['String']['input']>;
  bloom?: InputMaybe<Scalars['String']['input']>;
  bloomExposure?: InputMaybe<Scalars['Float']['input']>;
  bloomRadius?: InputMaybe<Scalars['Float']['input']>;
  bloomStrength?: InputMaybe<Scalars['Float']['input']>;
  bloomThreshold?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ssao?: InputMaybe<Scalars['String']['input']>;
  ssr?: InputMaybe<Scalars['String']['input']>;
};

export type PostProcessingSettingsCreateNestedOneWithoutGraphicsSettingsInput = {
  connect?: InputMaybe<PostProcessingSettingsWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PostProcessingSettingsCreateOrConnectWithoutGraphicsSettingsInput>;
  create?: InputMaybe<PostProcessingSettingsCreateWithoutGraphicsSettingsInput>;
};

export type PostProcessingSettingsCreateOrConnectWithoutGraphicsSettingsInput = {
  create: PostProcessingSettingsCreateWithoutGraphicsSettingsInput;
  where: PostProcessingSettingsWhereUniqueInput;
};

export type PostProcessingSettingsCreateWithoutGraphicsSettingsInput = {
  antialiasing?: InputMaybe<Scalars['String']['input']>;
  bloom?: InputMaybe<Scalars['String']['input']>;
  bloomExposure?: InputMaybe<Scalars['Float']['input']>;
  bloomRadius?: InputMaybe<Scalars['Float']['input']>;
  bloomStrength?: InputMaybe<Scalars['Float']['input']>;
  bloomThreshold?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ssao?: InputMaybe<Scalars['String']['input']>;
  ssr?: InputMaybe<Scalars['String']['input']>;
};

export type PostProcessingSettingsGroupBy = {
  __typename?: 'PostProcessingSettingsGroupBy';
  _avg?: Maybe<PostProcessingSettingsAvgAggregate>;
  _count?: Maybe<PostProcessingSettingsCountAggregate>;
  _max?: Maybe<PostProcessingSettingsMaxAggregate>;
  _min?: Maybe<PostProcessingSettingsMinAggregate>;
  _sum?: Maybe<PostProcessingSettingsSumAggregate>;
  antialiasing?: Maybe<Scalars['String']['output']>;
  bloom?: Maybe<Scalars['String']['output']>;
  bloomExposure: Scalars['Float']['output'];
  bloomRadius: Scalars['Float']['output'];
  bloomStrength: Scalars['Float']['output'];
  bloomThreshold: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  ssao?: Maybe<Scalars['String']['output']>;
  ssr?: Maybe<Scalars['String']['output']>;
};

export type PostProcessingSettingsMaxAggregate = {
  __typename?: 'PostProcessingSettingsMaxAggregate';
  antialiasing?: Maybe<Scalars['String']['output']>;
  bloom?: Maybe<Scalars['String']['output']>;
  bloomExposure?: Maybe<Scalars['Float']['output']>;
  bloomRadius?: Maybe<Scalars['Float']['output']>;
  bloomStrength?: Maybe<Scalars['Float']['output']>;
  bloomThreshold?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  ssao?: Maybe<Scalars['String']['output']>;
  ssr?: Maybe<Scalars['String']['output']>;
};

export type PostProcessingSettingsMaxOrderByAggregateInput = {
  antialiasing?: InputMaybe<SortOrder>;
  bloom?: InputMaybe<SortOrder>;
  bloomExposure?: InputMaybe<SortOrder>;
  bloomRadius?: InputMaybe<SortOrder>;
  bloomStrength?: InputMaybe<SortOrder>;
  bloomThreshold?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ssao?: InputMaybe<SortOrder>;
  ssr?: InputMaybe<SortOrder>;
};

export type PostProcessingSettingsMinAggregate = {
  __typename?: 'PostProcessingSettingsMinAggregate';
  antialiasing?: Maybe<Scalars['String']['output']>;
  bloom?: Maybe<Scalars['String']['output']>;
  bloomExposure?: Maybe<Scalars['Float']['output']>;
  bloomRadius?: Maybe<Scalars['Float']['output']>;
  bloomStrength?: Maybe<Scalars['Float']['output']>;
  bloomThreshold?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  ssao?: Maybe<Scalars['String']['output']>;
  ssr?: Maybe<Scalars['String']['output']>;
};

export type PostProcessingSettingsMinOrderByAggregateInput = {
  antialiasing?: InputMaybe<SortOrder>;
  bloom?: InputMaybe<SortOrder>;
  bloomExposure?: InputMaybe<SortOrder>;
  bloomRadius?: InputMaybe<SortOrder>;
  bloomStrength?: InputMaybe<SortOrder>;
  bloomThreshold?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ssao?: InputMaybe<SortOrder>;
  ssr?: InputMaybe<SortOrder>;
};

export type PostProcessingSettingsNullableRelationFilter = {
  is?: InputMaybe<PostProcessingSettingsWhereInput>;
  isNot?: InputMaybe<PostProcessingSettingsWhereInput>;
};

export type PostProcessingSettingsOrderByWithAggregationInput = {
  _avg?: InputMaybe<PostProcessingSettingsAvgOrderByAggregateInput>;
  _count?: InputMaybe<PostProcessingSettingsCountOrderByAggregateInput>;
  _max?: InputMaybe<PostProcessingSettingsMaxOrderByAggregateInput>;
  _min?: InputMaybe<PostProcessingSettingsMinOrderByAggregateInput>;
  _sum?: InputMaybe<PostProcessingSettingsSumOrderByAggregateInput>;
  antialiasing?: InputMaybe<SortOrderInput>;
  bloom?: InputMaybe<SortOrderInput>;
  bloomExposure?: InputMaybe<SortOrder>;
  bloomRadius?: InputMaybe<SortOrder>;
  bloomStrength?: InputMaybe<SortOrder>;
  bloomThreshold?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ssao?: InputMaybe<SortOrderInput>;
  ssr?: InputMaybe<SortOrderInput>;
};

export type PostProcessingSettingsOrderByWithRelationInput = {
  GraphicsSettings?: InputMaybe<GraphicsSettingsOrderByRelationAggregateInput>;
  antialiasing?: InputMaybe<SortOrderInput>;
  bloom?: InputMaybe<SortOrderInput>;
  bloomExposure?: InputMaybe<SortOrder>;
  bloomRadius?: InputMaybe<SortOrder>;
  bloomStrength?: InputMaybe<SortOrder>;
  bloomThreshold?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ssao?: InputMaybe<SortOrderInput>;
  ssr?: InputMaybe<SortOrderInput>;
};

export enum PostProcessingSettingsScalarFieldEnum {
  Antialiasing = 'antialiasing',
  Bloom = 'bloom',
  BloomExposure = 'bloomExposure',
  BloomRadius = 'bloomRadius',
  BloomStrength = 'bloomStrength',
  BloomThreshold = 'bloomThreshold',
  Id = 'id',
  Ssao = 'ssao',
  Ssr = 'ssr'
}

export type PostProcessingSettingsScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<PostProcessingSettingsScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<PostProcessingSettingsScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<PostProcessingSettingsScalarWhereWithAggregatesInput>>;
  antialiasing?: InputMaybe<StringNullableWithAggregatesFilter>;
  bloom?: InputMaybe<StringNullableWithAggregatesFilter>;
  bloomExposure?: InputMaybe<FloatWithAggregatesFilter>;
  bloomRadius?: InputMaybe<FloatWithAggregatesFilter>;
  bloomStrength?: InputMaybe<FloatWithAggregatesFilter>;
  bloomThreshold?: InputMaybe<FloatWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  ssao?: InputMaybe<StringNullableWithAggregatesFilter>;
  ssr?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type PostProcessingSettingsSumAggregate = {
  __typename?: 'PostProcessingSettingsSumAggregate';
  bloomExposure?: Maybe<Scalars['Float']['output']>;
  bloomRadius?: Maybe<Scalars['Float']['output']>;
  bloomStrength?: Maybe<Scalars['Float']['output']>;
  bloomThreshold?: Maybe<Scalars['Float']['output']>;
};

export type PostProcessingSettingsSumOrderByAggregateInput = {
  bloomExposure?: InputMaybe<SortOrder>;
  bloomRadius?: InputMaybe<SortOrder>;
  bloomStrength?: InputMaybe<SortOrder>;
  bloomThreshold?: InputMaybe<SortOrder>;
};

export type PostProcessingSettingsUpdateInput = {
  GraphicsSettings?: InputMaybe<GraphicsSettingsUpdateManyWithoutPostProcessingSettingsNestedInput>;
  antialiasing?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bloom?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bloomExposure?: InputMaybe<FloatFieldUpdateOperationsInput>;
  bloomRadius?: InputMaybe<FloatFieldUpdateOperationsInput>;
  bloomStrength?: InputMaybe<FloatFieldUpdateOperationsInput>;
  bloomThreshold?: InputMaybe<FloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ssao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ssr?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type PostProcessingSettingsUpdateManyMutationInput = {
  antialiasing?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bloom?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bloomExposure?: InputMaybe<FloatFieldUpdateOperationsInput>;
  bloomRadius?: InputMaybe<FloatFieldUpdateOperationsInput>;
  bloomStrength?: InputMaybe<FloatFieldUpdateOperationsInput>;
  bloomThreshold?: InputMaybe<FloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ssao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ssr?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type PostProcessingSettingsUpdateOneWithoutGraphicsSettingsNestedInput = {
  connect?: InputMaybe<PostProcessingSettingsWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PostProcessingSettingsCreateOrConnectWithoutGraphicsSettingsInput>;
  create?: InputMaybe<PostProcessingSettingsCreateWithoutGraphicsSettingsInput>;
  delete?: InputMaybe<PostProcessingSettingsWhereInput>;
  disconnect?: InputMaybe<PostProcessingSettingsWhereInput>;
  update?: InputMaybe<PostProcessingSettingsUpdateToOneWithWhereWithoutGraphicsSettingsInput>;
  upsert?: InputMaybe<PostProcessingSettingsUpsertWithoutGraphicsSettingsInput>;
};

export type PostProcessingSettingsUpdateToOneWithWhereWithoutGraphicsSettingsInput = {
  data: PostProcessingSettingsUpdateWithoutGraphicsSettingsInput;
  where?: InputMaybe<PostProcessingSettingsWhereInput>;
};

export type PostProcessingSettingsUpdateWithoutGraphicsSettingsInput = {
  antialiasing?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bloom?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bloomExposure?: InputMaybe<FloatFieldUpdateOperationsInput>;
  bloomRadius?: InputMaybe<FloatFieldUpdateOperationsInput>;
  bloomStrength?: InputMaybe<FloatFieldUpdateOperationsInput>;
  bloomThreshold?: InputMaybe<FloatFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  ssao?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ssr?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type PostProcessingSettingsUpsertWithoutGraphicsSettingsInput = {
  create: PostProcessingSettingsCreateWithoutGraphicsSettingsInput;
  update: PostProcessingSettingsUpdateWithoutGraphicsSettingsInput;
  where?: InputMaybe<PostProcessingSettingsWhereInput>;
};

export type PostProcessingSettingsWhereInput = {
  AND?: InputMaybe<Array<PostProcessingSettingsWhereInput>>;
  GraphicsSettings?: InputMaybe<GraphicsSettingsListRelationFilter>;
  NOT?: InputMaybe<Array<PostProcessingSettingsWhereInput>>;
  OR?: InputMaybe<Array<PostProcessingSettingsWhereInput>>;
  antialiasing?: InputMaybe<StringNullableFilter>;
  bloom?: InputMaybe<StringNullableFilter>;
  bloomExposure?: InputMaybe<FloatFilter>;
  bloomRadius?: InputMaybe<FloatFilter>;
  bloomStrength?: InputMaybe<FloatFilter>;
  bloomThreshold?: InputMaybe<FloatFilter>;
  id?: InputMaybe<UuidFilter>;
  ssao?: InputMaybe<StringNullableFilter>;
  ssr?: InputMaybe<StringNullableFilter>;
};

export type PostProcessingSettingsWhereUniqueInput = {
  AND?: InputMaybe<Array<PostProcessingSettingsWhereInput>>;
  GraphicsSettings?: InputMaybe<GraphicsSettingsListRelationFilter>;
  NOT?: InputMaybe<Array<PostProcessingSettingsWhereInput>>;
  OR?: InputMaybe<Array<PostProcessingSettingsWhereInput>>;
  antialiasing?: InputMaybe<StringNullableFilter>;
  bloom?: InputMaybe<StringNullableFilter>;
  bloomExposure?: InputMaybe<FloatFilter>;
  bloomRadius?: InputMaybe<FloatFilter>;
  bloomStrength?: InputMaybe<FloatFilter>;
  bloomThreshold?: InputMaybe<FloatFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  ssao?: InputMaybe<StringNullableFilter>;
  ssr?: InputMaybe<StringNullableFilter>;
};

export type PriceFilter = {
  max?: InputMaybe<Scalars['Float']['input']>;
  min?: InputMaybe<Scalars['Float']['input']>;
};

export type Product = {
  assetIdWeb2?: InputMaybe<Scalars['String']['input']>;
  assetURL: Scalars['String']['input'];
  creatorWalletType?: InputMaybe<Scalars['String']['input']>;
  fileType?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  previewImage?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['String']['input'];
};

export type ProductOutput = {
  __typename?: 'ProductOutput';
  assetIdWeb2?: Maybe<Scalars['String']['output']>;
  assetURL?: Maybe<Scalars['String']['output']>;
  creatorWalletType?: Maybe<Scalars['String']['output']>;
  fileType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  previewImage?: Maybe<Scalars['String']['output']>;
  price: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type Profile = {
  __typename?: 'Profile';
  createdAt: Scalars['DateTimeISO']['output'];
  draws?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  loses?: Maybe<Scalars['Int']['output']>;
  timeInLobby?: Maybe<Scalars['Int']['output']>;
  timePlayed?: Maybe<Scalars['Int']['output']>;
  totalGames?: Maybe<Scalars['Int']['output']>;
  username: Scalars['String']['output'];
  wins?: Maybe<Scalars['Int']['output']>;
};

export type Query = {
  __typename?: 'Query';
  addAssetToCollection: GetCollectionsByCreatorResponse;
  aggregateCameraConfig: AggregateCameraConfig;
  aggregateDomainScene: AggregateDomainScene;
  aggregateGraphicsSettings: AggregateGraphicsSettings;
  aggregateHideAndSeek: AggregateHideAndSeek;
  aggregateHideAndSeekNPC: AggregateHideAndSeekNpc;
  aggregateLightProps: AggregateLightProps;
  aggregateLightTemplate: AggregateLightTemplate;
  aggregateMFESTemplate: AggregateMfesTemplate;
  aggregateMFESceneTemplate: AggregateMfeSceneTemplate;
  aggregatePostProcessingSettings: AggregatePostProcessingSettings;
  aggregateQuest: AggregateQuest;
  aggregateSceneTemplate: AggregateSceneTemplate;
  aggregateShopifyStore: AggregateShopifyStore;
  aggregateSubScenesDomain: AggregateSubScenesDomain;
  aggregateSubscriptionPlan: AggregateSubscriptionPlan;
  aggregateTemplates: AggregateTemplates;
  aggregateTourItems: AggregateTourItems;
  aggregateUserScene: AggregateUserScene;
  aggregateUserSceneAssets: AggregateUserSceneAssets;
  aggregateUserSubscription: AggregateUserSubscription;
  aggregateUsersQuests: AggregateUsersQuests;
  assetPagination: AssetsPagination;
  cameraConfig?: Maybe<CameraConfig>;
  cameraConfigs: Array<CameraConfig>;
  creatorByWalletId: CreatorByWalletIdResponse;
  domainScene?: Maybe<DomainScene>;
  domainScenes: Array<DomainScene>;
  findAliasScene: FindAliasSceneResponse;
  findFirstCameraConfig?: Maybe<CameraConfig>;
  findFirstCameraConfigOrThrow?: Maybe<CameraConfig>;
  findFirstDomainScene?: Maybe<DomainScene>;
  findFirstDomainSceneOrThrow?: Maybe<DomainScene>;
  findFirstGraphicsSettings?: Maybe<GraphicsSettings>;
  findFirstGraphicsSettingsOrThrow?: Maybe<GraphicsSettings>;
  findFirstHideAndSeek?: Maybe<HideAndSeek>;
  findFirstHideAndSeekNPC?: Maybe<HideAndSeekNpc>;
  findFirstHideAndSeekNPCOrThrow?: Maybe<HideAndSeekNpc>;
  findFirstHideAndSeekOrThrow?: Maybe<HideAndSeek>;
  findFirstLightProps?: Maybe<LightProps>;
  findFirstLightPropsOrThrow?: Maybe<LightProps>;
  findFirstLightTemplate?: Maybe<LightTemplate>;
  findFirstLightTemplateOrThrow?: Maybe<LightTemplate>;
  findFirstMFESTemplate?: Maybe<MfesTemplate>;
  findFirstMFESTemplateOrThrow?: Maybe<MfesTemplate>;
  findFirstMFESceneTemplate?: Maybe<MfeSceneTemplate>;
  findFirstMFESceneTemplateOrThrow?: Maybe<MfeSceneTemplate>;
  findFirstPostProcessingSettings?: Maybe<PostProcessingSettings>;
  findFirstPostProcessingSettingsOrThrow?: Maybe<PostProcessingSettings>;
  findFirstQuest?: Maybe<Quest>;
  findFirstQuestOrThrow?: Maybe<Quest>;
  findFirstSceneTemplate?: Maybe<SceneTemplate>;
  findFirstSceneTemplateOrThrow?: Maybe<SceneTemplate>;
  findFirstShopifyStore?: Maybe<ShopifyStore>;
  findFirstShopifyStoreOrThrow?: Maybe<ShopifyStore>;
  findFirstSubScenesDomain?: Maybe<SubScenesDomain>;
  findFirstSubScenesDomainOrThrow?: Maybe<SubScenesDomain>;
  findFirstSubscriptionPlan?: Maybe<SubscriptionPlan>;
  findFirstSubscriptionPlanOrThrow?: Maybe<SubscriptionPlan>;
  findFirstTemplates?: Maybe<Templates>;
  findFirstTemplatesOrThrow?: Maybe<Templates>;
  findFirstTourItems?: Maybe<TourItems>;
  findFirstTourItemsOrThrow?: Maybe<TourItems>;
  findFirstUserScene?: Maybe<UserScene>;
  findFirstUserSceneAssets?: Maybe<UserSceneAssets>;
  findFirstUserSceneAssetsOrThrow?: Maybe<UserSceneAssets>;
  findFirstUserSceneOrThrow?: Maybe<UserScene>;
  findFirstUserSubscription?: Maybe<UserSubscription>;
  findFirstUserSubscriptionOrThrow?: Maybe<UserSubscription>;
  findFirstUsersQuests?: Maybe<UsersQuests>;
  findFirstUsersQuestsOrThrow?: Maybe<UsersQuests>;
  findManyGraphicsSettings: Array<GraphicsSettings>;
  findManyLightProps: Array<LightProps>;
  findManyPostProcessingSettings: Array<PostProcessingSettings>;
  findManyTemplates: Array<Templates>;
  findManyTourItems: Array<TourItems>;
  findManyUserSceneAssets: Array<UserSceneAssets>;
  findManyUsersQuests: Array<UsersQuests>;
  findMatchesByUserId: FindMatchesByUserIdResponse;
  findUniqueGraphicsSettings?: Maybe<GraphicsSettings>;
  findUniqueGraphicsSettingsOrThrow?: Maybe<GraphicsSettings>;
  findUniqueLightProps?: Maybe<LightProps>;
  findUniqueLightPropsOrThrow?: Maybe<LightProps>;
  findUniquePostProcessingSettings?: Maybe<PostProcessingSettings>;
  findUniquePostProcessingSettingsOrThrow?: Maybe<PostProcessingSettings>;
  findUniqueQuest: FindUniqueQuestResponse;
  findUniqueTemplates?: Maybe<Templates>;
  findUniqueTemplatesOrThrow?: Maybe<Templates>;
  findUniqueTourItems?: Maybe<TourItems>;
  findUniqueTourItemsOrThrow?: Maybe<TourItems>;
  findUniqueUserSceneAssets?: Maybe<UserSceneAssets>;
  findUniqueUserSceneAssetsOrThrow?: Maybe<UserSceneAssets>;
  findUniqueUsersQuests?: Maybe<UsersQuests>;
  findUniqueUsersQuestsOrThrow?: Maybe<UsersQuests>;
  getAllDomainScene: GetAllDomainSceneResponse;
  getAllUsersQuests: GetAllUsersQuestsResponse;
  getAssetCreatorBnbWallet: GetAssetCreatorBnbWalletResponse;
  getAssetCreatorWallet: GetAssetCreatorWalletResponse;
  getAssetInfo: GetAssetInfoResponse;
  getAssetWeb2Info: GetAssetWeb2InfoResponse;
  getAssets: GetAssetsResponse;
  getAssetsByCollectionPagination: GetAssetsByCollectionPaginationResponse;
  getAssetsByCollectionSingleProduct: GetAssetsByCollectionResponse;
  getAssetsByCreator: AssetsByCreator;
  getAssetsWeb2: GetAssetsWeb2Response;
  getBoughtAssetsByUser: GetBoughtAssetsByUserResponse;
  getCameraConfig?: Maybe<CameraConfig>;
  getCart: GetCartResponse;
  getCategories: GetCategoriesResponse;
  getCategoryById: GetCategoryByIdByResponse;
  getCategoryIdByKey: GetCategoryIdByKey;
  getCollectionInfo: GetCollectionInfoResponse;
  getCollections: GetCollectionsResponse;
  getCollectionsByCreator: GetCollectionsByCreatorResponse;
  getConversationSid: GetConversationSidResponse;
  getCreatorAssetsAndInfo: GetCreatorAssetsAndInfoResponse;
  getCreatorInfo: CreatorInfo;
  getCreators: GetCreatorsResponse;
  getCurrenciesPrice: GetCurrenciesPriceResponse;
  getDefaultTemplate: GetDefaultTemplate;
  getDomainScene?: Maybe<DomainScene>;
  getFeaturedAssets: GetFeaturedAssetsResponse;
  getFeaturedAssetsAdmin: GetFeaturedAssetsAdminRes;
  getFeaturedCollections: GetFeaturedCollectionsResponse;
  getFeaturedCreators: GetFeaturedCreatorsResponse;
  getHideAndSeek?: Maybe<HideAndSeek>;
  getHideAndSeekNPC?: Maybe<HideAndSeekNpc>;
  getLightTemplate?: Maybe<LightTemplate>;
  getLikesAssetsByUser: GetLikesAssetsByUserResponse;
  getMFESTemplate?: Maybe<MfesTemplate>;
  getMFESceneTemplate?: Maybe<MfeSceneTemplate>;
  getOpenAIAnswer: GetOpenAiAnswerResponse;
  getOrdersById: GetOrdersByIdResponse;
  getOrdersByPayerId: GetOrdersByPayerIdResponse;
  getPollyLector: GetPollyLectorResponse;
  getProfileByUserId: GetProfileByUserIdResponse;
  getQuest?: Maybe<Quest>;
  getSceneAlias: GetSceneAliasResponse;
  getSceneIdByDomain: GetSceneIdByDomainResponse;
  getSceneTemplate?: Maybe<SceneTemplate>;
  getScenesCount: GetScenesCountResponse;
  getShopifyStore: GetShopifyStoreResponse;
  getShopifyStoreByScene: GetShopifyStoreBySceneResponse;
  getSubSceneIdByDomain: GetSubSceneIdByDomainResponse;
  getSubScenesDomain: GetSubScenesDomainResponse;
  getSubscriptionPlan?: Maybe<SubscriptionPlan>;
  getTopCreators: GetTopCreatorsResponse;
  getTrendingCollections: GetTrendingCollectionsResponse;
  getUserPublic: UserPublic;
  getUserScene?: Maybe<UserScene>;
  getUserSubscription: UserSubscription;
  getUserWardrobe: GetUserWardrobeResponse;
  getWalletBalance: GetWalletBalanceResponse;
  googleLogin: GoogleLoginRes;
  groupByCameraConfig: Array<CameraConfigGroupBy>;
  groupByDomainScene: Array<DomainSceneGroupBy>;
  groupByGraphicsSettings: Array<GraphicsSettingsGroupBy>;
  groupByHideAndSeek: Array<HideAndSeekGroupBy>;
  groupByHideAndSeekNPC: Array<HideAndSeekNpcGroupBy>;
  groupByLightProps: Array<LightPropsGroupBy>;
  groupByLightTemplate: Array<LightTemplateGroupBy>;
  groupByMFESTemplate: Array<MfesTemplateGroupBy>;
  groupByMFESceneTemplate: Array<MfeSceneTemplateGroupBy>;
  groupByPostProcessingSettings: Array<PostProcessingSettingsGroupBy>;
  groupByQuest: Array<QuestGroupBy>;
  groupBySceneTemplate: Array<SceneTemplateGroupBy>;
  groupByShopifyStore: Array<ShopifyStoreGroupBy>;
  groupBySubScenesDomain: Array<SubScenesDomainGroupBy>;
  groupBySubscriptionPlan: Array<SubscriptionPlanGroupBy>;
  groupByTemplates: Array<TemplatesGroupBy>;
  groupByTourItems: Array<TourItemsGroupBy>;
  groupByUserScene: Array<UserSceneGroupBy>;
  groupByUserSceneAssets: Array<UserSceneAssetsGroupBy>;
  groupByUserSubscription: Array<UserSubscriptionGroupBy>;
  groupByUsersQuests: Array<UsersQuestsGroupBy>;
  hideAndSeek?: Maybe<HideAndSeek>;
  hideAndSeekNPC?: Maybe<HideAndSeekNpc>;
  hideAndSeekNPCS: Array<HideAndSeekNpc>;
  hideAndSeeks: Array<HideAndSeek>;
  isChatEnabledInScene: IsChatEnabledInSceneResponse;
  isEmptyCollection: IsEmptyCollectionResponse;
  isLikedAssetByUser: IsLikedAssetResponse;
  isScanEnabledInScene: IsScanEnabledInSceneResponse;
  lightTemplate?: Maybe<LightTemplate>;
  lightTemplates: Array<LightTemplate>;
  login: Login;
  mFESTemplate?: Maybe<MfesTemplate>;
  mFESTemplates: Array<MfesTemplate>;
  mFESceneTemplate?: Maybe<MfeSceneTemplate>;
  mFESceneTemplates: Array<MfeSceneTemplate>;
  quest?: Maybe<Quest>;
  quests: Array<Quest>;
  sceneTemplate?: Maybe<SceneTemplate>;
  sceneTemplates: Array<SceneTemplate>;
  searchAssets: AssetsSearch;
  searchByCategories: SearchByCategoriesResponse;
  searchCollections: CollectionSearch;
  searchCreators: CreatorSearch;
  shopifyStore?: Maybe<ShopifyStore>;
  shopifyStores: Array<ShopifyStore>;
  subScenesDomain?: Maybe<SubScenesDomain>;
  subScenesDomains: Array<SubScenesDomain>;
  subscriptionPlan?: Maybe<SubscriptionPlan>;
  subscriptionPlans: Array<SubscriptionPlan>;
  userScene?: Maybe<UserScene>;
  userScenes: Array<UserScene>;
  userSubscription?: Maybe<UserSubscription>;
  userSubscriptions: Array<UserSubscription>;
};


export type QueryAddAssetToCollectionArgs = {
  data: GetCollectionsByCreatorInput;
};


export type QueryAggregateCameraConfigArgs = {
  cursor?: InputMaybe<CameraConfigWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CameraConfigOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CameraConfigWhereInput>;
};


export type QueryAggregateDomainSceneArgs = {
  cursor?: InputMaybe<DomainSceneWhereUniqueInput>;
  orderBy?: InputMaybe<Array<DomainSceneOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DomainSceneWhereInput>;
};


export type QueryAggregateGraphicsSettingsArgs = {
  cursor?: InputMaybe<GraphicsSettingsWhereUniqueInput>;
  orderBy?: InputMaybe<Array<GraphicsSettingsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GraphicsSettingsWhereInput>;
};


export type QueryAggregateHideAndSeekArgs = {
  cursor?: InputMaybe<HideAndSeekWhereUniqueInput>;
  orderBy?: InputMaybe<Array<HideAndSeekOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HideAndSeekWhereInput>;
};


export type QueryAggregateHideAndSeekNpcArgs = {
  cursor?: InputMaybe<HideAndSeekNpcWhereUniqueInput>;
  orderBy?: InputMaybe<Array<HideAndSeekNpcOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HideAndSeekNpcWhereInput>;
};


export type QueryAggregateLightPropsArgs = {
  cursor?: InputMaybe<LightPropsWhereUniqueInput>;
  orderBy?: InputMaybe<Array<LightPropsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LightPropsWhereInput>;
};


export type QueryAggregateLightTemplateArgs = {
  cursor?: InputMaybe<LightTemplateWhereUniqueInput>;
  orderBy?: InputMaybe<Array<LightTemplateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LightTemplateWhereInput>;
};


export type QueryAggregateMfesTemplateArgs = {
  cursor?: InputMaybe<MfesTemplateWhereUniqueInput>;
  orderBy?: InputMaybe<Array<MfesTemplateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MfesTemplateWhereInput>;
};


export type QueryAggregateMfeSceneTemplateArgs = {
  cursor?: InputMaybe<MfeSceneTemplateWhereUniqueInput>;
  orderBy?: InputMaybe<Array<MfeSceneTemplateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MfeSceneTemplateWhereInput>;
};


export type QueryAggregatePostProcessingSettingsArgs = {
  cursor?: InputMaybe<PostProcessingSettingsWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PostProcessingSettingsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PostProcessingSettingsWhereInput>;
};


export type QueryAggregateQuestArgs = {
  cursor?: InputMaybe<QuestWhereUniqueInput>;
  orderBy?: InputMaybe<Array<QuestOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<QuestWhereInput>;
};


export type QueryAggregateSceneTemplateArgs = {
  cursor?: InputMaybe<SceneTemplateWhereUniqueInput>;
  orderBy?: InputMaybe<Array<SceneTemplateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SceneTemplateWhereInput>;
};


export type QueryAggregateShopifyStoreArgs = {
  cursor?: InputMaybe<ShopifyStoreWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ShopifyStoreOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ShopifyStoreWhereInput>;
};


export type QueryAggregateSubScenesDomainArgs = {
  cursor?: InputMaybe<SubScenesDomainWhereUniqueInput>;
  orderBy?: InputMaybe<Array<SubScenesDomainOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubScenesDomainWhereInput>;
};


export type QueryAggregateSubscriptionPlanArgs = {
  cursor?: InputMaybe<SubscriptionPlanWhereUniqueInput>;
  orderBy?: InputMaybe<Array<SubscriptionPlanOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubscriptionPlanWhereInput>;
};


export type QueryAggregateTemplatesArgs = {
  cursor?: InputMaybe<TemplatesWhereUniqueInput>;
  orderBy?: InputMaybe<Array<TemplatesOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TemplatesWhereInput>;
};


export type QueryAggregateTourItemsArgs = {
  cursor?: InputMaybe<TourItemsWhereUniqueInput>;
  orderBy?: InputMaybe<Array<TourItemsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TourItemsWhereInput>;
};


export type QueryAggregateUserSceneArgs = {
  cursor?: InputMaybe<UserSceneWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserSceneOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserSceneWhereInput>;
};


export type QueryAggregateUserSceneAssetsArgs = {
  cursor?: InputMaybe<UserSceneAssetsWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserSceneAssetsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserSceneAssetsWhereInput>;
};


export type QueryAggregateUserSubscriptionArgs = {
  cursor?: InputMaybe<UserSubscriptionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserSubscriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserSubscriptionWhereInput>;
};


export type QueryAggregateUsersQuestsArgs = {
  cursor?: InputMaybe<UsersQuestsWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UsersQuestsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UsersQuestsWhereInput>;
};


export type QueryAssetPaginationArgs = {
  where: AssetPaginationInput;
};


export type QueryCameraConfigArgs = {
  where: CameraConfigWhereUniqueInput;
};


export type QueryCameraConfigsArgs = {
  cursor?: InputMaybe<CameraConfigWhereUniqueInput>;
  distinct?: InputMaybe<Array<CameraConfigScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CameraConfigOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CameraConfigWhereInput>;
};


export type QueryCreatorByWalletIdArgs = {
  where: CreatorByWalletIdInput;
};


export type QueryDomainSceneArgs = {
  where: DomainSceneWhereUniqueInput;
};


export type QueryDomainScenesArgs = {
  cursor?: InputMaybe<DomainSceneWhereUniqueInput>;
  distinct?: InputMaybe<Array<DomainSceneScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DomainSceneOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DomainSceneWhereInput>;
};


export type QueryFindAliasSceneArgs = {
  where: FindAliasSceneInput;
};


export type QueryFindFirstCameraConfigArgs = {
  cursor?: InputMaybe<CameraConfigWhereUniqueInput>;
  distinct?: InputMaybe<Array<CameraConfigScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CameraConfigOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CameraConfigWhereInput>;
};


export type QueryFindFirstCameraConfigOrThrowArgs = {
  cursor?: InputMaybe<CameraConfigWhereUniqueInput>;
  distinct?: InputMaybe<Array<CameraConfigScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CameraConfigOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CameraConfigWhereInput>;
};


export type QueryFindFirstDomainSceneArgs = {
  cursor?: InputMaybe<DomainSceneWhereUniqueInput>;
  distinct?: InputMaybe<Array<DomainSceneScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DomainSceneOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DomainSceneWhereInput>;
};


export type QueryFindFirstDomainSceneOrThrowArgs = {
  cursor?: InputMaybe<DomainSceneWhereUniqueInput>;
  distinct?: InputMaybe<Array<DomainSceneScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DomainSceneOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DomainSceneWhereInput>;
};


export type QueryFindFirstGraphicsSettingsArgs = {
  cursor?: InputMaybe<GraphicsSettingsWhereUniqueInput>;
  distinct?: InputMaybe<Array<GraphicsSettingsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GraphicsSettingsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GraphicsSettingsWhereInput>;
};


export type QueryFindFirstGraphicsSettingsOrThrowArgs = {
  cursor?: InputMaybe<GraphicsSettingsWhereUniqueInput>;
  distinct?: InputMaybe<Array<GraphicsSettingsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GraphicsSettingsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GraphicsSettingsWhereInput>;
};


export type QueryFindFirstHideAndSeekArgs = {
  cursor?: InputMaybe<HideAndSeekWhereUniqueInput>;
  distinct?: InputMaybe<Array<HideAndSeekScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<HideAndSeekOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HideAndSeekWhereInput>;
};


export type QueryFindFirstHideAndSeekNpcArgs = {
  cursor?: InputMaybe<HideAndSeekNpcWhereUniqueInput>;
  distinct?: InputMaybe<Array<HideAndSeekNpcScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<HideAndSeekNpcOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HideAndSeekNpcWhereInput>;
};


export type QueryFindFirstHideAndSeekNpcOrThrowArgs = {
  cursor?: InputMaybe<HideAndSeekNpcWhereUniqueInput>;
  distinct?: InputMaybe<Array<HideAndSeekNpcScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<HideAndSeekNpcOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HideAndSeekNpcWhereInput>;
};


export type QueryFindFirstHideAndSeekOrThrowArgs = {
  cursor?: InputMaybe<HideAndSeekWhereUniqueInput>;
  distinct?: InputMaybe<Array<HideAndSeekScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<HideAndSeekOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HideAndSeekWhereInput>;
};


export type QueryFindFirstLightPropsArgs = {
  cursor?: InputMaybe<LightPropsWhereUniqueInput>;
  distinct?: InputMaybe<Array<LightPropsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LightPropsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LightPropsWhereInput>;
};


export type QueryFindFirstLightPropsOrThrowArgs = {
  cursor?: InputMaybe<LightPropsWhereUniqueInput>;
  distinct?: InputMaybe<Array<LightPropsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LightPropsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LightPropsWhereInput>;
};


export type QueryFindFirstLightTemplateArgs = {
  cursor?: InputMaybe<LightTemplateWhereUniqueInput>;
  distinct?: InputMaybe<Array<LightTemplateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LightTemplateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LightTemplateWhereInput>;
};


export type QueryFindFirstLightTemplateOrThrowArgs = {
  cursor?: InputMaybe<LightTemplateWhereUniqueInput>;
  distinct?: InputMaybe<Array<LightTemplateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LightTemplateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LightTemplateWhereInput>;
};


export type QueryFindFirstMfesTemplateArgs = {
  cursor?: InputMaybe<MfesTemplateWhereUniqueInput>;
  distinct?: InputMaybe<Array<MfesTemplateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MfesTemplateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MfesTemplateWhereInput>;
};


export type QueryFindFirstMfesTemplateOrThrowArgs = {
  cursor?: InputMaybe<MfesTemplateWhereUniqueInput>;
  distinct?: InputMaybe<Array<MfesTemplateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MfesTemplateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MfesTemplateWhereInput>;
};


export type QueryFindFirstMfeSceneTemplateArgs = {
  cursor?: InputMaybe<MfeSceneTemplateWhereUniqueInput>;
  distinct?: InputMaybe<Array<MfeSceneTemplateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MfeSceneTemplateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MfeSceneTemplateWhereInput>;
};


export type QueryFindFirstMfeSceneTemplateOrThrowArgs = {
  cursor?: InputMaybe<MfeSceneTemplateWhereUniqueInput>;
  distinct?: InputMaybe<Array<MfeSceneTemplateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MfeSceneTemplateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MfeSceneTemplateWhereInput>;
};


export type QueryFindFirstPostProcessingSettingsArgs = {
  cursor?: InputMaybe<PostProcessingSettingsWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostProcessingSettingsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostProcessingSettingsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PostProcessingSettingsWhereInput>;
};


export type QueryFindFirstPostProcessingSettingsOrThrowArgs = {
  cursor?: InputMaybe<PostProcessingSettingsWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostProcessingSettingsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostProcessingSettingsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PostProcessingSettingsWhereInput>;
};


export type QueryFindFirstQuestArgs = {
  cursor?: InputMaybe<QuestWhereUniqueInput>;
  distinct?: InputMaybe<Array<QuestScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<QuestOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<QuestWhereInput>;
};


export type QueryFindFirstQuestOrThrowArgs = {
  cursor?: InputMaybe<QuestWhereUniqueInput>;
  distinct?: InputMaybe<Array<QuestScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<QuestOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<QuestWhereInput>;
};


export type QueryFindFirstSceneTemplateArgs = {
  cursor?: InputMaybe<SceneTemplateWhereUniqueInput>;
  distinct?: InputMaybe<Array<SceneTemplateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SceneTemplateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SceneTemplateWhereInput>;
};


export type QueryFindFirstSceneTemplateOrThrowArgs = {
  cursor?: InputMaybe<SceneTemplateWhereUniqueInput>;
  distinct?: InputMaybe<Array<SceneTemplateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SceneTemplateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SceneTemplateWhereInput>;
};


export type QueryFindFirstShopifyStoreArgs = {
  cursor?: InputMaybe<ShopifyStoreWhereUniqueInput>;
  distinct?: InputMaybe<Array<ShopifyStoreScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ShopifyStoreOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ShopifyStoreWhereInput>;
};


export type QueryFindFirstShopifyStoreOrThrowArgs = {
  cursor?: InputMaybe<ShopifyStoreWhereUniqueInput>;
  distinct?: InputMaybe<Array<ShopifyStoreScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ShopifyStoreOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ShopifyStoreWhereInput>;
};


export type QueryFindFirstSubScenesDomainArgs = {
  cursor?: InputMaybe<SubScenesDomainWhereUniqueInput>;
  distinct?: InputMaybe<Array<SubScenesDomainScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SubScenesDomainOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubScenesDomainWhereInput>;
};


export type QueryFindFirstSubScenesDomainOrThrowArgs = {
  cursor?: InputMaybe<SubScenesDomainWhereUniqueInput>;
  distinct?: InputMaybe<Array<SubScenesDomainScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SubScenesDomainOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubScenesDomainWhereInput>;
};


export type QueryFindFirstSubscriptionPlanArgs = {
  cursor?: InputMaybe<SubscriptionPlanWhereUniqueInput>;
  distinct?: InputMaybe<Array<SubscriptionPlanScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SubscriptionPlanOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubscriptionPlanWhereInput>;
};


export type QueryFindFirstSubscriptionPlanOrThrowArgs = {
  cursor?: InputMaybe<SubscriptionPlanWhereUniqueInput>;
  distinct?: InputMaybe<Array<SubscriptionPlanScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SubscriptionPlanOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubscriptionPlanWhereInput>;
};


export type QueryFindFirstTemplatesArgs = {
  cursor?: InputMaybe<TemplatesWhereUniqueInput>;
  distinct?: InputMaybe<Array<TemplatesScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TemplatesOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TemplatesWhereInput>;
};


export type QueryFindFirstTemplatesOrThrowArgs = {
  cursor?: InputMaybe<TemplatesWhereUniqueInput>;
  distinct?: InputMaybe<Array<TemplatesScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TemplatesOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TemplatesWhereInput>;
};


export type QueryFindFirstTourItemsArgs = {
  cursor?: InputMaybe<TourItemsWhereUniqueInput>;
  distinct?: InputMaybe<Array<TourItemsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TourItemsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TourItemsWhereInput>;
};


export type QueryFindFirstTourItemsOrThrowArgs = {
  cursor?: InputMaybe<TourItemsWhereUniqueInput>;
  distinct?: InputMaybe<Array<TourItemsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TourItemsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TourItemsWhereInput>;
};


export type QueryFindFirstUserSceneArgs = {
  cursor?: InputMaybe<UserSceneWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserSceneScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserSceneOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserSceneWhereInput>;
};


export type QueryFindFirstUserSceneAssetsArgs = {
  cursor?: InputMaybe<UserSceneAssetsWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserSceneAssetsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserSceneAssetsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserSceneAssetsWhereInput>;
};


export type QueryFindFirstUserSceneAssetsOrThrowArgs = {
  cursor?: InputMaybe<UserSceneAssetsWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserSceneAssetsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserSceneAssetsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserSceneAssetsWhereInput>;
};


export type QueryFindFirstUserSceneOrThrowArgs = {
  cursor?: InputMaybe<UserSceneWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserSceneScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserSceneOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserSceneWhereInput>;
};


export type QueryFindFirstUserSubscriptionArgs = {
  cursor?: InputMaybe<UserSubscriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserSubscriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserSubscriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserSubscriptionWhereInput>;
};


export type QueryFindFirstUserSubscriptionOrThrowArgs = {
  cursor?: InputMaybe<UserSubscriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserSubscriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserSubscriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserSubscriptionWhereInput>;
};


export type QueryFindFirstUsersQuestsArgs = {
  cursor?: InputMaybe<UsersQuestsWhereUniqueInput>;
  distinct?: InputMaybe<Array<UsersQuestsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UsersQuestsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UsersQuestsWhereInput>;
};


export type QueryFindFirstUsersQuestsOrThrowArgs = {
  cursor?: InputMaybe<UsersQuestsWhereUniqueInput>;
  distinct?: InputMaybe<Array<UsersQuestsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UsersQuestsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UsersQuestsWhereInput>;
};


export type QueryFindManyGraphicsSettingsArgs = {
  cursor?: InputMaybe<GraphicsSettingsWhereUniqueInput>;
  distinct?: InputMaybe<Array<GraphicsSettingsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GraphicsSettingsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GraphicsSettingsWhereInput>;
};


export type QueryFindManyLightPropsArgs = {
  cursor?: InputMaybe<LightPropsWhereUniqueInput>;
  distinct?: InputMaybe<Array<LightPropsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LightPropsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LightPropsWhereInput>;
};


export type QueryFindManyPostProcessingSettingsArgs = {
  cursor?: InputMaybe<PostProcessingSettingsWhereUniqueInput>;
  distinct?: InputMaybe<Array<PostProcessingSettingsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PostProcessingSettingsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PostProcessingSettingsWhereInput>;
};


export type QueryFindManyTemplatesArgs = {
  cursor?: InputMaybe<TemplatesWhereUniqueInput>;
  distinct?: InputMaybe<Array<TemplatesScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TemplatesOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TemplatesWhereInput>;
};


export type QueryFindManyTourItemsArgs = {
  cursor?: InputMaybe<TourItemsWhereUniqueInput>;
  distinct?: InputMaybe<Array<TourItemsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TourItemsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TourItemsWhereInput>;
};


export type QueryFindManyUserSceneAssetsArgs = {
  cursor?: InputMaybe<UserSceneAssetsWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserSceneAssetsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserSceneAssetsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserSceneAssetsWhereInput>;
};


export type QueryFindManyUsersQuestsArgs = {
  cursor?: InputMaybe<UsersQuestsWhereUniqueInput>;
  distinct?: InputMaybe<Array<UsersQuestsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UsersQuestsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UsersQuestsWhereInput>;
};


export type QueryFindMatchesByUserIdArgs = {
  where: FindMatchesByUserIdInput;
};


export type QueryFindUniqueGraphicsSettingsArgs = {
  where: GraphicsSettingsWhereUniqueInput;
};


export type QueryFindUniqueGraphicsSettingsOrThrowArgs = {
  where: GraphicsSettingsWhereUniqueInput;
};


export type QueryFindUniqueLightPropsArgs = {
  where: LightPropsWhereUniqueInput;
};


export type QueryFindUniqueLightPropsOrThrowArgs = {
  where: LightPropsWhereUniqueInput;
};


export type QueryFindUniquePostProcessingSettingsArgs = {
  where: PostProcessingSettingsWhereUniqueInput;
};


export type QueryFindUniquePostProcessingSettingsOrThrowArgs = {
  where: PostProcessingSettingsWhereUniqueInput;
};


export type QueryFindUniqueQuestArgs = {
  where: FindUniqueQuestInput;
};


export type QueryFindUniqueTemplatesArgs = {
  where: TemplatesWhereUniqueInput;
};


export type QueryFindUniqueTemplatesOrThrowArgs = {
  where: TemplatesWhereUniqueInput;
};


export type QueryFindUniqueTourItemsArgs = {
  where: TourItemsWhereUniqueInput;
};


export type QueryFindUniqueTourItemsOrThrowArgs = {
  where: TourItemsWhereUniqueInput;
};


export type QueryFindUniqueUserSceneAssetsArgs = {
  where: UserSceneAssetsWhereUniqueInput;
};


export type QueryFindUniqueUserSceneAssetsOrThrowArgs = {
  where: UserSceneAssetsWhereUniqueInput;
};


export type QueryFindUniqueUsersQuestsArgs = {
  where: UsersQuestsWhereUniqueInput;
};


export type QueryFindUniqueUsersQuestsOrThrowArgs = {
  where: UsersQuestsWhereUniqueInput;
};


export type QueryGetAllDomainSceneArgs = {
  where: GetAllDomainSceneInput;
};


export type QueryGetAllUsersQuestsArgs = {
  where: GetAllUsersQuestsInput;
};


export type QueryGetAssetCreatorBnbWalletArgs = {
  id: Scalars['Float']['input'];
};


export type QueryGetAssetCreatorWalletArgs = {
  id: Scalars['Float']['input'];
};


export type QueryGetAssetInfoArgs = {
  where: GetAssetInfoInput;
};


export type QueryGetAssetWeb2InfoArgs = {
  where: GetAssetWeb2InfoInput;
};


export type QueryGetAssetsArgs = {
  where: GetAssetsInput;
};


export type QueryGetAssetsByCollectionPaginationArgs = {
  where: AssetGetByCollectionPaginationInput;
};


export type QueryGetAssetsByCollectionSingleProductArgs = {
  where: AssetGetByCollectionInput;
};


export type QueryGetAssetsByCreatorArgs = {
  where: AssetGetByCreatorInput;
};


export type QueryGetAssetsWeb2Args = {
  where: GetAssetsWeb2Input;
};


export type QueryGetBoughtAssetsByUserArgs = {
  where: GetBoughtAssetsByUserInput;
};


export type QueryGetCameraConfigArgs = {
  where: CameraConfigWhereUniqueInput;
};


export type QueryGetCartArgs = {
  data: GetCartInput;
};


export type QueryGetCategoryByIdArgs = {
  where: GetCategoryByIdInput;
};


export type QueryGetCategoryIdByKeyArgs = {
  where: GetCategoryIdByKeyInput;
};


export type QueryGetCollectionInfoArgs = {
  data: GetCollectionInfoInput;
};


export type QueryGetCollectionsArgs = {
  where: GetCollectionsInput;
};


export type QueryGetCollectionsByCreatorArgs = {
  data: GetCollectionsByCreatorInput;
};


export type QueryGetConversationSidArgs = {
  where: GetConversationSidInput;
};


export type QueryGetCreatorAssetsAndInfoArgs = {
  where: GetCreatorAssetsAndInfoInput;
};


export type QueryGetCreatorInfoArgs = {
  where: CreatorWhereUniqueInput;
};


export type QueryGetCreatorsArgs = {
  where: GetTopCreatorInput;
};


export type QueryGetDomainSceneArgs = {
  where: DomainSceneWhereUniqueInput;
};


export type QueryGetHideAndSeekArgs = {
  where: HideAndSeekWhereUniqueInput;
};


export type QueryGetHideAndSeekNpcArgs = {
  where: HideAndSeekNpcWhereUniqueInput;
};


export type QueryGetLightTemplateArgs = {
  where: LightTemplateWhereUniqueInput;
};


export type QueryGetLikesAssetsByUserArgs = {
  where: GetLikesAssetsByUserInput;
};


export type QueryGetMfesTemplateArgs = {
  where: MfesTemplateWhereUniqueInput;
};


export type QueryGetMfeSceneTemplateArgs = {
  where: MfeSceneTemplateWhereUniqueInput;
};


export type QueryGetOpenAiAnswerArgs = {
  where: GetOpenAiAnswerInput;
};


export type QueryGetOrdersByIdArgs = {
  where: GetOrdersByIdInput;
};


export type QueryGetOrdersByPayerIdArgs = {
  where: GetOrdersByPayerIdInput;
};


export type QueryGetPollyLectorArgs = {
  where: GetPollyLectorInput;
};


export type QueryGetProfileByUserIdArgs = {
  where: GetProfileByUserIdInput;
};


export type QueryGetQuestArgs = {
  where: QuestWhereUniqueInput;
};


export type QueryGetSceneAliasArgs = {
  where: GetSceneAliasInput;
};


export type QueryGetSceneIdByDomainArgs = {
  domain: Scalars['String']['input'];
};


export type QueryGetSceneTemplateArgs = {
  where: SceneTemplateWhereUniqueInput;
};


export type QueryGetShopifyStoreArgs = {
  where: GetShopifyStoreInput;
};


export type QueryGetShopifyStoreBySceneArgs = {
  where: GetShopifyStoreBySceneInput;
};


export type QueryGetSubSceneIdByDomainArgs = {
  where: GetSubSceneIdByDomainInput;
};


export type QueryGetSubScenesDomainArgs = {
  where: GetSubScenesDomainInput;
};


export type QueryGetSubscriptionPlanArgs = {
  where: SubscriptionPlanWhereUniqueInput;
};


export type QueryGetUserPublicArgs = {
  where: UsersWhereUniqueInput;
};


export type QueryGetUserSceneArgs = {
  where: UserSceneWhereUniqueInput;
};


export type QueryGetUserSubscriptionArgs = {
  userId: Scalars['String']['input'];
};


export type QueryGetUserWardrobeArgs = {
  where: GetUserWardrobeInput;
};


export type QueryGetWalletBalanceArgs = {
  data: GetWalletBalanceInput;
};


export type QueryGoogleLoginArgs = {
  email: Scalars['String']['input'];
};


export type QueryGroupByCameraConfigArgs = {
  by: Array<CameraConfigScalarFieldEnum>;
  having?: InputMaybe<CameraConfigScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CameraConfigOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CameraConfigWhereInput>;
};


export type QueryGroupByDomainSceneArgs = {
  by: Array<DomainSceneScalarFieldEnum>;
  having?: InputMaybe<DomainSceneScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<DomainSceneOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DomainSceneWhereInput>;
};


export type QueryGroupByGraphicsSettingsArgs = {
  by: Array<GraphicsSettingsScalarFieldEnum>;
  having?: InputMaybe<GraphicsSettingsScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<GraphicsSettingsOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GraphicsSettingsWhereInput>;
};


export type QueryGroupByHideAndSeekArgs = {
  by: Array<HideAndSeekScalarFieldEnum>;
  having?: InputMaybe<HideAndSeekScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<HideAndSeekOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HideAndSeekWhereInput>;
};


export type QueryGroupByHideAndSeekNpcArgs = {
  by: Array<HideAndSeekNpcScalarFieldEnum>;
  having?: InputMaybe<HideAndSeekNpcScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<HideAndSeekNpcOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HideAndSeekNpcWhereInput>;
};


export type QueryGroupByLightPropsArgs = {
  by: Array<LightPropsScalarFieldEnum>;
  having?: InputMaybe<LightPropsScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<LightPropsOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LightPropsWhereInput>;
};


export type QueryGroupByLightTemplateArgs = {
  by: Array<LightTemplateScalarFieldEnum>;
  having?: InputMaybe<LightTemplateScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<LightTemplateOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LightTemplateWhereInput>;
};


export type QueryGroupByMfesTemplateArgs = {
  by: Array<MfesTemplateScalarFieldEnum>;
  having?: InputMaybe<MfesTemplateScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<MfesTemplateOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MfesTemplateWhereInput>;
};


export type QueryGroupByMfeSceneTemplateArgs = {
  by: Array<MfeSceneTemplateScalarFieldEnum>;
  having?: InputMaybe<MfeSceneTemplateScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<MfeSceneTemplateOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MfeSceneTemplateWhereInput>;
};


export type QueryGroupByPostProcessingSettingsArgs = {
  by: Array<PostProcessingSettingsScalarFieldEnum>;
  having?: InputMaybe<PostProcessingSettingsScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<PostProcessingSettingsOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PostProcessingSettingsWhereInput>;
};


export type QueryGroupByQuestArgs = {
  by: Array<QuestScalarFieldEnum>;
  having?: InputMaybe<QuestScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<QuestOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<QuestWhereInput>;
};


export type QueryGroupBySceneTemplateArgs = {
  by: Array<SceneTemplateScalarFieldEnum>;
  having?: InputMaybe<SceneTemplateScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<SceneTemplateOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SceneTemplateWhereInput>;
};


export type QueryGroupByShopifyStoreArgs = {
  by: Array<ShopifyStoreScalarFieldEnum>;
  having?: InputMaybe<ShopifyStoreScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ShopifyStoreOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ShopifyStoreWhereInput>;
};


export type QueryGroupBySubScenesDomainArgs = {
  by: Array<SubScenesDomainScalarFieldEnum>;
  having?: InputMaybe<SubScenesDomainScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<SubScenesDomainOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubScenesDomainWhereInput>;
};


export type QueryGroupBySubscriptionPlanArgs = {
  by: Array<SubscriptionPlanScalarFieldEnum>;
  having?: InputMaybe<SubscriptionPlanScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<SubscriptionPlanOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubscriptionPlanWhereInput>;
};


export type QueryGroupByTemplatesArgs = {
  by: Array<TemplatesScalarFieldEnum>;
  having?: InputMaybe<TemplatesScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<TemplatesOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TemplatesWhereInput>;
};


export type QueryGroupByTourItemsArgs = {
  by: Array<TourItemsScalarFieldEnum>;
  having?: InputMaybe<TourItemsScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<TourItemsOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TourItemsWhereInput>;
};


export type QueryGroupByUserSceneArgs = {
  by: Array<UserSceneScalarFieldEnum>;
  having?: InputMaybe<UserSceneScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserSceneOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserSceneWhereInput>;
};


export type QueryGroupByUserSceneAssetsArgs = {
  by: Array<UserSceneAssetsScalarFieldEnum>;
  having?: InputMaybe<UserSceneAssetsScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserSceneAssetsOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserSceneAssetsWhereInput>;
};


export type QueryGroupByUserSubscriptionArgs = {
  by: Array<UserSubscriptionScalarFieldEnum>;
  having?: InputMaybe<UserSubscriptionScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserSubscriptionOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserSubscriptionWhereInput>;
};


export type QueryGroupByUsersQuestsArgs = {
  by: Array<UsersQuestsScalarFieldEnum>;
  having?: InputMaybe<UsersQuestsScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UsersQuestsOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UsersQuestsWhereInput>;
};


export type QueryHideAndSeekArgs = {
  where: HideAndSeekWhereUniqueInput;
};


export type QueryHideAndSeekNpcArgs = {
  where: HideAndSeekNpcWhereUniqueInput;
};


export type QueryHideAndSeekNpcsArgs = {
  cursor?: InputMaybe<HideAndSeekNpcWhereUniqueInput>;
  distinct?: InputMaybe<Array<HideAndSeekNpcScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<HideAndSeekNpcOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HideAndSeekNpcWhereInput>;
};


export type QueryHideAndSeeksArgs = {
  cursor?: InputMaybe<HideAndSeekWhereUniqueInput>;
  distinct?: InputMaybe<Array<HideAndSeekScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<HideAndSeekOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HideAndSeekWhereInput>;
};


export type QueryIsChatEnabledInSceneArgs = {
  where: IsChatEnabledInSceneInput;
};


export type QueryIsEmptyCollectionArgs = {
  where: IsEmptyCollectionInput;
};


export type QueryIsLikedAssetByUserArgs = {
  where: IsLikedAssetInput;
};


export type QueryIsScanEnabledInSceneArgs = {
  where: IsScanEnabledInSceneInput;
};


export type QueryLightTemplateArgs = {
  where: LightTemplateWhereUniqueInput;
};


export type QueryLightTemplatesArgs = {
  cursor?: InputMaybe<LightTemplateWhereUniqueInput>;
  distinct?: InputMaybe<Array<LightTemplateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LightTemplateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LightTemplateWhereInput>;
};


export type QueryLoginArgs = {
  data: LoginInput;
};


export type QueryMFesTemplateArgs = {
  where: MfesTemplateWhereUniqueInput;
};


export type QueryMFesTemplatesArgs = {
  cursor?: InputMaybe<MfesTemplateWhereUniqueInput>;
  distinct?: InputMaybe<Array<MfesTemplateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MfesTemplateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MfesTemplateWhereInput>;
};


export type QueryMFeSceneTemplateArgs = {
  where: MfeSceneTemplateWhereUniqueInput;
};


export type QueryMFeSceneTemplatesArgs = {
  cursor?: InputMaybe<MfeSceneTemplateWhereUniqueInput>;
  distinct?: InputMaybe<Array<MfeSceneTemplateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MfeSceneTemplateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MfeSceneTemplateWhereInput>;
};


export type QueryQuestArgs = {
  where: QuestWhereUniqueInput;
};


export type QueryQuestsArgs = {
  cursor?: InputMaybe<QuestWhereUniqueInput>;
  distinct?: InputMaybe<Array<QuestScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<QuestOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<QuestWhereInput>;
};


export type QuerySceneTemplateArgs = {
  where: SceneTemplateWhereUniqueInput;
};


export type QuerySceneTemplatesArgs = {
  cursor?: InputMaybe<SceneTemplateWhereUniqueInput>;
  distinct?: InputMaybe<Array<SceneTemplateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SceneTemplateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SceneTemplateWhereInput>;
};


export type QuerySearchAssetsArgs = {
  where: SearchAssetsInput;
};


export type QuerySearchByCategoriesArgs = {
  where: SearchByCategoriesInput;
};


export type QuerySearchCollectionsArgs = {
  where: SearchCollectionInput;
};


export type QuerySearchCreatorsArgs = {
  where: SearchCreatorInput;
};


export type QueryShopifyStoreArgs = {
  where: ShopifyStoreWhereUniqueInput;
};


export type QueryShopifyStoresArgs = {
  cursor?: InputMaybe<ShopifyStoreWhereUniqueInput>;
  distinct?: InputMaybe<Array<ShopifyStoreScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ShopifyStoreOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ShopifyStoreWhereInput>;
};


export type QuerySubScenesDomainArgs = {
  where: SubScenesDomainWhereUniqueInput;
};


export type QuerySubScenesDomainsArgs = {
  cursor?: InputMaybe<SubScenesDomainWhereUniqueInput>;
  distinct?: InputMaybe<Array<SubScenesDomainScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SubScenesDomainOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubScenesDomainWhereInput>;
};


export type QuerySubscriptionPlanArgs = {
  where: SubscriptionPlanWhereUniqueInput;
};


export type QuerySubscriptionPlansArgs = {
  cursor?: InputMaybe<SubscriptionPlanWhereUniqueInput>;
  distinct?: InputMaybe<Array<SubscriptionPlanScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SubscriptionPlanOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubscriptionPlanWhereInput>;
};


export type QueryUserSceneArgs = {
  where: UserSceneWhereUniqueInput;
};


export type QueryUserScenesArgs = {
  cursor?: InputMaybe<UserSceneWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserSceneScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserSceneOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserSceneWhereInput>;
};


export type QueryUserSubscriptionArgs = {
  where: UserSubscriptionWhereUniqueInput;
};


export type QueryUserSubscriptionsArgs = {
  cursor?: InputMaybe<UserSubscriptionWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserSubscriptionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserSubscriptionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserSubscriptionWhereInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type Quest = {
  __typename?: 'Quest';
  _count?: Maybe<QuestCount>;
  collectableCount: Scalars['Int']['output'];
  collectableItem: Array<Scalars['String']['output']>;
  enableDefaultModal: Scalars['Boolean']['output'];
  fakeCount: Scalars['Int']['output'];
  fakeItem: Array<Scalars['String']['output']>;
  finishPrompt: Scalars['String']['output'];
  hasReward: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  rewardAmount?: Maybe<Scalars['Float']['output']>;
  rewardType: RewardType;
  splashScreenMessage: Scalars['String']['output'];
  startPrompt: Scalars['String']['output'];
  unMarkedStartPrompt: Scalars['String']['output'];
};

export type QuestAvgAggregate = {
  __typename?: 'QuestAvgAggregate';
  collectableCount?: Maybe<Scalars['Float']['output']>;
  fakeCount?: Maybe<Scalars['Float']['output']>;
  rewardAmount?: Maybe<Scalars['Float']['output']>;
};

export type QuestAvgOrderByAggregateInput = {
  collectableCount?: InputMaybe<SortOrder>;
  fakeCount?: InputMaybe<SortOrder>;
  rewardAmount?: InputMaybe<SortOrder>;
};

export type QuestCount = {
  __typename?: 'QuestCount';
  userScene: Scalars['Int']['output'];
  usersQuest: Scalars['Int']['output'];
};


export type QuestCountUserSceneArgs = {
  where?: InputMaybe<UserSceneWhereInput>;
};


export type QuestCountUsersQuestArgs = {
  where?: InputMaybe<UsersQuestsWhereInput>;
};

export type QuestCountAggregate = {
  __typename?: 'QuestCountAggregate';
  _all: Scalars['Int']['output'];
  collectableCount: Scalars['Int']['output'];
  collectableItem: Scalars['Int']['output'];
  enableDefaultModal: Scalars['Int']['output'];
  fakeCount: Scalars['Int']['output'];
  fakeItem: Scalars['Int']['output'];
  finishPrompt: Scalars['Int']['output'];
  hasReward: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  rewardAmount: Scalars['Int']['output'];
  rewardType: Scalars['Int']['output'];
  splashScreenMessage: Scalars['Int']['output'];
  startPrompt: Scalars['Int']['output'];
  unMarkedStartPrompt: Scalars['Int']['output'];
};

export type QuestCountOrderByAggregateInput = {
  collectableCount?: InputMaybe<SortOrder>;
  collectableItem?: InputMaybe<SortOrder>;
  enableDefaultModal?: InputMaybe<SortOrder>;
  fakeCount?: InputMaybe<SortOrder>;
  fakeItem?: InputMaybe<SortOrder>;
  finishPrompt?: InputMaybe<SortOrder>;
  hasReward?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  rewardAmount?: InputMaybe<SortOrder>;
  rewardType?: InputMaybe<SortOrder>;
  splashScreenMessage?: InputMaybe<SortOrder>;
  startPrompt?: InputMaybe<SortOrder>;
  unMarkedStartPrompt?: InputMaybe<SortOrder>;
};

export type QuestCreateInput = {
  collectableCount?: InputMaybe<Scalars['Int']['input']>;
  collectableItem?: InputMaybe<QuestCreatecollectableItemInput>;
  enableDefaultModal?: InputMaybe<Scalars['Boolean']['input']>;
  fakeCount?: InputMaybe<Scalars['Int']['input']>;
  fakeItem?: InputMaybe<QuestCreatefakeItemInput>;
  finishPrompt?: InputMaybe<Scalars['String']['input']>;
  hasReward?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  rewardAmount?: InputMaybe<Scalars['Float']['input']>;
  rewardType?: InputMaybe<RewardType>;
  splashScreenMessage?: InputMaybe<Scalars['String']['input']>;
  startPrompt: Scalars['String']['input'];
  unMarkedStartPrompt?: InputMaybe<Scalars['String']['input']>;
  userScene?: InputMaybe<UserSceneCreateNestedManyWithoutSceneQuestInput>;
  usersQuest?: InputMaybe<UsersQuestsCreateNestedManyWithoutQuestInput>;
};

export type QuestCreateManyInput = {
  collectableCount?: InputMaybe<Scalars['Int']['input']>;
  collectableItem?: InputMaybe<QuestCreatecollectableItemInput>;
  enableDefaultModal?: InputMaybe<Scalars['Boolean']['input']>;
  fakeCount?: InputMaybe<Scalars['Int']['input']>;
  fakeItem?: InputMaybe<QuestCreatefakeItemInput>;
  finishPrompt?: InputMaybe<Scalars['String']['input']>;
  hasReward?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  rewardAmount?: InputMaybe<Scalars['Float']['input']>;
  rewardType?: InputMaybe<RewardType>;
  splashScreenMessage?: InputMaybe<Scalars['String']['input']>;
  startPrompt: Scalars['String']['input'];
  unMarkedStartPrompt?: InputMaybe<Scalars['String']['input']>;
};

export type QuestCreateNestedOneWithoutUserSceneInput = {
  connect?: InputMaybe<QuestWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuestCreateOrConnectWithoutUserSceneInput>;
  create?: InputMaybe<QuestCreateWithoutUserSceneInput>;
};

export type QuestCreateNestedOneWithoutUsersQuestInput = {
  connect?: InputMaybe<QuestWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuestCreateOrConnectWithoutUsersQuestInput>;
  create?: InputMaybe<QuestCreateWithoutUsersQuestInput>;
};

export type QuestCreateOrConnectWithoutUserSceneInput = {
  create: QuestCreateWithoutUserSceneInput;
  where: QuestWhereUniqueInput;
};

export type QuestCreateOrConnectWithoutUsersQuestInput = {
  create: QuestCreateWithoutUsersQuestInput;
  where: QuestWhereUniqueInput;
};

export type QuestCreateWithoutUserSceneInput = {
  collectableCount?: InputMaybe<Scalars['Int']['input']>;
  collectableItem?: InputMaybe<QuestCreatecollectableItemInput>;
  enableDefaultModal?: InputMaybe<Scalars['Boolean']['input']>;
  fakeCount?: InputMaybe<Scalars['Int']['input']>;
  fakeItem?: InputMaybe<QuestCreatefakeItemInput>;
  finishPrompt?: InputMaybe<Scalars['String']['input']>;
  hasReward?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  rewardAmount?: InputMaybe<Scalars['Float']['input']>;
  rewardType?: InputMaybe<RewardType>;
  splashScreenMessage?: InputMaybe<Scalars['String']['input']>;
  startPrompt: Scalars['String']['input'];
  unMarkedStartPrompt?: InputMaybe<Scalars['String']['input']>;
  usersQuest?: InputMaybe<UsersQuestsCreateNestedManyWithoutQuestInput>;
};

export type QuestCreateWithoutUsersQuestInput = {
  collectableCount?: InputMaybe<Scalars['Int']['input']>;
  collectableItem?: InputMaybe<QuestCreatecollectableItemInput>;
  enableDefaultModal?: InputMaybe<Scalars['Boolean']['input']>;
  fakeCount?: InputMaybe<Scalars['Int']['input']>;
  fakeItem?: InputMaybe<QuestCreatefakeItemInput>;
  finishPrompt?: InputMaybe<Scalars['String']['input']>;
  hasReward?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  rewardAmount?: InputMaybe<Scalars['Float']['input']>;
  rewardType?: InputMaybe<RewardType>;
  splashScreenMessage?: InputMaybe<Scalars['String']['input']>;
  startPrompt: Scalars['String']['input'];
  unMarkedStartPrompt?: InputMaybe<Scalars['String']['input']>;
  userScene?: InputMaybe<UserSceneCreateNestedManyWithoutSceneQuestInput>;
};

export type QuestCreatecollectableItemInput = {
  set: Array<Scalars['String']['input']>;
};

export type QuestCreatefakeItemInput = {
  set: Array<Scalars['String']['input']>;
};

export type QuestGroupBy = {
  __typename?: 'QuestGroupBy';
  _avg?: Maybe<QuestAvgAggregate>;
  _count?: Maybe<QuestCountAggregate>;
  _max?: Maybe<QuestMaxAggregate>;
  _min?: Maybe<QuestMinAggregate>;
  _sum?: Maybe<QuestSumAggregate>;
  collectableCount: Scalars['Int']['output'];
  collectableItem?: Maybe<Array<Scalars['String']['output']>>;
  enableDefaultModal: Scalars['Boolean']['output'];
  fakeCount: Scalars['Int']['output'];
  fakeItem?: Maybe<Array<Scalars['String']['output']>>;
  finishPrompt: Scalars['String']['output'];
  hasReward: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  rewardAmount?: Maybe<Scalars['Float']['output']>;
  rewardType: RewardType;
  splashScreenMessage: Scalars['String']['output'];
  startPrompt: Scalars['String']['output'];
  unMarkedStartPrompt: Scalars['String']['output'];
};

export type QuestMaxAggregate = {
  __typename?: 'QuestMaxAggregate';
  collectableCount?: Maybe<Scalars['Int']['output']>;
  enableDefaultModal?: Maybe<Scalars['Boolean']['output']>;
  fakeCount?: Maybe<Scalars['Int']['output']>;
  finishPrompt?: Maybe<Scalars['String']['output']>;
  hasReward?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  rewardAmount?: Maybe<Scalars['Float']['output']>;
  rewardType?: Maybe<RewardType>;
  splashScreenMessage?: Maybe<Scalars['String']['output']>;
  startPrompt?: Maybe<Scalars['String']['output']>;
  unMarkedStartPrompt?: Maybe<Scalars['String']['output']>;
};

export type QuestMaxOrderByAggregateInput = {
  collectableCount?: InputMaybe<SortOrder>;
  enableDefaultModal?: InputMaybe<SortOrder>;
  fakeCount?: InputMaybe<SortOrder>;
  finishPrompt?: InputMaybe<SortOrder>;
  hasReward?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  rewardAmount?: InputMaybe<SortOrder>;
  rewardType?: InputMaybe<SortOrder>;
  splashScreenMessage?: InputMaybe<SortOrder>;
  startPrompt?: InputMaybe<SortOrder>;
  unMarkedStartPrompt?: InputMaybe<SortOrder>;
};

export type QuestMinAggregate = {
  __typename?: 'QuestMinAggregate';
  collectableCount?: Maybe<Scalars['Int']['output']>;
  enableDefaultModal?: Maybe<Scalars['Boolean']['output']>;
  fakeCount?: Maybe<Scalars['Int']['output']>;
  finishPrompt?: Maybe<Scalars['String']['output']>;
  hasReward?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  rewardAmount?: Maybe<Scalars['Float']['output']>;
  rewardType?: Maybe<RewardType>;
  splashScreenMessage?: Maybe<Scalars['String']['output']>;
  startPrompt?: Maybe<Scalars['String']['output']>;
  unMarkedStartPrompt?: Maybe<Scalars['String']['output']>;
};

export type QuestMinOrderByAggregateInput = {
  collectableCount?: InputMaybe<SortOrder>;
  enableDefaultModal?: InputMaybe<SortOrder>;
  fakeCount?: InputMaybe<SortOrder>;
  finishPrompt?: InputMaybe<SortOrder>;
  hasReward?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  rewardAmount?: InputMaybe<SortOrder>;
  rewardType?: InputMaybe<SortOrder>;
  splashScreenMessage?: InputMaybe<SortOrder>;
  startPrompt?: InputMaybe<SortOrder>;
  unMarkedStartPrompt?: InputMaybe<SortOrder>;
};

export type QuestNullableRelationFilter = {
  is?: InputMaybe<QuestWhereInput>;
  isNot?: InputMaybe<QuestWhereInput>;
};

export type QuestOrderByWithAggregationInput = {
  _avg?: InputMaybe<QuestAvgOrderByAggregateInput>;
  _count?: InputMaybe<QuestCountOrderByAggregateInput>;
  _max?: InputMaybe<QuestMaxOrderByAggregateInput>;
  _min?: InputMaybe<QuestMinOrderByAggregateInput>;
  _sum?: InputMaybe<QuestSumOrderByAggregateInput>;
  collectableCount?: InputMaybe<SortOrder>;
  collectableItem?: InputMaybe<SortOrder>;
  enableDefaultModal?: InputMaybe<SortOrder>;
  fakeCount?: InputMaybe<SortOrder>;
  fakeItem?: InputMaybe<SortOrder>;
  finishPrompt?: InputMaybe<SortOrder>;
  hasReward?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  rewardAmount?: InputMaybe<SortOrderInput>;
  rewardType?: InputMaybe<SortOrder>;
  splashScreenMessage?: InputMaybe<SortOrder>;
  startPrompt?: InputMaybe<SortOrder>;
  unMarkedStartPrompt?: InputMaybe<SortOrder>;
};

export type QuestOrderByWithRelationInput = {
  collectableCount?: InputMaybe<SortOrder>;
  collectableItem?: InputMaybe<SortOrder>;
  enableDefaultModal?: InputMaybe<SortOrder>;
  fakeCount?: InputMaybe<SortOrder>;
  fakeItem?: InputMaybe<SortOrder>;
  finishPrompt?: InputMaybe<SortOrder>;
  hasReward?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  rewardAmount?: InputMaybe<SortOrderInput>;
  rewardType?: InputMaybe<SortOrder>;
  splashScreenMessage?: InputMaybe<SortOrder>;
  startPrompt?: InputMaybe<SortOrder>;
  unMarkedStartPrompt?: InputMaybe<SortOrder>;
  userScene?: InputMaybe<UserSceneOrderByRelationAggregateInput>;
  usersQuest?: InputMaybe<UsersQuestsOrderByRelationAggregateInput>;
};

export enum QuestScalarFieldEnum {
  CollectableCount = 'collectableCount',
  CollectableItem = 'collectableItem',
  EnableDefaultModal = 'enableDefaultModal',
  FakeCount = 'fakeCount',
  FakeItem = 'fakeItem',
  FinishPrompt = 'finishPrompt',
  HasReward = 'hasReward',
  Id = 'id',
  Name = 'name',
  RewardAmount = 'rewardAmount',
  RewardType = 'rewardType',
  SplashScreenMessage = 'splashScreenMessage',
  StartPrompt = 'startPrompt',
  UnMarkedStartPrompt = 'unMarkedStartPrompt'
}

export type QuestScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<QuestScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<QuestScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<QuestScalarWhereWithAggregatesInput>>;
  collectableCount?: InputMaybe<IntWithAggregatesFilter>;
  collectableItem?: InputMaybe<StringNullableListFilter>;
  enableDefaultModal?: InputMaybe<BoolWithAggregatesFilter>;
  fakeCount?: InputMaybe<IntWithAggregatesFilter>;
  fakeItem?: InputMaybe<StringNullableListFilter>;
  finishPrompt?: InputMaybe<StringWithAggregatesFilter>;
  hasReward?: InputMaybe<BoolWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  rewardAmount?: InputMaybe<FloatNullableWithAggregatesFilter>;
  rewardType?: InputMaybe<EnumRewardTypeWithAggregatesFilter>;
  splashScreenMessage?: InputMaybe<StringWithAggregatesFilter>;
  startPrompt?: InputMaybe<StringWithAggregatesFilter>;
  unMarkedStartPrompt?: InputMaybe<StringWithAggregatesFilter>;
};

export enum QuestState {
  Completed = 'COMPLETED',
  Initialized = 'INITIALIZED',
  Pending = 'PENDING'
}

export type QuestSumAggregate = {
  __typename?: 'QuestSumAggregate';
  collectableCount?: Maybe<Scalars['Int']['output']>;
  fakeCount?: Maybe<Scalars['Int']['output']>;
  rewardAmount?: Maybe<Scalars['Float']['output']>;
};

export type QuestSumOrderByAggregateInput = {
  collectableCount?: InputMaybe<SortOrder>;
  fakeCount?: InputMaybe<SortOrder>;
  rewardAmount?: InputMaybe<SortOrder>;
};

export type QuestUpdateInput = {
  collectableCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  collectableItem?: InputMaybe<QuestUpdatecollectableItemInput>;
  enableDefaultModal?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fakeCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  fakeItem?: InputMaybe<QuestUpdatefakeItemInput>;
  finishPrompt?: InputMaybe<StringFieldUpdateOperationsInput>;
  hasReward?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  rewardAmount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  rewardType?: InputMaybe<EnumRewardTypeFieldUpdateOperationsInput>;
  splashScreenMessage?: InputMaybe<StringFieldUpdateOperationsInput>;
  startPrompt?: InputMaybe<StringFieldUpdateOperationsInput>;
  unMarkedStartPrompt?: InputMaybe<StringFieldUpdateOperationsInput>;
  userScene?: InputMaybe<UserSceneUpdateManyWithoutSceneQuestNestedInput>;
  usersQuest?: InputMaybe<UsersQuestsUpdateManyWithoutQuestNestedInput>;
};

export type QuestUpdateManyMutationInput = {
  collectableCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  collectableItem?: InputMaybe<QuestUpdatecollectableItemInput>;
  enableDefaultModal?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fakeCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  fakeItem?: InputMaybe<QuestUpdatefakeItemInput>;
  finishPrompt?: InputMaybe<StringFieldUpdateOperationsInput>;
  hasReward?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  rewardAmount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  rewardType?: InputMaybe<EnumRewardTypeFieldUpdateOperationsInput>;
  splashScreenMessage?: InputMaybe<StringFieldUpdateOperationsInput>;
  startPrompt?: InputMaybe<StringFieldUpdateOperationsInput>;
  unMarkedStartPrompt?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type QuestUpdateOneWithoutUserSceneNestedInput = {
  connect?: InputMaybe<QuestWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuestCreateOrConnectWithoutUserSceneInput>;
  create?: InputMaybe<QuestCreateWithoutUserSceneInput>;
  delete?: InputMaybe<QuestWhereInput>;
  disconnect?: InputMaybe<QuestWhereInput>;
  update?: InputMaybe<QuestUpdateToOneWithWhereWithoutUserSceneInput>;
  upsert?: InputMaybe<QuestUpsertWithoutUserSceneInput>;
};

export type QuestUpdateOneWithoutUsersQuestNestedInput = {
  connect?: InputMaybe<QuestWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuestCreateOrConnectWithoutUsersQuestInput>;
  create?: InputMaybe<QuestCreateWithoutUsersQuestInput>;
  delete?: InputMaybe<QuestWhereInput>;
  disconnect?: InputMaybe<QuestWhereInput>;
  update?: InputMaybe<QuestUpdateToOneWithWhereWithoutUsersQuestInput>;
  upsert?: InputMaybe<QuestUpsertWithoutUsersQuestInput>;
};

export type QuestUpdateToOneWithWhereWithoutUserSceneInput = {
  data: QuestUpdateWithoutUserSceneInput;
  where?: InputMaybe<QuestWhereInput>;
};

export type QuestUpdateToOneWithWhereWithoutUsersQuestInput = {
  data: QuestUpdateWithoutUsersQuestInput;
  where?: InputMaybe<QuestWhereInput>;
};

export type QuestUpdateWithoutUserSceneInput = {
  collectableCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  collectableItem?: InputMaybe<QuestUpdatecollectableItemInput>;
  enableDefaultModal?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fakeCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  fakeItem?: InputMaybe<QuestUpdatefakeItemInput>;
  finishPrompt?: InputMaybe<StringFieldUpdateOperationsInput>;
  hasReward?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  rewardAmount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  rewardType?: InputMaybe<EnumRewardTypeFieldUpdateOperationsInput>;
  splashScreenMessage?: InputMaybe<StringFieldUpdateOperationsInput>;
  startPrompt?: InputMaybe<StringFieldUpdateOperationsInput>;
  unMarkedStartPrompt?: InputMaybe<StringFieldUpdateOperationsInput>;
  usersQuest?: InputMaybe<UsersQuestsUpdateManyWithoutQuestNestedInput>;
};

export type QuestUpdateWithoutUsersQuestInput = {
  collectableCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  collectableItem?: InputMaybe<QuestUpdatecollectableItemInput>;
  enableDefaultModal?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fakeCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  fakeItem?: InputMaybe<QuestUpdatefakeItemInput>;
  finishPrompt?: InputMaybe<StringFieldUpdateOperationsInput>;
  hasReward?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  rewardAmount?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  rewardType?: InputMaybe<EnumRewardTypeFieldUpdateOperationsInput>;
  splashScreenMessage?: InputMaybe<StringFieldUpdateOperationsInput>;
  startPrompt?: InputMaybe<StringFieldUpdateOperationsInput>;
  unMarkedStartPrompt?: InputMaybe<StringFieldUpdateOperationsInput>;
  userScene?: InputMaybe<UserSceneUpdateManyWithoutSceneQuestNestedInput>;
};

export type QuestUpdatecollectableItemInput = {
  push?: InputMaybe<Array<Scalars['String']['input']>>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QuestUpdatefakeItemInput = {
  push?: InputMaybe<Array<Scalars['String']['input']>>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QuestUpsertWithoutUserSceneInput = {
  create: QuestCreateWithoutUserSceneInput;
  update: QuestUpdateWithoutUserSceneInput;
  where?: InputMaybe<QuestWhereInput>;
};

export type QuestUpsertWithoutUsersQuestInput = {
  create: QuestCreateWithoutUsersQuestInput;
  update: QuestUpdateWithoutUsersQuestInput;
  where?: InputMaybe<QuestWhereInput>;
};

export type QuestWhereInput = {
  AND?: InputMaybe<Array<QuestWhereInput>>;
  NOT?: InputMaybe<Array<QuestWhereInput>>;
  OR?: InputMaybe<Array<QuestWhereInput>>;
  collectableCount?: InputMaybe<IntFilter>;
  collectableItem?: InputMaybe<StringNullableListFilter>;
  enableDefaultModal?: InputMaybe<BoolFilter>;
  fakeCount?: InputMaybe<IntFilter>;
  fakeItem?: InputMaybe<StringNullableListFilter>;
  finishPrompt?: InputMaybe<StringFilter>;
  hasReward?: InputMaybe<BoolFilter>;
  id?: InputMaybe<UuidFilter>;
  name?: InputMaybe<StringFilter>;
  rewardAmount?: InputMaybe<FloatNullableFilter>;
  rewardType?: InputMaybe<EnumRewardTypeFilter>;
  splashScreenMessage?: InputMaybe<StringFilter>;
  startPrompt?: InputMaybe<StringFilter>;
  unMarkedStartPrompt?: InputMaybe<StringFilter>;
  userScene?: InputMaybe<UserSceneListRelationFilter>;
  usersQuest?: InputMaybe<UsersQuestsListRelationFilter>;
};

export type QuestWhereUniqueInput = {
  AND?: InputMaybe<Array<QuestWhereInput>>;
  NOT?: InputMaybe<Array<QuestWhereInput>>;
  OR?: InputMaybe<Array<QuestWhereInput>>;
  collectableCount?: InputMaybe<IntFilter>;
  collectableItem?: InputMaybe<StringNullableListFilter>;
  enableDefaultModal?: InputMaybe<BoolFilter>;
  fakeCount?: InputMaybe<IntFilter>;
  fakeItem?: InputMaybe<StringNullableListFilter>;
  finishPrompt?: InputMaybe<StringFilter>;
  hasReward?: InputMaybe<BoolFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<StringFilter>;
  rewardAmount?: InputMaybe<FloatNullableFilter>;
  rewardType?: InputMaybe<EnumRewardTypeFilter>;
  splashScreenMessage?: InputMaybe<StringFilter>;
  startPrompt?: InputMaybe<StringFilter>;
  unMarkedStartPrompt?: InputMaybe<StringFilter>;
  userScene?: InputMaybe<UserSceneListRelationFilter>;
  usersQuest?: InputMaybe<UsersQuestsListRelationFilter>;
};

export type ReduceCountCopiesInput = {
  assetId: Scalars['Float']['input'];
};

export type ReduceCountCopiesResponse = {
  __typename?: 'ReduceCountCopiesResponse';
  status: Scalars['String']['output'];
};

export type RemoveFromCartInput = {
  productId?: InputMaybe<Scalars['Float']['input']>;
  productIdWeb2?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  userEmail: Scalars['String']['input'];
};

export type RemoveFromCartResponse = {
  __typename?: 'RemoveFromCartResponse';
  message: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
};

export enum RewardStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Received = 'RECEIVED'
}

export enum RewardType {
  Nft = 'NFT',
  None = 'NONE',
  Token = 'TOKEN'
}

export type SceneTemplate = {
  __typename?: 'SceneTemplate';
  baseModelURL: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  subscriptionPlanId: Scalars['String']['output'];
  thumbnail?: Maybe<Scalars['String']['output']>;
};

export type SceneTemplateCountAggregate = {
  __typename?: 'SceneTemplateCountAggregate';
  _all: Scalars['Int']['output'];
  baseModelURL: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  subscriptionPlanId: Scalars['Int']['output'];
  thumbnail: Scalars['Int']['output'];
};

export type SceneTemplateCountOrderByAggregateInput = {
  baseModelURL?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  subscriptionPlanId?: InputMaybe<SortOrder>;
  thumbnail?: InputMaybe<SortOrder>;
};

export type SceneTemplateCreateInput = {
  baseModelURL: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  subscription: SubscriptionPlanCreateNestedOneWithoutSceneTemplateInput;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
};

export type SceneTemplateCreateManyInput = {
  baseModelURL: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  subscriptionPlanId: Scalars['String']['input'];
  thumbnail?: InputMaybe<Scalars['String']['input']>;
};

export type SceneTemplateCreateManySubscriptionInput = {
  baseModelURL: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  thumbnail?: InputMaybe<Scalars['String']['input']>;
};

export type SceneTemplateCreateManySubscriptionInputEnvelope = {
  data: Array<SceneTemplateCreateManySubscriptionInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SceneTemplateCreateNestedManyWithoutSubscriptionInput = {
  connect?: InputMaybe<Array<SceneTemplateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SceneTemplateCreateOrConnectWithoutSubscriptionInput>>;
  create?: InputMaybe<Array<SceneTemplateCreateWithoutSubscriptionInput>>;
  createMany?: InputMaybe<SceneTemplateCreateManySubscriptionInputEnvelope>;
};

export type SceneTemplateCreateOrConnectWithoutSubscriptionInput = {
  create: SceneTemplateCreateWithoutSubscriptionInput;
  where: SceneTemplateWhereUniqueInput;
};

export type SceneTemplateCreateWithoutSubscriptionInput = {
  baseModelURL: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  thumbnail?: InputMaybe<Scalars['String']['input']>;
};

export type SceneTemplateGroupBy = {
  __typename?: 'SceneTemplateGroupBy';
  _count?: Maybe<SceneTemplateCountAggregate>;
  _max?: Maybe<SceneTemplateMaxAggregate>;
  _min?: Maybe<SceneTemplateMinAggregate>;
  baseModelURL: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  subscriptionPlanId: Scalars['String']['output'];
  thumbnail?: Maybe<Scalars['String']['output']>;
};

export type SceneTemplateListRelationFilter = {
  every?: InputMaybe<SceneTemplateWhereInput>;
  none?: InputMaybe<SceneTemplateWhereInput>;
  some?: InputMaybe<SceneTemplateWhereInput>;
};

export type SceneTemplateMaxAggregate = {
  __typename?: 'SceneTemplateMaxAggregate';
  baseModelURL?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  subscriptionPlanId?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
};

export type SceneTemplateMaxOrderByAggregateInput = {
  baseModelURL?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  subscriptionPlanId?: InputMaybe<SortOrder>;
  thumbnail?: InputMaybe<SortOrder>;
};

export type SceneTemplateMinAggregate = {
  __typename?: 'SceneTemplateMinAggregate';
  baseModelURL?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  subscriptionPlanId?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
};

export type SceneTemplateMinOrderByAggregateInput = {
  baseModelURL?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  subscriptionPlanId?: InputMaybe<SortOrder>;
  thumbnail?: InputMaybe<SortOrder>;
};

export type SceneTemplateOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type SceneTemplateOrderByWithAggregationInput = {
  _count?: InputMaybe<SceneTemplateCountOrderByAggregateInput>;
  _max?: InputMaybe<SceneTemplateMaxOrderByAggregateInput>;
  _min?: InputMaybe<SceneTemplateMinOrderByAggregateInput>;
  baseModelURL?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  subscriptionPlanId?: InputMaybe<SortOrder>;
  thumbnail?: InputMaybe<SortOrderInput>;
};

export type SceneTemplateOrderByWithRelationInput = {
  baseModelURL?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  subscription?: InputMaybe<SubscriptionPlanOrderByWithRelationInput>;
  subscriptionPlanId?: InputMaybe<SortOrder>;
  thumbnail?: InputMaybe<SortOrderInput>;
};

export enum SceneTemplateScalarFieldEnum {
  BaseModelUrl = 'baseModelURL',
  Id = 'id',
  Name = 'name',
  SubscriptionPlanId = 'subscriptionPlanId',
  Thumbnail = 'thumbnail'
}

export type SceneTemplateScalarWhereInput = {
  AND?: InputMaybe<Array<SceneTemplateScalarWhereInput>>;
  NOT?: InputMaybe<Array<SceneTemplateScalarWhereInput>>;
  OR?: InputMaybe<Array<SceneTemplateScalarWhereInput>>;
  baseModelURL?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  name?: InputMaybe<StringFilter>;
  subscriptionPlanId?: InputMaybe<UuidFilter>;
  thumbnail?: InputMaybe<StringNullableFilter>;
};

export type SceneTemplateScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<SceneTemplateScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<SceneTemplateScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<SceneTemplateScalarWhereWithAggregatesInput>>;
  baseModelURL?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  subscriptionPlanId?: InputMaybe<UuidWithAggregatesFilter>;
  thumbnail?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type SceneTemplateUpdateInput = {
  baseModelURL?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  subscription?: InputMaybe<SubscriptionPlanUpdateOneRequiredWithoutSceneTemplateNestedInput>;
  thumbnail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SceneTemplateUpdateManyMutationInput = {
  baseModelURL?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  thumbnail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SceneTemplateUpdateManyWithWhereWithoutSubscriptionInput = {
  data: SceneTemplateUpdateManyMutationInput;
  where: SceneTemplateScalarWhereInput;
};

export type SceneTemplateUpdateManyWithoutSubscriptionNestedInput = {
  connect?: InputMaybe<Array<SceneTemplateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SceneTemplateCreateOrConnectWithoutSubscriptionInput>>;
  create?: InputMaybe<Array<SceneTemplateCreateWithoutSubscriptionInput>>;
  createMany?: InputMaybe<SceneTemplateCreateManySubscriptionInputEnvelope>;
  delete?: InputMaybe<Array<SceneTemplateWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SceneTemplateScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SceneTemplateWhereUniqueInput>>;
  set?: InputMaybe<Array<SceneTemplateWhereUniqueInput>>;
  update?: InputMaybe<Array<SceneTemplateUpdateWithWhereUniqueWithoutSubscriptionInput>>;
  updateMany?: InputMaybe<Array<SceneTemplateUpdateManyWithWhereWithoutSubscriptionInput>>;
  upsert?: InputMaybe<Array<SceneTemplateUpsertWithWhereUniqueWithoutSubscriptionInput>>;
};

export type SceneTemplateUpdateWithWhereUniqueWithoutSubscriptionInput = {
  data: SceneTemplateUpdateWithoutSubscriptionInput;
  where: SceneTemplateWhereUniqueInput;
};

export type SceneTemplateUpdateWithoutSubscriptionInput = {
  baseModelURL?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  thumbnail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SceneTemplateUpsertWithWhereUniqueWithoutSubscriptionInput = {
  create: SceneTemplateCreateWithoutSubscriptionInput;
  update: SceneTemplateUpdateWithoutSubscriptionInput;
  where: SceneTemplateWhereUniqueInput;
};

export type SceneTemplateWhereInput = {
  AND?: InputMaybe<Array<SceneTemplateWhereInput>>;
  NOT?: InputMaybe<Array<SceneTemplateWhereInput>>;
  OR?: InputMaybe<Array<SceneTemplateWhereInput>>;
  baseModelURL?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  name?: InputMaybe<StringFilter>;
  subscription?: InputMaybe<SubscriptionPlanRelationFilter>;
  subscriptionPlanId?: InputMaybe<UuidFilter>;
  thumbnail?: InputMaybe<StringNullableFilter>;
};

export type SceneTemplateWhereUniqueInput = {
  AND?: InputMaybe<Array<SceneTemplateWhereInput>>;
  NOT?: InputMaybe<Array<SceneTemplateWhereInput>>;
  OR?: InputMaybe<Array<SceneTemplateWhereInput>>;
  baseModelURL?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<StringFilter>;
  subscription?: InputMaybe<SubscriptionPlanRelationFilter>;
  subscriptionPlanId?: InputMaybe<UuidFilter>;
  thumbnail?: InputMaybe<StringNullableFilter>;
};

export type SearchAssetsInput = {
  name: Scalars['String']['input'];
};

export type SearchByCategoriesInput = {
  name: Scalars['String']['input'];
};

export type SearchByCategoriesResponse = {
  __typename?: 'SearchByCategoriesResponse';
  category?: Maybe<CategoriesSearch>;
};

export type SearchCollectionInput = {
  name: Scalars['String']['input'];
};

export type SearchCreatorInput = {
  alias: Scalars['String']['input'];
};

export type SetConversationSidInput = {
  id: Scalars['String']['input'];
  sid: Scalars['String']['input'];
};

export type SetConversationSidResponse = {
  __typename?: 'SetConversationSidResponse';
  status: Scalars['Boolean']['output'];
};

export type SetIsCameraThirdPersonSceneInput = {
  enabled: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
};

export type SetIsCameraThirdPersonSceneResponse = {
  __typename?: 'SetIsCameraThirdPersonSceneResponse';
  status: Scalars['Boolean']['output'];
};

export type SetIsChatEnabledSceneInput = {
  enabled: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
};

export type SetIsChatEnabledSceneResponse = {
  __typename?: 'SetIsChatEnabledSceneResponse';
  status: Scalars['Boolean']['output'];
};

export type SetIsScanEnabledSceneInput = {
  enabled: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
};

export type SetIsScanEnabledSceneResponse = {
  __typename?: 'SetIsScanEnabledSceneResponse';
  status: Scalars['Boolean']['output'];
};

export type ShopifyStore = {
  __typename?: 'ShopifyStore';
  apiVersion: Scalars['String']['output'];
  domain: Scalars['String']['output'];
  id: Scalars['String']['output'];
  token: Scalars['String']['output'];
  userSceneId: Scalars['String']['output'];
};

export type ShopifyStoreCountAggregate = {
  __typename?: 'ShopifyStoreCountAggregate';
  _all: Scalars['Int']['output'];
  apiVersion: Scalars['Int']['output'];
  domain: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  token: Scalars['Int']['output'];
  userSceneId: Scalars['Int']['output'];
};

export type ShopifyStoreCountOrderByAggregateInput = {
  apiVersion?: InputMaybe<SortOrder>;
  domain?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
  userSceneId?: InputMaybe<SortOrder>;
};

export type ShopifyStoreCreateInput = {
  apiVersion: Scalars['String']['input'];
  domain: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
  userScene: UserSceneCreateNestedOneWithoutShopifyStoreInput;
};

export type ShopifyStoreCreateManyInput = {
  apiVersion: Scalars['String']['input'];
  domain: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
  userSceneId: Scalars['String']['input'];
};

export type ShopifyStoreCreateManyUserSceneInput = {
  apiVersion: Scalars['String']['input'];
  domain: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

export type ShopifyStoreCreateManyUserSceneInputEnvelope = {
  data: Array<ShopifyStoreCreateManyUserSceneInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ShopifyStoreCreateNestedManyWithoutUserSceneInput = {
  connect?: InputMaybe<Array<ShopifyStoreWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ShopifyStoreCreateOrConnectWithoutUserSceneInput>>;
  create?: InputMaybe<Array<ShopifyStoreCreateWithoutUserSceneInput>>;
  createMany?: InputMaybe<ShopifyStoreCreateManyUserSceneInputEnvelope>;
};

export type ShopifyStoreCreateOrConnectWithoutUserSceneInput = {
  create: ShopifyStoreCreateWithoutUserSceneInput;
  where: ShopifyStoreWhereUniqueInput;
};

export type ShopifyStoreCreateWithoutUserSceneInput = {
  apiVersion: Scalars['String']['input'];
  domain: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

export type ShopifyStoreGroupBy = {
  __typename?: 'ShopifyStoreGroupBy';
  _count?: Maybe<ShopifyStoreCountAggregate>;
  _max?: Maybe<ShopifyStoreMaxAggregate>;
  _min?: Maybe<ShopifyStoreMinAggregate>;
  apiVersion: Scalars['String']['output'];
  domain: Scalars['String']['output'];
  id: Scalars['String']['output'];
  token: Scalars['String']['output'];
  userSceneId: Scalars['String']['output'];
};

export type ShopifyStoreListRelationFilter = {
  every?: InputMaybe<ShopifyStoreWhereInput>;
  none?: InputMaybe<ShopifyStoreWhereInput>;
  some?: InputMaybe<ShopifyStoreWhereInput>;
};

export type ShopifyStoreMaxAggregate = {
  __typename?: 'ShopifyStoreMaxAggregate';
  apiVersion?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  userSceneId?: Maybe<Scalars['String']['output']>;
};

export type ShopifyStoreMaxOrderByAggregateInput = {
  apiVersion?: InputMaybe<SortOrder>;
  domain?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
  userSceneId?: InputMaybe<SortOrder>;
};

export type ShopifyStoreMinAggregate = {
  __typename?: 'ShopifyStoreMinAggregate';
  apiVersion?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  userSceneId?: Maybe<Scalars['String']['output']>;
};

export type ShopifyStoreMinOrderByAggregateInput = {
  apiVersion?: InputMaybe<SortOrder>;
  domain?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
  userSceneId?: InputMaybe<SortOrder>;
};

export type ShopifyStoreOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ShopifyStoreOrderByWithAggregationInput = {
  _count?: InputMaybe<ShopifyStoreCountOrderByAggregateInput>;
  _max?: InputMaybe<ShopifyStoreMaxOrderByAggregateInput>;
  _min?: InputMaybe<ShopifyStoreMinOrderByAggregateInput>;
  apiVersion?: InputMaybe<SortOrder>;
  domain?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
  userSceneId?: InputMaybe<SortOrder>;
};

export type ShopifyStoreOrderByWithRelationInput = {
  apiVersion?: InputMaybe<SortOrder>;
  domain?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
  userScene?: InputMaybe<UserSceneOrderByWithRelationInput>;
  userSceneId?: InputMaybe<SortOrder>;
};

export enum ShopifyStoreScalarFieldEnum {
  ApiVersion = 'apiVersion',
  Domain = 'domain',
  Id = 'id',
  Token = 'token',
  UserSceneId = 'userSceneId'
}

export type ShopifyStoreScalarWhereInput = {
  AND?: InputMaybe<Array<ShopifyStoreScalarWhereInput>>;
  NOT?: InputMaybe<Array<ShopifyStoreScalarWhereInput>>;
  OR?: InputMaybe<Array<ShopifyStoreScalarWhereInput>>;
  apiVersion?: InputMaybe<StringFilter>;
  domain?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  token?: InputMaybe<StringFilter>;
  userSceneId?: InputMaybe<UuidFilter>;
};

export type ShopifyStoreScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ShopifyStoreScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ShopifyStoreScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ShopifyStoreScalarWhereWithAggregatesInput>>;
  apiVersion?: InputMaybe<StringWithAggregatesFilter>;
  domain?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  token?: InputMaybe<StringWithAggregatesFilter>;
  userSceneId?: InputMaybe<UuidWithAggregatesFilter>;
};

export type ShopifyStoreUpdateInput = {
  apiVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  token?: InputMaybe<StringFieldUpdateOperationsInput>;
  userScene?: InputMaybe<UserSceneUpdateOneRequiredWithoutShopifyStoreNestedInput>;
};

export type ShopifyStoreUpdateManyMutationInput = {
  apiVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  token?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ShopifyStoreUpdateManyWithWhereWithoutUserSceneInput = {
  data: ShopifyStoreUpdateManyMutationInput;
  where: ShopifyStoreScalarWhereInput;
};

export type ShopifyStoreUpdateManyWithoutUserSceneNestedInput = {
  connect?: InputMaybe<Array<ShopifyStoreWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ShopifyStoreCreateOrConnectWithoutUserSceneInput>>;
  create?: InputMaybe<Array<ShopifyStoreCreateWithoutUserSceneInput>>;
  createMany?: InputMaybe<ShopifyStoreCreateManyUserSceneInputEnvelope>;
  delete?: InputMaybe<Array<ShopifyStoreWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ShopifyStoreScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ShopifyStoreWhereUniqueInput>>;
  set?: InputMaybe<Array<ShopifyStoreWhereUniqueInput>>;
  update?: InputMaybe<Array<ShopifyStoreUpdateWithWhereUniqueWithoutUserSceneInput>>;
  updateMany?: InputMaybe<Array<ShopifyStoreUpdateManyWithWhereWithoutUserSceneInput>>;
  upsert?: InputMaybe<Array<ShopifyStoreUpsertWithWhereUniqueWithoutUserSceneInput>>;
};

export type ShopifyStoreUpdateWithWhereUniqueWithoutUserSceneInput = {
  data: ShopifyStoreUpdateWithoutUserSceneInput;
  where: ShopifyStoreWhereUniqueInput;
};

export type ShopifyStoreUpdateWithoutUserSceneInput = {
  apiVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  token?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ShopifyStoreUpsertWithWhereUniqueWithoutUserSceneInput = {
  create: ShopifyStoreCreateWithoutUserSceneInput;
  update: ShopifyStoreUpdateWithoutUserSceneInput;
  where: ShopifyStoreWhereUniqueInput;
};

export type ShopifyStoreWhereInput = {
  AND?: InputMaybe<Array<ShopifyStoreWhereInput>>;
  NOT?: InputMaybe<Array<ShopifyStoreWhereInput>>;
  OR?: InputMaybe<Array<ShopifyStoreWhereInput>>;
  apiVersion?: InputMaybe<StringFilter>;
  domain?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  token?: InputMaybe<StringFilter>;
  userScene?: InputMaybe<UserSceneRelationFilter>;
  userSceneId?: InputMaybe<UuidFilter>;
};

export type ShopifyStoreWhereUniqueInput = {
  AND?: InputMaybe<Array<ShopifyStoreWhereInput>>;
  NOT?: InputMaybe<Array<ShopifyStoreWhereInput>>;
  OR?: InputMaybe<Array<ShopifyStoreWhereInput>>;
  apiVersion?: InputMaybe<StringFilter>;
  domain?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<StringFilter>;
  userScene?: InputMaybe<UserSceneRelationFilter>;
  userSceneId?: InputMaybe<UuidFilter>;
};

export type SignUp = {
  __typename?: 'SignUp';
  isSignedUp: Scalars['Boolean']['output'];
};

export type SignUpInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  redirectUrl: Scalars['String']['input'];
  referral: Scalars['String']['input'];
  registrationOrigin: Scalars['String']['input'];
};

export type SkinsWardrobe = {
  __typename?: 'SkinsWardrobe';
  id: Scalars['String']['output'];
  skins?: Maybe<Scalars['JSON']['output']>;
  userId: Scalars['String']['output'];
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type SortOrderInput = {
  nulls?: InputMaybe<NullsOrder>;
  sort: SortOrder;
};

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['String']['input']>>;
  has?: InputMaybe<Scalars['String']['input']>;
  hasEvery?: InputMaybe<Array<Scalars['String']['input']>>;
  hasSome?: InputMaybe<Array<Scalars['String']['input']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type SubScenesDomain = {
  __typename?: 'SubScenesDomain';
  domain: Scalars['String']['output'];
  id: Scalars['String']['output'];
  route: Scalars['String']['output'];
  sceneId: Scalars['String']['output'];
};

export type SubScenesDomainCountAggregate = {
  __typename?: 'SubScenesDomainCountAggregate';
  _all: Scalars['Int']['output'];
  domain: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  route: Scalars['Int']['output'];
  sceneId: Scalars['Int']['output'];
};

export type SubScenesDomainCountOrderByAggregateInput = {
  domain?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  route?: InputMaybe<SortOrder>;
  sceneId?: InputMaybe<SortOrder>;
};

export type SubScenesDomainCreateInput = {
  domainRec?: InputMaybe<DomainSceneCreateNestedOneWithoutSubScenesInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  route: Scalars['String']['input'];
  sceneId: Scalars['String']['input'];
};

export type SubScenesDomainCreateManyDomainRecInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  route: Scalars['String']['input'];
  sceneId: Scalars['String']['input'];
};

export type SubScenesDomainCreateManyDomainRecInputEnvelope = {
  data: Array<SubScenesDomainCreateManyDomainRecInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SubScenesDomainCreateManyInput = {
  domain: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  route: Scalars['String']['input'];
  sceneId: Scalars['String']['input'];
};

export type SubScenesDomainCreateNestedManyWithoutDomainRecInput = {
  connect?: InputMaybe<Array<SubScenesDomainWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SubScenesDomainCreateOrConnectWithoutDomainRecInput>>;
  create?: InputMaybe<Array<SubScenesDomainCreateWithoutDomainRecInput>>;
  createMany?: InputMaybe<SubScenesDomainCreateManyDomainRecInputEnvelope>;
};

export type SubScenesDomainCreateOrConnectWithoutDomainRecInput = {
  create: SubScenesDomainCreateWithoutDomainRecInput;
  where: SubScenesDomainWhereUniqueInput;
};

export type SubScenesDomainCreateWithoutDomainRecInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  route: Scalars['String']['input'];
  sceneId: Scalars['String']['input'];
};

export type SubScenesDomainGroupBy = {
  __typename?: 'SubScenesDomainGroupBy';
  _count?: Maybe<SubScenesDomainCountAggregate>;
  _max?: Maybe<SubScenesDomainMaxAggregate>;
  _min?: Maybe<SubScenesDomainMinAggregate>;
  domain: Scalars['String']['output'];
  id: Scalars['String']['output'];
  route: Scalars['String']['output'];
  sceneId: Scalars['String']['output'];
};

export type SubScenesDomainListRelationFilter = {
  every?: InputMaybe<SubScenesDomainWhereInput>;
  none?: InputMaybe<SubScenesDomainWhereInput>;
  some?: InputMaybe<SubScenesDomainWhereInput>;
};

export type SubScenesDomainMaxAggregate = {
  __typename?: 'SubScenesDomainMaxAggregate';
  domain?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  route?: Maybe<Scalars['String']['output']>;
  sceneId?: Maybe<Scalars['String']['output']>;
};

export type SubScenesDomainMaxOrderByAggregateInput = {
  domain?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  route?: InputMaybe<SortOrder>;
  sceneId?: InputMaybe<SortOrder>;
};

export type SubScenesDomainMinAggregate = {
  __typename?: 'SubScenesDomainMinAggregate';
  domain?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  route?: Maybe<Scalars['String']['output']>;
  sceneId?: Maybe<Scalars['String']['output']>;
};

export type SubScenesDomainMinOrderByAggregateInput = {
  domain?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  route?: InputMaybe<SortOrder>;
  sceneId?: InputMaybe<SortOrder>;
};

export type SubScenesDomainOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type SubScenesDomainOrderByWithAggregationInput = {
  _count?: InputMaybe<SubScenesDomainCountOrderByAggregateInput>;
  _max?: InputMaybe<SubScenesDomainMaxOrderByAggregateInput>;
  _min?: InputMaybe<SubScenesDomainMinOrderByAggregateInput>;
  domain?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  route?: InputMaybe<SortOrder>;
  sceneId?: InputMaybe<SortOrder>;
};

export type SubScenesDomainOrderByWithRelationInput = {
  domain?: InputMaybe<SortOrder>;
  domainRec?: InputMaybe<DomainSceneOrderByWithRelationInput>;
  id?: InputMaybe<SortOrder>;
  route?: InputMaybe<SortOrder>;
  sceneId?: InputMaybe<SortOrder>;
};

export enum SubScenesDomainScalarFieldEnum {
  Domain = 'domain',
  Id = 'id',
  Route = 'route',
  SceneId = 'sceneId'
}

export type SubScenesDomainScalarWhereInput = {
  AND?: InputMaybe<Array<SubScenesDomainScalarWhereInput>>;
  NOT?: InputMaybe<Array<SubScenesDomainScalarWhereInput>>;
  OR?: InputMaybe<Array<SubScenesDomainScalarWhereInput>>;
  domain?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  route?: InputMaybe<StringFilter>;
  sceneId?: InputMaybe<UuidFilter>;
};

export type SubScenesDomainScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<SubScenesDomainScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<SubScenesDomainScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<SubScenesDomainScalarWhereWithAggregatesInput>>;
  domain?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  route?: InputMaybe<StringWithAggregatesFilter>;
  sceneId?: InputMaybe<UuidWithAggregatesFilter>;
};

export type SubScenesDomainUpdateInput = {
  domainRec?: InputMaybe<DomainSceneUpdateOneWithoutSubScenesNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  route?: InputMaybe<StringFieldUpdateOperationsInput>;
  sceneId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type SubScenesDomainUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  route?: InputMaybe<StringFieldUpdateOperationsInput>;
  sceneId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type SubScenesDomainUpdateManyWithWhereWithoutDomainRecInput = {
  data: SubScenesDomainUpdateManyMutationInput;
  where: SubScenesDomainScalarWhereInput;
};

export type SubScenesDomainUpdateManyWithoutDomainRecNestedInput = {
  connect?: InputMaybe<Array<SubScenesDomainWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SubScenesDomainCreateOrConnectWithoutDomainRecInput>>;
  create?: InputMaybe<Array<SubScenesDomainCreateWithoutDomainRecInput>>;
  createMany?: InputMaybe<SubScenesDomainCreateManyDomainRecInputEnvelope>;
  delete?: InputMaybe<Array<SubScenesDomainWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SubScenesDomainScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SubScenesDomainWhereUniqueInput>>;
  set?: InputMaybe<Array<SubScenesDomainWhereUniqueInput>>;
  update?: InputMaybe<Array<SubScenesDomainUpdateWithWhereUniqueWithoutDomainRecInput>>;
  updateMany?: InputMaybe<Array<SubScenesDomainUpdateManyWithWhereWithoutDomainRecInput>>;
  upsert?: InputMaybe<Array<SubScenesDomainUpsertWithWhereUniqueWithoutDomainRecInput>>;
};

export type SubScenesDomainUpdateWithWhereUniqueWithoutDomainRecInput = {
  data: SubScenesDomainUpdateWithoutDomainRecInput;
  where: SubScenesDomainWhereUniqueInput;
};

export type SubScenesDomainUpdateWithoutDomainRecInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  route?: InputMaybe<StringFieldUpdateOperationsInput>;
  sceneId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type SubScenesDomainUpsertWithWhereUniqueWithoutDomainRecInput = {
  create: SubScenesDomainCreateWithoutDomainRecInput;
  update: SubScenesDomainUpdateWithoutDomainRecInput;
  where: SubScenesDomainWhereUniqueInput;
};

export type SubScenesDomainWhereInput = {
  AND?: InputMaybe<Array<SubScenesDomainWhereInput>>;
  NOT?: InputMaybe<Array<SubScenesDomainWhereInput>>;
  OR?: InputMaybe<Array<SubScenesDomainWhereInput>>;
  domain?: InputMaybe<StringFilter>;
  domainRec?: InputMaybe<DomainSceneNullableRelationFilter>;
  id?: InputMaybe<UuidFilter>;
  route?: InputMaybe<StringFilter>;
  sceneId?: InputMaybe<UuidFilter>;
};

export type SubScenesDomainWhereUniqueInput = {
  AND?: InputMaybe<Array<SubScenesDomainWhereInput>>;
  NOT?: InputMaybe<Array<SubScenesDomainWhereInput>>;
  OR?: InputMaybe<Array<SubScenesDomainWhereInput>>;
  domain?: InputMaybe<StringFilter>;
  domainRec?: InputMaybe<DomainSceneNullableRelationFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  route?: InputMaybe<StringFilter>;
  sceneId?: InputMaybe<UuidFilter>;
};

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  _count?: Maybe<SubscriptionPlanCount>;
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  numberOfScenes: Scalars['Int']['output'];
  price: Scalars['Float']['output'];
  type: Scalars['String']['output'];
};

export type SubscriptionPlanAvgAggregate = {
  __typename?: 'SubscriptionPlanAvgAggregate';
  numberOfScenes?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

export type SubscriptionPlanAvgOrderByAggregateInput = {
  numberOfScenes?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
};

export type SubscriptionPlanCount = {
  __typename?: 'SubscriptionPlanCount';
  LightTemplate: Scalars['Int']['output'];
  MFESTemplate: Scalars['Int']['output'];
  sceneTemplate: Scalars['Int']['output'];
  subscription: Scalars['Int']['output'];
};


export type SubscriptionPlanCountLightTemplateArgs = {
  where?: InputMaybe<LightTemplateWhereInput>;
};


export type SubscriptionPlanCountMfesTemplateArgs = {
  where?: InputMaybe<MfesTemplateWhereInput>;
};


export type SubscriptionPlanCountSceneTemplateArgs = {
  where?: InputMaybe<SceneTemplateWhereInput>;
};


export type SubscriptionPlanCountSubscriptionArgs = {
  where?: InputMaybe<UserSubscriptionWhereInput>;
};

export type SubscriptionPlanCountAggregate = {
  __typename?: 'SubscriptionPlanCountAggregate';
  _all: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  numberOfScenes: Scalars['Int']['output'];
  price: Scalars['Int']['output'];
  type: Scalars['Int']['output'];
};

export type SubscriptionPlanCountOrderByAggregateInput = {
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  numberOfScenes?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type SubscriptionPlanCreateInput = {
  LightTemplate?: InputMaybe<LightTemplateCreateNestedManyWithoutSubscriptionInput>;
  MFESTemplate?: InputMaybe<MfesTemplateCreateNestedManyWithoutSubscriptionInput>;
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  numberOfScenes: Scalars['Int']['input'];
  price: Scalars['Float']['input'];
  sceneTemplate?: InputMaybe<SceneTemplateCreateNestedManyWithoutSubscriptionInput>;
  subscription?: InputMaybe<UserSubscriptionCreateNestedManyWithoutSubscriptionInput>;
  type: Scalars['String']['input'];
};

export type SubscriptionPlanCreateManyInput = {
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  numberOfScenes: Scalars['Int']['input'];
  price: Scalars['Float']['input'];
  type: Scalars['String']['input'];
};

export type SubscriptionPlanCreateNestedOneWithoutLightTemplateInput = {
  connect?: InputMaybe<SubscriptionPlanWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SubscriptionPlanCreateOrConnectWithoutLightTemplateInput>;
  create?: InputMaybe<SubscriptionPlanCreateWithoutLightTemplateInput>;
};

export type SubscriptionPlanCreateNestedOneWithoutMfesTemplateInput = {
  connect?: InputMaybe<SubscriptionPlanWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SubscriptionPlanCreateOrConnectWithoutMfesTemplateInput>;
  create?: InputMaybe<SubscriptionPlanCreateWithoutMfesTemplateInput>;
};

export type SubscriptionPlanCreateNestedOneWithoutSceneTemplateInput = {
  connect?: InputMaybe<SubscriptionPlanWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SubscriptionPlanCreateOrConnectWithoutSceneTemplateInput>;
  create?: InputMaybe<SubscriptionPlanCreateWithoutSceneTemplateInput>;
};

export type SubscriptionPlanCreateNestedOneWithoutSubscriptionInput = {
  connect?: InputMaybe<SubscriptionPlanWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SubscriptionPlanCreateOrConnectWithoutSubscriptionInput>;
  create?: InputMaybe<SubscriptionPlanCreateWithoutSubscriptionInput>;
};

export type SubscriptionPlanCreateOrConnectWithoutLightTemplateInput = {
  create: SubscriptionPlanCreateWithoutLightTemplateInput;
  where: SubscriptionPlanWhereUniqueInput;
};

export type SubscriptionPlanCreateOrConnectWithoutMfesTemplateInput = {
  create: SubscriptionPlanCreateWithoutMfesTemplateInput;
  where: SubscriptionPlanWhereUniqueInput;
};

export type SubscriptionPlanCreateOrConnectWithoutSceneTemplateInput = {
  create: SubscriptionPlanCreateWithoutSceneTemplateInput;
  where: SubscriptionPlanWhereUniqueInput;
};

export type SubscriptionPlanCreateOrConnectWithoutSubscriptionInput = {
  create: SubscriptionPlanCreateWithoutSubscriptionInput;
  where: SubscriptionPlanWhereUniqueInput;
};

export type SubscriptionPlanCreateWithoutLightTemplateInput = {
  MFESTemplate?: InputMaybe<MfesTemplateCreateNestedManyWithoutSubscriptionInput>;
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  numberOfScenes: Scalars['Int']['input'];
  price: Scalars['Float']['input'];
  sceneTemplate?: InputMaybe<SceneTemplateCreateNestedManyWithoutSubscriptionInput>;
  subscription?: InputMaybe<UserSubscriptionCreateNestedManyWithoutSubscriptionInput>;
  type: Scalars['String']['input'];
};

export type SubscriptionPlanCreateWithoutMfesTemplateInput = {
  LightTemplate?: InputMaybe<LightTemplateCreateNestedManyWithoutSubscriptionInput>;
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  numberOfScenes: Scalars['Int']['input'];
  price: Scalars['Float']['input'];
  sceneTemplate?: InputMaybe<SceneTemplateCreateNestedManyWithoutSubscriptionInput>;
  subscription?: InputMaybe<UserSubscriptionCreateNestedManyWithoutSubscriptionInput>;
  type: Scalars['String']['input'];
};

export type SubscriptionPlanCreateWithoutSceneTemplateInput = {
  LightTemplate?: InputMaybe<LightTemplateCreateNestedManyWithoutSubscriptionInput>;
  MFESTemplate?: InputMaybe<MfesTemplateCreateNestedManyWithoutSubscriptionInput>;
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  numberOfScenes: Scalars['Int']['input'];
  price: Scalars['Float']['input'];
  subscription?: InputMaybe<UserSubscriptionCreateNestedManyWithoutSubscriptionInput>;
  type: Scalars['String']['input'];
};

export type SubscriptionPlanCreateWithoutSubscriptionInput = {
  LightTemplate?: InputMaybe<LightTemplateCreateNestedManyWithoutSubscriptionInput>;
  MFESTemplate?: InputMaybe<MfesTemplateCreateNestedManyWithoutSubscriptionInput>;
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  numberOfScenes: Scalars['Int']['input'];
  price: Scalars['Float']['input'];
  sceneTemplate?: InputMaybe<SceneTemplateCreateNestedManyWithoutSubscriptionInput>;
  type: Scalars['String']['input'];
};

export type SubscriptionPlanGroupBy = {
  __typename?: 'SubscriptionPlanGroupBy';
  _avg?: Maybe<SubscriptionPlanAvgAggregate>;
  _count?: Maybe<SubscriptionPlanCountAggregate>;
  _max?: Maybe<SubscriptionPlanMaxAggregate>;
  _min?: Maybe<SubscriptionPlanMinAggregate>;
  _sum?: Maybe<SubscriptionPlanSumAggregate>;
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  numberOfScenes: Scalars['Int']['output'];
  price: Scalars['Float']['output'];
  type: Scalars['String']['output'];
};

export type SubscriptionPlanMaxAggregate = {
  __typename?: 'SubscriptionPlanMaxAggregate';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  numberOfScenes?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionPlanMaxOrderByAggregateInput = {
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  numberOfScenes?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type SubscriptionPlanMinAggregate = {
  __typename?: 'SubscriptionPlanMinAggregate';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  numberOfScenes?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionPlanMinOrderByAggregateInput = {
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  numberOfScenes?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type SubscriptionPlanOrderByWithAggregationInput = {
  _avg?: InputMaybe<SubscriptionPlanAvgOrderByAggregateInput>;
  _count?: InputMaybe<SubscriptionPlanCountOrderByAggregateInput>;
  _max?: InputMaybe<SubscriptionPlanMaxOrderByAggregateInput>;
  _min?: InputMaybe<SubscriptionPlanMinOrderByAggregateInput>;
  _sum?: InputMaybe<SubscriptionPlanSumOrderByAggregateInput>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  numberOfScenes?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type SubscriptionPlanOrderByWithRelationInput = {
  LightTemplate?: InputMaybe<LightTemplateOrderByRelationAggregateInput>;
  MFESTemplate?: InputMaybe<MfesTemplateOrderByRelationAggregateInput>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  numberOfScenes?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  sceneTemplate?: InputMaybe<SceneTemplateOrderByRelationAggregateInput>;
  subscription?: InputMaybe<UserSubscriptionOrderByRelationAggregateInput>;
  type?: InputMaybe<SortOrder>;
};

export type SubscriptionPlanRelationFilter = {
  is?: InputMaybe<SubscriptionPlanWhereInput>;
  isNot?: InputMaybe<SubscriptionPlanWhereInput>;
};

export enum SubscriptionPlanScalarFieldEnum {
  Description = 'description',
  Id = 'id',
  Name = 'name',
  NumberOfScenes = 'numberOfScenes',
  Price = 'price',
  Type = 'type'
}

export type SubscriptionPlanScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<SubscriptionPlanScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<SubscriptionPlanScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<SubscriptionPlanScalarWhereWithAggregatesInput>>;
  description?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  numberOfScenes?: InputMaybe<IntWithAggregatesFilter>;
  price?: InputMaybe<FloatWithAggregatesFilter>;
  type?: InputMaybe<StringWithAggregatesFilter>;
};

export type SubscriptionPlanSumAggregate = {
  __typename?: 'SubscriptionPlanSumAggregate';
  numberOfScenes?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

export type SubscriptionPlanSumOrderByAggregateInput = {
  numberOfScenes?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
};

export type SubscriptionPlanUpdateInput = {
  LightTemplate?: InputMaybe<LightTemplateUpdateManyWithoutSubscriptionNestedInput>;
  MFESTemplate?: InputMaybe<MfesTemplateUpdateManyWithoutSubscriptionNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numberOfScenes?: InputMaybe<IntFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sceneTemplate?: InputMaybe<SceneTemplateUpdateManyWithoutSubscriptionNestedInput>;
  subscription?: InputMaybe<UserSubscriptionUpdateManyWithoutSubscriptionNestedInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type SubscriptionPlanUpdateManyMutationInput = {
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numberOfScenes?: InputMaybe<IntFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type SubscriptionPlanUpdateOneRequiredWithoutLightTemplateNestedInput = {
  connect?: InputMaybe<SubscriptionPlanWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SubscriptionPlanCreateOrConnectWithoutLightTemplateInput>;
  create?: InputMaybe<SubscriptionPlanCreateWithoutLightTemplateInput>;
  update?: InputMaybe<SubscriptionPlanUpdateToOneWithWhereWithoutLightTemplateInput>;
  upsert?: InputMaybe<SubscriptionPlanUpsertWithoutLightTemplateInput>;
};

export type SubscriptionPlanUpdateOneRequiredWithoutMfesTemplateNestedInput = {
  connect?: InputMaybe<SubscriptionPlanWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SubscriptionPlanCreateOrConnectWithoutMfesTemplateInput>;
  create?: InputMaybe<SubscriptionPlanCreateWithoutMfesTemplateInput>;
  update?: InputMaybe<SubscriptionPlanUpdateToOneWithWhereWithoutMfesTemplateInput>;
  upsert?: InputMaybe<SubscriptionPlanUpsertWithoutMfesTemplateInput>;
};

export type SubscriptionPlanUpdateOneRequiredWithoutSceneTemplateNestedInput = {
  connect?: InputMaybe<SubscriptionPlanWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SubscriptionPlanCreateOrConnectWithoutSceneTemplateInput>;
  create?: InputMaybe<SubscriptionPlanCreateWithoutSceneTemplateInput>;
  update?: InputMaybe<SubscriptionPlanUpdateToOneWithWhereWithoutSceneTemplateInput>;
  upsert?: InputMaybe<SubscriptionPlanUpsertWithoutSceneTemplateInput>;
};

export type SubscriptionPlanUpdateOneRequiredWithoutSubscriptionNestedInput = {
  connect?: InputMaybe<SubscriptionPlanWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SubscriptionPlanCreateOrConnectWithoutSubscriptionInput>;
  create?: InputMaybe<SubscriptionPlanCreateWithoutSubscriptionInput>;
  update?: InputMaybe<SubscriptionPlanUpdateToOneWithWhereWithoutSubscriptionInput>;
  upsert?: InputMaybe<SubscriptionPlanUpsertWithoutSubscriptionInput>;
};

export type SubscriptionPlanUpdateToOneWithWhereWithoutLightTemplateInput = {
  data: SubscriptionPlanUpdateWithoutLightTemplateInput;
  where?: InputMaybe<SubscriptionPlanWhereInput>;
};

export type SubscriptionPlanUpdateToOneWithWhereWithoutMfesTemplateInput = {
  data: SubscriptionPlanUpdateWithoutMfesTemplateInput;
  where?: InputMaybe<SubscriptionPlanWhereInput>;
};

export type SubscriptionPlanUpdateToOneWithWhereWithoutSceneTemplateInput = {
  data: SubscriptionPlanUpdateWithoutSceneTemplateInput;
  where?: InputMaybe<SubscriptionPlanWhereInput>;
};

export type SubscriptionPlanUpdateToOneWithWhereWithoutSubscriptionInput = {
  data: SubscriptionPlanUpdateWithoutSubscriptionInput;
  where?: InputMaybe<SubscriptionPlanWhereInput>;
};

export type SubscriptionPlanUpdateWithoutLightTemplateInput = {
  MFESTemplate?: InputMaybe<MfesTemplateUpdateManyWithoutSubscriptionNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numberOfScenes?: InputMaybe<IntFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sceneTemplate?: InputMaybe<SceneTemplateUpdateManyWithoutSubscriptionNestedInput>;
  subscription?: InputMaybe<UserSubscriptionUpdateManyWithoutSubscriptionNestedInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type SubscriptionPlanUpdateWithoutMfesTemplateInput = {
  LightTemplate?: InputMaybe<LightTemplateUpdateManyWithoutSubscriptionNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numberOfScenes?: InputMaybe<IntFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sceneTemplate?: InputMaybe<SceneTemplateUpdateManyWithoutSubscriptionNestedInput>;
  subscription?: InputMaybe<UserSubscriptionUpdateManyWithoutSubscriptionNestedInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type SubscriptionPlanUpdateWithoutSceneTemplateInput = {
  LightTemplate?: InputMaybe<LightTemplateUpdateManyWithoutSubscriptionNestedInput>;
  MFESTemplate?: InputMaybe<MfesTemplateUpdateManyWithoutSubscriptionNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numberOfScenes?: InputMaybe<IntFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  subscription?: InputMaybe<UserSubscriptionUpdateManyWithoutSubscriptionNestedInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type SubscriptionPlanUpdateWithoutSubscriptionInput = {
  LightTemplate?: InputMaybe<LightTemplateUpdateManyWithoutSubscriptionNestedInput>;
  MFESTemplate?: InputMaybe<MfesTemplateUpdateManyWithoutSubscriptionNestedInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numberOfScenes?: InputMaybe<IntFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  sceneTemplate?: InputMaybe<SceneTemplateUpdateManyWithoutSubscriptionNestedInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type SubscriptionPlanUpsertWithoutLightTemplateInput = {
  create: SubscriptionPlanCreateWithoutLightTemplateInput;
  update: SubscriptionPlanUpdateWithoutLightTemplateInput;
  where?: InputMaybe<SubscriptionPlanWhereInput>;
};

export type SubscriptionPlanUpsertWithoutMfesTemplateInput = {
  create: SubscriptionPlanCreateWithoutMfesTemplateInput;
  update: SubscriptionPlanUpdateWithoutMfesTemplateInput;
  where?: InputMaybe<SubscriptionPlanWhereInput>;
};

export type SubscriptionPlanUpsertWithoutSceneTemplateInput = {
  create: SubscriptionPlanCreateWithoutSceneTemplateInput;
  update: SubscriptionPlanUpdateWithoutSceneTemplateInput;
  where?: InputMaybe<SubscriptionPlanWhereInput>;
};

export type SubscriptionPlanUpsertWithoutSubscriptionInput = {
  create: SubscriptionPlanCreateWithoutSubscriptionInput;
  update: SubscriptionPlanUpdateWithoutSubscriptionInput;
  where?: InputMaybe<SubscriptionPlanWhereInput>;
};

export type SubscriptionPlanWhereInput = {
  AND?: InputMaybe<Array<SubscriptionPlanWhereInput>>;
  LightTemplate?: InputMaybe<LightTemplateListRelationFilter>;
  MFESTemplate?: InputMaybe<MfesTemplateListRelationFilter>;
  NOT?: InputMaybe<Array<SubscriptionPlanWhereInput>>;
  OR?: InputMaybe<Array<SubscriptionPlanWhereInput>>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  name?: InputMaybe<StringFilter>;
  numberOfScenes?: InputMaybe<IntFilter>;
  price?: InputMaybe<FloatFilter>;
  sceneTemplate?: InputMaybe<SceneTemplateListRelationFilter>;
  subscription?: InputMaybe<UserSubscriptionListRelationFilter>;
  type?: InputMaybe<StringFilter>;
};

export type SubscriptionPlanWhereUniqueInput = {
  AND?: InputMaybe<Array<SubscriptionPlanWhereInput>>;
  LightTemplate?: InputMaybe<LightTemplateListRelationFilter>;
  MFESTemplate?: InputMaybe<MfesTemplateListRelationFilter>;
  NOT?: InputMaybe<Array<SubscriptionPlanWhereInput>>;
  OR?: InputMaybe<Array<SubscriptionPlanWhereInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  numberOfScenes?: InputMaybe<IntFilter>;
  price?: InputMaybe<FloatFilter>;
  sceneTemplate?: InputMaybe<SceneTemplateListRelationFilter>;
  subscription?: InputMaybe<UserSubscriptionListRelationFilter>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type SwitchEnableQuestsInput = {
  sceneId: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
};

export type SwitchEnableQuestsResponse = {
  __typename?: 'SwitchEnableQuestsResponse';
  status: Scalars['String']['output'];
};

export type TCollection = {
  __typename?: 'TCollection';
  bannerImageURL: Scalars['String']['output'];
  categoryKey: Scalars['String']['output'];
  creator?: Maybe<TCreator>;
  creatorEmail: Scalars['String']['output'];
  description: Scalars['String']['output'];
  featureImageURL: Scalars['String']['output'];
  id: Scalars['String']['output'];
  logoImageURL: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type TCreator = {
  __typename?: 'TCreator';
  alias?: Maybe<Scalars['String']['output']>;
};

export type Templates = {
  __typename?: 'Templates';
  createdAt: Scalars['DateTimeISO']['output'];
  default: Scalars['Boolean']['output'];
  html: Scalars['String']['output'];
  id: Scalars['String']['output'];
  json: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type TemplatesCountAggregate = {
  __typename?: 'TemplatesCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  default: Scalars['Int']['output'];
  html: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  json: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type TemplatesCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  default?: InputMaybe<SortOrder>;
  html?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  json?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type TemplatesCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  default?: InputMaybe<Scalars['Boolean']['input']>;
  html: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  json: Scalars['String']['input'];
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type TemplatesCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  default?: InputMaybe<Scalars['Boolean']['input']>;
  html: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  json: Scalars['String']['input'];
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type TemplatesGroupBy = {
  __typename?: 'TemplatesGroupBy';
  _count?: Maybe<TemplatesCountAggregate>;
  _max?: Maybe<TemplatesMaxAggregate>;
  _min?: Maybe<TemplatesMinAggregate>;
  createdAt: Scalars['DateTimeISO']['output'];
  default: Scalars['Boolean']['output'];
  html: Scalars['String']['output'];
  id: Scalars['String']['output'];
  json: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type TemplatesMaxAggregate = {
  __typename?: 'TemplatesMaxAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  default?: Maybe<Scalars['Boolean']['output']>;
  html?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  json?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type TemplatesMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  default?: InputMaybe<SortOrder>;
  html?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  json?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type TemplatesMinAggregate = {
  __typename?: 'TemplatesMinAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  default?: Maybe<Scalars['Boolean']['output']>;
  html?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  json?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type TemplatesMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  default?: InputMaybe<SortOrder>;
  html?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  json?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type TemplatesOrderByWithAggregationInput = {
  _count?: InputMaybe<TemplatesCountOrderByAggregateInput>;
  _max?: InputMaybe<TemplatesMaxOrderByAggregateInput>;
  _min?: InputMaybe<TemplatesMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  default?: InputMaybe<SortOrder>;
  html?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  json?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type TemplatesOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  default?: InputMaybe<SortOrder>;
  html?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  json?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum TemplatesScalarFieldEnum {
  CreatedAt = 'createdAt',
  Default = 'default',
  Html = 'html',
  Id = 'id',
  Json = 'json',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type TemplatesScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<TemplatesScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<TemplatesScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<TemplatesScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  default?: InputMaybe<BoolWithAggregatesFilter>;
  html?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  json?: InputMaybe<StringWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type TemplatesUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  default?: InputMaybe<BoolFieldUpdateOperationsInput>;
  html?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  json?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TemplatesUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  default?: InputMaybe<BoolFieldUpdateOperationsInput>;
  html?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  json?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TemplatesWhereInput = {
  AND?: InputMaybe<Array<TemplatesWhereInput>>;
  NOT?: InputMaybe<Array<TemplatesWhereInput>>;
  OR?: InputMaybe<Array<TemplatesWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  default?: InputMaybe<BoolFilter>;
  html?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  json?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TemplatesWhereUniqueInput = {
  AND?: InputMaybe<Array<TemplatesWhereInput>>;
  NOT?: InputMaybe<Array<TemplatesWhereInput>>;
  OR?: InputMaybe<Array<TemplatesWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  default?: InputMaybe<BoolFilter>;
  html?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  json?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TestSendInput = {
  email: Scalars['String']['input'];
  subject: Scalars['String']['input'];
  templateId: Scalars['String']['input'];
};

export type TourItems = {
  __typename?: 'TourItems';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  position: Array<Scalars['Float']['output']>;
  userSceneId: Scalars['String']['output'];
};

export type TourItemsAvgAggregate = {
  __typename?: 'TourItemsAvgAggregate';
  position?: Maybe<Scalars['Float']['output']>;
};

export type TourItemsAvgOrderByAggregateInput = {
  position?: InputMaybe<SortOrder>;
};

export type TourItemsCountAggregate = {
  __typename?: 'TourItemsCountAggregate';
  _all: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  position: Scalars['Int']['output'];
  userSceneId: Scalars['Int']['output'];
};

export type TourItemsCountOrderByAggregateInput = {
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  position?: InputMaybe<SortOrder>;
  userSceneId?: InputMaybe<SortOrder>;
};

export type TourItemsCreateInput = {
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  position?: InputMaybe<TourItemsCreatepositionInput>;
  userScene: UserSceneCreateNestedOneWithoutTourItemsInput;
};

export type TourItemsCreateManyInput = {
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  position?: InputMaybe<TourItemsCreatepositionInput>;
  userSceneId: Scalars['String']['input'];
};

export type TourItemsCreateManyUserSceneInput = {
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  position?: InputMaybe<TourItemsCreatepositionInput>;
};

export type TourItemsCreateManyUserSceneInputEnvelope = {
  data: Array<TourItemsCreateManyUserSceneInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TourItemsCreateNestedManyWithoutUserSceneInput = {
  connect?: InputMaybe<Array<TourItemsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TourItemsCreateOrConnectWithoutUserSceneInput>>;
  create?: InputMaybe<Array<TourItemsCreateWithoutUserSceneInput>>;
  createMany?: InputMaybe<TourItemsCreateManyUserSceneInputEnvelope>;
};

export type TourItemsCreateOrConnectWithoutUserSceneInput = {
  create: TourItemsCreateWithoutUserSceneInput;
  where: TourItemsWhereUniqueInput;
};

export type TourItemsCreateWithoutUserSceneInput = {
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  position?: InputMaybe<TourItemsCreatepositionInput>;
};

export type TourItemsCreatepositionInput = {
  set: Array<Scalars['Float']['input']>;
};

export type TourItemsGroupBy = {
  __typename?: 'TourItemsGroupBy';
  _avg?: Maybe<TourItemsAvgAggregate>;
  _count?: Maybe<TourItemsCountAggregate>;
  _max?: Maybe<TourItemsMaxAggregate>;
  _min?: Maybe<TourItemsMinAggregate>;
  _sum?: Maybe<TourItemsSumAggregate>;
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  position?: Maybe<Array<Scalars['Float']['output']>>;
  userSceneId: Scalars['String']['output'];
};

export type TourItemsListRelationFilter = {
  every?: InputMaybe<TourItemsWhereInput>;
  none?: InputMaybe<TourItemsWhereInput>;
  some?: InputMaybe<TourItemsWhereInput>;
};

export type TourItemsMaxAggregate = {
  __typename?: 'TourItemsMaxAggregate';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  userSceneId?: Maybe<Scalars['String']['output']>;
};

export type TourItemsMaxOrderByAggregateInput = {
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  userSceneId?: InputMaybe<SortOrder>;
};

export type TourItemsMinAggregate = {
  __typename?: 'TourItemsMinAggregate';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  userSceneId?: Maybe<Scalars['String']['output']>;
};

export type TourItemsMinOrderByAggregateInput = {
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  userSceneId?: InputMaybe<SortOrder>;
};

export type TourItemsOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TourItemsOrderByWithAggregationInput = {
  _avg?: InputMaybe<TourItemsAvgOrderByAggregateInput>;
  _count?: InputMaybe<TourItemsCountOrderByAggregateInput>;
  _max?: InputMaybe<TourItemsMaxOrderByAggregateInput>;
  _min?: InputMaybe<TourItemsMinOrderByAggregateInput>;
  _sum?: InputMaybe<TourItemsSumOrderByAggregateInput>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  position?: InputMaybe<SortOrder>;
  userSceneId?: InputMaybe<SortOrder>;
};

export type TourItemsOrderByWithRelationInput = {
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  position?: InputMaybe<SortOrder>;
  userScene?: InputMaybe<UserSceneOrderByWithRelationInput>;
  userSceneId?: InputMaybe<SortOrder>;
};

export enum TourItemsScalarFieldEnum {
  Description = 'description',
  Id = 'id',
  Name = 'name',
  Position = 'position',
  UserSceneId = 'userSceneId'
}

export type TourItemsScalarWhereInput = {
  AND?: InputMaybe<Array<TourItemsScalarWhereInput>>;
  NOT?: InputMaybe<Array<TourItemsScalarWhereInput>>;
  OR?: InputMaybe<Array<TourItemsScalarWhereInput>>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  name?: InputMaybe<StringFilter>;
  position?: InputMaybe<FloatNullableListFilter>;
  userSceneId?: InputMaybe<UuidFilter>;
};

export type TourItemsScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<TourItemsScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<TourItemsScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<TourItemsScalarWhereWithAggregatesInput>>;
  description?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  position?: InputMaybe<FloatNullableListFilter>;
  userSceneId?: InputMaybe<UuidWithAggregatesFilter>;
};

export type TourItemsSumAggregate = {
  __typename?: 'TourItemsSumAggregate';
  position?: Maybe<Array<Scalars['Float']['output']>>;
};

export type TourItemsSumOrderByAggregateInput = {
  position?: InputMaybe<SortOrder>;
};

export type TourItemsUpdateInput = {
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  position?: InputMaybe<TourItemsUpdatepositionInput>;
  userScene?: InputMaybe<UserSceneUpdateOneRequiredWithoutTourItemsNestedInput>;
};

export type TourItemsUpdateManyMutationInput = {
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  position?: InputMaybe<TourItemsUpdatepositionInput>;
};

export type TourItemsUpdateManyWithWhereWithoutUserSceneInput = {
  data: TourItemsUpdateManyMutationInput;
  where: TourItemsScalarWhereInput;
};

export type TourItemsUpdateManyWithoutUserSceneNestedInput = {
  connect?: InputMaybe<Array<TourItemsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TourItemsCreateOrConnectWithoutUserSceneInput>>;
  create?: InputMaybe<Array<TourItemsCreateWithoutUserSceneInput>>;
  createMany?: InputMaybe<TourItemsCreateManyUserSceneInputEnvelope>;
  delete?: InputMaybe<Array<TourItemsWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TourItemsScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TourItemsWhereUniqueInput>>;
  set?: InputMaybe<Array<TourItemsWhereUniqueInput>>;
  update?: InputMaybe<Array<TourItemsUpdateWithWhereUniqueWithoutUserSceneInput>>;
  updateMany?: InputMaybe<Array<TourItemsUpdateManyWithWhereWithoutUserSceneInput>>;
  upsert?: InputMaybe<Array<TourItemsUpsertWithWhereUniqueWithoutUserSceneInput>>;
};

export type TourItemsUpdateWithWhereUniqueWithoutUserSceneInput = {
  data: TourItemsUpdateWithoutUserSceneInput;
  where: TourItemsWhereUniqueInput;
};

export type TourItemsUpdateWithoutUserSceneInput = {
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  position?: InputMaybe<TourItemsUpdatepositionInput>;
};

export type TourItemsUpdatepositionInput = {
  push?: InputMaybe<Array<Scalars['Float']['input']>>;
  set?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type TourItemsUpsertWithWhereUniqueWithoutUserSceneInput = {
  create: TourItemsCreateWithoutUserSceneInput;
  update: TourItemsUpdateWithoutUserSceneInput;
  where: TourItemsWhereUniqueInput;
};

export type TourItemsWhereInput = {
  AND?: InputMaybe<Array<TourItemsWhereInput>>;
  NOT?: InputMaybe<Array<TourItemsWhereInput>>;
  OR?: InputMaybe<Array<TourItemsWhereInput>>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  name?: InputMaybe<StringFilter>;
  position?: InputMaybe<FloatNullableListFilter>;
  userScene?: InputMaybe<UserSceneRelationFilter>;
  userSceneId?: InputMaybe<UuidFilter>;
};

export type TourItemsWhereUniqueInput = {
  AND?: InputMaybe<Array<TourItemsWhereInput>>;
  NOT?: InputMaybe<Array<TourItemsWhereInput>>;
  OR?: InputMaybe<Array<TourItemsWhereInput>>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<StringFilter>;
  position?: InputMaybe<FloatNullableListFilter>;
  userScene?: InputMaybe<UserSceneRelationFilter>;
  userSceneId?: InputMaybe<UuidFilter>;
};

export type TrendCollection = {
  __typename?: 'TrendCollection';
  bannerImageURL: Scalars['String']['output'];
  categoryKey: Scalars['String']['output'];
  creator?: Maybe<CreatorT>;
  creatorEmail: Scalars['String']['output'];
  description: Scalars['String']['output'];
  featureImageURL: Scalars['String']['output'];
  id: Scalars['String']['output'];
  logoImageURL: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum TypeOrder {
  Blockchain = 'BLOCKCHAIN',
  Stripe = 'STRIPE'
}

export type UnLikeAssetInput = {
  assetId?: InputMaybe<Scalars['Float']['input']>;
  assetIdWeb2?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UnLikeAssetResponse = {
  __typename?: 'UnLikeAssetResponse';
  status: Scalars['String']['output'];
};

export type UpdateAliasSceneInput = {
  alias: Scalars['String']['input'];
  sceneId: Scalars['String']['input'];
};

export type UpdateCollectionInput = {
  bannerImageURL?: InputMaybe<Scalars['String']['input']>;
  categoryKey?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  featureImageURL?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCollectionResponse = {
  __typename?: 'UpdateCollectionResponse';
  message?: Maybe<Scalars['String']['output']>;
  status: Scalars['Boolean']['output'];
};

export type UpdateCreatorInput = {
  bio?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  isSetupedOzoneToken?: InputMaybe<Scalars['Boolean']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCreatorResponse = {
  __typename?: 'UpdateCreatorResponse';
  id?: Maybe<Scalars['String']['output']>;
  updateCreatorStatus: Scalars['Boolean']['output'];
};

export type UpdateDomainSceneInput = {
  domain?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  sceneId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDomainSceneResponse = {
  __typename?: 'UpdateDomainSceneResponse';
  status: Scalars['String']['output'];
};

export type UpdateDomainSubSceneInput = {
  id: Scalars['String']['input'];
  route?: InputMaybe<Scalars['String']['input']>;
  sceneId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDomainSubSceneResponse = {
  __typename?: 'UpdateDomainSubSceneResponse';
  status: Scalars['String']['output'];
};

export type UpdateMatchInput = {
  isDraw?: InputMaybe<Scalars['Boolean']['input']>;
  matchDuration?: InputMaybe<Scalars['Float']['input']>;
  matchId: Scalars['String']['input'];
  reward?: InputMaybe<Scalars['Float']['input']>;
  status: MatchStatus;
  winner?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMatchResponse = {
  __typename?: 'UpdateMatchResponse';
  status: Scalars['Boolean']['output'];
};

export type UpdateOrderStatusInput = {
  bnbPaymentStatus?: InputMaybe<Scalars['String']['input']>;
  bnbTxId?: InputMaybe<Scalars['String']['input']>;
  nftReceived?: InputMaybe<Scalars['Boolean']['input']>;
  orederId: Scalars['String']['input'];
  status: Scalars['String']['input'];
  stripeTxId?: InputMaybe<Scalars['String']['input']>;
  txId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrderStatusResponse = {
  __typename?: 'UpdateOrderStatusResponse';
  orderUpdateStatus: Scalars['Boolean']['output'];
};

export type UpdateQuestInput = {
  collectableItem?: InputMaybe<Array<Scalars['String']['input']>>;
  enableDefaultModal?: InputMaybe<Scalars['Boolean']['input']>;
  fakeItem?: InputMaybe<Array<Scalars['String']['input']>>;
  finishPrompt?: InputMaybe<Scalars['String']['input']>;
  hasReward?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  rewardAmount?: InputMaybe<Scalars['Float']['input']>;
  rewardType?: InputMaybe<RewardType>;
  sceneId: Scalars['String']['input'];
  splashScreenMessage: Scalars['String']['input'];
  startPrompt: Scalars['String']['input'];
};

export type UpdateQuestResponse = {
  __typename?: 'UpdateQuestResponse';
  status: Scalars['String']['output'];
};

export type UpdateSceneAssetsInput = {
  assets?: InputMaybe<Array<UserSceneAssetsUpdateInput>>;
};

export type UpdateSceneAssetsResponse = {
  __typename?: 'UpdateSceneAssetsResponse';
  message?: Maybe<Scalars['String']['output']>;
  status: Scalars['Boolean']['output'];
};

export type UpdateSceneResponse = {
  __typename?: 'UpdateSceneResponse';
  errorMessage?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type UpdateShopifyStoreInput = {
  apiVersion: Scalars['String']['input'];
  domain: Scalars['String']['input'];
  id: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type UpdateShopifyStoreResponse = {
  __typename?: 'UpdateShopifyStoreResponse';
  status: Scalars['String']['output'];
};

export type UpdateTimeInLobbyInput = {
  time: Scalars['Float']['input'];
  userId: Scalars['String']['input'];
};

export type UpdateTimeInLobbyResponse = {
  __typename?: 'UpdateTimeInLobbyResponse';
  message?: Maybe<Scalars['String']['output']>;
  status: Scalars['Boolean']['output'];
};

export type UserCreateInput = {
  avatarUrl: Scalars['String']['input'];
  email: Scalars['String']['input'];
  isVerified: Scalars['Boolean']['input'];
  lastname: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  role: Scalars['String']['input'];
  verificationToken: Scalars['String']['input'];
};

export type UserFinishedQuestInput = {
  email: Scalars['String']['input'];
  questId: Scalars['String']['input'];
  walletId: Scalars['String']['input'];
  walletType: Scalars['String']['input'];
};

export type UserFinishedQuestResponse = {
  __typename?: 'UserFinishedQuestResponse';
  status: Scalars['String']['output'];
};

export type UserFriendsNullableRelationFilter = {
  is?: InputMaybe<UserFriendsWhereInput>;
  isNot?: InputMaybe<UserFriendsWhereInput>;
};

export type UserFriendsWhereInput = {
  AND?: InputMaybe<Array<UserFriendsWhereInput>>;
  NOT?: InputMaybe<Array<UserFriendsWhereInput>>;
  OR?: InputMaybe<Array<UserFriendsWhereInput>>;
  friends?: InputMaybe<UsersListRelationFilter>;
  id?: InputMaybe<UuidFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type UserPublic = {
  __typename?: 'UserPublic';
  user?: Maybe<UserPublicObject>;
};

export type UserPublicObject = {
  __typename?: 'UserPublicObject';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  bannerUrl?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
};

export type UserScene = {
  __typename?: 'UserScene';
  _count?: Maybe<UserSceneCount>;
  alias?: Maybe<Scalars['String']['output']>;
  branding: Brand;
  cameraConfigId?: Maybe<Scalars['String']['output']>;
  chatConversationSID?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  description: Scalars['String']['output'];
  gameMode?: Maybe<GameType>;
  graphicsSettingsId?: Maybe<Scalars['String']['output']>;
  hasHideAndSeek?: Maybe<Scalars['Boolean']['output']>;
  hasQuest?: Maybe<Scalars['Boolean']['output']>;
  hideAndSeekId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isCameraThirdPerson?: Maybe<Scalars['Boolean']['output']>;
  isChatEnabled?: Maybe<Scalars['Boolean']['output']>;
  isScanEnabled?: Maybe<Scalars['Boolean']['output']>;
  lightTemplate?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  questId?: Maybe<Scalars['String']['output']>;
  skybox?: Maybe<Scalars['Int']['output']>;
  thumbnail: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type UserSceneAssets = {
  __typename?: 'UserSceneAssets';
  autoPlayVideo?: Maybe<Scalars['Boolean']['output']>;
  baseColor?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enableLinking?: Maybe<Scalars['Boolean']['output']>;
  enablePurchase?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  isPortal?: Maybe<Scalars['Boolean']['output']>;
  lightId?: Maybe<Scalars['String']['output']>;
  loopVideo?: Maybe<Scalars['Boolean']['output']>;
  marketId?: Maybe<Scalars['Int']['output']>;
  metadata?: Maybe<Scalars['String']['output']>;
  metalness?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  portalSceneId?: Maybe<Scalars['String']['output']>;
  position: Array<Scalars['Float']['output']>;
  rotation: Array<Scalars['Float']['output']>;
  roughness?: Maybe<Scalars['Float']['output']>;
  scale: Array<Scalars['Float']['output']>;
  shopifyMetadata?: Maybe<Scalars['String']['output']>;
  textureToChange?: Maybe<Scalars['String']['output']>;
  textureToChangeMetalness?: Maybe<Scalars['String']['output']>;
  textureToChangeNormal?: Maybe<Scalars['String']['output']>;
  textureToChangeRoughness?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
  userSceneId: Scalars['String']['output'];
};

export type UserSceneAssetsAvgAggregate = {
  __typename?: 'UserSceneAssetsAvgAggregate';
  baseColor?: Maybe<Scalars['Float']['output']>;
  marketId?: Maybe<Scalars['Float']['output']>;
  metalness?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
  rotation?: Maybe<Scalars['Float']['output']>;
  roughness?: Maybe<Scalars['Float']['output']>;
  scale?: Maybe<Scalars['Float']['output']>;
};

export type UserSceneAssetsAvgOrderByAggregateInput = {
  baseColor?: InputMaybe<SortOrder>;
  marketId?: InputMaybe<SortOrder>;
  metalness?: InputMaybe<SortOrder>;
  position?: InputMaybe<SortOrder>;
  rotation?: InputMaybe<SortOrder>;
  roughness?: InputMaybe<SortOrder>;
  scale?: InputMaybe<SortOrder>;
};

export type UserSceneAssetsCountAggregate = {
  __typename?: 'UserSceneAssetsCountAggregate';
  _all: Scalars['Int']['output'];
  autoPlayVideo: Scalars['Int']['output'];
  baseColor: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  enableLinking: Scalars['Int']['output'];
  enablePurchase: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isPortal: Scalars['Int']['output'];
  lightId: Scalars['Int']['output'];
  loopVideo: Scalars['Int']['output'];
  marketId: Scalars['Int']['output'];
  metadata: Scalars['Int']['output'];
  metalness: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  portalSceneId: Scalars['Int']['output'];
  position: Scalars['Int']['output'];
  rotation: Scalars['Int']['output'];
  roughness: Scalars['Int']['output'];
  scale: Scalars['Int']['output'];
  shopifyMetadata: Scalars['Int']['output'];
  textureToChange: Scalars['Int']['output'];
  textureToChangeMetalness: Scalars['Int']['output'];
  textureToChangeNormal: Scalars['Int']['output'];
  textureToChangeRoughness: Scalars['Int']['output'];
  type: Scalars['Int']['output'];
  url: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
  userSceneId: Scalars['Int']['output'];
};

export type UserSceneAssetsCountOrderByAggregateInput = {
  autoPlayVideo?: InputMaybe<SortOrder>;
  baseColor?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  enableLinking?: InputMaybe<SortOrder>;
  enablePurchase?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPortal?: InputMaybe<SortOrder>;
  lightId?: InputMaybe<SortOrder>;
  loopVideo?: InputMaybe<SortOrder>;
  marketId?: InputMaybe<SortOrder>;
  metadata?: InputMaybe<SortOrder>;
  metalness?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  portalSceneId?: InputMaybe<SortOrder>;
  position?: InputMaybe<SortOrder>;
  rotation?: InputMaybe<SortOrder>;
  roughness?: InputMaybe<SortOrder>;
  scale?: InputMaybe<SortOrder>;
  shopifyMetadata?: InputMaybe<SortOrder>;
  textureToChange?: InputMaybe<SortOrder>;
  textureToChangeMetalness?: InputMaybe<SortOrder>;
  textureToChangeNormal?: InputMaybe<SortOrder>;
  textureToChangeRoughness?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  userSceneId?: InputMaybe<SortOrder>;
};

export type UserSceneAssetsCreateInput = {
  autoPlayVideo?: InputMaybe<Scalars['Boolean']['input']>;
  baseColor?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enableLinking?: InputMaybe<Scalars['Boolean']['input']>;
  enablePurchase?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPortal?: InputMaybe<Scalars['Boolean']['input']>;
  light?: InputMaybe<LightPropsCreateNestedOneWithoutAssetsInput>;
  loopVideo?: InputMaybe<Scalars['Boolean']['input']>;
  marketId?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  metalness?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  portalSceneId?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<UserSceneAssetsCreatepositionInput>;
  rotation?: InputMaybe<UserSceneAssetsCreaterotationInput>;
  roughness?: InputMaybe<Scalars['Float']['input']>;
  scale?: InputMaybe<UserSceneAssetsCreatescaleInput>;
  shopifyMetadata?: InputMaybe<Scalars['String']['input']>;
  textureToChange?: InputMaybe<Scalars['String']['input']>;
  textureToChangeMetalness?: InputMaybe<Scalars['String']['input']>;
  textureToChangeNormal?: InputMaybe<Scalars['String']['input']>;
  textureToChangeRoughness?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
  userScene: UserSceneCreateNestedOneWithoutAssetsInput;
};

export type UserSceneAssetsCreateManyInput = {
  autoPlayVideo?: InputMaybe<Scalars['Boolean']['input']>;
  baseColor?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enableLinking?: InputMaybe<Scalars['Boolean']['input']>;
  enablePurchase?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPortal?: InputMaybe<Scalars['Boolean']['input']>;
  lightId?: InputMaybe<Scalars['String']['input']>;
  loopVideo?: InputMaybe<Scalars['Boolean']['input']>;
  marketId?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  metalness?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  portalSceneId?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<UserSceneAssetsCreatepositionInput>;
  rotation?: InputMaybe<UserSceneAssetsCreaterotationInput>;
  roughness?: InputMaybe<Scalars['Float']['input']>;
  scale?: InputMaybe<UserSceneAssetsCreatescaleInput>;
  shopifyMetadata?: InputMaybe<Scalars['String']['input']>;
  textureToChange?: InputMaybe<Scalars['String']['input']>;
  textureToChangeMetalness?: InputMaybe<Scalars['String']['input']>;
  textureToChangeNormal?: InputMaybe<Scalars['String']['input']>;
  textureToChangeRoughness?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
  userSceneId: Scalars['String']['input'];
};

export type UserSceneAssetsCreateManyLightInput = {
  autoPlayVideo?: InputMaybe<Scalars['Boolean']['input']>;
  baseColor?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enableLinking?: InputMaybe<Scalars['Boolean']['input']>;
  enablePurchase?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPortal?: InputMaybe<Scalars['Boolean']['input']>;
  loopVideo?: InputMaybe<Scalars['Boolean']['input']>;
  marketId?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  metalness?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  portalSceneId?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<UserSceneAssetsCreatepositionInput>;
  rotation?: InputMaybe<UserSceneAssetsCreaterotationInput>;
  roughness?: InputMaybe<Scalars['Float']['input']>;
  scale?: InputMaybe<UserSceneAssetsCreatescaleInput>;
  shopifyMetadata?: InputMaybe<Scalars['String']['input']>;
  textureToChange?: InputMaybe<Scalars['String']['input']>;
  textureToChangeMetalness?: InputMaybe<Scalars['String']['input']>;
  textureToChangeNormal?: InputMaybe<Scalars['String']['input']>;
  textureToChangeRoughness?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
  userSceneId: Scalars['String']['input'];
};

export type UserSceneAssetsCreateManyLightInputEnvelope = {
  data: Array<UserSceneAssetsCreateManyLightInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserSceneAssetsCreateManyUserSceneInput = {
  autoPlayVideo?: InputMaybe<Scalars['Boolean']['input']>;
  baseColor?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enableLinking?: InputMaybe<Scalars['Boolean']['input']>;
  enablePurchase?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPortal?: InputMaybe<Scalars['Boolean']['input']>;
  lightId?: InputMaybe<Scalars['String']['input']>;
  loopVideo?: InputMaybe<Scalars['Boolean']['input']>;
  marketId?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  metalness?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  portalSceneId?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<UserSceneAssetsCreatepositionInput>;
  rotation?: InputMaybe<UserSceneAssetsCreaterotationInput>;
  roughness?: InputMaybe<Scalars['Float']['input']>;
  scale?: InputMaybe<UserSceneAssetsCreatescaleInput>;
  shopifyMetadata?: InputMaybe<Scalars['String']['input']>;
  textureToChange?: InputMaybe<Scalars['String']['input']>;
  textureToChangeMetalness?: InputMaybe<Scalars['String']['input']>;
  textureToChangeNormal?: InputMaybe<Scalars['String']['input']>;
  textureToChangeRoughness?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type UserSceneAssetsCreateManyUserSceneInputEnvelope = {
  data: Array<UserSceneAssetsCreateManyUserSceneInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserSceneAssetsCreateNestedManyWithoutLightInput = {
  connect?: InputMaybe<Array<UserSceneAssetsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserSceneAssetsCreateOrConnectWithoutLightInput>>;
  create?: InputMaybe<Array<UserSceneAssetsCreateWithoutLightInput>>;
  createMany?: InputMaybe<UserSceneAssetsCreateManyLightInputEnvelope>;
};

export type UserSceneAssetsCreateNestedManyWithoutUserSceneInput = {
  connect?: InputMaybe<Array<UserSceneAssetsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserSceneAssetsCreateOrConnectWithoutUserSceneInput>>;
  create?: InputMaybe<Array<UserSceneAssetsCreateWithoutUserSceneInput>>;
  createMany?: InputMaybe<UserSceneAssetsCreateManyUserSceneInputEnvelope>;
};

export type UserSceneAssetsCreateOrConnectWithoutLightInput = {
  create: UserSceneAssetsCreateWithoutLightInput;
  where: UserSceneAssetsWhereUniqueInput;
};

export type UserSceneAssetsCreateOrConnectWithoutUserSceneInput = {
  create: UserSceneAssetsCreateWithoutUserSceneInput;
  where: UserSceneAssetsWhereUniqueInput;
};

export type UserSceneAssetsCreateWithoutLightInput = {
  autoPlayVideo?: InputMaybe<Scalars['Boolean']['input']>;
  baseColor?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enableLinking?: InputMaybe<Scalars['Boolean']['input']>;
  enablePurchase?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPortal?: InputMaybe<Scalars['Boolean']['input']>;
  loopVideo?: InputMaybe<Scalars['Boolean']['input']>;
  marketId?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  metalness?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  portalSceneId?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<UserSceneAssetsCreatepositionInput>;
  rotation?: InputMaybe<UserSceneAssetsCreaterotationInput>;
  roughness?: InputMaybe<Scalars['Float']['input']>;
  scale?: InputMaybe<UserSceneAssetsCreatescaleInput>;
  shopifyMetadata?: InputMaybe<Scalars['String']['input']>;
  textureToChange?: InputMaybe<Scalars['String']['input']>;
  textureToChangeMetalness?: InputMaybe<Scalars['String']['input']>;
  textureToChangeNormal?: InputMaybe<Scalars['String']['input']>;
  textureToChangeRoughness?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
  userScene: UserSceneCreateNestedOneWithoutAssetsInput;
};

export type UserSceneAssetsCreateWithoutUserSceneInput = {
  autoPlayVideo?: InputMaybe<Scalars['Boolean']['input']>;
  baseColor?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enableLinking?: InputMaybe<Scalars['Boolean']['input']>;
  enablePurchase?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPortal?: InputMaybe<Scalars['Boolean']['input']>;
  light?: InputMaybe<LightPropsCreateNestedOneWithoutAssetsInput>;
  loopVideo?: InputMaybe<Scalars['Boolean']['input']>;
  marketId?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  metalness?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  portalSceneId?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<UserSceneAssetsCreatepositionInput>;
  rotation?: InputMaybe<UserSceneAssetsCreaterotationInput>;
  roughness?: InputMaybe<Scalars['Float']['input']>;
  scale?: InputMaybe<UserSceneAssetsCreatescaleInput>;
  shopifyMetadata?: InputMaybe<Scalars['String']['input']>;
  textureToChange?: InputMaybe<Scalars['String']['input']>;
  textureToChangeMetalness?: InputMaybe<Scalars['String']['input']>;
  textureToChangeNormal?: InputMaybe<Scalars['String']['input']>;
  textureToChangeRoughness?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type UserSceneAssetsCreatepositionInput = {
  set: Array<Scalars['Float']['input']>;
};

export type UserSceneAssetsCreaterotationInput = {
  set: Array<Scalars['Float']['input']>;
};

export type UserSceneAssetsCreatescaleInput = {
  set: Array<Scalars['Float']['input']>;
};

export type UserSceneAssetsGroupBy = {
  __typename?: 'UserSceneAssetsGroupBy';
  _avg?: Maybe<UserSceneAssetsAvgAggregate>;
  _count?: Maybe<UserSceneAssetsCountAggregate>;
  _max?: Maybe<UserSceneAssetsMaxAggregate>;
  _min?: Maybe<UserSceneAssetsMinAggregate>;
  _sum?: Maybe<UserSceneAssetsSumAggregate>;
  autoPlayVideo?: Maybe<Scalars['Boolean']['output']>;
  baseColor?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enableLinking?: Maybe<Scalars['Boolean']['output']>;
  enablePurchase?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  isPortal?: Maybe<Scalars['Boolean']['output']>;
  lightId?: Maybe<Scalars['String']['output']>;
  loopVideo?: Maybe<Scalars['Boolean']['output']>;
  marketId?: Maybe<Scalars['Int']['output']>;
  metadata?: Maybe<Scalars['String']['output']>;
  metalness?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  portalSceneId?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Array<Scalars['Float']['output']>>;
  rotation?: Maybe<Array<Scalars['Float']['output']>>;
  roughness?: Maybe<Scalars['Float']['output']>;
  scale?: Maybe<Array<Scalars['Float']['output']>>;
  shopifyMetadata?: Maybe<Scalars['String']['output']>;
  textureToChange?: Maybe<Scalars['String']['output']>;
  textureToChangeMetalness?: Maybe<Scalars['String']['output']>;
  textureToChangeNormal?: Maybe<Scalars['String']['output']>;
  textureToChangeRoughness?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
  userSceneId: Scalars['String']['output'];
};

export type UserSceneAssetsListRelationFilter = {
  every?: InputMaybe<UserSceneAssetsWhereInput>;
  none?: InputMaybe<UserSceneAssetsWhereInput>;
  some?: InputMaybe<UserSceneAssetsWhereInput>;
};

export type UserSceneAssetsMaxAggregate = {
  __typename?: 'UserSceneAssetsMaxAggregate';
  autoPlayVideo?: Maybe<Scalars['Boolean']['output']>;
  baseColor?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enableLinking?: Maybe<Scalars['Boolean']['output']>;
  enablePurchase?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isPortal?: Maybe<Scalars['Boolean']['output']>;
  lightId?: Maybe<Scalars['String']['output']>;
  loopVideo?: Maybe<Scalars['Boolean']['output']>;
  marketId?: Maybe<Scalars['Int']['output']>;
  metadata?: Maybe<Scalars['String']['output']>;
  metalness?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  portalSceneId?: Maybe<Scalars['String']['output']>;
  roughness?: Maybe<Scalars['Float']['output']>;
  shopifyMetadata?: Maybe<Scalars['String']['output']>;
  textureToChange?: Maybe<Scalars['String']['output']>;
  textureToChangeMetalness?: Maybe<Scalars['String']['output']>;
  textureToChangeNormal?: Maybe<Scalars['String']['output']>;
  textureToChangeRoughness?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  userSceneId?: Maybe<Scalars['String']['output']>;
};

export type UserSceneAssetsMaxOrderByAggregateInput = {
  autoPlayVideo?: InputMaybe<SortOrder>;
  baseColor?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  enableLinking?: InputMaybe<SortOrder>;
  enablePurchase?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPortal?: InputMaybe<SortOrder>;
  lightId?: InputMaybe<SortOrder>;
  loopVideo?: InputMaybe<SortOrder>;
  marketId?: InputMaybe<SortOrder>;
  metadata?: InputMaybe<SortOrder>;
  metalness?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  portalSceneId?: InputMaybe<SortOrder>;
  roughness?: InputMaybe<SortOrder>;
  shopifyMetadata?: InputMaybe<SortOrder>;
  textureToChange?: InputMaybe<SortOrder>;
  textureToChangeMetalness?: InputMaybe<SortOrder>;
  textureToChangeNormal?: InputMaybe<SortOrder>;
  textureToChangeRoughness?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  userSceneId?: InputMaybe<SortOrder>;
};

export type UserSceneAssetsMinAggregate = {
  __typename?: 'UserSceneAssetsMinAggregate';
  autoPlayVideo?: Maybe<Scalars['Boolean']['output']>;
  baseColor?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enableLinking?: Maybe<Scalars['Boolean']['output']>;
  enablePurchase?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isPortal?: Maybe<Scalars['Boolean']['output']>;
  lightId?: Maybe<Scalars['String']['output']>;
  loopVideo?: Maybe<Scalars['Boolean']['output']>;
  marketId?: Maybe<Scalars['Int']['output']>;
  metadata?: Maybe<Scalars['String']['output']>;
  metalness?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  portalSceneId?: Maybe<Scalars['String']['output']>;
  roughness?: Maybe<Scalars['Float']['output']>;
  shopifyMetadata?: Maybe<Scalars['String']['output']>;
  textureToChange?: Maybe<Scalars['String']['output']>;
  textureToChangeMetalness?: Maybe<Scalars['String']['output']>;
  textureToChangeNormal?: Maybe<Scalars['String']['output']>;
  textureToChangeRoughness?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  userSceneId?: Maybe<Scalars['String']['output']>;
};

export type UserSceneAssetsMinOrderByAggregateInput = {
  autoPlayVideo?: InputMaybe<SortOrder>;
  baseColor?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  enableLinking?: InputMaybe<SortOrder>;
  enablePurchase?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isPortal?: InputMaybe<SortOrder>;
  lightId?: InputMaybe<SortOrder>;
  loopVideo?: InputMaybe<SortOrder>;
  marketId?: InputMaybe<SortOrder>;
  metadata?: InputMaybe<SortOrder>;
  metalness?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  portalSceneId?: InputMaybe<SortOrder>;
  roughness?: InputMaybe<SortOrder>;
  shopifyMetadata?: InputMaybe<SortOrder>;
  textureToChange?: InputMaybe<SortOrder>;
  textureToChangeMetalness?: InputMaybe<SortOrder>;
  textureToChangeNormal?: InputMaybe<SortOrder>;
  textureToChangeRoughness?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  userSceneId?: InputMaybe<SortOrder>;
};

export type UserSceneAssetsOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserSceneAssetsOrderByWithAggregationInput = {
  _avg?: InputMaybe<UserSceneAssetsAvgOrderByAggregateInput>;
  _count?: InputMaybe<UserSceneAssetsCountOrderByAggregateInput>;
  _max?: InputMaybe<UserSceneAssetsMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserSceneAssetsMinOrderByAggregateInput>;
  _sum?: InputMaybe<UserSceneAssetsSumOrderByAggregateInput>;
  autoPlayVideo?: InputMaybe<SortOrderInput>;
  baseColor?: InputMaybe<SortOrderInput>;
  description?: InputMaybe<SortOrderInput>;
  enableLinking?: InputMaybe<SortOrderInput>;
  enablePurchase?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isPortal?: InputMaybe<SortOrderInput>;
  lightId?: InputMaybe<SortOrderInput>;
  loopVideo?: InputMaybe<SortOrderInput>;
  marketId?: InputMaybe<SortOrderInput>;
  metadata?: InputMaybe<SortOrderInput>;
  metalness?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrderInput>;
  portalSceneId?: InputMaybe<SortOrderInput>;
  position?: InputMaybe<SortOrder>;
  rotation?: InputMaybe<SortOrder>;
  roughness?: InputMaybe<SortOrderInput>;
  scale?: InputMaybe<SortOrder>;
  shopifyMetadata?: InputMaybe<SortOrderInput>;
  textureToChange?: InputMaybe<SortOrderInput>;
  textureToChangeMetalness?: InputMaybe<SortOrderInput>;
  textureToChangeNormal?: InputMaybe<SortOrderInput>;
  textureToChangeRoughness?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrderInput>;
  userId?: InputMaybe<SortOrder>;
  userSceneId?: InputMaybe<SortOrder>;
};

export type UserSceneAssetsOrderByWithRelationInput = {
  autoPlayVideo?: InputMaybe<SortOrderInput>;
  baseColor?: InputMaybe<SortOrderInput>;
  description?: InputMaybe<SortOrderInput>;
  enableLinking?: InputMaybe<SortOrderInput>;
  enablePurchase?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isPortal?: InputMaybe<SortOrderInput>;
  light?: InputMaybe<LightPropsOrderByWithRelationInput>;
  lightId?: InputMaybe<SortOrderInput>;
  loopVideo?: InputMaybe<SortOrderInput>;
  marketId?: InputMaybe<SortOrderInput>;
  metadata?: InputMaybe<SortOrderInput>;
  metalness?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrderInput>;
  portalSceneId?: InputMaybe<SortOrderInput>;
  position?: InputMaybe<SortOrder>;
  rotation?: InputMaybe<SortOrder>;
  roughness?: InputMaybe<SortOrderInput>;
  scale?: InputMaybe<SortOrder>;
  shopifyMetadata?: InputMaybe<SortOrderInput>;
  textureToChange?: InputMaybe<SortOrderInput>;
  textureToChangeMetalness?: InputMaybe<SortOrderInput>;
  textureToChangeNormal?: InputMaybe<SortOrderInput>;
  textureToChangeRoughness?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrderInput>;
  userId?: InputMaybe<SortOrder>;
  userScene?: InputMaybe<UserSceneOrderByWithRelationInput>;
  userSceneId?: InputMaybe<SortOrder>;
};

export enum UserSceneAssetsScalarFieldEnum {
  AutoPlayVideo = 'autoPlayVideo',
  BaseColor = 'baseColor',
  Description = 'description',
  EnableLinking = 'enableLinking',
  EnablePurchase = 'enablePurchase',
  Id = 'id',
  IsPortal = 'isPortal',
  LightId = 'lightId',
  LoopVideo = 'loopVideo',
  MarketId = 'marketId',
  Metadata = 'metadata',
  Metalness = 'metalness',
  Name = 'name',
  PortalSceneId = 'portalSceneId',
  Position = 'position',
  Rotation = 'rotation',
  Roughness = 'roughness',
  Scale = 'scale',
  ShopifyMetadata = 'shopifyMetadata',
  TextureToChange = 'textureToChange',
  TextureToChangeMetalness = 'textureToChangeMetalness',
  TextureToChangeNormal = 'textureToChangeNormal',
  TextureToChangeRoughness = 'textureToChangeRoughness',
  Type = 'type',
  Url = 'url',
  UserId = 'userId',
  UserSceneId = 'userSceneId'
}

export type UserSceneAssetsScalarWhereInput = {
  AND?: InputMaybe<Array<UserSceneAssetsScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserSceneAssetsScalarWhereInput>>;
  OR?: InputMaybe<Array<UserSceneAssetsScalarWhereInput>>;
  autoPlayVideo?: InputMaybe<BoolNullableFilter>;
  baseColor?: InputMaybe<IntNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  enableLinking?: InputMaybe<BoolNullableFilter>;
  enablePurchase?: InputMaybe<BoolNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  isPortal?: InputMaybe<BoolNullableFilter>;
  lightId?: InputMaybe<UuidNullableFilter>;
  loopVideo?: InputMaybe<BoolNullableFilter>;
  marketId?: InputMaybe<IntNullableFilter>;
  metadata?: InputMaybe<StringNullableFilter>;
  metalness?: InputMaybe<FloatNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  portalSceneId?: InputMaybe<StringNullableFilter>;
  position?: InputMaybe<FloatNullableListFilter>;
  rotation?: InputMaybe<FloatNullableListFilter>;
  roughness?: InputMaybe<FloatNullableFilter>;
  scale?: InputMaybe<FloatNullableListFilter>;
  shopifyMetadata?: InputMaybe<StringNullableFilter>;
  textureToChange?: InputMaybe<StringNullableFilter>;
  textureToChangeMetalness?: InputMaybe<StringNullableFilter>;
  textureToChangeNormal?: InputMaybe<StringNullableFilter>;
  textureToChangeRoughness?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringNullableFilter>;
  userId?: InputMaybe<UuidFilter>;
  userSceneId?: InputMaybe<UuidFilter>;
};

export type UserSceneAssetsScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserSceneAssetsScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserSceneAssetsScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserSceneAssetsScalarWhereWithAggregatesInput>>;
  autoPlayVideo?: InputMaybe<BoolNullableWithAggregatesFilter>;
  baseColor?: InputMaybe<IntNullableWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  enableLinking?: InputMaybe<BoolNullableWithAggregatesFilter>;
  enablePurchase?: InputMaybe<BoolNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  isPortal?: InputMaybe<BoolNullableWithAggregatesFilter>;
  lightId?: InputMaybe<UuidNullableWithAggregatesFilter>;
  loopVideo?: InputMaybe<BoolNullableWithAggregatesFilter>;
  marketId?: InputMaybe<IntNullableWithAggregatesFilter>;
  metadata?: InputMaybe<StringNullableWithAggregatesFilter>;
  metalness?: InputMaybe<FloatNullableWithAggregatesFilter>;
  name?: InputMaybe<StringNullableWithAggregatesFilter>;
  portalSceneId?: InputMaybe<StringNullableWithAggregatesFilter>;
  position?: InputMaybe<FloatNullableListFilter>;
  rotation?: InputMaybe<FloatNullableListFilter>;
  roughness?: InputMaybe<FloatNullableWithAggregatesFilter>;
  scale?: InputMaybe<FloatNullableListFilter>;
  shopifyMetadata?: InputMaybe<StringNullableWithAggregatesFilter>;
  textureToChange?: InputMaybe<StringNullableWithAggregatesFilter>;
  textureToChangeMetalness?: InputMaybe<StringNullableWithAggregatesFilter>;
  textureToChangeNormal?: InputMaybe<StringNullableWithAggregatesFilter>;
  textureToChangeRoughness?: InputMaybe<StringNullableWithAggregatesFilter>;
  type?: InputMaybe<StringWithAggregatesFilter>;
  url?: InputMaybe<StringNullableWithAggregatesFilter>;
  userId?: InputMaybe<UuidWithAggregatesFilter>;
  userSceneId?: InputMaybe<UuidWithAggregatesFilter>;
};

export type UserSceneAssetsSumAggregate = {
  __typename?: 'UserSceneAssetsSumAggregate';
  baseColor?: Maybe<Scalars['Int']['output']>;
  marketId?: Maybe<Scalars['Int']['output']>;
  metalness?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Array<Scalars['Float']['output']>>;
  rotation?: Maybe<Array<Scalars['Float']['output']>>;
  roughness?: Maybe<Scalars['Float']['output']>;
  scale?: Maybe<Array<Scalars['Float']['output']>>;
};

export type UserSceneAssetsSumOrderByAggregateInput = {
  baseColor?: InputMaybe<SortOrder>;
  marketId?: InputMaybe<SortOrder>;
  metalness?: InputMaybe<SortOrder>;
  position?: InputMaybe<SortOrder>;
  rotation?: InputMaybe<SortOrder>;
  roughness?: InputMaybe<SortOrder>;
  scale?: InputMaybe<SortOrder>;
};

export type UserSceneAssetsUpdateInput = {
  autoPlayVideo?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  baseColor?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enableLinking?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enablePurchase?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPortal?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  light?: InputMaybe<LightPropsUpdateOneWithoutAssetsNestedInput>;
  loopVideo?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  marketId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  metadata?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  metalness?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  portalSceneId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  position?: InputMaybe<UserSceneAssetsUpdatepositionInput>;
  rotation?: InputMaybe<UserSceneAssetsUpdaterotationInput>;
  roughness?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  scale?: InputMaybe<UserSceneAssetsUpdatescaleInput>;
  shopifyMetadata?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  textureToChange?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  textureToChangeMetalness?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  textureToChangeNormal?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  textureToChangeRoughness?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  userScene?: InputMaybe<UserSceneUpdateOneRequiredWithoutAssetsNestedInput>;
};

export type UserSceneAssetsUpdateManyMutationInput = {
  autoPlayVideo?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  baseColor?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enableLinking?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enablePurchase?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPortal?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  loopVideo?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  marketId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  metadata?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  metalness?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  portalSceneId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  position?: InputMaybe<UserSceneAssetsUpdatepositionInput>;
  rotation?: InputMaybe<UserSceneAssetsUpdaterotationInput>;
  roughness?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  scale?: InputMaybe<UserSceneAssetsUpdatescaleInput>;
  shopifyMetadata?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  textureToChange?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  textureToChangeMetalness?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  textureToChangeNormal?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  textureToChangeRoughness?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserSceneAssetsUpdateManyWithWhereWithoutLightInput = {
  data: UserSceneAssetsUpdateManyMutationInput;
  where: UserSceneAssetsScalarWhereInput;
};

export type UserSceneAssetsUpdateManyWithWhereWithoutUserSceneInput = {
  data: UserSceneAssetsUpdateManyMutationInput;
  where: UserSceneAssetsScalarWhereInput;
};

export type UserSceneAssetsUpdateManyWithoutLightNestedInput = {
  connect?: InputMaybe<Array<UserSceneAssetsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserSceneAssetsCreateOrConnectWithoutLightInput>>;
  create?: InputMaybe<Array<UserSceneAssetsCreateWithoutLightInput>>;
  createMany?: InputMaybe<UserSceneAssetsCreateManyLightInputEnvelope>;
  delete?: InputMaybe<Array<UserSceneAssetsWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserSceneAssetsScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserSceneAssetsWhereUniqueInput>>;
  set?: InputMaybe<Array<UserSceneAssetsWhereUniqueInput>>;
  update?: InputMaybe<Array<UserSceneAssetsUpdateWithWhereUniqueWithoutLightInput>>;
  updateMany?: InputMaybe<Array<UserSceneAssetsUpdateManyWithWhereWithoutLightInput>>;
  upsert?: InputMaybe<Array<UserSceneAssetsUpsertWithWhereUniqueWithoutLightInput>>;
};

export type UserSceneAssetsUpdateManyWithoutUserSceneNestedInput = {
  connect?: InputMaybe<Array<UserSceneAssetsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserSceneAssetsCreateOrConnectWithoutUserSceneInput>>;
  create?: InputMaybe<Array<UserSceneAssetsCreateWithoutUserSceneInput>>;
  createMany?: InputMaybe<UserSceneAssetsCreateManyUserSceneInputEnvelope>;
  delete?: InputMaybe<Array<UserSceneAssetsWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserSceneAssetsScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserSceneAssetsWhereUniqueInput>>;
  set?: InputMaybe<Array<UserSceneAssetsWhereUniqueInput>>;
  update?: InputMaybe<Array<UserSceneAssetsUpdateWithWhereUniqueWithoutUserSceneInput>>;
  updateMany?: InputMaybe<Array<UserSceneAssetsUpdateManyWithWhereWithoutUserSceneInput>>;
  upsert?: InputMaybe<Array<UserSceneAssetsUpsertWithWhereUniqueWithoutUserSceneInput>>;
};

export type UserSceneAssetsUpdateWithWhereUniqueWithoutLightInput = {
  data: UserSceneAssetsUpdateWithoutLightInput;
  where: UserSceneAssetsWhereUniqueInput;
};

export type UserSceneAssetsUpdateWithWhereUniqueWithoutUserSceneInput = {
  data: UserSceneAssetsUpdateWithoutUserSceneInput;
  where: UserSceneAssetsWhereUniqueInput;
};

export type UserSceneAssetsUpdateWithoutLightInput = {
  autoPlayVideo?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  baseColor?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enableLinking?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enablePurchase?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPortal?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  loopVideo?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  marketId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  metadata?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  metalness?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  portalSceneId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  position?: InputMaybe<UserSceneAssetsUpdatepositionInput>;
  rotation?: InputMaybe<UserSceneAssetsUpdaterotationInput>;
  roughness?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  scale?: InputMaybe<UserSceneAssetsUpdatescaleInput>;
  shopifyMetadata?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  textureToChange?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  textureToChangeMetalness?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  textureToChangeNormal?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  textureToChangeRoughness?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  userScene?: InputMaybe<UserSceneUpdateOneRequiredWithoutAssetsNestedInput>;
};

export type UserSceneAssetsUpdateWithoutUserSceneInput = {
  autoPlayVideo?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  baseColor?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enableLinking?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  enablePurchase?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isPortal?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  light?: InputMaybe<LightPropsUpdateOneWithoutAssetsNestedInput>;
  loopVideo?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  marketId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  metadata?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  metalness?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  portalSceneId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  position?: InputMaybe<UserSceneAssetsUpdatepositionInput>;
  rotation?: InputMaybe<UserSceneAssetsUpdaterotationInput>;
  roughness?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  scale?: InputMaybe<UserSceneAssetsUpdatescaleInput>;
  shopifyMetadata?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  textureToChange?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  textureToChangeMetalness?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  textureToChangeNormal?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  textureToChangeRoughness?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserSceneAssetsUpdatepositionInput = {
  push?: InputMaybe<Array<Scalars['Float']['input']>>;
  set?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type UserSceneAssetsUpdaterotationInput = {
  push?: InputMaybe<Array<Scalars['Float']['input']>>;
  set?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type UserSceneAssetsUpdatescaleInput = {
  push?: InputMaybe<Array<Scalars['Float']['input']>>;
  set?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type UserSceneAssetsUpsertWithWhereUniqueWithoutLightInput = {
  create: UserSceneAssetsCreateWithoutLightInput;
  update: UserSceneAssetsUpdateWithoutLightInput;
  where: UserSceneAssetsWhereUniqueInput;
};

export type UserSceneAssetsUpsertWithWhereUniqueWithoutUserSceneInput = {
  create: UserSceneAssetsCreateWithoutUserSceneInput;
  update: UserSceneAssetsUpdateWithoutUserSceneInput;
  where: UserSceneAssetsWhereUniqueInput;
};

export type UserSceneAssetsWhereInput = {
  AND?: InputMaybe<Array<UserSceneAssetsWhereInput>>;
  NOT?: InputMaybe<Array<UserSceneAssetsWhereInput>>;
  OR?: InputMaybe<Array<UserSceneAssetsWhereInput>>;
  autoPlayVideo?: InputMaybe<BoolNullableFilter>;
  baseColor?: InputMaybe<IntNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  enableLinking?: InputMaybe<BoolNullableFilter>;
  enablePurchase?: InputMaybe<BoolNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  isPortal?: InputMaybe<BoolNullableFilter>;
  light?: InputMaybe<LightPropsNullableRelationFilter>;
  lightId?: InputMaybe<UuidNullableFilter>;
  loopVideo?: InputMaybe<BoolNullableFilter>;
  marketId?: InputMaybe<IntNullableFilter>;
  metadata?: InputMaybe<StringNullableFilter>;
  metalness?: InputMaybe<FloatNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  portalSceneId?: InputMaybe<StringNullableFilter>;
  position?: InputMaybe<FloatNullableListFilter>;
  rotation?: InputMaybe<FloatNullableListFilter>;
  roughness?: InputMaybe<FloatNullableFilter>;
  scale?: InputMaybe<FloatNullableListFilter>;
  shopifyMetadata?: InputMaybe<StringNullableFilter>;
  textureToChange?: InputMaybe<StringNullableFilter>;
  textureToChangeMetalness?: InputMaybe<StringNullableFilter>;
  textureToChangeNormal?: InputMaybe<StringNullableFilter>;
  textureToChangeRoughness?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringNullableFilter>;
  userId?: InputMaybe<UuidFilter>;
  userScene?: InputMaybe<UserSceneRelationFilter>;
  userSceneId?: InputMaybe<UuidFilter>;
};

export type UserSceneAssetsWhereUniqueInput = {
  AND?: InputMaybe<Array<UserSceneAssetsWhereInput>>;
  NOT?: InputMaybe<Array<UserSceneAssetsWhereInput>>;
  OR?: InputMaybe<Array<UserSceneAssetsWhereInput>>;
  autoPlayVideo?: InputMaybe<BoolNullableFilter>;
  baseColor?: InputMaybe<IntNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  enableLinking?: InputMaybe<BoolNullableFilter>;
  enablePurchase?: InputMaybe<BoolNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPortal?: InputMaybe<BoolNullableFilter>;
  light?: InputMaybe<LightPropsNullableRelationFilter>;
  lightId?: InputMaybe<UuidNullableFilter>;
  loopVideo?: InputMaybe<BoolNullableFilter>;
  marketId?: InputMaybe<IntNullableFilter>;
  metadata?: InputMaybe<StringNullableFilter>;
  metalness?: InputMaybe<FloatNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  portalSceneId?: InputMaybe<StringNullableFilter>;
  position?: InputMaybe<FloatNullableListFilter>;
  rotation?: InputMaybe<FloatNullableListFilter>;
  roughness?: InputMaybe<FloatNullableFilter>;
  scale?: InputMaybe<FloatNullableListFilter>;
  shopifyMetadata?: InputMaybe<StringNullableFilter>;
  textureToChange?: InputMaybe<StringNullableFilter>;
  textureToChangeMetalness?: InputMaybe<StringNullableFilter>;
  textureToChangeNormal?: InputMaybe<StringNullableFilter>;
  textureToChangeRoughness?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringNullableFilter>;
  userId?: InputMaybe<UuidFilter>;
  userScene?: InputMaybe<UserSceneRelationFilter>;
  userSceneId?: InputMaybe<UuidFilter>;
};

export type UserSceneAvgAggregate = {
  __typename?: 'UserSceneAvgAggregate';
  lightTemplate?: Maybe<Scalars['Float']['output']>;
  skybox?: Maybe<Scalars['Float']['output']>;
};

export type UserSceneAvgOrderByAggregateInput = {
  lightTemplate?: InputMaybe<SortOrder>;
  skybox?: InputMaybe<SortOrder>;
};

export type UserSceneCount = {
  __typename?: 'UserSceneCount';
  assets: Scalars['Int']['output'];
  domain: Scalars['Int']['output'];
  shopifyStore: Scalars['Int']['output'];
  tourItems: Scalars['Int']['output'];
};


export type UserSceneCountAssetsArgs = {
  where?: InputMaybe<UserSceneAssetsWhereInput>;
};


export type UserSceneCountDomainArgs = {
  where?: InputMaybe<DomainSceneWhereInput>;
};


export type UserSceneCountShopifyStoreArgs = {
  where?: InputMaybe<ShopifyStoreWhereInput>;
};


export type UserSceneCountTourItemsArgs = {
  where?: InputMaybe<TourItemsWhereInput>;
};

export type UserSceneCountAggregate = {
  __typename?: 'UserSceneCountAggregate';
  _all: Scalars['Int']['output'];
  alias: Scalars['Int']['output'];
  branding: Scalars['Int']['output'];
  cameraConfigId: Scalars['Int']['output'];
  chatConversationSID: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  gameMode: Scalars['Int']['output'];
  graphicsSettingsId: Scalars['Int']['output'];
  hasHideAndSeek: Scalars['Int']['output'];
  hasQuest: Scalars['Int']['output'];
  hideAndSeekId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isCameraThirdPerson: Scalars['Int']['output'];
  isChatEnabled: Scalars['Int']['output'];
  isScanEnabled: Scalars['Int']['output'];
  lightTemplate: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  questId: Scalars['Int']['output'];
  skybox: Scalars['Int']['output'];
  thumbnail: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type UserSceneCountOrderByAggregateInput = {
  alias?: InputMaybe<SortOrder>;
  branding?: InputMaybe<SortOrder>;
  cameraConfigId?: InputMaybe<SortOrder>;
  chatConversationSID?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  gameMode?: InputMaybe<SortOrder>;
  graphicsSettingsId?: InputMaybe<SortOrder>;
  hasHideAndSeek?: InputMaybe<SortOrder>;
  hasQuest?: InputMaybe<SortOrder>;
  hideAndSeekId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCameraThirdPerson?: InputMaybe<SortOrder>;
  isChatEnabled?: InputMaybe<SortOrder>;
  isScanEnabled?: InputMaybe<SortOrder>;
  lightTemplate?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  questId?: InputMaybe<SortOrder>;
  skybox?: InputMaybe<SortOrder>;
  thumbnail?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserSceneCreateInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  assets?: InputMaybe<UserSceneAssetsCreateNestedManyWithoutUserSceneInput>;
  branding?: InputMaybe<Brand>;
  cameraConfig?: InputMaybe<CameraConfigCreateNestedOneWithoutUserSceneInput>;
  chatConversationSID?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  domain?: InputMaybe<DomainSceneCreateNestedManyWithoutSceneInput>;
  gameMode?: InputMaybe<GameType>;
  graphicsSettings?: InputMaybe<GraphicsSettingsCreateNestedOneWithoutUserSceneInput>;
  hasHideAndSeek?: InputMaybe<Scalars['Boolean']['input']>;
  hasQuest?: InputMaybe<Scalars['Boolean']['input']>;
  hideAndSeek?: InputMaybe<HideAndSeekCreateNestedOneWithoutUserSceneInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCameraThirdPerson?: InputMaybe<Scalars['Boolean']['input']>;
  isChatEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isScanEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  lightTemplate?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  sceneQuest?: InputMaybe<QuestCreateNestedOneWithoutUserSceneInput>;
  shopifyStore?: InputMaybe<ShopifyStoreCreateNestedManyWithoutUserSceneInput>;
  skybox?: InputMaybe<Scalars['Int']['input']>;
  thumbnail: Scalars['String']['input'];
  tourItems?: InputMaybe<TourItemsCreateNestedManyWithoutUserSceneInput>;
  userId: Scalars['String']['input'];
};

export type UserSceneCreateManyCameraConfigInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  branding?: InputMaybe<Brand>;
  chatConversationSID?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  gameMode?: InputMaybe<GameType>;
  graphicsSettingsId?: InputMaybe<Scalars['String']['input']>;
  hasHideAndSeek?: InputMaybe<Scalars['Boolean']['input']>;
  hasQuest?: InputMaybe<Scalars['Boolean']['input']>;
  hideAndSeekId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCameraThirdPerson?: InputMaybe<Scalars['Boolean']['input']>;
  isChatEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isScanEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  lightTemplate?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  questId?: InputMaybe<Scalars['String']['input']>;
  skybox?: InputMaybe<Scalars['Int']['input']>;
  thumbnail: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UserSceneCreateManyCameraConfigInputEnvelope = {
  data: Array<UserSceneCreateManyCameraConfigInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserSceneCreateManyGraphicsSettingsInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  branding?: InputMaybe<Brand>;
  cameraConfigId?: InputMaybe<Scalars['String']['input']>;
  chatConversationSID?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  gameMode?: InputMaybe<GameType>;
  hasHideAndSeek?: InputMaybe<Scalars['Boolean']['input']>;
  hasQuest?: InputMaybe<Scalars['Boolean']['input']>;
  hideAndSeekId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCameraThirdPerson?: InputMaybe<Scalars['Boolean']['input']>;
  isChatEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isScanEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  lightTemplate?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  questId?: InputMaybe<Scalars['String']['input']>;
  skybox?: InputMaybe<Scalars['Int']['input']>;
  thumbnail: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UserSceneCreateManyGraphicsSettingsInputEnvelope = {
  data: Array<UserSceneCreateManyGraphicsSettingsInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserSceneCreateManyHideAndSeekInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  branding?: InputMaybe<Brand>;
  cameraConfigId?: InputMaybe<Scalars['String']['input']>;
  chatConversationSID?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  gameMode?: InputMaybe<GameType>;
  graphicsSettingsId?: InputMaybe<Scalars['String']['input']>;
  hasHideAndSeek?: InputMaybe<Scalars['Boolean']['input']>;
  hasQuest?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCameraThirdPerson?: InputMaybe<Scalars['Boolean']['input']>;
  isChatEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isScanEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  lightTemplate?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  questId?: InputMaybe<Scalars['String']['input']>;
  skybox?: InputMaybe<Scalars['Int']['input']>;
  thumbnail: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UserSceneCreateManyHideAndSeekInputEnvelope = {
  data: Array<UserSceneCreateManyHideAndSeekInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserSceneCreateManyInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  branding?: InputMaybe<Brand>;
  cameraConfigId?: InputMaybe<Scalars['String']['input']>;
  chatConversationSID?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  gameMode?: InputMaybe<GameType>;
  graphicsSettingsId?: InputMaybe<Scalars['String']['input']>;
  hasHideAndSeek?: InputMaybe<Scalars['Boolean']['input']>;
  hasQuest?: InputMaybe<Scalars['Boolean']['input']>;
  hideAndSeekId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCameraThirdPerson?: InputMaybe<Scalars['Boolean']['input']>;
  isChatEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isScanEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  lightTemplate?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  questId?: InputMaybe<Scalars['String']['input']>;
  skybox?: InputMaybe<Scalars['Int']['input']>;
  thumbnail: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UserSceneCreateManySceneQuestInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  branding?: InputMaybe<Brand>;
  cameraConfigId?: InputMaybe<Scalars['String']['input']>;
  chatConversationSID?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  gameMode?: InputMaybe<GameType>;
  graphicsSettingsId?: InputMaybe<Scalars['String']['input']>;
  hasHideAndSeek?: InputMaybe<Scalars['Boolean']['input']>;
  hasQuest?: InputMaybe<Scalars['Boolean']['input']>;
  hideAndSeekId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCameraThirdPerson?: InputMaybe<Scalars['Boolean']['input']>;
  isChatEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isScanEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  lightTemplate?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  skybox?: InputMaybe<Scalars['Int']['input']>;
  thumbnail: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UserSceneCreateManySceneQuestInputEnvelope = {
  data: Array<UserSceneCreateManySceneQuestInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserSceneCreateNestedManyWithoutCameraConfigInput = {
  connect?: InputMaybe<Array<UserSceneWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserSceneCreateOrConnectWithoutCameraConfigInput>>;
  create?: InputMaybe<Array<UserSceneCreateWithoutCameraConfigInput>>;
  createMany?: InputMaybe<UserSceneCreateManyCameraConfigInputEnvelope>;
};

export type UserSceneCreateNestedManyWithoutGraphicsSettingsInput = {
  connect?: InputMaybe<Array<UserSceneWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserSceneCreateOrConnectWithoutGraphicsSettingsInput>>;
  create?: InputMaybe<Array<UserSceneCreateWithoutGraphicsSettingsInput>>;
  createMany?: InputMaybe<UserSceneCreateManyGraphicsSettingsInputEnvelope>;
};

export type UserSceneCreateNestedManyWithoutHideAndSeekInput = {
  connect?: InputMaybe<Array<UserSceneWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserSceneCreateOrConnectWithoutHideAndSeekInput>>;
  create?: InputMaybe<Array<UserSceneCreateWithoutHideAndSeekInput>>;
  createMany?: InputMaybe<UserSceneCreateManyHideAndSeekInputEnvelope>;
};

export type UserSceneCreateNestedManyWithoutSceneQuestInput = {
  connect?: InputMaybe<Array<UserSceneWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserSceneCreateOrConnectWithoutSceneQuestInput>>;
  create?: InputMaybe<Array<UserSceneCreateWithoutSceneQuestInput>>;
  createMany?: InputMaybe<UserSceneCreateManySceneQuestInputEnvelope>;
};

export type UserSceneCreateNestedOneWithoutAssetsInput = {
  connect?: InputMaybe<UserSceneWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserSceneCreateOrConnectWithoutAssetsInput>;
  create?: InputMaybe<UserSceneCreateWithoutAssetsInput>;
};

export type UserSceneCreateNestedOneWithoutDomainInput = {
  connect?: InputMaybe<UserSceneWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserSceneCreateOrConnectWithoutDomainInput>;
  create?: InputMaybe<UserSceneCreateWithoutDomainInput>;
};

export type UserSceneCreateNestedOneWithoutShopifyStoreInput = {
  connect?: InputMaybe<UserSceneWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserSceneCreateOrConnectWithoutShopifyStoreInput>;
  create?: InputMaybe<UserSceneCreateWithoutShopifyStoreInput>;
};

export type UserSceneCreateNestedOneWithoutTourItemsInput = {
  connect?: InputMaybe<UserSceneWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserSceneCreateOrConnectWithoutTourItemsInput>;
  create?: InputMaybe<UserSceneCreateWithoutTourItemsInput>;
};

export type UserSceneCreateOrConnectWithoutAssetsInput = {
  create: UserSceneCreateWithoutAssetsInput;
  where: UserSceneWhereUniqueInput;
};

export type UserSceneCreateOrConnectWithoutCameraConfigInput = {
  create: UserSceneCreateWithoutCameraConfigInput;
  where: UserSceneWhereUniqueInput;
};

export type UserSceneCreateOrConnectWithoutDomainInput = {
  create: UserSceneCreateWithoutDomainInput;
  where: UserSceneWhereUniqueInput;
};

export type UserSceneCreateOrConnectWithoutGraphicsSettingsInput = {
  create: UserSceneCreateWithoutGraphicsSettingsInput;
  where: UserSceneWhereUniqueInput;
};

export type UserSceneCreateOrConnectWithoutHideAndSeekInput = {
  create: UserSceneCreateWithoutHideAndSeekInput;
  where: UserSceneWhereUniqueInput;
};

export type UserSceneCreateOrConnectWithoutSceneQuestInput = {
  create: UserSceneCreateWithoutSceneQuestInput;
  where: UserSceneWhereUniqueInput;
};

export type UserSceneCreateOrConnectWithoutShopifyStoreInput = {
  create: UserSceneCreateWithoutShopifyStoreInput;
  where: UserSceneWhereUniqueInput;
};

export type UserSceneCreateOrConnectWithoutTourItemsInput = {
  create: UserSceneCreateWithoutTourItemsInput;
  where: UserSceneWhereUniqueInput;
};

export type UserSceneCreateWithoutAssetsInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  branding?: InputMaybe<Brand>;
  cameraConfig?: InputMaybe<CameraConfigCreateNestedOneWithoutUserSceneInput>;
  chatConversationSID?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  domain?: InputMaybe<DomainSceneCreateNestedManyWithoutSceneInput>;
  gameMode?: InputMaybe<GameType>;
  graphicsSettings?: InputMaybe<GraphicsSettingsCreateNestedOneWithoutUserSceneInput>;
  hasHideAndSeek?: InputMaybe<Scalars['Boolean']['input']>;
  hasQuest?: InputMaybe<Scalars['Boolean']['input']>;
  hideAndSeek?: InputMaybe<HideAndSeekCreateNestedOneWithoutUserSceneInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCameraThirdPerson?: InputMaybe<Scalars['Boolean']['input']>;
  isChatEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isScanEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  lightTemplate?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  sceneQuest?: InputMaybe<QuestCreateNestedOneWithoutUserSceneInput>;
  shopifyStore?: InputMaybe<ShopifyStoreCreateNestedManyWithoutUserSceneInput>;
  skybox?: InputMaybe<Scalars['Int']['input']>;
  thumbnail: Scalars['String']['input'];
  tourItems?: InputMaybe<TourItemsCreateNestedManyWithoutUserSceneInput>;
  userId: Scalars['String']['input'];
};

export type UserSceneCreateWithoutCameraConfigInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  assets?: InputMaybe<UserSceneAssetsCreateNestedManyWithoutUserSceneInput>;
  branding?: InputMaybe<Brand>;
  chatConversationSID?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  domain?: InputMaybe<DomainSceneCreateNestedManyWithoutSceneInput>;
  gameMode?: InputMaybe<GameType>;
  graphicsSettings?: InputMaybe<GraphicsSettingsCreateNestedOneWithoutUserSceneInput>;
  hasHideAndSeek?: InputMaybe<Scalars['Boolean']['input']>;
  hasQuest?: InputMaybe<Scalars['Boolean']['input']>;
  hideAndSeek?: InputMaybe<HideAndSeekCreateNestedOneWithoutUserSceneInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCameraThirdPerson?: InputMaybe<Scalars['Boolean']['input']>;
  isChatEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isScanEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  lightTemplate?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  sceneQuest?: InputMaybe<QuestCreateNestedOneWithoutUserSceneInput>;
  shopifyStore?: InputMaybe<ShopifyStoreCreateNestedManyWithoutUserSceneInput>;
  skybox?: InputMaybe<Scalars['Int']['input']>;
  thumbnail: Scalars['String']['input'];
  tourItems?: InputMaybe<TourItemsCreateNestedManyWithoutUserSceneInput>;
  userId: Scalars['String']['input'];
};

export type UserSceneCreateWithoutDomainInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  assets?: InputMaybe<UserSceneAssetsCreateNestedManyWithoutUserSceneInput>;
  branding?: InputMaybe<Brand>;
  cameraConfig?: InputMaybe<CameraConfigCreateNestedOneWithoutUserSceneInput>;
  chatConversationSID?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  gameMode?: InputMaybe<GameType>;
  graphicsSettings?: InputMaybe<GraphicsSettingsCreateNestedOneWithoutUserSceneInput>;
  hasHideAndSeek?: InputMaybe<Scalars['Boolean']['input']>;
  hasQuest?: InputMaybe<Scalars['Boolean']['input']>;
  hideAndSeek?: InputMaybe<HideAndSeekCreateNestedOneWithoutUserSceneInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCameraThirdPerson?: InputMaybe<Scalars['Boolean']['input']>;
  isChatEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isScanEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  lightTemplate?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  sceneQuest?: InputMaybe<QuestCreateNestedOneWithoutUserSceneInput>;
  shopifyStore?: InputMaybe<ShopifyStoreCreateNestedManyWithoutUserSceneInput>;
  skybox?: InputMaybe<Scalars['Int']['input']>;
  thumbnail: Scalars['String']['input'];
  tourItems?: InputMaybe<TourItemsCreateNestedManyWithoutUserSceneInput>;
  userId: Scalars['String']['input'];
};

export type UserSceneCreateWithoutGraphicsSettingsInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  assets?: InputMaybe<UserSceneAssetsCreateNestedManyWithoutUserSceneInput>;
  branding?: InputMaybe<Brand>;
  cameraConfig?: InputMaybe<CameraConfigCreateNestedOneWithoutUserSceneInput>;
  chatConversationSID?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  domain?: InputMaybe<DomainSceneCreateNestedManyWithoutSceneInput>;
  gameMode?: InputMaybe<GameType>;
  hasHideAndSeek?: InputMaybe<Scalars['Boolean']['input']>;
  hasQuest?: InputMaybe<Scalars['Boolean']['input']>;
  hideAndSeek?: InputMaybe<HideAndSeekCreateNestedOneWithoutUserSceneInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCameraThirdPerson?: InputMaybe<Scalars['Boolean']['input']>;
  isChatEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isScanEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  lightTemplate?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  sceneQuest?: InputMaybe<QuestCreateNestedOneWithoutUserSceneInput>;
  shopifyStore?: InputMaybe<ShopifyStoreCreateNestedManyWithoutUserSceneInput>;
  skybox?: InputMaybe<Scalars['Int']['input']>;
  thumbnail: Scalars['String']['input'];
  tourItems?: InputMaybe<TourItemsCreateNestedManyWithoutUserSceneInput>;
  userId: Scalars['String']['input'];
};

export type UserSceneCreateWithoutHideAndSeekInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  assets?: InputMaybe<UserSceneAssetsCreateNestedManyWithoutUserSceneInput>;
  branding?: InputMaybe<Brand>;
  cameraConfig?: InputMaybe<CameraConfigCreateNestedOneWithoutUserSceneInput>;
  chatConversationSID?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  domain?: InputMaybe<DomainSceneCreateNestedManyWithoutSceneInput>;
  gameMode?: InputMaybe<GameType>;
  graphicsSettings?: InputMaybe<GraphicsSettingsCreateNestedOneWithoutUserSceneInput>;
  hasHideAndSeek?: InputMaybe<Scalars['Boolean']['input']>;
  hasQuest?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCameraThirdPerson?: InputMaybe<Scalars['Boolean']['input']>;
  isChatEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isScanEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  lightTemplate?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  sceneQuest?: InputMaybe<QuestCreateNestedOneWithoutUserSceneInput>;
  shopifyStore?: InputMaybe<ShopifyStoreCreateNestedManyWithoutUserSceneInput>;
  skybox?: InputMaybe<Scalars['Int']['input']>;
  thumbnail: Scalars['String']['input'];
  tourItems?: InputMaybe<TourItemsCreateNestedManyWithoutUserSceneInput>;
  userId: Scalars['String']['input'];
};

export type UserSceneCreateWithoutSceneQuestInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  assets?: InputMaybe<UserSceneAssetsCreateNestedManyWithoutUserSceneInput>;
  branding?: InputMaybe<Brand>;
  cameraConfig?: InputMaybe<CameraConfigCreateNestedOneWithoutUserSceneInput>;
  chatConversationSID?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  domain?: InputMaybe<DomainSceneCreateNestedManyWithoutSceneInput>;
  gameMode?: InputMaybe<GameType>;
  graphicsSettings?: InputMaybe<GraphicsSettingsCreateNestedOneWithoutUserSceneInput>;
  hasHideAndSeek?: InputMaybe<Scalars['Boolean']['input']>;
  hasQuest?: InputMaybe<Scalars['Boolean']['input']>;
  hideAndSeek?: InputMaybe<HideAndSeekCreateNestedOneWithoutUserSceneInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCameraThirdPerson?: InputMaybe<Scalars['Boolean']['input']>;
  isChatEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isScanEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  lightTemplate?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  shopifyStore?: InputMaybe<ShopifyStoreCreateNestedManyWithoutUserSceneInput>;
  skybox?: InputMaybe<Scalars['Int']['input']>;
  thumbnail: Scalars['String']['input'];
  tourItems?: InputMaybe<TourItemsCreateNestedManyWithoutUserSceneInput>;
  userId: Scalars['String']['input'];
};

export type UserSceneCreateWithoutShopifyStoreInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  assets?: InputMaybe<UserSceneAssetsCreateNestedManyWithoutUserSceneInput>;
  branding?: InputMaybe<Brand>;
  cameraConfig?: InputMaybe<CameraConfigCreateNestedOneWithoutUserSceneInput>;
  chatConversationSID?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  domain?: InputMaybe<DomainSceneCreateNestedManyWithoutSceneInput>;
  gameMode?: InputMaybe<GameType>;
  graphicsSettings?: InputMaybe<GraphicsSettingsCreateNestedOneWithoutUserSceneInput>;
  hasHideAndSeek?: InputMaybe<Scalars['Boolean']['input']>;
  hasQuest?: InputMaybe<Scalars['Boolean']['input']>;
  hideAndSeek?: InputMaybe<HideAndSeekCreateNestedOneWithoutUserSceneInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCameraThirdPerson?: InputMaybe<Scalars['Boolean']['input']>;
  isChatEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isScanEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  lightTemplate?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  sceneQuest?: InputMaybe<QuestCreateNestedOneWithoutUserSceneInput>;
  skybox?: InputMaybe<Scalars['Int']['input']>;
  thumbnail: Scalars['String']['input'];
  tourItems?: InputMaybe<TourItemsCreateNestedManyWithoutUserSceneInput>;
  userId: Scalars['String']['input'];
};

export type UserSceneCreateWithoutTourItemsInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  assets?: InputMaybe<UserSceneAssetsCreateNestedManyWithoutUserSceneInput>;
  branding?: InputMaybe<Brand>;
  cameraConfig?: InputMaybe<CameraConfigCreateNestedOneWithoutUserSceneInput>;
  chatConversationSID?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description: Scalars['String']['input'];
  domain?: InputMaybe<DomainSceneCreateNestedManyWithoutSceneInput>;
  gameMode?: InputMaybe<GameType>;
  graphicsSettings?: InputMaybe<GraphicsSettingsCreateNestedOneWithoutUserSceneInput>;
  hasHideAndSeek?: InputMaybe<Scalars['Boolean']['input']>;
  hasQuest?: InputMaybe<Scalars['Boolean']['input']>;
  hideAndSeek?: InputMaybe<HideAndSeekCreateNestedOneWithoutUserSceneInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCameraThirdPerson?: InputMaybe<Scalars['Boolean']['input']>;
  isChatEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isScanEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  lightTemplate?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  sceneQuest?: InputMaybe<QuestCreateNestedOneWithoutUserSceneInput>;
  shopifyStore?: InputMaybe<ShopifyStoreCreateNestedManyWithoutUserSceneInput>;
  skybox?: InputMaybe<Scalars['Int']['input']>;
  thumbnail: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UserSceneGroupBy = {
  __typename?: 'UserSceneGroupBy';
  _avg?: Maybe<UserSceneAvgAggregate>;
  _count?: Maybe<UserSceneCountAggregate>;
  _max?: Maybe<UserSceneMaxAggregate>;
  _min?: Maybe<UserSceneMinAggregate>;
  _sum?: Maybe<UserSceneSumAggregate>;
  alias?: Maybe<Scalars['String']['output']>;
  branding: Brand;
  cameraConfigId?: Maybe<Scalars['String']['output']>;
  chatConversationSID?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  description: Scalars['String']['output'];
  gameMode?: Maybe<GameType>;
  graphicsSettingsId?: Maybe<Scalars['String']['output']>;
  hasHideAndSeek?: Maybe<Scalars['Boolean']['output']>;
  hasQuest?: Maybe<Scalars['Boolean']['output']>;
  hideAndSeekId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isCameraThirdPerson?: Maybe<Scalars['Boolean']['output']>;
  isChatEnabled?: Maybe<Scalars['Boolean']['output']>;
  isScanEnabled?: Maybe<Scalars['Boolean']['output']>;
  lightTemplate?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  questId?: Maybe<Scalars['String']['output']>;
  skybox?: Maybe<Scalars['Int']['output']>;
  thumbnail: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type UserSceneListRelationFilter = {
  every?: InputMaybe<UserSceneWhereInput>;
  none?: InputMaybe<UserSceneWhereInput>;
  some?: InputMaybe<UserSceneWhereInput>;
};

export type UserSceneMaxAggregate = {
  __typename?: 'UserSceneMaxAggregate';
  alias?: Maybe<Scalars['String']['output']>;
  branding?: Maybe<Brand>;
  cameraConfigId?: Maybe<Scalars['String']['output']>;
  chatConversationSID?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  gameMode?: Maybe<GameType>;
  graphicsSettingsId?: Maybe<Scalars['String']['output']>;
  hasHideAndSeek?: Maybe<Scalars['Boolean']['output']>;
  hasQuest?: Maybe<Scalars['Boolean']['output']>;
  hideAndSeekId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isCameraThirdPerson?: Maybe<Scalars['Boolean']['output']>;
  isChatEnabled?: Maybe<Scalars['Boolean']['output']>;
  isScanEnabled?: Maybe<Scalars['Boolean']['output']>;
  lightTemplate?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  questId?: Maybe<Scalars['String']['output']>;
  skybox?: Maybe<Scalars['Int']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserSceneMaxOrderByAggregateInput = {
  alias?: InputMaybe<SortOrder>;
  branding?: InputMaybe<SortOrder>;
  cameraConfigId?: InputMaybe<SortOrder>;
  chatConversationSID?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  gameMode?: InputMaybe<SortOrder>;
  graphicsSettingsId?: InputMaybe<SortOrder>;
  hasHideAndSeek?: InputMaybe<SortOrder>;
  hasQuest?: InputMaybe<SortOrder>;
  hideAndSeekId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCameraThirdPerson?: InputMaybe<SortOrder>;
  isChatEnabled?: InputMaybe<SortOrder>;
  isScanEnabled?: InputMaybe<SortOrder>;
  lightTemplate?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  questId?: InputMaybe<SortOrder>;
  skybox?: InputMaybe<SortOrder>;
  thumbnail?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserSceneMinAggregate = {
  __typename?: 'UserSceneMinAggregate';
  alias?: Maybe<Scalars['String']['output']>;
  branding?: Maybe<Brand>;
  cameraConfigId?: Maybe<Scalars['String']['output']>;
  chatConversationSID?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  gameMode?: Maybe<GameType>;
  graphicsSettingsId?: Maybe<Scalars['String']['output']>;
  hasHideAndSeek?: Maybe<Scalars['Boolean']['output']>;
  hasQuest?: Maybe<Scalars['Boolean']['output']>;
  hideAndSeekId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isCameraThirdPerson?: Maybe<Scalars['Boolean']['output']>;
  isChatEnabled?: Maybe<Scalars['Boolean']['output']>;
  isScanEnabled?: Maybe<Scalars['Boolean']['output']>;
  lightTemplate?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  questId?: Maybe<Scalars['String']['output']>;
  skybox?: Maybe<Scalars['Int']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserSceneMinOrderByAggregateInput = {
  alias?: InputMaybe<SortOrder>;
  branding?: InputMaybe<SortOrder>;
  cameraConfigId?: InputMaybe<SortOrder>;
  chatConversationSID?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  gameMode?: InputMaybe<SortOrder>;
  graphicsSettingsId?: InputMaybe<SortOrder>;
  hasHideAndSeek?: InputMaybe<SortOrder>;
  hasQuest?: InputMaybe<SortOrder>;
  hideAndSeekId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCameraThirdPerson?: InputMaybe<SortOrder>;
  isChatEnabled?: InputMaybe<SortOrder>;
  isScanEnabled?: InputMaybe<SortOrder>;
  lightTemplate?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  questId?: InputMaybe<SortOrder>;
  skybox?: InputMaybe<SortOrder>;
  thumbnail?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserSceneNullableRelationFilter = {
  is?: InputMaybe<UserSceneWhereInput>;
  isNot?: InputMaybe<UserSceneWhereInput>;
};

export type UserSceneOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserSceneOrderByWithAggregationInput = {
  _avg?: InputMaybe<UserSceneAvgOrderByAggregateInput>;
  _count?: InputMaybe<UserSceneCountOrderByAggregateInput>;
  _max?: InputMaybe<UserSceneMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserSceneMinOrderByAggregateInput>;
  _sum?: InputMaybe<UserSceneSumOrderByAggregateInput>;
  alias?: InputMaybe<SortOrderInput>;
  branding?: InputMaybe<SortOrder>;
  cameraConfigId?: InputMaybe<SortOrderInput>;
  chatConversationSID?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  gameMode?: InputMaybe<SortOrderInput>;
  graphicsSettingsId?: InputMaybe<SortOrderInput>;
  hasHideAndSeek?: InputMaybe<SortOrderInput>;
  hasQuest?: InputMaybe<SortOrderInput>;
  hideAndSeekId?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isCameraThirdPerson?: InputMaybe<SortOrderInput>;
  isChatEnabled?: InputMaybe<SortOrderInput>;
  isScanEnabled?: InputMaybe<SortOrderInput>;
  lightTemplate?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  questId?: InputMaybe<SortOrderInput>;
  skybox?: InputMaybe<SortOrderInput>;
  thumbnail?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserSceneOrderByWithRelationInput = {
  alias?: InputMaybe<SortOrderInput>;
  assets?: InputMaybe<UserSceneAssetsOrderByRelationAggregateInput>;
  branding?: InputMaybe<SortOrder>;
  cameraConfig?: InputMaybe<CameraConfigOrderByWithRelationInput>;
  cameraConfigId?: InputMaybe<SortOrderInput>;
  chatConversationSID?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  domain?: InputMaybe<DomainSceneOrderByRelationAggregateInput>;
  gameMode?: InputMaybe<SortOrderInput>;
  graphicsSettings?: InputMaybe<GraphicsSettingsOrderByWithRelationInput>;
  graphicsSettingsId?: InputMaybe<SortOrderInput>;
  hasHideAndSeek?: InputMaybe<SortOrderInput>;
  hasQuest?: InputMaybe<SortOrderInput>;
  hideAndSeek?: InputMaybe<HideAndSeekOrderByWithRelationInput>;
  hideAndSeekId?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isCameraThirdPerson?: InputMaybe<SortOrderInput>;
  isChatEnabled?: InputMaybe<SortOrderInput>;
  isScanEnabled?: InputMaybe<SortOrderInput>;
  lightTemplate?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  questId?: InputMaybe<SortOrderInput>;
  sceneQuest?: InputMaybe<QuestOrderByWithRelationInput>;
  shopifyStore?: InputMaybe<ShopifyStoreOrderByRelationAggregateInput>;
  skybox?: InputMaybe<SortOrderInput>;
  thumbnail?: InputMaybe<SortOrder>;
  tourItems?: InputMaybe<TourItemsOrderByRelationAggregateInput>;
  userId?: InputMaybe<SortOrder>;
};

export type UserSceneRelationFilter = {
  is?: InputMaybe<UserSceneWhereInput>;
  isNot?: InputMaybe<UserSceneWhereInput>;
};

export enum UserSceneScalarFieldEnum {
  Alias = 'alias',
  Branding = 'branding',
  CameraConfigId = 'cameraConfigId',
  ChatConversationSid = 'chatConversationSID',
  CreatedAt = 'createdAt',
  Description = 'description',
  GameMode = 'gameMode',
  GraphicsSettingsId = 'graphicsSettingsId',
  HasHideAndSeek = 'hasHideAndSeek',
  HasQuest = 'hasQuest',
  HideAndSeekId = 'hideAndSeekId',
  Id = 'id',
  IsCameraThirdPerson = 'isCameraThirdPerson',
  IsChatEnabled = 'isChatEnabled',
  IsScanEnabled = 'isScanEnabled',
  LightTemplate = 'lightTemplate',
  Name = 'name',
  QuestId = 'questId',
  Skybox = 'skybox',
  Thumbnail = 'thumbnail',
  UserId = 'userId'
}

export type UserSceneScalarWhereInput = {
  AND?: InputMaybe<Array<UserSceneScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserSceneScalarWhereInput>>;
  OR?: InputMaybe<Array<UserSceneScalarWhereInput>>;
  alias?: InputMaybe<StringNullableFilter>;
  branding?: InputMaybe<EnumBrandFilter>;
  cameraConfigId?: InputMaybe<UuidNullableFilter>;
  chatConversationSID?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  gameMode?: InputMaybe<EnumGameTypeNullableFilter>;
  graphicsSettingsId?: InputMaybe<UuidNullableFilter>;
  hasHideAndSeek?: InputMaybe<BoolNullableFilter>;
  hasQuest?: InputMaybe<BoolNullableFilter>;
  hideAndSeekId?: InputMaybe<UuidNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  isCameraThirdPerson?: InputMaybe<BoolNullableFilter>;
  isChatEnabled?: InputMaybe<BoolNullableFilter>;
  isScanEnabled?: InputMaybe<BoolNullableFilter>;
  lightTemplate?: InputMaybe<IntNullableFilter>;
  name?: InputMaybe<StringFilter>;
  questId?: InputMaybe<UuidNullableFilter>;
  skybox?: InputMaybe<IntNullableFilter>;
  thumbnail?: InputMaybe<StringFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type UserSceneScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserSceneScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserSceneScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserSceneScalarWhereWithAggregatesInput>>;
  alias?: InputMaybe<StringNullableWithAggregatesFilter>;
  branding?: InputMaybe<EnumBrandWithAggregatesFilter>;
  cameraConfigId?: InputMaybe<UuidNullableWithAggregatesFilter>;
  chatConversationSID?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringWithAggregatesFilter>;
  gameMode?: InputMaybe<EnumGameTypeNullableWithAggregatesFilter>;
  graphicsSettingsId?: InputMaybe<UuidNullableWithAggregatesFilter>;
  hasHideAndSeek?: InputMaybe<BoolNullableWithAggregatesFilter>;
  hasQuest?: InputMaybe<BoolNullableWithAggregatesFilter>;
  hideAndSeekId?: InputMaybe<UuidNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  isCameraThirdPerson?: InputMaybe<BoolNullableWithAggregatesFilter>;
  isChatEnabled?: InputMaybe<BoolNullableWithAggregatesFilter>;
  isScanEnabled?: InputMaybe<BoolNullableWithAggregatesFilter>;
  lightTemplate?: InputMaybe<IntNullableWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  questId?: InputMaybe<UuidNullableWithAggregatesFilter>;
  skybox?: InputMaybe<IntNullableWithAggregatesFilter>;
  thumbnail?: InputMaybe<StringWithAggregatesFilter>;
  userId?: InputMaybe<UuidWithAggregatesFilter>;
};

export type UserSceneSumAggregate = {
  __typename?: 'UserSceneSumAggregate';
  lightTemplate?: Maybe<Scalars['Int']['output']>;
  skybox?: Maybe<Scalars['Int']['output']>;
};

export type UserSceneSumOrderByAggregateInput = {
  lightTemplate?: InputMaybe<SortOrder>;
  skybox?: InputMaybe<SortOrder>;
};

export type UserSceneUpdateInput = {
  alias?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  assets?: InputMaybe<UserSceneAssetsUpdateManyWithoutUserSceneNestedInput>;
  branding?: InputMaybe<EnumBrandFieldUpdateOperationsInput>;
  cameraConfig?: InputMaybe<CameraConfigUpdateOneWithoutUserSceneNestedInput>;
  chatConversationSID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  domain?: InputMaybe<DomainSceneUpdateManyWithoutSceneNestedInput>;
  gameMode?: InputMaybe<NullableEnumGameTypeFieldUpdateOperationsInput>;
  graphicsSettings?: InputMaybe<GraphicsSettingsUpdateOneWithoutUserSceneNestedInput>;
  hasHideAndSeek?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasQuest?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hideAndSeek?: InputMaybe<HideAndSeekUpdateOneWithoutUserSceneNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCameraThirdPerson?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isChatEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isScanEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  lightTemplate?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  sceneQuest?: InputMaybe<QuestUpdateOneWithoutUserSceneNestedInput>;
  shopifyStore?: InputMaybe<ShopifyStoreUpdateManyWithoutUserSceneNestedInput>;
  skybox?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  thumbnail?: InputMaybe<StringFieldUpdateOperationsInput>;
  tourItems?: InputMaybe<TourItemsUpdateManyWithoutUserSceneNestedInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserSceneUpdateManyMutationInput = {
  alias?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  branding?: InputMaybe<EnumBrandFieldUpdateOperationsInput>;
  chatConversationSID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameMode?: InputMaybe<NullableEnumGameTypeFieldUpdateOperationsInput>;
  hasHideAndSeek?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasQuest?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCameraThirdPerson?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isChatEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isScanEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  lightTemplate?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  skybox?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  thumbnail?: InputMaybe<StringFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserSceneUpdateManyWithWhereWithoutCameraConfigInput = {
  data: UserSceneUpdateManyMutationInput;
  where: UserSceneScalarWhereInput;
};

export type UserSceneUpdateManyWithWhereWithoutGraphicsSettingsInput = {
  data: UserSceneUpdateManyMutationInput;
  where: UserSceneScalarWhereInput;
};

export type UserSceneUpdateManyWithWhereWithoutHideAndSeekInput = {
  data: UserSceneUpdateManyMutationInput;
  where: UserSceneScalarWhereInput;
};

export type UserSceneUpdateManyWithWhereWithoutSceneQuestInput = {
  data: UserSceneUpdateManyMutationInput;
  where: UserSceneScalarWhereInput;
};

export type UserSceneUpdateManyWithoutCameraConfigNestedInput = {
  connect?: InputMaybe<Array<UserSceneWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserSceneCreateOrConnectWithoutCameraConfigInput>>;
  create?: InputMaybe<Array<UserSceneCreateWithoutCameraConfigInput>>;
  createMany?: InputMaybe<UserSceneCreateManyCameraConfigInputEnvelope>;
  delete?: InputMaybe<Array<UserSceneWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserSceneScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserSceneWhereUniqueInput>>;
  set?: InputMaybe<Array<UserSceneWhereUniqueInput>>;
  update?: InputMaybe<Array<UserSceneUpdateWithWhereUniqueWithoutCameraConfigInput>>;
  updateMany?: InputMaybe<Array<UserSceneUpdateManyWithWhereWithoutCameraConfigInput>>;
  upsert?: InputMaybe<Array<UserSceneUpsertWithWhereUniqueWithoutCameraConfigInput>>;
};

export type UserSceneUpdateManyWithoutGraphicsSettingsNestedInput = {
  connect?: InputMaybe<Array<UserSceneWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserSceneCreateOrConnectWithoutGraphicsSettingsInput>>;
  create?: InputMaybe<Array<UserSceneCreateWithoutGraphicsSettingsInput>>;
  createMany?: InputMaybe<UserSceneCreateManyGraphicsSettingsInputEnvelope>;
  delete?: InputMaybe<Array<UserSceneWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserSceneScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserSceneWhereUniqueInput>>;
  set?: InputMaybe<Array<UserSceneWhereUniqueInput>>;
  update?: InputMaybe<Array<UserSceneUpdateWithWhereUniqueWithoutGraphicsSettingsInput>>;
  updateMany?: InputMaybe<Array<UserSceneUpdateManyWithWhereWithoutGraphicsSettingsInput>>;
  upsert?: InputMaybe<Array<UserSceneUpsertWithWhereUniqueWithoutGraphicsSettingsInput>>;
};

export type UserSceneUpdateManyWithoutHideAndSeekNestedInput = {
  connect?: InputMaybe<Array<UserSceneWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserSceneCreateOrConnectWithoutHideAndSeekInput>>;
  create?: InputMaybe<Array<UserSceneCreateWithoutHideAndSeekInput>>;
  createMany?: InputMaybe<UserSceneCreateManyHideAndSeekInputEnvelope>;
  delete?: InputMaybe<Array<UserSceneWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserSceneScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserSceneWhereUniqueInput>>;
  set?: InputMaybe<Array<UserSceneWhereUniqueInput>>;
  update?: InputMaybe<Array<UserSceneUpdateWithWhereUniqueWithoutHideAndSeekInput>>;
  updateMany?: InputMaybe<Array<UserSceneUpdateManyWithWhereWithoutHideAndSeekInput>>;
  upsert?: InputMaybe<Array<UserSceneUpsertWithWhereUniqueWithoutHideAndSeekInput>>;
};

export type UserSceneUpdateManyWithoutSceneQuestNestedInput = {
  connect?: InputMaybe<Array<UserSceneWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserSceneCreateOrConnectWithoutSceneQuestInput>>;
  create?: InputMaybe<Array<UserSceneCreateWithoutSceneQuestInput>>;
  createMany?: InputMaybe<UserSceneCreateManySceneQuestInputEnvelope>;
  delete?: InputMaybe<Array<UserSceneWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserSceneScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserSceneWhereUniqueInput>>;
  set?: InputMaybe<Array<UserSceneWhereUniqueInput>>;
  update?: InputMaybe<Array<UserSceneUpdateWithWhereUniqueWithoutSceneQuestInput>>;
  updateMany?: InputMaybe<Array<UserSceneUpdateManyWithWhereWithoutSceneQuestInput>>;
  upsert?: InputMaybe<Array<UserSceneUpsertWithWhereUniqueWithoutSceneQuestInput>>;
};

export type UserSceneUpdateOneRequiredWithoutAssetsNestedInput = {
  connect?: InputMaybe<UserSceneWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserSceneCreateOrConnectWithoutAssetsInput>;
  create?: InputMaybe<UserSceneCreateWithoutAssetsInput>;
  update?: InputMaybe<UserSceneUpdateToOneWithWhereWithoutAssetsInput>;
  upsert?: InputMaybe<UserSceneUpsertWithoutAssetsInput>;
};

export type UserSceneUpdateOneRequiredWithoutShopifyStoreNestedInput = {
  connect?: InputMaybe<UserSceneWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserSceneCreateOrConnectWithoutShopifyStoreInput>;
  create?: InputMaybe<UserSceneCreateWithoutShopifyStoreInput>;
  update?: InputMaybe<UserSceneUpdateToOneWithWhereWithoutShopifyStoreInput>;
  upsert?: InputMaybe<UserSceneUpsertWithoutShopifyStoreInput>;
};

export type UserSceneUpdateOneRequiredWithoutTourItemsNestedInput = {
  connect?: InputMaybe<UserSceneWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserSceneCreateOrConnectWithoutTourItemsInput>;
  create?: InputMaybe<UserSceneCreateWithoutTourItemsInput>;
  update?: InputMaybe<UserSceneUpdateToOneWithWhereWithoutTourItemsInput>;
  upsert?: InputMaybe<UserSceneUpsertWithoutTourItemsInput>;
};

export type UserSceneUpdateOneWithoutDomainNestedInput = {
  connect?: InputMaybe<UserSceneWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserSceneCreateOrConnectWithoutDomainInput>;
  create?: InputMaybe<UserSceneCreateWithoutDomainInput>;
  delete?: InputMaybe<UserSceneWhereInput>;
  disconnect?: InputMaybe<UserSceneWhereInput>;
  update?: InputMaybe<UserSceneUpdateToOneWithWhereWithoutDomainInput>;
  upsert?: InputMaybe<UserSceneUpsertWithoutDomainInput>;
};

export type UserSceneUpdateToOneWithWhereWithoutAssetsInput = {
  data: UserSceneUpdateWithoutAssetsInput;
  where?: InputMaybe<UserSceneWhereInput>;
};

export type UserSceneUpdateToOneWithWhereWithoutDomainInput = {
  data: UserSceneUpdateWithoutDomainInput;
  where?: InputMaybe<UserSceneWhereInput>;
};

export type UserSceneUpdateToOneWithWhereWithoutShopifyStoreInput = {
  data: UserSceneUpdateWithoutShopifyStoreInput;
  where?: InputMaybe<UserSceneWhereInput>;
};

export type UserSceneUpdateToOneWithWhereWithoutTourItemsInput = {
  data: UserSceneUpdateWithoutTourItemsInput;
  where?: InputMaybe<UserSceneWhereInput>;
};

export type UserSceneUpdateWithWhereUniqueWithoutCameraConfigInput = {
  data: UserSceneUpdateWithoutCameraConfigInput;
  where: UserSceneWhereUniqueInput;
};

export type UserSceneUpdateWithWhereUniqueWithoutGraphicsSettingsInput = {
  data: UserSceneUpdateWithoutGraphicsSettingsInput;
  where: UserSceneWhereUniqueInput;
};

export type UserSceneUpdateWithWhereUniqueWithoutHideAndSeekInput = {
  data: UserSceneUpdateWithoutHideAndSeekInput;
  where: UserSceneWhereUniqueInput;
};

export type UserSceneUpdateWithWhereUniqueWithoutSceneQuestInput = {
  data: UserSceneUpdateWithoutSceneQuestInput;
  where: UserSceneWhereUniqueInput;
};

export type UserSceneUpdateWithoutAssetsInput = {
  alias?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  branding?: InputMaybe<EnumBrandFieldUpdateOperationsInput>;
  cameraConfig?: InputMaybe<CameraConfigUpdateOneWithoutUserSceneNestedInput>;
  chatConversationSID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  domain?: InputMaybe<DomainSceneUpdateManyWithoutSceneNestedInput>;
  gameMode?: InputMaybe<NullableEnumGameTypeFieldUpdateOperationsInput>;
  graphicsSettings?: InputMaybe<GraphicsSettingsUpdateOneWithoutUserSceneNestedInput>;
  hasHideAndSeek?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasQuest?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hideAndSeek?: InputMaybe<HideAndSeekUpdateOneWithoutUserSceneNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCameraThirdPerson?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isChatEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isScanEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  lightTemplate?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  sceneQuest?: InputMaybe<QuestUpdateOneWithoutUserSceneNestedInput>;
  shopifyStore?: InputMaybe<ShopifyStoreUpdateManyWithoutUserSceneNestedInput>;
  skybox?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  thumbnail?: InputMaybe<StringFieldUpdateOperationsInput>;
  tourItems?: InputMaybe<TourItemsUpdateManyWithoutUserSceneNestedInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserSceneUpdateWithoutCameraConfigInput = {
  alias?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  assets?: InputMaybe<UserSceneAssetsUpdateManyWithoutUserSceneNestedInput>;
  branding?: InputMaybe<EnumBrandFieldUpdateOperationsInput>;
  chatConversationSID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  domain?: InputMaybe<DomainSceneUpdateManyWithoutSceneNestedInput>;
  gameMode?: InputMaybe<NullableEnumGameTypeFieldUpdateOperationsInput>;
  graphicsSettings?: InputMaybe<GraphicsSettingsUpdateOneWithoutUserSceneNestedInput>;
  hasHideAndSeek?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasQuest?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hideAndSeek?: InputMaybe<HideAndSeekUpdateOneWithoutUserSceneNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCameraThirdPerson?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isChatEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isScanEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  lightTemplate?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  sceneQuest?: InputMaybe<QuestUpdateOneWithoutUserSceneNestedInput>;
  shopifyStore?: InputMaybe<ShopifyStoreUpdateManyWithoutUserSceneNestedInput>;
  skybox?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  thumbnail?: InputMaybe<StringFieldUpdateOperationsInput>;
  tourItems?: InputMaybe<TourItemsUpdateManyWithoutUserSceneNestedInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserSceneUpdateWithoutDomainInput = {
  alias?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  assets?: InputMaybe<UserSceneAssetsUpdateManyWithoutUserSceneNestedInput>;
  branding?: InputMaybe<EnumBrandFieldUpdateOperationsInput>;
  cameraConfig?: InputMaybe<CameraConfigUpdateOneWithoutUserSceneNestedInput>;
  chatConversationSID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameMode?: InputMaybe<NullableEnumGameTypeFieldUpdateOperationsInput>;
  graphicsSettings?: InputMaybe<GraphicsSettingsUpdateOneWithoutUserSceneNestedInput>;
  hasHideAndSeek?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasQuest?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hideAndSeek?: InputMaybe<HideAndSeekUpdateOneWithoutUserSceneNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCameraThirdPerson?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isChatEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isScanEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  lightTemplate?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  sceneQuest?: InputMaybe<QuestUpdateOneWithoutUserSceneNestedInput>;
  shopifyStore?: InputMaybe<ShopifyStoreUpdateManyWithoutUserSceneNestedInput>;
  skybox?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  thumbnail?: InputMaybe<StringFieldUpdateOperationsInput>;
  tourItems?: InputMaybe<TourItemsUpdateManyWithoutUserSceneNestedInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserSceneUpdateWithoutGraphicsSettingsInput = {
  alias?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  assets?: InputMaybe<UserSceneAssetsUpdateManyWithoutUserSceneNestedInput>;
  branding?: InputMaybe<EnumBrandFieldUpdateOperationsInput>;
  cameraConfig?: InputMaybe<CameraConfigUpdateOneWithoutUserSceneNestedInput>;
  chatConversationSID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  domain?: InputMaybe<DomainSceneUpdateManyWithoutSceneNestedInput>;
  gameMode?: InputMaybe<NullableEnumGameTypeFieldUpdateOperationsInput>;
  hasHideAndSeek?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasQuest?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hideAndSeek?: InputMaybe<HideAndSeekUpdateOneWithoutUserSceneNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCameraThirdPerson?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isChatEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isScanEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  lightTemplate?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  sceneQuest?: InputMaybe<QuestUpdateOneWithoutUserSceneNestedInput>;
  shopifyStore?: InputMaybe<ShopifyStoreUpdateManyWithoutUserSceneNestedInput>;
  skybox?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  thumbnail?: InputMaybe<StringFieldUpdateOperationsInput>;
  tourItems?: InputMaybe<TourItemsUpdateManyWithoutUserSceneNestedInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserSceneUpdateWithoutHideAndSeekInput = {
  alias?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  assets?: InputMaybe<UserSceneAssetsUpdateManyWithoutUserSceneNestedInput>;
  branding?: InputMaybe<EnumBrandFieldUpdateOperationsInput>;
  cameraConfig?: InputMaybe<CameraConfigUpdateOneWithoutUserSceneNestedInput>;
  chatConversationSID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  domain?: InputMaybe<DomainSceneUpdateManyWithoutSceneNestedInput>;
  gameMode?: InputMaybe<NullableEnumGameTypeFieldUpdateOperationsInput>;
  graphicsSettings?: InputMaybe<GraphicsSettingsUpdateOneWithoutUserSceneNestedInput>;
  hasHideAndSeek?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasQuest?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCameraThirdPerson?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isChatEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isScanEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  lightTemplate?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  sceneQuest?: InputMaybe<QuestUpdateOneWithoutUserSceneNestedInput>;
  shopifyStore?: InputMaybe<ShopifyStoreUpdateManyWithoutUserSceneNestedInput>;
  skybox?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  thumbnail?: InputMaybe<StringFieldUpdateOperationsInput>;
  tourItems?: InputMaybe<TourItemsUpdateManyWithoutUserSceneNestedInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserSceneUpdateWithoutSceneQuestInput = {
  alias?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  assets?: InputMaybe<UserSceneAssetsUpdateManyWithoutUserSceneNestedInput>;
  branding?: InputMaybe<EnumBrandFieldUpdateOperationsInput>;
  cameraConfig?: InputMaybe<CameraConfigUpdateOneWithoutUserSceneNestedInput>;
  chatConversationSID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  domain?: InputMaybe<DomainSceneUpdateManyWithoutSceneNestedInput>;
  gameMode?: InputMaybe<NullableEnumGameTypeFieldUpdateOperationsInput>;
  graphicsSettings?: InputMaybe<GraphicsSettingsUpdateOneWithoutUserSceneNestedInput>;
  hasHideAndSeek?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasQuest?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hideAndSeek?: InputMaybe<HideAndSeekUpdateOneWithoutUserSceneNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCameraThirdPerson?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isChatEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isScanEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  lightTemplate?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  shopifyStore?: InputMaybe<ShopifyStoreUpdateManyWithoutUserSceneNestedInput>;
  skybox?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  thumbnail?: InputMaybe<StringFieldUpdateOperationsInput>;
  tourItems?: InputMaybe<TourItemsUpdateManyWithoutUserSceneNestedInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserSceneUpdateWithoutShopifyStoreInput = {
  alias?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  assets?: InputMaybe<UserSceneAssetsUpdateManyWithoutUserSceneNestedInput>;
  branding?: InputMaybe<EnumBrandFieldUpdateOperationsInput>;
  cameraConfig?: InputMaybe<CameraConfigUpdateOneWithoutUserSceneNestedInput>;
  chatConversationSID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  domain?: InputMaybe<DomainSceneUpdateManyWithoutSceneNestedInput>;
  gameMode?: InputMaybe<NullableEnumGameTypeFieldUpdateOperationsInput>;
  graphicsSettings?: InputMaybe<GraphicsSettingsUpdateOneWithoutUserSceneNestedInput>;
  hasHideAndSeek?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasQuest?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hideAndSeek?: InputMaybe<HideAndSeekUpdateOneWithoutUserSceneNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCameraThirdPerson?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isChatEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isScanEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  lightTemplate?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  sceneQuest?: InputMaybe<QuestUpdateOneWithoutUserSceneNestedInput>;
  skybox?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  thumbnail?: InputMaybe<StringFieldUpdateOperationsInput>;
  tourItems?: InputMaybe<TourItemsUpdateManyWithoutUserSceneNestedInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserSceneUpdateWithoutTourItemsInput = {
  alias?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  assets?: InputMaybe<UserSceneAssetsUpdateManyWithoutUserSceneNestedInput>;
  branding?: InputMaybe<EnumBrandFieldUpdateOperationsInput>;
  cameraConfig?: InputMaybe<CameraConfigUpdateOneWithoutUserSceneNestedInput>;
  chatConversationSID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  domain?: InputMaybe<DomainSceneUpdateManyWithoutSceneNestedInput>;
  gameMode?: InputMaybe<NullableEnumGameTypeFieldUpdateOperationsInput>;
  graphicsSettings?: InputMaybe<GraphicsSettingsUpdateOneWithoutUserSceneNestedInput>;
  hasHideAndSeek?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hasQuest?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  hideAndSeek?: InputMaybe<HideAndSeekUpdateOneWithoutUserSceneNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isCameraThirdPerson?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isChatEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  isScanEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  lightTemplate?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  sceneQuest?: InputMaybe<QuestUpdateOneWithoutUserSceneNestedInput>;
  shopifyStore?: InputMaybe<ShopifyStoreUpdateManyWithoutUserSceneNestedInput>;
  skybox?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  thumbnail?: InputMaybe<StringFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserSceneUpsertWithWhereUniqueWithoutCameraConfigInput = {
  create: UserSceneCreateWithoutCameraConfigInput;
  update: UserSceneUpdateWithoutCameraConfigInput;
  where: UserSceneWhereUniqueInput;
};

export type UserSceneUpsertWithWhereUniqueWithoutGraphicsSettingsInput = {
  create: UserSceneCreateWithoutGraphicsSettingsInput;
  update: UserSceneUpdateWithoutGraphicsSettingsInput;
  where: UserSceneWhereUniqueInput;
};

export type UserSceneUpsertWithWhereUniqueWithoutHideAndSeekInput = {
  create: UserSceneCreateWithoutHideAndSeekInput;
  update: UserSceneUpdateWithoutHideAndSeekInput;
  where: UserSceneWhereUniqueInput;
};

export type UserSceneUpsertWithWhereUniqueWithoutSceneQuestInput = {
  create: UserSceneCreateWithoutSceneQuestInput;
  update: UserSceneUpdateWithoutSceneQuestInput;
  where: UserSceneWhereUniqueInput;
};

export type UserSceneUpsertWithoutAssetsInput = {
  create: UserSceneCreateWithoutAssetsInput;
  update: UserSceneUpdateWithoutAssetsInput;
  where?: InputMaybe<UserSceneWhereInput>;
};

export type UserSceneUpsertWithoutDomainInput = {
  create: UserSceneCreateWithoutDomainInput;
  update: UserSceneUpdateWithoutDomainInput;
  where?: InputMaybe<UserSceneWhereInput>;
};

export type UserSceneUpsertWithoutShopifyStoreInput = {
  create: UserSceneCreateWithoutShopifyStoreInput;
  update: UserSceneUpdateWithoutShopifyStoreInput;
  where?: InputMaybe<UserSceneWhereInput>;
};

export type UserSceneUpsertWithoutTourItemsInput = {
  create: UserSceneCreateWithoutTourItemsInput;
  update: UserSceneUpdateWithoutTourItemsInput;
  where?: InputMaybe<UserSceneWhereInput>;
};

export type UserSceneWhereInput = {
  AND?: InputMaybe<Array<UserSceneWhereInput>>;
  NOT?: InputMaybe<Array<UserSceneWhereInput>>;
  OR?: InputMaybe<Array<UserSceneWhereInput>>;
  alias?: InputMaybe<StringNullableFilter>;
  assets?: InputMaybe<UserSceneAssetsListRelationFilter>;
  branding?: InputMaybe<EnumBrandFilter>;
  cameraConfig?: InputMaybe<CameraConfigNullableRelationFilter>;
  cameraConfigId?: InputMaybe<UuidNullableFilter>;
  chatConversationSID?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  domain?: InputMaybe<DomainSceneListRelationFilter>;
  gameMode?: InputMaybe<EnumGameTypeNullableFilter>;
  graphicsSettings?: InputMaybe<GraphicsSettingsNullableRelationFilter>;
  graphicsSettingsId?: InputMaybe<UuidNullableFilter>;
  hasHideAndSeek?: InputMaybe<BoolNullableFilter>;
  hasQuest?: InputMaybe<BoolNullableFilter>;
  hideAndSeek?: InputMaybe<HideAndSeekNullableRelationFilter>;
  hideAndSeekId?: InputMaybe<UuidNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  isCameraThirdPerson?: InputMaybe<BoolNullableFilter>;
  isChatEnabled?: InputMaybe<BoolNullableFilter>;
  isScanEnabled?: InputMaybe<BoolNullableFilter>;
  lightTemplate?: InputMaybe<IntNullableFilter>;
  name?: InputMaybe<StringFilter>;
  questId?: InputMaybe<UuidNullableFilter>;
  sceneQuest?: InputMaybe<QuestNullableRelationFilter>;
  shopifyStore?: InputMaybe<ShopifyStoreListRelationFilter>;
  skybox?: InputMaybe<IntNullableFilter>;
  thumbnail?: InputMaybe<StringFilter>;
  tourItems?: InputMaybe<TourItemsListRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type UserSceneWhereUniqueInput = {
  AND?: InputMaybe<Array<UserSceneWhereInput>>;
  NOT?: InputMaybe<Array<UserSceneWhereInput>>;
  OR?: InputMaybe<Array<UserSceneWhereInput>>;
  alias?: InputMaybe<Scalars['String']['input']>;
  assets?: InputMaybe<UserSceneAssetsListRelationFilter>;
  branding?: InputMaybe<EnumBrandFilter>;
  cameraConfig?: InputMaybe<CameraConfigNullableRelationFilter>;
  cameraConfigId?: InputMaybe<UuidNullableFilter>;
  chatConversationSID?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  domain?: InputMaybe<DomainSceneListRelationFilter>;
  gameMode?: InputMaybe<EnumGameTypeNullableFilter>;
  graphicsSettings?: InputMaybe<GraphicsSettingsNullableRelationFilter>;
  graphicsSettingsId?: InputMaybe<UuidNullableFilter>;
  hasHideAndSeek?: InputMaybe<BoolNullableFilter>;
  hasQuest?: InputMaybe<BoolNullableFilter>;
  hideAndSeek?: InputMaybe<HideAndSeekNullableRelationFilter>;
  hideAndSeekId?: InputMaybe<UuidNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCameraThirdPerson?: InputMaybe<BoolNullableFilter>;
  isChatEnabled?: InputMaybe<BoolNullableFilter>;
  isScanEnabled?: InputMaybe<BoolNullableFilter>;
  lightTemplate?: InputMaybe<IntNullableFilter>;
  name?: InputMaybe<StringFilter>;
  questId?: InputMaybe<UuidNullableFilter>;
  sceneQuest?: InputMaybe<QuestNullableRelationFilter>;
  shopifyStore?: InputMaybe<ShopifyStoreListRelationFilter>;
  skybox?: InputMaybe<IntNullableFilter>;
  thumbnail?: InputMaybe<StringFilter>;
  tourItems?: InputMaybe<TourItemsListRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type UserSubscription = {
  __typename?: 'UserSubscription';
  subscriptionPlanId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type UserSubscriptionCountAggregate = {
  __typename?: 'UserSubscriptionCountAggregate';
  _all: Scalars['Int']['output'];
  subscriptionPlanId: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type UserSubscriptionCountOrderByAggregateInput = {
  subscriptionPlanId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserSubscriptionCreateInput = {
  subscription: SubscriptionPlanCreateNestedOneWithoutSubscriptionInput;
  userId: Scalars['String']['input'];
};

export type UserSubscriptionCreateManyInput = {
  subscriptionPlanId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UserSubscriptionCreateManySubscriptionInput = {
  userId: Scalars['String']['input'];
};

export type UserSubscriptionCreateManySubscriptionInputEnvelope = {
  data: Array<UserSubscriptionCreateManySubscriptionInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserSubscriptionCreateNestedManyWithoutSubscriptionInput = {
  connect?: InputMaybe<Array<UserSubscriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserSubscriptionCreateOrConnectWithoutSubscriptionInput>>;
  create?: InputMaybe<Array<UserSubscriptionCreateWithoutSubscriptionInput>>;
  createMany?: InputMaybe<UserSubscriptionCreateManySubscriptionInputEnvelope>;
};

export type UserSubscriptionCreateOrConnectWithoutSubscriptionInput = {
  create: UserSubscriptionCreateWithoutSubscriptionInput;
  where: UserSubscriptionWhereUniqueInput;
};

export type UserSubscriptionCreateWithoutSubscriptionInput = {
  userId: Scalars['String']['input'];
};

export type UserSubscriptionGroupBy = {
  __typename?: 'UserSubscriptionGroupBy';
  _count?: Maybe<UserSubscriptionCountAggregate>;
  _max?: Maybe<UserSubscriptionMaxAggregate>;
  _min?: Maybe<UserSubscriptionMinAggregate>;
  subscriptionPlanId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type UserSubscriptionListRelationFilter = {
  every?: InputMaybe<UserSubscriptionWhereInput>;
  none?: InputMaybe<UserSubscriptionWhereInput>;
  some?: InputMaybe<UserSubscriptionWhereInput>;
};

export type UserSubscriptionMaxAggregate = {
  __typename?: 'UserSubscriptionMaxAggregate';
  subscriptionPlanId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserSubscriptionMaxOrderByAggregateInput = {
  subscriptionPlanId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserSubscriptionMinAggregate = {
  __typename?: 'UserSubscriptionMinAggregate';
  subscriptionPlanId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserSubscriptionMinOrderByAggregateInput = {
  subscriptionPlanId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserSubscriptionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserSubscriptionOrderByWithAggregationInput = {
  _count?: InputMaybe<UserSubscriptionCountOrderByAggregateInput>;
  _max?: InputMaybe<UserSubscriptionMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserSubscriptionMinOrderByAggregateInput>;
  subscriptionPlanId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserSubscriptionOrderByWithRelationInput = {
  subscription?: InputMaybe<SubscriptionPlanOrderByWithRelationInput>;
  subscriptionPlanId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export enum UserSubscriptionScalarFieldEnum {
  SubscriptionPlanId = 'subscriptionPlanId',
  UserId = 'userId'
}

export type UserSubscriptionScalarWhereInput = {
  AND?: InputMaybe<Array<UserSubscriptionScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserSubscriptionScalarWhereInput>>;
  OR?: InputMaybe<Array<UserSubscriptionScalarWhereInput>>;
  subscriptionPlanId?: InputMaybe<UuidFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type UserSubscriptionScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserSubscriptionScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserSubscriptionScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserSubscriptionScalarWhereWithAggregatesInput>>;
  subscriptionPlanId?: InputMaybe<UuidWithAggregatesFilter>;
  userId?: InputMaybe<UuidWithAggregatesFilter>;
};

export type UserSubscriptionUpdateInput = {
  subscription?: InputMaybe<SubscriptionPlanUpdateOneRequiredWithoutSubscriptionNestedInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserSubscriptionUpdateManyMutationInput = {
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserSubscriptionUpdateManyWithWhereWithoutSubscriptionInput = {
  data: UserSubscriptionUpdateManyMutationInput;
  where: UserSubscriptionScalarWhereInput;
};

export type UserSubscriptionUpdateManyWithoutSubscriptionNestedInput = {
  connect?: InputMaybe<Array<UserSubscriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserSubscriptionCreateOrConnectWithoutSubscriptionInput>>;
  create?: InputMaybe<Array<UserSubscriptionCreateWithoutSubscriptionInput>>;
  createMany?: InputMaybe<UserSubscriptionCreateManySubscriptionInputEnvelope>;
  delete?: InputMaybe<Array<UserSubscriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserSubscriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserSubscriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<UserSubscriptionWhereUniqueInput>>;
  update?: InputMaybe<Array<UserSubscriptionUpdateWithWhereUniqueWithoutSubscriptionInput>>;
  updateMany?: InputMaybe<Array<UserSubscriptionUpdateManyWithWhereWithoutSubscriptionInput>>;
  upsert?: InputMaybe<Array<UserSubscriptionUpsertWithWhereUniqueWithoutSubscriptionInput>>;
};

export type UserSubscriptionUpdateWithWhereUniqueWithoutSubscriptionInput = {
  data: UserSubscriptionUpdateWithoutSubscriptionInput;
  where: UserSubscriptionWhereUniqueInput;
};

export type UserSubscriptionUpdateWithoutSubscriptionInput = {
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserSubscriptionUpsertWithWhereUniqueWithoutSubscriptionInput = {
  create: UserSubscriptionCreateWithoutSubscriptionInput;
  update: UserSubscriptionUpdateWithoutSubscriptionInput;
  where: UserSubscriptionWhereUniqueInput;
};

export type UserSubscriptionWhereInput = {
  AND?: InputMaybe<Array<UserSubscriptionWhereInput>>;
  NOT?: InputMaybe<Array<UserSubscriptionWhereInput>>;
  OR?: InputMaybe<Array<UserSubscriptionWhereInput>>;
  subscription?: InputMaybe<SubscriptionPlanRelationFilter>;
  subscriptionPlanId?: InputMaybe<UuidFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type UserSubscriptionWhereUniqueInput = {
  AND?: InputMaybe<Array<UserSubscriptionWhereInput>>;
  NOT?: InputMaybe<Array<UserSubscriptionWhereInput>>;
  OR?: InputMaybe<Array<UserSubscriptionWhereInput>>;
  subscription?: InputMaybe<SubscriptionPlanRelationFilter>;
  subscriptionPlanId?: InputMaybe<UuidFilter>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type UserUpdateInput = {
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  bannerUrl?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
};

export type Users = {
  __typename?: 'Users';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  bannerUrl?: Maybe<Scalars['String']['output']>;
  changePasswordToken?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isVerified: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  password: Scalars['String']['output'];
  registrationOrigin?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  subscribed: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  userFriendsId?: Maybe<Scalars['String']['output']>;
  verificationToken: Scalars['String']['output'];
};

export type UsersListRelationFilter = {
  every?: InputMaybe<UsersWhereInput>;
  none?: InputMaybe<UsersWhereInput>;
  some?: InputMaybe<UsersWhereInput>;
};

export type UsersQuests = {
  __typename?: 'UsersQuests';
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  questId: Scalars['String']['output'];
  questState: QuestState;
  rewardStatus: RewardStatus;
  updatedAt: Scalars['DateTimeISO']['output'];
  userEmail: Scalars['String']['output'];
  userId: Scalars['String']['output'];
  walletId: Scalars['String']['output'];
  walletType: Scalars['String']['output'];
};

export type UsersQuestsCountAggregate = {
  __typename?: 'UsersQuestsCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  questId: Scalars['Int']['output'];
  questState: Scalars['Int']['output'];
  rewardStatus: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  userEmail: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
  walletId: Scalars['Int']['output'];
  walletType: Scalars['Int']['output'];
};

export type UsersQuestsCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  questId?: InputMaybe<SortOrder>;
  questState?: InputMaybe<SortOrder>;
  rewardStatus?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userEmail?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  walletId?: InputMaybe<SortOrder>;
  walletType?: InputMaybe<SortOrder>;
};

export type UsersQuestsCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  quest?: InputMaybe<QuestCreateNestedOneWithoutUsersQuestInput>;
  questState?: InputMaybe<QuestState>;
  rewardStatus?: InputMaybe<RewardStatus>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  userEmail?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
  walletId: Scalars['String']['input'];
  walletType: Scalars['String']['input'];
};

export type UsersQuestsCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  questId: Scalars['String']['input'];
  questState?: InputMaybe<QuestState>;
  rewardStatus?: InputMaybe<RewardStatus>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  userEmail?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
  walletId: Scalars['String']['input'];
  walletType: Scalars['String']['input'];
};

export type UsersQuestsCreateManyQuestInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  questState?: InputMaybe<QuestState>;
  rewardStatus?: InputMaybe<RewardStatus>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  userEmail?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
  walletId: Scalars['String']['input'];
  walletType: Scalars['String']['input'];
};

export type UsersQuestsCreateManyQuestInputEnvelope = {
  data: Array<UsersQuestsCreateManyQuestInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UsersQuestsCreateNestedManyWithoutQuestInput = {
  connect?: InputMaybe<Array<UsersQuestsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UsersQuestsCreateOrConnectWithoutQuestInput>>;
  create?: InputMaybe<Array<UsersQuestsCreateWithoutQuestInput>>;
  createMany?: InputMaybe<UsersQuestsCreateManyQuestInputEnvelope>;
};

export type UsersQuestsCreateOrConnectWithoutQuestInput = {
  create: UsersQuestsCreateWithoutQuestInput;
  where: UsersQuestsWhereUniqueInput;
};

export type UsersQuestsCreateWithoutQuestInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  questState?: InputMaybe<QuestState>;
  rewardStatus?: InputMaybe<RewardStatus>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  userEmail?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
  walletId: Scalars['String']['input'];
  walletType: Scalars['String']['input'];
};

export type UsersQuestsGroupBy = {
  __typename?: 'UsersQuestsGroupBy';
  _count?: Maybe<UsersQuestsCountAggregate>;
  _max?: Maybe<UsersQuestsMaxAggregate>;
  _min?: Maybe<UsersQuestsMinAggregate>;
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  questId: Scalars['String']['output'];
  questState: QuestState;
  rewardStatus: RewardStatus;
  updatedAt: Scalars['DateTimeISO']['output'];
  userEmail: Scalars['String']['output'];
  userId: Scalars['String']['output'];
  walletId: Scalars['String']['output'];
  walletType: Scalars['String']['output'];
};

export type UsersQuestsListRelationFilter = {
  every?: InputMaybe<UsersQuestsWhereInput>;
  none?: InputMaybe<UsersQuestsWhereInput>;
  some?: InputMaybe<UsersQuestsWhereInput>;
};

export type UsersQuestsMaxAggregate = {
  __typename?: 'UsersQuestsMaxAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  questId?: Maybe<Scalars['String']['output']>;
  questState?: Maybe<QuestState>;
  rewardStatus?: Maybe<RewardStatus>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  walletId?: Maybe<Scalars['String']['output']>;
  walletType?: Maybe<Scalars['String']['output']>;
};

export type UsersQuestsMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  questId?: InputMaybe<SortOrder>;
  questState?: InputMaybe<SortOrder>;
  rewardStatus?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userEmail?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  walletId?: InputMaybe<SortOrder>;
  walletType?: InputMaybe<SortOrder>;
};

export type UsersQuestsMinAggregate = {
  __typename?: 'UsersQuestsMinAggregate';
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  questId?: Maybe<Scalars['String']['output']>;
  questState?: Maybe<QuestState>;
  rewardStatus?: Maybe<RewardStatus>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  walletId?: Maybe<Scalars['String']['output']>;
  walletType?: Maybe<Scalars['String']['output']>;
};

export type UsersQuestsMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  questId?: InputMaybe<SortOrder>;
  questState?: InputMaybe<SortOrder>;
  rewardStatus?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userEmail?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  walletId?: InputMaybe<SortOrder>;
  walletType?: InputMaybe<SortOrder>;
};

export type UsersQuestsOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UsersQuestsOrderByWithAggregationInput = {
  _count?: InputMaybe<UsersQuestsCountOrderByAggregateInput>;
  _max?: InputMaybe<UsersQuestsMaxOrderByAggregateInput>;
  _min?: InputMaybe<UsersQuestsMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  questId?: InputMaybe<SortOrder>;
  questState?: InputMaybe<SortOrder>;
  rewardStatus?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userEmail?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  walletId?: InputMaybe<SortOrder>;
  walletType?: InputMaybe<SortOrder>;
};

export type UsersQuestsOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  quest?: InputMaybe<QuestOrderByWithRelationInput>;
  questId?: InputMaybe<SortOrder>;
  questState?: InputMaybe<SortOrder>;
  rewardStatus?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userEmail?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  walletId?: InputMaybe<SortOrder>;
  walletType?: InputMaybe<SortOrder>;
};

export enum UsersQuestsScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  QuestId = 'questId',
  QuestState = 'questState',
  RewardStatus = 'rewardStatus',
  UpdatedAt = 'updatedAt',
  UserEmail = 'userEmail',
  UserId = 'userId',
  WalletId = 'walletId',
  WalletType = 'walletType'
}

export type UsersQuestsScalarWhereInput = {
  AND?: InputMaybe<Array<UsersQuestsScalarWhereInput>>;
  NOT?: InputMaybe<Array<UsersQuestsScalarWhereInput>>;
  OR?: InputMaybe<Array<UsersQuestsScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  questId?: InputMaybe<UuidFilter>;
  questState?: InputMaybe<EnumQuestStateFilter>;
  rewardStatus?: InputMaybe<EnumRewardStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userEmail?: InputMaybe<StringFilter>;
  userId?: InputMaybe<UuidFilter>;
  walletId?: InputMaybe<StringFilter>;
  walletType?: InputMaybe<StringFilter>;
};

export type UsersQuestsScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UsersQuestsScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UsersQuestsScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UsersQuestsScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  questId?: InputMaybe<UuidWithAggregatesFilter>;
  questState?: InputMaybe<EnumQuestStateWithAggregatesFilter>;
  rewardStatus?: InputMaybe<EnumRewardStatusWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userEmail?: InputMaybe<StringWithAggregatesFilter>;
  userId?: InputMaybe<UuidWithAggregatesFilter>;
  walletId?: InputMaybe<StringWithAggregatesFilter>;
  walletType?: InputMaybe<StringWithAggregatesFilter>;
};

export type UsersQuestsUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  quest?: InputMaybe<QuestUpdateOneWithoutUsersQuestNestedInput>;
  questState?: InputMaybe<EnumQuestStateFieldUpdateOperationsInput>;
  rewardStatus?: InputMaybe<EnumRewardStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  walletId?: InputMaybe<StringFieldUpdateOperationsInput>;
  walletType?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UsersQuestsUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  questState?: InputMaybe<EnumQuestStateFieldUpdateOperationsInput>;
  rewardStatus?: InputMaybe<EnumRewardStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  walletId?: InputMaybe<StringFieldUpdateOperationsInput>;
  walletType?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UsersQuestsUpdateManyWithWhereWithoutQuestInput = {
  data: UsersQuestsUpdateManyMutationInput;
  where: UsersQuestsScalarWhereInput;
};

export type UsersQuestsUpdateManyWithoutQuestNestedInput = {
  connect?: InputMaybe<Array<UsersQuestsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UsersQuestsCreateOrConnectWithoutQuestInput>>;
  create?: InputMaybe<Array<UsersQuestsCreateWithoutQuestInput>>;
  createMany?: InputMaybe<UsersQuestsCreateManyQuestInputEnvelope>;
  delete?: InputMaybe<Array<UsersQuestsWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UsersQuestsScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UsersQuestsWhereUniqueInput>>;
  set?: InputMaybe<Array<UsersQuestsWhereUniqueInput>>;
  update?: InputMaybe<Array<UsersQuestsUpdateWithWhereUniqueWithoutQuestInput>>;
  updateMany?: InputMaybe<Array<UsersQuestsUpdateManyWithWhereWithoutQuestInput>>;
  upsert?: InputMaybe<Array<UsersQuestsUpsertWithWhereUniqueWithoutQuestInput>>;
};

export type UsersQuestsUpdateWithWhereUniqueWithoutQuestInput = {
  data: UsersQuestsUpdateWithoutQuestInput;
  where: UsersQuestsWhereUniqueInput;
};

export type UsersQuestsUpdateWithoutQuestInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  questState?: InputMaybe<EnumQuestStateFieldUpdateOperationsInput>;
  rewardStatus?: InputMaybe<EnumRewardStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userEmail?: InputMaybe<StringFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
  walletId?: InputMaybe<StringFieldUpdateOperationsInput>;
  walletType?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UsersQuestsUpsertWithWhereUniqueWithoutQuestInput = {
  create: UsersQuestsCreateWithoutQuestInput;
  update: UsersQuestsUpdateWithoutQuestInput;
  where: UsersQuestsWhereUniqueInput;
};

export type UsersQuestsWhereInput = {
  AND?: InputMaybe<Array<UsersQuestsWhereInput>>;
  NOT?: InputMaybe<Array<UsersQuestsWhereInput>>;
  OR?: InputMaybe<Array<UsersQuestsWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  quest?: InputMaybe<QuestNullableRelationFilter>;
  questId?: InputMaybe<UuidFilter>;
  questState?: InputMaybe<EnumQuestStateFilter>;
  rewardStatus?: InputMaybe<EnumRewardStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userEmail?: InputMaybe<StringFilter>;
  userId?: InputMaybe<UuidFilter>;
  walletId?: InputMaybe<StringFilter>;
  walletType?: InputMaybe<StringFilter>;
};

export type UsersQuestsWhereUniqueInput = {
  AND?: InputMaybe<Array<UsersQuestsWhereInput>>;
  NOT?: InputMaybe<Array<UsersQuestsWhereInput>>;
  OR?: InputMaybe<Array<UsersQuestsWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  quest?: InputMaybe<QuestNullableRelationFilter>;
  questId?: InputMaybe<UuidFilter>;
  questState?: InputMaybe<EnumQuestStateFilter>;
  rewardStatus?: InputMaybe<EnumRewardStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userEmail?: InputMaybe<StringFilter>;
  userId?: InputMaybe<UuidFilter>;
  walletId?: InputMaybe<StringFilter>;
  walletType?: InputMaybe<StringFilter>;
};

export type UsersWhereInput = {
  AND?: InputMaybe<Array<UsersWhereInput>>;
  NOT?: InputMaybe<Array<UsersWhereInput>>;
  OR?: InputMaybe<Array<UsersWhereInput>>;
  avatarUrl?: InputMaybe<StringNullableFilter>;
  bannerUrl?: InputMaybe<StringNullableFilter>;
  changePasswordToken?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  isVerified?: InputMaybe<BoolFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  password?: InputMaybe<StringFilter>;
  registrationOrigin?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<StringNullableFilter>;
  subscribed?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userFriends?: InputMaybe<UserFriendsNullableRelationFilter>;
  userFriendsId?: InputMaybe<UuidNullableFilter>;
  verificationToken?: InputMaybe<StringFilter>;
};

export type UsersWhereUniqueInput = {
  AND?: InputMaybe<Array<UsersWhereInput>>;
  NOT?: InputMaybe<Array<UsersWhereInput>>;
  OR?: InputMaybe<Array<UsersWhereInput>>;
  avatarUrl?: InputMaybe<StringNullableFilter>;
  bannerUrl?: InputMaybe<StringNullableFilter>;
  changePasswordToken?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isVerified?: InputMaybe<BoolFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  password?: InputMaybe<StringFilter>;
  registrationOrigin?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<StringNullableFilter>;
  subscribed?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userFriends?: InputMaybe<UserFriendsNullableRelationFilter>;
  userFriendsId?: InputMaybe<UuidNullableFilter>;
  verificationToken?: InputMaybe<StringFilter>;
};

export type UuidFilter = {
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedUuidFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UuidNullableFilter = {
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedUuidNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UuidNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedUuidNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UuidWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedUuidWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type VerifyAccount = {
  __typename?: 'VerifyAccount';
  isVerified: Scalars['Boolean']['output'];
};

export type VerifyAccountInput = {
  email: Scalars['String']['input'];
  verificationToken: Scalars['String']['input'];
};

export type WearSkinInput = {
  assetId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type WearSkinResponse = {
  __typename?: 'WearSkinResponse';
  message?: Maybe<Scalars['String']['output']>;
  status: Scalars['Boolean']['output'];
};

export type FindUniqueQuestInput = {
  questId: Scalars['String']['input'];
};

export type FindUniqueQuestResponse = {
  __typename?: 'findUniqueQuestResponse';
  quest?: Maybe<Quest>;
};

export type GetAllDomainSceneQueryVariables = Exact<{
  where: GetAllDomainSceneInput;
}>;


export type GetAllDomainSceneQuery = { __typename?: 'Query', getAllDomainScene: { __typename?: 'GetAllDomainSceneResponse', total: number, domainScene?: Array<{ __typename?: 'DomainScene', domain: string, sceneId: string, id: string }> | null } };

export type UpdateDomainSceneMutationVariables = Exact<{
  data: UpdateDomainSceneInput;
}>;


export type UpdateDomainSceneMutation = { __typename?: 'Mutation', updateDomainScene: { __typename?: 'UpdateDomainSceneResponse', status: string } };

export type CreateDomainSceneMutationVariables = Exact<{
  data: CreateDomainSceneInput;
}>;


export type CreateDomainSceneMutation = { __typename?: 'Mutation', createDomainScene: { __typename?: 'CreateDomainSceneResponse', status: string } };

export type DeleteDomainSceneMutationVariables = Exact<{
  where: DeleteDomainSceneInput;
}>;


export type DeleteDomainSceneMutation = { __typename?: 'Mutation', deleteDomainScene: { __typename?: 'DeleteDomainSceneResponse', status: string } };

export type AssetPaginationQueryVariables = Exact<{
  where: AssetPaginationInput;
}>;


export type AssetPaginationQuery = { __typename?: 'Query', assetPagination: { __typename?: 'AssetsPagination', totalCount: number, assets?: Array<{ __typename?: 'Asset', id: number, name: string, countCopies: number, maxSupply: number, description: string, assetURL: string, fileType: FileType, createdAt: any, previewImage: string, price: number, currency: Currency, viewsCount: number, creatorEmail: string, collectionId: string }> | null } };

export type GetCartQueryVariables = Exact<{
  data: GetCartInput;
}>;


export type GetCartQuery = { __typename?: 'Query', getCart: { __typename?: 'GetCartResponse', status: boolean, message?: string | null, cart?: Array<{ __typename?: 'ProductOutput', id?: number | null, assetIdWeb2?: string | null, price: string, name: string, assetURL?: string | null, type: string, fileType?: string | null, previewImage?: string | null, creatorWalletType?: string | null }> | null } };

export type CreateCartMutationVariables = Exact<{
  data: CreateCartInput;
}>;


export type CreateCartMutation = { __typename?: 'Mutation', createCart: { __typename?: 'CreateCartResponse', status: boolean, message: string } };

export type GetAssetsByCreatorQueryVariables = Exact<{
  where: AssetGetByCreatorInput;
}>;


export type GetAssetsByCreatorQuery = { __typename?: 'Query', getAssetsByCreator: { __typename?: 'AssetsByCreator', assets?: Array<{ __typename?: 'Asset', id: number, name: string, countCopies: number, maxSupply: number, description: string, assetURL: string, featured?: boolean | null, fileType: FileType, createdAt: any, previewImage: string, price: number, currency: Currency, viewsCount: number, creatorEmail: string, collectionId: string }> | null } };

export type GetAssetInfoQueryVariables = Exact<{
  where: GetAssetInfoInput;
}>;


export type GetAssetInfoQuery = { __typename?: 'Query', getAssetInfo: { __typename?: 'GetAssetInfoResponse', asset?: { __typename?: 'AssetInfo', id: number, name: string, description: string, currency: string, creator: string, likesCount: number, category: string, viewsCount: number, creatorId: string, price: number, countCopies: number, maxSupply: number, fileType: string, previewImage: string, assetURL: string, collection?: string | null, collectionId?: string | null } | null } };

export type AddToCartMutationVariables = Exact<{
  data: AddToCartInput;
}>;


export type AddToCartMutation = { __typename?: 'Mutation', addToCart: { __typename?: 'AddToCartResponse', status: boolean, message?: string | null } };

export type RemoveFromCartMutationVariables = Exact<{
  data: RemoveFromCartInput;
}>;


export type RemoveFromCartMutation = { __typename?: 'Mutation', removeFromCart: { __typename?: 'RemoveFromCartResponse', status: boolean, message: string } };

export type SubscriptionPlansQueryVariables = Exact<{ [key: string]: never; }>;


export type SubscriptionPlansQuery = { __typename?: 'Query', subscriptionPlans: Array<{ __typename?: 'SubscriptionPlan', id: string, name: string }> };

export type GetUserSubscriptionQueryVariables = Exact<{
  where?: InputMaybe<SubscriptionPlanWhereInput>;
}>;


export type GetUserSubscriptionQuery = { __typename?: 'Query', subscriptionPlans: Array<{ __typename?: 'SubscriptionPlan', name: string, description: string, id: string, numberOfScenes: number, type: string }> };

export type CreateUserSubscriptionMutationVariables = Exact<{
  data: UserSubscriptionCreateInput;
}>;


export type CreateUserSubscriptionMutation = { __typename?: 'Mutation', createOneUserSubscription: { __typename?: 'UserSubscription', userId: string, subscriptionPlanId: string } };

export type DeleteUserSubscriptionMutationVariables = Exact<{
  where: UserSubscriptionWhereUniqueInput;
}>;


export type DeleteUserSubscriptionMutation = { __typename?: 'Mutation', deleteOneUserSubscription?: { __typename?: 'UserSubscription', userId: string } | null };

export type CreateUserSceneAssetsMutationVariables = Exact<{
  data: UserSceneAssetsCreateInput;
}>;


export type CreateUserSceneAssetsMutation = { __typename?: 'Mutation', createOneUserSceneAssets: { __typename?: 'UserSceneAssets', id: string, lightId?: string | null } };

export type UpdateUserSceneAssetsMutationVariables = Exact<{
  data: UserSceneAssetsUpdateInput;
  where: UserSceneAssetsWhereUniqueInput;
}>;


export type UpdateUserSceneAssetsMutation = { __typename?: 'Mutation', updateOneUserSceneAssets?: { __typename?: 'UserSceneAssets', description?: string | null, id: string, name?: string | null } | null };

export type UpdateUserSceneMutationVariables = Exact<{
  data: UserSceneUpdateInput;
  where: UserSceneWhereUniqueInput;
}>;


export type UpdateUserSceneMutation = { __typename?: 'Mutation', updateOneUserScene?: { __typename?: 'UserScene', id: string } | null };

export type CreateUserSceneMutationVariables = Exact<{
  data: UserSceneCreateInput;
}>;


export type CreateUserSceneMutation = { __typename?: 'Mutation', createOneUserScene: { __typename?: 'UserScene', id: string, name: string } };

export type DeleteUserSceneMutationVariables = Exact<{
  where: UserSceneWhereUniqueInput;
}>;


export type DeleteUserSceneMutation = { __typename?: 'Mutation', deleteOneUserScene?: { __typename?: 'UserScene', id: string, name: string } | null };

export type DeleteManyUserSceneAssetsMutationVariables = Exact<{
  where?: InputMaybe<UserSceneAssetsWhereInput>;
}>;


export type DeleteManyUserSceneAssetsMutation = { __typename?: 'Mutation', deleteManyUserSceneAssets: { __typename?: 'AffectedRowsOutput', count: number } };

export type DeleteUserSceneAssetsMutationVariables = Exact<{
  where: UserSceneAssetsWhereUniqueInput;
}>;


export type DeleteUserSceneAssetsMutation = { __typename?: 'Mutation', deleteOneUserSceneAssets?: { __typename?: 'UserSceneAssets', userSceneId: string } | null };

export type SceneTemplatesQueryVariables = Exact<{
  where?: InputMaybe<SceneTemplateWhereInput>;
}>;


export type SceneTemplatesQuery = { __typename?: 'Query', sceneTemplates: Array<{ __typename?: 'SceneTemplate', id: string, baseModelURL: string, name: string, thumbnail?: string | null }> };

export type MFesTemplatesQueryVariables = Exact<{
  where?: InputMaybe<MfesTemplateWhereInput>;
}>;


export type MFesTemplatesQuery = { __typename?: 'Query', mFESTemplates: Array<{ __typename?: 'MFESTemplate', id: string, name: string }> };

export type MfeSceneTemplatesQueryVariables = Exact<{
  where?: InputMaybe<MfeSceneTemplateWhereInput>;
}>;


export type MfeSceneTemplatesQuery = { __typename?: 'Query', mFESceneTemplates: Array<{ __typename?: 'MFESceneTemplate', MFESTemplateId: string, baseModelURL: string, id: string, name: string }> };

export type CameraConfigQueryVariables = Exact<{
  where: CameraConfigWhereUniqueInput;
}>;


export type CameraConfigQuery = { __typename?: 'Query', cameraConfig?: { __typename?: 'CameraConfig', fov: number, far: number, near: number, maxSpeed: number, minSpeed: number } | null };

export type CreateOneCameraConfigMutationVariables = Exact<{
  data?: InputMaybe<CameraConfigCreateInput>;
}>;


export type CreateOneCameraConfigMutation = { __typename?: 'Mutation', createOneCameraConfig: { __typename?: 'CameraConfig', id: string } };

export type UpdateOneCameraConfigMutationVariables = Exact<{
  data: CameraConfigUpdateInput;
  where: CameraConfigWhereUniqueInput;
}>;


export type UpdateOneCameraConfigMutation = { __typename?: 'Mutation', updateOneCameraConfig?: { __typename?: 'CameraConfig', id: string } | null };

export type UserScenesQueryVariables = Exact<{
  where?: InputMaybe<UserSceneWhereInput>;
}>;


export type UserScenesQuery = { __typename?: 'Query', userScenes: Array<{ __typename?: 'UserScene', id: string, name: string, description: string, thumbnail: string, skybox?: number | null, lightTemplate?: number | null, userId: string, hasQuest?: boolean | null, questId?: string | null, isChatEnabled?: boolean | null, graphicsSettingsId?: string | null, isScanEnabled?: boolean | null, isCameraThirdPerson?: boolean | null, hasHideAndSeek?: boolean | null, hideAndSeekId?: string | null, cameraConfigId?: string | null, branding: Brand, gameMode?: GameType | null }> };

export type FindManyUserSceneAssetsQueryVariables = Exact<{
  where?: InputMaybe<UserSceneAssetsWhereInput>;
}>;


export type FindManyUserSceneAssetsQuery = { __typename?: 'Query', findManyUserSceneAssets: Array<{ __typename?: 'UserSceneAssets', userSceneId: string, lightId?: string | null, url?: string | null, type: string, id: string, name?: string | null, position: Array<number>, rotation: Array<number>, metadata?: string | null, scale: Array<number>, enablePurchase?: boolean | null, enableLinking?: boolean | null, portalSceneId?: string | null, isPortal?: boolean | null, marketId?: number | null, shopifyMetadata?: string | null, textureToChange?: string | null, textureToChangeNormal?: string | null, textureToChangeRoughness?: string | null, textureToChangeMetalness?: string | null, baseColor?: number | null, roughness?: number | null, metalness?: number | null }> };

export type FindFirstLightPropsQueryVariables = Exact<{
  where?: InputMaybe<LightPropsWhereInput>;
}>;


export type FindFirstLightPropsQuery = { __typename?: 'Query', findFirstLightProps?: { __typename?: 'LightProps', id: string, type: string, color?: number | null, intensity: number, distance?: number | null, decay?: number | null, angle?: number | null, penumbra?: number | null, visible?: boolean | null, shadow?: boolean | null, skyColor?: number | null, groundColor?: number | null } | null };

export type FindManyLightPropsQueryVariables = Exact<{
  where?: InputMaybe<LightPropsWhereInput>;
}>;


export type FindManyLightPropsQuery = { __typename?: 'Query', findManyLightProps: Array<{ __typename?: 'LightProps', id: string, color?: number | null, type: string, intensity: number, distance?: number | null, decay?: number | null, angle?: number | null, penumbra?: number | null, visible?: boolean | null, shadow?: boolean | null, skyColor?: number | null, groundColor?: number | null }> };

export type DeleteLightPropsMutationVariables = Exact<{
  where: LightPropsWhereUniqueInput;
}>;


export type DeleteLightPropsMutation = { __typename?: 'Mutation', deleteOneLightProps?: { __typename?: 'LightProps', id: string } | null };

export type DeleteManyLightPropsMutationVariables = Exact<{
  where?: InputMaybe<LightPropsWhereInput>;
}>;


export type DeleteManyLightPropsMutation = { __typename?: 'Mutation', deleteManyLightProps: { __typename?: 'AffectedRowsOutput', count: number } };

export type FindUniqueQuestQueryVariables = Exact<{
  where: FindUniqueQuestInput;
}>;


export type FindUniqueQuestQuery = { __typename?: 'Query', findUniqueQuest: { __typename?: 'findUniqueQuestResponse', quest?: { __typename?: 'Quest', id: string, name: string, startPrompt: string, rewardType: RewardType, rewardAmount?: number | null, hasReward: boolean, collectableItem: Array<string>, collectableCount: number, finishPrompt: string, fakeItem: Array<string>, fakeCount: number, splashScreenMessage: string, enableDefaultModal: boolean, unMarkedStartPrompt: string } | null } };

export type GetAssetVideoUrlByMetaOgMutationVariables = Exact<{
  url: Scalars['String']['input'];
}>;


export type GetAssetVideoUrlByMetaOgMutation = { __typename?: 'Mutation', getAssetVideoUrlByMetaOG: { __typename?: 'GetAssetVideoUrlByMetaOGResponse', url: string, status: string } };

export type GetShopifyStoreBySceneQueryVariables = Exact<{
  where: GetShopifyStoreBySceneInput;
}>;


export type GetShopifyStoreBySceneQuery = { __typename?: 'Query', getShopifyStoreByScene: { __typename?: 'GetShopifyStoreBySceneResponse', store?: { __typename?: 'ShopifyStore', apiVersion: string, domain: string, id: string, token: string, userSceneId: string } | null } };

export type CreateShopifyStoreMutationVariables = Exact<{
  where: CreateShopifyStoreInput;
}>;


export type CreateShopifyStoreMutation = { __typename?: 'Mutation', createShopifyStore: { __typename?: 'CreateShopifyStoreResponse', status: string } };

export type UpdateShopifyStoreMutationVariables = Exact<{
  data: UpdateShopifyStoreInput;
}>;


export type UpdateShopifyStoreMutation = { __typename?: 'Mutation', updateShopifyStore: { __typename?: 'UpdateShopifyStoreResponse', status: string } };

export type GetAssetVideoUrlByHtmlMutationVariables = Exact<{
  url: Scalars['String']['input'];
  type: Scalars['String']['input'];
}>;


export type GetAssetVideoUrlByHtmlMutation = { __typename?: 'Mutation', getAssetVideoUrlByHtml: { __typename?: 'GetAssetVideoUrlByMetaOGResponse', status: string, url: string } };

export type FindAliasSceneQueryVariables = Exact<{
  where: FindAliasSceneInput;
}>;


export type FindAliasSceneQuery = { __typename?: 'Query', findAliasScene: { __typename?: 'FindAliasSceneResponse', exists: boolean, sceneId?: string | null } };

export type UpdateAliasSceneMutationVariables = Exact<{
  where: UpdateAliasSceneInput;
}>;


export type UpdateAliasSceneMutation = { __typename?: 'Mutation', updateAliasScene: { __typename?: 'UpdateSceneResponse', errorMessage?: string | null, status: string } };

export type GetSceneAliasQueryVariables = Exact<{
  where: GetSceneAliasInput;
}>;


export type GetSceneAliasQuery = { __typename?: 'Query', getSceneAlias: { __typename?: 'GetSceneAliasResponse', alias?: string | null, message?: string | null } };

export type SetConversationSidMutationVariables = Exact<{
  where: SetConversationSidInput;
}>;


export type SetConversationSidMutation = { __typename?: 'Mutation', setConversationSid: { __typename?: 'SetConversationSidResponse', status: boolean } };

export type GetConversationSidQueryVariables = Exact<{
  where: GetConversationSidInput;
}>;


export type GetConversationSidQuery = { __typename?: 'Query', getConversationSid: { __typename?: 'GetConversationSidResponse', sid?: string | null } };

export type SetIsChatEnabledSceneMutationVariables = Exact<{
  where: SetIsChatEnabledSceneInput;
}>;


export type SetIsChatEnabledSceneMutation = { __typename?: 'Mutation', setIsChatEnabledScene: { __typename?: 'SetIsChatEnabledSceneResponse', status: boolean } };

export type IsChatEnabledInSceneQueryVariables = Exact<{
  where: IsChatEnabledInSceneInput;
}>;


export type IsChatEnabledInSceneQuery = { __typename?: 'Query', isChatEnabledInScene: { __typename?: 'IsChatEnabledInSceneResponse', enabled: boolean } };

export type SetIsScanEnabledSceneMutationVariables = Exact<{
  where: SetIsScanEnabledSceneInput;
}>;


export type SetIsScanEnabledSceneMutation = { __typename?: 'Mutation', setIsScanEnabledScene: { __typename?: 'SetIsScanEnabledSceneResponse', status: boolean } };

export type IsScanEnabledInSceneQueryVariables = Exact<{
  where: IsScanEnabledInSceneInput;
}>;


export type IsScanEnabledInSceneQuery = { __typename?: 'Query', isScanEnabledInScene: { __typename?: 'IsScanEnabledInSceneResponse', enabled: boolean } };

export type SetIsCameraThirdPersonSceneMutationVariables = Exact<{
  where: SetIsCameraThirdPersonSceneInput;
}>;


export type SetIsCameraThirdPersonSceneMutation = { __typename?: 'Mutation', setIsCameraThirdPersonScene: { __typename?: 'SetIsCameraThirdPersonSceneResponse', status: boolean } };

export type GetUnityPackageMutationVariables = Exact<{
  data: GetUnityPackageInput;
}>;


export type GetUnityPackageMutation = { __typename?: 'Mutation', getUnityPackage: { __typename?: 'GetUnityPackageResponse', status: boolean, fileURL: string } };

export type CreateOneTourItemsMutationVariables = Exact<{
  data: TourItemsCreateInput;
}>;


export type CreateOneTourItemsMutation = { __typename?: 'Mutation', createOneTourItems: { __typename?: 'TourItems', id: string, name: string, description: string, userSceneId: string, position: Array<number> } };

export type FindManyTourItemsQueryVariables = Exact<{
  where?: InputMaybe<TourItemsWhereInput>;
}>;


export type FindManyTourItemsQuery = { __typename?: 'Query', findManyTourItems: Array<{ __typename?: 'TourItems', id: string, name: string, description: string, userSceneId: string, position: Array<number> }> };

export type DeleteOneTourItemsMutationVariables = Exact<{
  where: TourItemsWhereUniqueInput;
}>;


export type DeleteOneTourItemsMutation = { __typename?: 'Mutation', deleteOneTourItems?: { __typename?: 'TourItems', id: string, name: string, description: string, userSceneId: string, position: Array<number> } | null };

export type UpdateSceneAssetsMutationVariables = Exact<{
  data: UpdateSceneAssetsInput;
}>;


export type UpdateSceneAssetsMutation = { __typename?: 'Mutation', updateSceneAssets: { __typename?: 'UpdateSceneAssetsResponse', status: boolean, message?: string | null } };

export type GetWalletBalanceQueryVariables = Exact<{
  data: GetWalletBalanceInput;
}>;


export type GetWalletBalanceQuery = { __typename?: 'Query', getWalletBalance: { __typename?: 'GetWalletBalanceResponse', balance: number, message?: string | null } };

export type DepositFcMutationVariables = Exact<{
  data: DepositFcInput;
}>;


export type DepositFcMutation = { __typename?: 'Mutation', depositFC: { __typename?: 'DepositFCResponse', status: boolean, message?: string | null } };

export type ConvertOzoneToFcMutationVariables = Exact<{
  where: ConvertOzoneToFcInput;
}>;


export type ConvertOzoneToFcMutation = { __typename?: 'Mutation', convertOzoneToFC: { __typename?: 'ConvertOzoneToFCResponse', status: boolean, convertedPrice?: number | null } };

export type FindMatchesByUserIdQueryVariables = Exact<{
  where: FindMatchesByUserIdInput;
}>;


export type FindMatchesByUserIdQuery = { __typename?: 'Query', findMatchesByUserId: { __typename?: 'FindMatchesByUserIdResponse', matches?: Array<{ __typename?: 'FightMatches', id: string, player1: string, player2: string, winner?: string | null, createdAt: any, reward?: number | null, status?: MatchStatus | null }> | null } };

export type BuyAssetWeb2FcMutationVariables = Exact<{
  data: BuyAssetWeb2FcInput;
}>;


export type BuyAssetWeb2FcMutation = { __typename?: 'Mutation', buyAssetWeb2FC: { __typename?: 'BuyAssetWeb2FCResponse', status: boolean, message?: string | null } };

export type WearSkinMutationVariables = Exact<{
  where: WearSkinInput;
}>;


export type WearSkinMutation = { __typename?: 'Mutation', wearSkin: { __typename?: 'WearSkinResponse', status: boolean, message?: string | null } };

export type GetUserWardrobeQueryVariables = Exact<{
  where: GetUserWardrobeInput;
}>;


export type GetUserWardrobeQuery = { __typename?: 'Query', getUserWardrobe: { __typename?: 'GetUserWardrobeResponse', wardrobe?: { __typename?: 'SkinsWardrobe', id: string, userId: string, skins?: any | null } | null } };

export type GetProfileByUserIdQueryVariables = Exact<{
  where: GetProfileByUserIdInput;
}>;


export type GetProfileByUserIdQuery = { __typename?: 'Query', getProfileByUserId: { __typename?: 'GetProfileByUserIdResponse', status: boolean, message?: string | null, profile?: { __typename?: 'Profile', id: string, timePlayed?: number | null, timeInLobby?: number | null, createdAt: any, username: string, totalGames?: number | null, wins?: number | null, loses?: number | null, draws?: number | null } | null } };

export type CreateUserProfileMutationVariables = Exact<{
  data: CreateUserProfileInput;
}>;


export type CreateUserProfileMutation = { __typename?: 'Mutation', createUserProfile: { __typename?: 'CreateUserProfileResponse', status: boolean, message?: string | null } };

export type ChangeUsernameMutationVariables = Exact<{
  data: ChangeUsernameInput;
}>;


export type ChangeUsernameMutation = { __typename?: 'Mutation', changeUsername: { __typename?: 'ChangeUsernameResponse', status: boolean, message?: string | null } };

export type FindFirstHideAndSeekQueryVariables = Exact<{
  where?: InputMaybe<HideAndSeekWhereInput>;
}>;


export type FindFirstHideAndSeekQuery = { __typename?: 'Query', findFirstHideAndSeek?: { __typename?: 'HideAndSeek', finishPrompt: string, id: string, startPrompt: string, searchTime: number } | null };

export type HideAndSeekNpcsQueryVariables = Exact<{
  where?: InputMaybe<HideAndSeekNpcWhereInput>;
}>;


export type HideAndSeekNpcsQuery = { __typename?: 'Query', hideAndSeekNPCS: Array<{ __typename?: 'HideAndSeekNPC', hideAndSeekId?: string | null, id: string, propUrl: string, assetUrl: string }> };

export type CreateOneHideAndSeekMutationVariables = Exact<{
  data: HideAndSeekCreateInput;
}>;


export type CreateOneHideAndSeekMutation = { __typename?: 'Mutation', createOneHideAndSeek: { __typename?: 'HideAndSeek', startPrompt: string, finishPrompt: string, id: string } };

export type UpdateOneHideAndSeekMutationVariables = Exact<{
  data: HideAndSeekUpdateInput;
  where: HideAndSeekWhereUniqueInput;
}>;


export type UpdateOneHideAndSeekMutation = { __typename?: 'Mutation', updateOneHideAndSeek?: { __typename?: 'HideAndSeek', finishPrompt: string, id: string, startPrompt: string } | null };

export type DeleteManyHideAndSeekNpcMutationVariables = Exact<{
  where?: InputMaybe<HideAndSeekNpcWhereInput>;
}>;


export type DeleteManyHideAndSeekNpcMutation = { __typename?: 'Mutation', deleteManyHideAndSeekNPC: { __typename?: 'AffectedRowsOutput', count: number } };

export type UpdateOneGraphicsSettingsMutationVariables = Exact<{
  data: GraphicsSettingsUpdateInput;
  where: GraphicsSettingsWhereUniqueInput;
}>;


export type UpdateOneGraphicsSettingsMutation = { __typename?: 'Mutation', updateOneGraphicsSettings?: { __typename?: 'GraphicsSettings', id: string } | null };

export type UpdateOnePostProcessingSettingsMutationVariables = Exact<{
  data: PostProcessingSettingsUpdateInput;
  where: PostProcessingSettingsWhereUniqueInput;
}>;


export type UpdateOnePostProcessingSettingsMutation = { __typename?: 'Mutation', updateOnePostProcessingSettings?: { __typename?: 'PostProcessingSettings', antialiasing?: string | null, bloom?: string | null, id: string, ssr?: string | null, ssao?: string | null } | null };

export type FindFirstPostProcessingSettingsQueryVariables = Exact<{
  where?: InputMaybe<PostProcessingSettingsWhereInput>;
}>;


export type FindFirstPostProcessingSettingsQuery = { __typename?: 'Query', findFirstPostProcessingSettings?: { __typename?: 'PostProcessingSettings', antialiasing?: string | null, bloom?: string | null, id: string, ssr?: string | null, ssao?: string | null } | null };

export type FindFirstGraphicsSettingsQueryVariables = Exact<{
  where?: InputMaybe<GraphicsSettingsWhereInput>;
}>;


export type FindFirstGraphicsSettingsQuery = { __typename?: 'Query', findFirstGraphicsSettings?: { __typename?: 'GraphicsSettings', id: string, postProcessingSettingsId?: string | null, preset?: string | null, physicsEngine?: string | null } | null };

export type CreateOneGraphicsSettingsMutationVariables = Exact<{
  data?: InputMaybe<GraphicsSettingsCreateInput>;
}>;


export type CreateOneGraphicsSettingsMutation = { __typename?: 'Mutation', createOneGraphicsSettings: { __typename?: 'GraphicsSettings', preset?: string | null, postProcessingSettingsId?: string | null, id: string, physicsEngine?: string | null } };

export type SwitchEnableQuestsMutationVariables = Exact<{
  data: SwitchEnableQuestsInput;
}>;


export type SwitchEnableQuestsMutation = { __typename?: 'Mutation', switchEnableQuests: { __typename?: 'SwitchEnableQuestsResponse', status: string } };

export type UserFinishedQuestMutationVariables = Exact<{
  data: UserFinishedQuestInput;
}>;


export type UserFinishedQuestMutation = { __typename?: 'Mutation', userFinishedQuest: { __typename?: 'UserFinishedQuestResponse', status: string } };

export type UpdateQuestMutationVariables = Exact<{
  data: UpdateQuestInput;
}>;


export type UpdateQuestMutation = { __typename?: 'Mutation', updateQuest: { __typename?: 'UpdateQuestResponse', status: string } };

export type GetAssetsWeb2QueryVariables = Exact<{
  where: GetAssetsWeb2Input;
}>;


export type GetAssetsWeb2Query = { __typename?: 'Query', getAssetsWeb2: { __typename?: 'GetAssetsWeb2Response', totalCount: number, assets?: Array<{ __typename?: 'AssetWeb2', id: string, name: string, description: string, assetURL: string, fileType: FileType, previewImage: string, price: number, currency: Currency, createdAt: any, viewsCount: number, creatorEmail: string, collectionId: string, tag: Array<string> }> | null } };

export type GetBoughtAssetsByUserQueryVariables = Exact<{
  where: GetBoughtAssetsByUserInput;
}>;


export type GetBoughtAssetsByUserQuery = { __typename?: 'Query', getBoughtAssetsByUser: { __typename?: 'GetBoughtAssetsByUserResponse', totalCount: number, assets?: Array<{ __typename?: 'AssetWeb2', id: string, name: string, description: string, assetURL: string, fileType: FileType, previewImage: string, createdAt: any, price: number, currency: Currency, viewsCount: number, creatorEmail: string, collectionId: string, tag: Array<string> }> | null } };

export type LoginQueryVariables = Exact<{
  data: LoginInput;
}>;


export type LoginQuery = { __typename?: 'Query', login: { __typename?: 'Login', isLoggedIn: boolean } };

export type GetUserPublicQueryVariables = Exact<{
  where: UsersWhereUniqueInput;
}>;


export type GetUserPublicQuery = { __typename?: 'Query', getUserPublic: { __typename?: 'UserPublic', user?: { __typename?: 'UserPublicObject', id?: string | null, role?: string | null, email?: string | null, avatarUrl?: string | null, bannerUrl?: string | null } | null } };

export type SignUpMutationVariables = Exact<{
  data: SignUpInput;
}>;


export type SignUpMutation = { __typename?: 'Mutation', signUp: { __typename?: 'SignUp', isSignedUp: boolean } };

export type VerifyAccountMutationVariables = Exact<{
  data: VerifyAccountInput;
}>;


export type VerifyAccountMutation = { __typename?: 'Mutation', verifyAccount: { __typename?: 'VerifyAccount', isVerified: boolean } };

export type ForgotPasswordMutationVariables = Exact<{
  data: ForgotPasswordInput;
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword: { __typename?: 'ForgotPassword', forgotPassword: boolean } };

export type ChangeUserPasswordMutationVariables = Exact<{
  data: ChangeUserPasswordInput;
  where: UsersWhereUniqueInput;
}>;


export type ChangeUserPasswordMutation = { __typename?: 'Mutation', changeUserPassword: { __typename?: 'ChangeUserPassword', changePassword: boolean } };

export type UpdateUserMutationVariables = Exact<{
  where: UsersWhereUniqueInput;
  data: UserUpdateInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'UserPublic', user?: { __typename?: 'UserPublicObject', id?: string | null, email?: string | null, role?: string | null } | null } };

export type GoogleLoginQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type GoogleLoginQuery = { __typename?: 'Query', googleLogin: { __typename?: 'GoogleLoginRes', status: string, user?: { __typename?: 'Users', id: string, email: string, verificationToken: string, password: string, isVerified: boolean, subscribed: boolean, changePasswordToken?: string | null, avatarUrl?: string | null, bannerUrl?: string | null, role?: string | null, firstName?: string | null, lastName?: string | null, registrationOrigin?: string | null, createdAt: any, updatedAt: any } | null } };


export const GetAllDomainSceneDocument = gql`
    query GetAllDomainScene($where: GetAllDomainSceneInput!) {
  getAllDomainScene(where: $where) {
    domainScene {
      domain
      sceneId
      id
    }
    total
  }
}
    `;

/**
 * __useGetAllDomainSceneQuery__
 *
 * To run a query within a React component, call `useGetAllDomainSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDomainSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDomainSceneQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAllDomainSceneQuery(baseOptions: Apollo.QueryHookOptions<GetAllDomainSceneQuery, GetAllDomainSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllDomainSceneQuery, GetAllDomainSceneQueryVariables>(GetAllDomainSceneDocument, options);
      }
export function useGetAllDomainSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDomainSceneQuery, GetAllDomainSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllDomainSceneQuery, GetAllDomainSceneQueryVariables>(GetAllDomainSceneDocument, options);
        }
export type GetAllDomainSceneQueryHookResult = ReturnType<typeof useGetAllDomainSceneQuery>;
export type GetAllDomainSceneLazyQueryHookResult = ReturnType<typeof useGetAllDomainSceneLazyQuery>;
export type GetAllDomainSceneQueryResult = Apollo.QueryResult<GetAllDomainSceneQuery, GetAllDomainSceneQueryVariables>;
export const UpdateDomainSceneDocument = gql`
    mutation UpdateDomainScene($data: UpdateDomainSceneInput!) {
  updateDomainScene(data: $data) {
    status
  }
}
    `;
export type UpdateDomainSceneMutationFn = Apollo.MutationFunction<UpdateDomainSceneMutation, UpdateDomainSceneMutationVariables>;

/**
 * __useUpdateDomainSceneMutation__
 *
 * To run a mutation, you first call `useUpdateDomainSceneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDomainSceneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDomainSceneMutation, { data, loading, error }] = useUpdateDomainSceneMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDomainSceneMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDomainSceneMutation, UpdateDomainSceneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDomainSceneMutation, UpdateDomainSceneMutationVariables>(UpdateDomainSceneDocument, options);
      }
export type UpdateDomainSceneMutationHookResult = ReturnType<typeof useUpdateDomainSceneMutation>;
export type UpdateDomainSceneMutationResult = Apollo.MutationResult<UpdateDomainSceneMutation>;
export type UpdateDomainSceneMutationOptions = Apollo.BaseMutationOptions<UpdateDomainSceneMutation, UpdateDomainSceneMutationVariables>;
export const CreateDomainSceneDocument = gql`
    mutation CreateDomainScene($data: CreateDomainSceneInput!) {
  createDomainScene(data: $data) {
    status
  }
}
    `;
export type CreateDomainSceneMutationFn = Apollo.MutationFunction<CreateDomainSceneMutation, CreateDomainSceneMutationVariables>;

/**
 * __useCreateDomainSceneMutation__
 *
 * To run a mutation, you first call `useCreateDomainSceneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDomainSceneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDomainSceneMutation, { data, loading, error }] = useCreateDomainSceneMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDomainSceneMutation(baseOptions?: Apollo.MutationHookOptions<CreateDomainSceneMutation, CreateDomainSceneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDomainSceneMutation, CreateDomainSceneMutationVariables>(CreateDomainSceneDocument, options);
      }
export type CreateDomainSceneMutationHookResult = ReturnType<typeof useCreateDomainSceneMutation>;
export type CreateDomainSceneMutationResult = Apollo.MutationResult<CreateDomainSceneMutation>;
export type CreateDomainSceneMutationOptions = Apollo.BaseMutationOptions<CreateDomainSceneMutation, CreateDomainSceneMutationVariables>;
export const DeleteDomainSceneDocument = gql`
    mutation DeleteDomainScene($where: DeleteDomainSceneInput!) {
  deleteDomainScene(where: $where) {
    status
  }
}
    `;
export type DeleteDomainSceneMutationFn = Apollo.MutationFunction<DeleteDomainSceneMutation, DeleteDomainSceneMutationVariables>;

/**
 * __useDeleteDomainSceneMutation__
 *
 * To run a mutation, you first call `useDeleteDomainSceneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDomainSceneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDomainSceneMutation, { data, loading, error }] = useDeleteDomainSceneMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteDomainSceneMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDomainSceneMutation, DeleteDomainSceneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDomainSceneMutation, DeleteDomainSceneMutationVariables>(DeleteDomainSceneDocument, options);
      }
export type DeleteDomainSceneMutationHookResult = ReturnType<typeof useDeleteDomainSceneMutation>;
export type DeleteDomainSceneMutationResult = Apollo.MutationResult<DeleteDomainSceneMutation>;
export type DeleteDomainSceneMutationOptions = Apollo.BaseMutationOptions<DeleteDomainSceneMutation, DeleteDomainSceneMutationVariables>;
export const AssetPaginationDocument = gql`
    query AssetPagination($where: AssetPaginationInput!) {
  assetPagination(where: $where) {
    assets {
      id
      name
      countCopies
      maxSupply
      description
      assetURL
      fileType
      createdAt
      previewImage
      price
      currency
      viewsCount
      creatorEmail
      collectionId
    }
    totalCount
  }
}
    `;

/**
 * __useAssetPaginationQuery__
 *
 * To run a query within a React component, call `useAssetPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetPaginationQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAssetPaginationQuery(baseOptions: Apollo.QueryHookOptions<AssetPaginationQuery, AssetPaginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetPaginationQuery, AssetPaginationQueryVariables>(AssetPaginationDocument, options);
      }
export function useAssetPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetPaginationQuery, AssetPaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetPaginationQuery, AssetPaginationQueryVariables>(AssetPaginationDocument, options);
        }
export type AssetPaginationQueryHookResult = ReturnType<typeof useAssetPaginationQuery>;
export type AssetPaginationLazyQueryHookResult = ReturnType<typeof useAssetPaginationLazyQuery>;
export type AssetPaginationQueryResult = Apollo.QueryResult<AssetPaginationQuery, AssetPaginationQueryVariables>;
export const GetCartDocument = gql`
    query getCart($data: GetCartInput!) {
  getCart(data: $data) {
    status
    message
    cart {
      id
      assetIdWeb2
      price
      name
      assetURL
      type
      fileType
      previewImage
      creatorWalletType
    }
  }
}
    `;

/**
 * __useGetCartQuery__
 *
 * To run a query within a React component, call `useGetCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCartQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetCartQuery(baseOptions: Apollo.QueryHookOptions<GetCartQuery, GetCartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCartQuery, GetCartQueryVariables>(GetCartDocument, options);
      }
export function useGetCartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCartQuery, GetCartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCartQuery, GetCartQueryVariables>(GetCartDocument, options);
        }
export type GetCartQueryHookResult = ReturnType<typeof useGetCartQuery>;
export type GetCartLazyQueryHookResult = ReturnType<typeof useGetCartLazyQuery>;
export type GetCartQueryResult = Apollo.QueryResult<GetCartQuery, GetCartQueryVariables>;
export const CreateCartDocument = gql`
    mutation createCart($data: CreateCartInput!) {
  createCart(data: $data) {
    status
    message
  }
}
    `;
export type CreateCartMutationFn = Apollo.MutationFunction<CreateCartMutation, CreateCartMutationVariables>;

/**
 * __useCreateCartMutation__
 *
 * To run a mutation, you first call `useCreateCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCartMutation, { data, loading, error }] = useCreateCartMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCartMutation(baseOptions?: Apollo.MutationHookOptions<CreateCartMutation, CreateCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCartMutation, CreateCartMutationVariables>(CreateCartDocument, options);
      }
export type CreateCartMutationHookResult = ReturnType<typeof useCreateCartMutation>;
export type CreateCartMutationResult = Apollo.MutationResult<CreateCartMutation>;
export type CreateCartMutationOptions = Apollo.BaseMutationOptions<CreateCartMutation, CreateCartMutationVariables>;
export const GetAssetsByCreatorDocument = gql`
    query GetAssetsByCreator($where: AssetGetByCreatorInput!) {
  getAssetsByCreator(where: $where) {
    assets {
      id
      name
      countCopies
      maxSupply
      description
      assetURL
      featured
      fileType
      createdAt
      previewImage
      price
      currency
      viewsCount
      creatorEmail
      collectionId
    }
  }
}
    `;

/**
 * __useGetAssetsByCreatorQuery__
 *
 * To run a query within a React component, call `useGetAssetsByCreatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetsByCreatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetsByCreatorQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAssetsByCreatorQuery(baseOptions: Apollo.QueryHookOptions<GetAssetsByCreatorQuery, GetAssetsByCreatorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssetsByCreatorQuery, GetAssetsByCreatorQueryVariables>(GetAssetsByCreatorDocument, options);
      }
export function useGetAssetsByCreatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssetsByCreatorQuery, GetAssetsByCreatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssetsByCreatorQuery, GetAssetsByCreatorQueryVariables>(GetAssetsByCreatorDocument, options);
        }
export type GetAssetsByCreatorQueryHookResult = ReturnType<typeof useGetAssetsByCreatorQuery>;
export type GetAssetsByCreatorLazyQueryHookResult = ReturnType<typeof useGetAssetsByCreatorLazyQuery>;
export type GetAssetsByCreatorQueryResult = Apollo.QueryResult<GetAssetsByCreatorQuery, GetAssetsByCreatorQueryVariables>;
export const GetAssetInfoDocument = gql`
    query GetAssetInfo($where: GetAssetInfoInput!) {
  getAssetInfo(where: $where) {
    asset {
      id
      name
      description
      currency
      creator
      likesCount
      category
      viewsCount
      creatorId
      price
      countCopies
      maxSupply
      fileType
      previewImage
      assetURL
      collection
      collectionId
    }
  }
}
    `;

/**
 * __useGetAssetInfoQuery__
 *
 * To run a query within a React component, call `useGetAssetInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetInfoQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAssetInfoQuery(baseOptions: Apollo.QueryHookOptions<GetAssetInfoQuery, GetAssetInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssetInfoQuery, GetAssetInfoQueryVariables>(GetAssetInfoDocument, options);
      }
export function useGetAssetInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssetInfoQuery, GetAssetInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssetInfoQuery, GetAssetInfoQueryVariables>(GetAssetInfoDocument, options);
        }
export type GetAssetInfoQueryHookResult = ReturnType<typeof useGetAssetInfoQuery>;
export type GetAssetInfoLazyQueryHookResult = ReturnType<typeof useGetAssetInfoLazyQuery>;
export type GetAssetInfoQueryResult = Apollo.QueryResult<GetAssetInfoQuery, GetAssetInfoQueryVariables>;
export const AddToCartDocument = gql`
    mutation addToCart($data: AddToCartInput!) {
  addToCart(data: $data) {
    status
    message
  }
}
    `;
export type AddToCartMutationFn = Apollo.MutationFunction<AddToCartMutation, AddToCartMutationVariables>;

/**
 * __useAddToCartMutation__
 *
 * To run a mutation, you first call `useAddToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToCartMutation, { data, loading, error }] = useAddToCartMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddToCartMutation(baseOptions?: Apollo.MutationHookOptions<AddToCartMutation, AddToCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddToCartMutation, AddToCartMutationVariables>(AddToCartDocument, options);
      }
export type AddToCartMutationHookResult = ReturnType<typeof useAddToCartMutation>;
export type AddToCartMutationResult = Apollo.MutationResult<AddToCartMutation>;
export type AddToCartMutationOptions = Apollo.BaseMutationOptions<AddToCartMutation, AddToCartMutationVariables>;
export const RemoveFromCartDocument = gql`
    mutation removeFromCart($data: RemoveFromCartInput!) {
  removeFromCart(data: $data) {
    status
    message
  }
}
    `;
export type RemoveFromCartMutationFn = Apollo.MutationFunction<RemoveFromCartMutation, RemoveFromCartMutationVariables>;

/**
 * __useRemoveFromCartMutation__
 *
 * To run a mutation, you first call `useRemoveFromCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromCartMutation, { data, loading, error }] = useRemoveFromCartMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveFromCartMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFromCartMutation, RemoveFromCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFromCartMutation, RemoveFromCartMutationVariables>(RemoveFromCartDocument, options);
      }
export type RemoveFromCartMutationHookResult = ReturnType<typeof useRemoveFromCartMutation>;
export type RemoveFromCartMutationResult = Apollo.MutationResult<RemoveFromCartMutation>;
export type RemoveFromCartMutationOptions = Apollo.BaseMutationOptions<RemoveFromCartMutation, RemoveFromCartMutationVariables>;
export const SubscriptionPlansDocument = gql`
    query SubscriptionPlans {
  subscriptionPlans {
    id
    name
  }
}
    `;

/**
 * __useSubscriptionPlansQuery__
 *
 * To run a query within a React component, call `useSubscriptionPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionPlansQuery(baseOptions?: Apollo.QueryHookOptions<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>(SubscriptionPlansDocument, options);
      }
export function useSubscriptionPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>(SubscriptionPlansDocument, options);
        }
export type SubscriptionPlansQueryHookResult = ReturnType<typeof useSubscriptionPlansQuery>;
export type SubscriptionPlansLazyQueryHookResult = ReturnType<typeof useSubscriptionPlansLazyQuery>;
export type SubscriptionPlansQueryResult = Apollo.QueryResult<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>;
export const GetUserSubscriptionDocument = gql`
    query GetUserSubscription($where: SubscriptionPlanWhereInput) {
  subscriptionPlans(where: $where) {
    name
    description
    id
    numberOfScenes
    type
  }
}
    `;

/**
 * __useGetUserSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetUserSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSubscriptionQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUserSubscriptionQuery(baseOptions?: Apollo.QueryHookOptions<GetUserSubscriptionQuery, GetUserSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserSubscriptionQuery, GetUserSubscriptionQueryVariables>(GetUserSubscriptionDocument, options);
      }
export function useGetUserSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserSubscriptionQuery, GetUserSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserSubscriptionQuery, GetUserSubscriptionQueryVariables>(GetUserSubscriptionDocument, options);
        }
export type GetUserSubscriptionQueryHookResult = ReturnType<typeof useGetUserSubscriptionQuery>;
export type GetUserSubscriptionLazyQueryHookResult = ReturnType<typeof useGetUserSubscriptionLazyQuery>;
export type GetUserSubscriptionQueryResult = Apollo.QueryResult<GetUserSubscriptionQuery, GetUserSubscriptionQueryVariables>;
export const CreateUserSubscriptionDocument = gql`
    mutation CreateUserSubscription($data: UserSubscriptionCreateInput!) {
  createOneUserSubscription(data: $data) {
    userId
    subscriptionPlanId
  }
}
    `;
export type CreateUserSubscriptionMutationFn = Apollo.MutationFunction<CreateUserSubscriptionMutation, CreateUserSubscriptionMutationVariables>;

/**
 * __useCreateUserSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateUserSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserSubscriptionMutation, { data, loading, error }] = useCreateUserSubscriptionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserSubscriptionMutation, CreateUserSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserSubscriptionMutation, CreateUserSubscriptionMutationVariables>(CreateUserSubscriptionDocument, options);
      }
export type CreateUserSubscriptionMutationHookResult = ReturnType<typeof useCreateUserSubscriptionMutation>;
export type CreateUserSubscriptionMutationResult = Apollo.MutationResult<CreateUserSubscriptionMutation>;
export type CreateUserSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateUserSubscriptionMutation, CreateUserSubscriptionMutationVariables>;
export const DeleteUserSubscriptionDocument = gql`
    mutation DeleteUserSubscription($where: UserSubscriptionWhereUniqueInput!) {
  deleteOneUserSubscription(where: $where) {
    userId
  }
}
    `;
export type DeleteUserSubscriptionMutationFn = Apollo.MutationFunction<DeleteUserSubscriptionMutation, DeleteUserSubscriptionMutationVariables>;

/**
 * __useDeleteUserSubscriptionMutation__
 *
 * To run a mutation, you first call `useDeleteUserSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserSubscriptionMutation, { data, loading, error }] = useDeleteUserSubscriptionMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteUserSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserSubscriptionMutation, DeleteUserSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserSubscriptionMutation, DeleteUserSubscriptionMutationVariables>(DeleteUserSubscriptionDocument, options);
      }
export type DeleteUserSubscriptionMutationHookResult = ReturnType<typeof useDeleteUserSubscriptionMutation>;
export type DeleteUserSubscriptionMutationResult = Apollo.MutationResult<DeleteUserSubscriptionMutation>;
export type DeleteUserSubscriptionMutationOptions = Apollo.BaseMutationOptions<DeleteUserSubscriptionMutation, DeleteUserSubscriptionMutationVariables>;
export const CreateUserSceneAssetsDocument = gql`
    mutation CreateUserSceneAssets($data: UserSceneAssetsCreateInput!) {
  createOneUserSceneAssets(data: $data) {
    id
    lightId
  }
}
    `;
export type CreateUserSceneAssetsMutationFn = Apollo.MutationFunction<CreateUserSceneAssetsMutation, CreateUserSceneAssetsMutationVariables>;

/**
 * __useCreateUserSceneAssetsMutation__
 *
 * To run a mutation, you first call `useCreateUserSceneAssetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserSceneAssetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserSceneAssetsMutation, { data, loading, error }] = useCreateUserSceneAssetsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserSceneAssetsMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserSceneAssetsMutation, CreateUserSceneAssetsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserSceneAssetsMutation, CreateUserSceneAssetsMutationVariables>(CreateUserSceneAssetsDocument, options);
      }
export type CreateUserSceneAssetsMutationHookResult = ReturnType<typeof useCreateUserSceneAssetsMutation>;
export type CreateUserSceneAssetsMutationResult = Apollo.MutationResult<CreateUserSceneAssetsMutation>;
export type CreateUserSceneAssetsMutationOptions = Apollo.BaseMutationOptions<CreateUserSceneAssetsMutation, CreateUserSceneAssetsMutationVariables>;
export const UpdateUserSceneAssetsDocument = gql`
    mutation UpdateUserSceneAssets($data: UserSceneAssetsUpdateInput!, $where: UserSceneAssetsWhereUniqueInput!) {
  updateOneUserSceneAssets(data: $data, where: $where) {
    description
    id
    name
  }
}
    `;
export type UpdateUserSceneAssetsMutationFn = Apollo.MutationFunction<UpdateUserSceneAssetsMutation, UpdateUserSceneAssetsMutationVariables>;

/**
 * __useUpdateUserSceneAssetsMutation__
 *
 * To run a mutation, you first call `useUpdateUserSceneAssetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSceneAssetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSceneAssetsMutation, { data, loading, error }] = useUpdateUserSceneAssetsMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateUserSceneAssetsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserSceneAssetsMutation, UpdateUserSceneAssetsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserSceneAssetsMutation, UpdateUserSceneAssetsMutationVariables>(UpdateUserSceneAssetsDocument, options);
      }
export type UpdateUserSceneAssetsMutationHookResult = ReturnType<typeof useUpdateUserSceneAssetsMutation>;
export type UpdateUserSceneAssetsMutationResult = Apollo.MutationResult<UpdateUserSceneAssetsMutation>;
export type UpdateUserSceneAssetsMutationOptions = Apollo.BaseMutationOptions<UpdateUserSceneAssetsMutation, UpdateUserSceneAssetsMutationVariables>;
export const UpdateUserSceneDocument = gql`
    mutation UpdateUserScene($data: UserSceneUpdateInput!, $where: UserSceneWhereUniqueInput!) {
  updateOneUserScene(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateUserSceneMutationFn = Apollo.MutationFunction<UpdateUserSceneMutation, UpdateUserSceneMutationVariables>;

/**
 * __useUpdateUserSceneMutation__
 *
 * To run a mutation, you first call `useUpdateUserSceneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSceneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSceneMutation, { data, loading, error }] = useUpdateUserSceneMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateUserSceneMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserSceneMutation, UpdateUserSceneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserSceneMutation, UpdateUserSceneMutationVariables>(UpdateUserSceneDocument, options);
      }
export type UpdateUserSceneMutationHookResult = ReturnType<typeof useUpdateUserSceneMutation>;
export type UpdateUserSceneMutationResult = Apollo.MutationResult<UpdateUserSceneMutation>;
export type UpdateUserSceneMutationOptions = Apollo.BaseMutationOptions<UpdateUserSceneMutation, UpdateUserSceneMutationVariables>;
export const CreateUserSceneDocument = gql`
    mutation CreateUserScene($data: UserSceneCreateInput!) {
  createOneUserScene(data: $data) {
    id
    name
  }
}
    `;
export type CreateUserSceneMutationFn = Apollo.MutationFunction<CreateUserSceneMutation, CreateUserSceneMutationVariables>;

/**
 * __useCreateUserSceneMutation__
 *
 * To run a mutation, you first call `useCreateUserSceneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserSceneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserSceneMutation, { data, loading, error }] = useCreateUserSceneMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserSceneMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserSceneMutation, CreateUserSceneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserSceneMutation, CreateUserSceneMutationVariables>(CreateUserSceneDocument, options);
      }
export type CreateUserSceneMutationHookResult = ReturnType<typeof useCreateUserSceneMutation>;
export type CreateUserSceneMutationResult = Apollo.MutationResult<CreateUserSceneMutation>;
export type CreateUserSceneMutationOptions = Apollo.BaseMutationOptions<CreateUserSceneMutation, CreateUserSceneMutationVariables>;
export const DeleteUserSceneDocument = gql`
    mutation DeleteUserScene($where: UserSceneWhereUniqueInput!) {
  deleteOneUserScene(where: $where) {
    id
    name
  }
}
    `;
export type DeleteUserSceneMutationFn = Apollo.MutationFunction<DeleteUserSceneMutation, DeleteUserSceneMutationVariables>;

/**
 * __useDeleteUserSceneMutation__
 *
 * To run a mutation, you first call `useDeleteUserSceneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserSceneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserSceneMutation, { data, loading, error }] = useDeleteUserSceneMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteUserSceneMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserSceneMutation, DeleteUserSceneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserSceneMutation, DeleteUserSceneMutationVariables>(DeleteUserSceneDocument, options);
      }
export type DeleteUserSceneMutationHookResult = ReturnType<typeof useDeleteUserSceneMutation>;
export type DeleteUserSceneMutationResult = Apollo.MutationResult<DeleteUserSceneMutation>;
export type DeleteUserSceneMutationOptions = Apollo.BaseMutationOptions<DeleteUserSceneMutation, DeleteUserSceneMutationVariables>;
export const DeleteManyUserSceneAssetsDocument = gql`
    mutation DeleteManyUserSceneAssets($where: UserSceneAssetsWhereInput) {
  deleteManyUserSceneAssets(where: $where) {
    count
  }
}
    `;
export type DeleteManyUserSceneAssetsMutationFn = Apollo.MutationFunction<DeleteManyUserSceneAssetsMutation, DeleteManyUserSceneAssetsMutationVariables>;

/**
 * __useDeleteManyUserSceneAssetsMutation__
 *
 * To run a mutation, you first call `useDeleteManyUserSceneAssetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyUserSceneAssetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyUserSceneAssetsMutation, { data, loading, error }] = useDeleteManyUserSceneAssetsMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyUserSceneAssetsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyUserSceneAssetsMutation, DeleteManyUserSceneAssetsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyUserSceneAssetsMutation, DeleteManyUserSceneAssetsMutationVariables>(DeleteManyUserSceneAssetsDocument, options);
      }
export type DeleteManyUserSceneAssetsMutationHookResult = ReturnType<typeof useDeleteManyUserSceneAssetsMutation>;
export type DeleteManyUserSceneAssetsMutationResult = Apollo.MutationResult<DeleteManyUserSceneAssetsMutation>;
export type DeleteManyUserSceneAssetsMutationOptions = Apollo.BaseMutationOptions<DeleteManyUserSceneAssetsMutation, DeleteManyUserSceneAssetsMutationVariables>;
export const DeleteUserSceneAssetsDocument = gql`
    mutation DeleteUserSceneAssets($where: UserSceneAssetsWhereUniqueInput!) {
  deleteOneUserSceneAssets(where: $where) {
    userSceneId
  }
}
    `;
export type DeleteUserSceneAssetsMutationFn = Apollo.MutationFunction<DeleteUserSceneAssetsMutation, DeleteUserSceneAssetsMutationVariables>;

/**
 * __useDeleteUserSceneAssetsMutation__
 *
 * To run a mutation, you first call `useDeleteUserSceneAssetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserSceneAssetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserSceneAssetsMutation, { data, loading, error }] = useDeleteUserSceneAssetsMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteUserSceneAssetsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserSceneAssetsMutation, DeleteUserSceneAssetsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserSceneAssetsMutation, DeleteUserSceneAssetsMutationVariables>(DeleteUserSceneAssetsDocument, options);
      }
export type DeleteUserSceneAssetsMutationHookResult = ReturnType<typeof useDeleteUserSceneAssetsMutation>;
export type DeleteUserSceneAssetsMutationResult = Apollo.MutationResult<DeleteUserSceneAssetsMutation>;
export type DeleteUserSceneAssetsMutationOptions = Apollo.BaseMutationOptions<DeleteUserSceneAssetsMutation, DeleteUserSceneAssetsMutationVariables>;
export const SceneTemplatesDocument = gql`
    query SceneTemplates($where: SceneTemplateWhereInput) {
  sceneTemplates(where: $where) {
    id
    baseModelURL
    name
    thumbnail
  }
}
    `;

/**
 * __useSceneTemplatesQuery__
 *
 * To run a query within a React component, call `useSceneTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSceneTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSceneTemplatesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSceneTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<SceneTemplatesQuery, SceneTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SceneTemplatesQuery, SceneTemplatesQueryVariables>(SceneTemplatesDocument, options);
      }
export function useSceneTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SceneTemplatesQuery, SceneTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SceneTemplatesQuery, SceneTemplatesQueryVariables>(SceneTemplatesDocument, options);
        }
export type SceneTemplatesQueryHookResult = ReturnType<typeof useSceneTemplatesQuery>;
export type SceneTemplatesLazyQueryHookResult = ReturnType<typeof useSceneTemplatesLazyQuery>;
export type SceneTemplatesQueryResult = Apollo.QueryResult<SceneTemplatesQuery, SceneTemplatesQueryVariables>;
export const MFesTemplatesDocument = gql`
    query mFESTemplates($where: MFESTemplateWhereInput) {
  mFESTemplates(where: $where) {
    id
    name
  }
}
    `;

/**
 * __useMFesTemplatesQuery__
 *
 * To run a query within a React component, call `useMFesTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMFesTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMFesTemplatesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useMFesTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<MFesTemplatesQuery, MFesTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MFesTemplatesQuery, MFesTemplatesQueryVariables>(MFesTemplatesDocument, options);
      }
export function useMFesTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MFesTemplatesQuery, MFesTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MFesTemplatesQuery, MFesTemplatesQueryVariables>(MFesTemplatesDocument, options);
        }
export type MFesTemplatesQueryHookResult = ReturnType<typeof useMFesTemplatesQuery>;
export type MFesTemplatesLazyQueryHookResult = ReturnType<typeof useMFesTemplatesLazyQuery>;
export type MFesTemplatesQueryResult = Apollo.QueryResult<MFesTemplatesQuery, MFesTemplatesQueryVariables>;
export const MfeSceneTemplatesDocument = gql`
    query MFESceneTemplates($where: MFESceneTemplateWhereInput) {
  mFESceneTemplates(where: $where) {
    MFESTemplateId
    baseModelURL
    id
    name
  }
}
    `;

/**
 * __useMfeSceneTemplatesQuery__
 *
 * To run a query within a React component, call `useMfeSceneTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMfeSceneTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMfeSceneTemplatesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useMfeSceneTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<MfeSceneTemplatesQuery, MfeSceneTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MfeSceneTemplatesQuery, MfeSceneTemplatesQueryVariables>(MfeSceneTemplatesDocument, options);
      }
export function useMfeSceneTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MfeSceneTemplatesQuery, MfeSceneTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MfeSceneTemplatesQuery, MfeSceneTemplatesQueryVariables>(MfeSceneTemplatesDocument, options);
        }
export type MfeSceneTemplatesQueryHookResult = ReturnType<typeof useMfeSceneTemplatesQuery>;
export type MfeSceneTemplatesLazyQueryHookResult = ReturnType<typeof useMfeSceneTemplatesLazyQuery>;
export type MfeSceneTemplatesQueryResult = Apollo.QueryResult<MfeSceneTemplatesQuery, MfeSceneTemplatesQueryVariables>;
export const CameraConfigDocument = gql`
    query CameraConfig($where: CameraConfigWhereUniqueInput!) {
  cameraConfig(where: $where) {
    fov
    far
    near
    maxSpeed
    minSpeed
  }
}
    `;

/**
 * __useCameraConfigQuery__
 *
 * To run a query within a React component, call `useCameraConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useCameraConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCameraConfigQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCameraConfigQuery(baseOptions: Apollo.QueryHookOptions<CameraConfigQuery, CameraConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CameraConfigQuery, CameraConfigQueryVariables>(CameraConfigDocument, options);
      }
export function useCameraConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CameraConfigQuery, CameraConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CameraConfigQuery, CameraConfigQueryVariables>(CameraConfigDocument, options);
        }
export type CameraConfigQueryHookResult = ReturnType<typeof useCameraConfigQuery>;
export type CameraConfigLazyQueryHookResult = ReturnType<typeof useCameraConfigLazyQuery>;
export type CameraConfigQueryResult = Apollo.QueryResult<CameraConfigQuery, CameraConfigQueryVariables>;
export const CreateOneCameraConfigDocument = gql`
    mutation CreateOneCameraConfig($data: CameraConfigCreateInput) {
  createOneCameraConfig(data: $data) {
    id
  }
}
    `;
export type CreateOneCameraConfigMutationFn = Apollo.MutationFunction<CreateOneCameraConfigMutation, CreateOneCameraConfigMutationVariables>;

/**
 * __useCreateOneCameraConfigMutation__
 *
 * To run a mutation, you first call `useCreateOneCameraConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneCameraConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneCameraConfigMutation, { data, loading, error }] = useCreateOneCameraConfigMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneCameraConfigMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneCameraConfigMutation, CreateOneCameraConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneCameraConfigMutation, CreateOneCameraConfigMutationVariables>(CreateOneCameraConfigDocument, options);
      }
export type CreateOneCameraConfigMutationHookResult = ReturnType<typeof useCreateOneCameraConfigMutation>;
export type CreateOneCameraConfigMutationResult = Apollo.MutationResult<CreateOneCameraConfigMutation>;
export type CreateOneCameraConfigMutationOptions = Apollo.BaseMutationOptions<CreateOneCameraConfigMutation, CreateOneCameraConfigMutationVariables>;
export const UpdateOneCameraConfigDocument = gql`
    mutation UpdateOneCameraConfig($data: CameraConfigUpdateInput!, $where: CameraConfigWhereUniqueInput!) {
  updateOneCameraConfig(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateOneCameraConfigMutationFn = Apollo.MutationFunction<UpdateOneCameraConfigMutation, UpdateOneCameraConfigMutationVariables>;

/**
 * __useUpdateOneCameraConfigMutation__
 *
 * To run a mutation, you first call `useUpdateOneCameraConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneCameraConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneCameraConfigMutation, { data, loading, error }] = useUpdateOneCameraConfigMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneCameraConfigMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneCameraConfigMutation, UpdateOneCameraConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneCameraConfigMutation, UpdateOneCameraConfigMutationVariables>(UpdateOneCameraConfigDocument, options);
      }
export type UpdateOneCameraConfigMutationHookResult = ReturnType<typeof useUpdateOneCameraConfigMutation>;
export type UpdateOneCameraConfigMutationResult = Apollo.MutationResult<UpdateOneCameraConfigMutation>;
export type UpdateOneCameraConfigMutationOptions = Apollo.BaseMutationOptions<UpdateOneCameraConfigMutation, UpdateOneCameraConfigMutationVariables>;
export const UserScenesDocument = gql`
    query UserScenes($where: UserSceneWhereInput) {
  userScenes(where: $where) {
    id
    name
    description
    thumbnail
    skybox
    lightTemplate
    userId
    hasQuest
    questId
    isChatEnabled
    graphicsSettingsId
    isScanEnabled
    isCameraThirdPerson
    hasHideAndSeek
    hideAndSeekId
    cameraConfigId
    branding
    gameMode
  }
}
    `;

/**
 * __useUserScenesQuery__
 *
 * To run a query within a React component, call `useUserScenesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserScenesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserScenesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUserScenesQuery(baseOptions?: Apollo.QueryHookOptions<UserScenesQuery, UserScenesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserScenesQuery, UserScenesQueryVariables>(UserScenesDocument, options);
      }
export function useUserScenesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserScenesQuery, UserScenesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserScenesQuery, UserScenesQueryVariables>(UserScenesDocument, options);
        }
export type UserScenesQueryHookResult = ReturnType<typeof useUserScenesQuery>;
export type UserScenesLazyQueryHookResult = ReturnType<typeof useUserScenesLazyQuery>;
export type UserScenesQueryResult = Apollo.QueryResult<UserScenesQuery, UserScenesQueryVariables>;
export const FindManyUserSceneAssetsDocument = gql`
    query FindManyUserSceneAssets($where: UserSceneAssetsWhereInput) {
  findManyUserSceneAssets(where: $where) {
    userSceneId
    lightId
    url
    type
    id
    name
    position
    rotation
    metadata
    scale
    enablePurchase
    enableLinking
    portalSceneId
    isPortal
    marketId
    shopifyMetadata
    textureToChange
    textureToChangeNormal
    textureToChangeRoughness
    textureToChangeMetalness
    baseColor
    roughness
    metalness
  }
}
    `;

/**
 * __useFindManyUserSceneAssetsQuery__
 *
 * To run a query within a React component, call `useFindManyUserSceneAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyUserSceneAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyUserSceneAssetsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindManyUserSceneAssetsQuery(baseOptions?: Apollo.QueryHookOptions<FindManyUserSceneAssetsQuery, FindManyUserSceneAssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindManyUserSceneAssetsQuery, FindManyUserSceneAssetsQueryVariables>(FindManyUserSceneAssetsDocument, options);
      }
export function useFindManyUserSceneAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindManyUserSceneAssetsQuery, FindManyUserSceneAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindManyUserSceneAssetsQuery, FindManyUserSceneAssetsQueryVariables>(FindManyUserSceneAssetsDocument, options);
        }
export type FindManyUserSceneAssetsQueryHookResult = ReturnType<typeof useFindManyUserSceneAssetsQuery>;
export type FindManyUserSceneAssetsLazyQueryHookResult = ReturnType<typeof useFindManyUserSceneAssetsLazyQuery>;
export type FindManyUserSceneAssetsQueryResult = Apollo.QueryResult<FindManyUserSceneAssetsQuery, FindManyUserSceneAssetsQueryVariables>;
export const FindFirstLightPropsDocument = gql`
    query FindFirstLightProps($where: LightPropsWhereInput) {
  findFirstLightProps(where: $where) {
    id
    type
    color
    intensity
    distance
    decay
    angle
    penumbra
    visible
    shadow
    skyColor
    groundColor
  }
}
    `;

/**
 * __useFindFirstLightPropsQuery__
 *
 * To run a query within a React component, call `useFindFirstLightPropsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindFirstLightPropsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindFirstLightPropsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindFirstLightPropsQuery(baseOptions?: Apollo.QueryHookOptions<FindFirstLightPropsQuery, FindFirstLightPropsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindFirstLightPropsQuery, FindFirstLightPropsQueryVariables>(FindFirstLightPropsDocument, options);
      }
export function useFindFirstLightPropsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindFirstLightPropsQuery, FindFirstLightPropsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindFirstLightPropsQuery, FindFirstLightPropsQueryVariables>(FindFirstLightPropsDocument, options);
        }
export type FindFirstLightPropsQueryHookResult = ReturnType<typeof useFindFirstLightPropsQuery>;
export type FindFirstLightPropsLazyQueryHookResult = ReturnType<typeof useFindFirstLightPropsLazyQuery>;
export type FindFirstLightPropsQueryResult = Apollo.QueryResult<FindFirstLightPropsQuery, FindFirstLightPropsQueryVariables>;
export const FindManyLightPropsDocument = gql`
    query FindManyLightProps($where: LightPropsWhereInput) {
  findManyLightProps(where: $where) {
    id
    color
    type
    intensity
    distance
    decay
    angle
    penumbra
    visible
    shadow
    skyColor
    groundColor
  }
}
    `;

/**
 * __useFindManyLightPropsQuery__
 *
 * To run a query within a React component, call `useFindManyLightPropsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyLightPropsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyLightPropsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindManyLightPropsQuery(baseOptions?: Apollo.QueryHookOptions<FindManyLightPropsQuery, FindManyLightPropsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindManyLightPropsQuery, FindManyLightPropsQueryVariables>(FindManyLightPropsDocument, options);
      }
export function useFindManyLightPropsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindManyLightPropsQuery, FindManyLightPropsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindManyLightPropsQuery, FindManyLightPropsQueryVariables>(FindManyLightPropsDocument, options);
        }
export type FindManyLightPropsQueryHookResult = ReturnType<typeof useFindManyLightPropsQuery>;
export type FindManyLightPropsLazyQueryHookResult = ReturnType<typeof useFindManyLightPropsLazyQuery>;
export type FindManyLightPropsQueryResult = Apollo.QueryResult<FindManyLightPropsQuery, FindManyLightPropsQueryVariables>;
export const DeleteLightPropsDocument = gql`
    mutation DeleteLightProps($where: LightPropsWhereUniqueInput!) {
  deleteOneLightProps(where: $where) {
    id
  }
}
    `;
export type DeleteLightPropsMutationFn = Apollo.MutationFunction<DeleteLightPropsMutation, DeleteLightPropsMutationVariables>;

/**
 * __useDeleteLightPropsMutation__
 *
 * To run a mutation, you first call `useDeleteLightPropsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLightPropsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLightPropsMutation, { data, loading, error }] = useDeleteLightPropsMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteLightPropsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLightPropsMutation, DeleteLightPropsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLightPropsMutation, DeleteLightPropsMutationVariables>(DeleteLightPropsDocument, options);
      }
export type DeleteLightPropsMutationHookResult = ReturnType<typeof useDeleteLightPropsMutation>;
export type DeleteLightPropsMutationResult = Apollo.MutationResult<DeleteLightPropsMutation>;
export type DeleteLightPropsMutationOptions = Apollo.BaseMutationOptions<DeleteLightPropsMutation, DeleteLightPropsMutationVariables>;
export const DeleteManyLightPropsDocument = gql`
    mutation DeleteManyLightProps($where: LightPropsWhereInput) {
  deleteManyLightProps(where: $where) {
    count
  }
}
    `;
export type DeleteManyLightPropsMutationFn = Apollo.MutationFunction<DeleteManyLightPropsMutation, DeleteManyLightPropsMutationVariables>;

/**
 * __useDeleteManyLightPropsMutation__
 *
 * To run a mutation, you first call `useDeleteManyLightPropsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyLightPropsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyLightPropsMutation, { data, loading, error }] = useDeleteManyLightPropsMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyLightPropsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyLightPropsMutation, DeleteManyLightPropsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyLightPropsMutation, DeleteManyLightPropsMutationVariables>(DeleteManyLightPropsDocument, options);
      }
export type DeleteManyLightPropsMutationHookResult = ReturnType<typeof useDeleteManyLightPropsMutation>;
export type DeleteManyLightPropsMutationResult = Apollo.MutationResult<DeleteManyLightPropsMutation>;
export type DeleteManyLightPropsMutationOptions = Apollo.BaseMutationOptions<DeleteManyLightPropsMutation, DeleteManyLightPropsMutationVariables>;
export const FindUniqueQuestDocument = gql`
    query findUniqueQuest($where: findUniqueQuestInput!) {
  findUniqueQuest(where: $where) {
    quest {
      id
      name
      startPrompt
      rewardType
      rewardAmount
      hasReward
      collectableItem
      collectableCount
      finishPrompt
      fakeItem
      fakeCount
      splashScreenMessage
      enableDefaultModal
      unMarkedStartPrompt
    }
  }
}
    `;

/**
 * __useFindUniqueQuestQuery__
 *
 * To run a query within a React component, call `useFindUniqueQuestQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUniqueQuestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUniqueQuestQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindUniqueQuestQuery(baseOptions: Apollo.QueryHookOptions<FindUniqueQuestQuery, FindUniqueQuestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindUniqueQuestQuery, FindUniqueQuestQueryVariables>(FindUniqueQuestDocument, options);
      }
export function useFindUniqueQuestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUniqueQuestQuery, FindUniqueQuestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindUniqueQuestQuery, FindUniqueQuestQueryVariables>(FindUniqueQuestDocument, options);
        }
export type FindUniqueQuestQueryHookResult = ReturnType<typeof useFindUniqueQuestQuery>;
export type FindUniqueQuestLazyQueryHookResult = ReturnType<typeof useFindUniqueQuestLazyQuery>;
export type FindUniqueQuestQueryResult = Apollo.QueryResult<FindUniqueQuestQuery, FindUniqueQuestQueryVariables>;
export const GetAssetVideoUrlByMetaOgDocument = gql`
    mutation GetAssetVideoUrlByMetaOG($url: String!) {
  getAssetVideoUrlByMetaOG(url: $url) {
    url
    status
  }
}
    `;
export type GetAssetVideoUrlByMetaOgMutationFn = Apollo.MutationFunction<GetAssetVideoUrlByMetaOgMutation, GetAssetVideoUrlByMetaOgMutationVariables>;

/**
 * __useGetAssetVideoUrlByMetaOgMutation__
 *
 * To run a mutation, you first call `useGetAssetVideoUrlByMetaOgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetAssetVideoUrlByMetaOgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getAssetVideoUrlByMetaOgMutation, { data, loading, error }] = useGetAssetVideoUrlByMetaOgMutation({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useGetAssetVideoUrlByMetaOgMutation(baseOptions?: Apollo.MutationHookOptions<GetAssetVideoUrlByMetaOgMutation, GetAssetVideoUrlByMetaOgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetAssetVideoUrlByMetaOgMutation, GetAssetVideoUrlByMetaOgMutationVariables>(GetAssetVideoUrlByMetaOgDocument, options);
      }
export type GetAssetVideoUrlByMetaOgMutationHookResult = ReturnType<typeof useGetAssetVideoUrlByMetaOgMutation>;
export type GetAssetVideoUrlByMetaOgMutationResult = Apollo.MutationResult<GetAssetVideoUrlByMetaOgMutation>;
export type GetAssetVideoUrlByMetaOgMutationOptions = Apollo.BaseMutationOptions<GetAssetVideoUrlByMetaOgMutation, GetAssetVideoUrlByMetaOgMutationVariables>;
export const GetShopifyStoreBySceneDocument = gql`
    query GetShopifyStoreByScene($where: GetShopifyStoreBySceneInput!) {
  getShopifyStoreByScene(where: $where) {
    store {
      apiVersion
      domain
      id
      token
      userSceneId
    }
  }
}
    `;

/**
 * __useGetShopifyStoreBySceneQuery__
 *
 * To run a query within a React component, call `useGetShopifyStoreBySceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopifyStoreBySceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopifyStoreBySceneQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetShopifyStoreBySceneQuery(baseOptions: Apollo.QueryHookOptions<GetShopifyStoreBySceneQuery, GetShopifyStoreBySceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShopifyStoreBySceneQuery, GetShopifyStoreBySceneQueryVariables>(GetShopifyStoreBySceneDocument, options);
      }
export function useGetShopifyStoreBySceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShopifyStoreBySceneQuery, GetShopifyStoreBySceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShopifyStoreBySceneQuery, GetShopifyStoreBySceneQueryVariables>(GetShopifyStoreBySceneDocument, options);
        }
export type GetShopifyStoreBySceneQueryHookResult = ReturnType<typeof useGetShopifyStoreBySceneQuery>;
export type GetShopifyStoreBySceneLazyQueryHookResult = ReturnType<typeof useGetShopifyStoreBySceneLazyQuery>;
export type GetShopifyStoreBySceneQueryResult = Apollo.QueryResult<GetShopifyStoreBySceneQuery, GetShopifyStoreBySceneQueryVariables>;
export const CreateShopifyStoreDocument = gql`
    mutation CreateShopifyStore($where: CreateShopifyStoreInput!) {
  createShopifyStore(where: $where) {
    status
  }
}
    `;
export type CreateShopifyStoreMutationFn = Apollo.MutationFunction<CreateShopifyStoreMutation, CreateShopifyStoreMutationVariables>;

/**
 * __useCreateShopifyStoreMutation__
 *
 * To run a mutation, you first call `useCreateShopifyStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShopifyStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShopifyStoreMutation, { data, loading, error }] = useCreateShopifyStoreMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCreateShopifyStoreMutation(baseOptions?: Apollo.MutationHookOptions<CreateShopifyStoreMutation, CreateShopifyStoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateShopifyStoreMutation, CreateShopifyStoreMutationVariables>(CreateShopifyStoreDocument, options);
      }
export type CreateShopifyStoreMutationHookResult = ReturnType<typeof useCreateShopifyStoreMutation>;
export type CreateShopifyStoreMutationResult = Apollo.MutationResult<CreateShopifyStoreMutation>;
export type CreateShopifyStoreMutationOptions = Apollo.BaseMutationOptions<CreateShopifyStoreMutation, CreateShopifyStoreMutationVariables>;
export const UpdateShopifyStoreDocument = gql`
    mutation UpdateShopifyStore($data: UpdateShopifyStoreInput!) {
  updateShopifyStore(data: $data) {
    status
  }
}
    `;
export type UpdateShopifyStoreMutationFn = Apollo.MutationFunction<UpdateShopifyStoreMutation, UpdateShopifyStoreMutationVariables>;

/**
 * __useUpdateShopifyStoreMutation__
 *
 * To run a mutation, you first call `useUpdateShopifyStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShopifyStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShopifyStoreMutation, { data, loading, error }] = useUpdateShopifyStoreMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateShopifyStoreMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShopifyStoreMutation, UpdateShopifyStoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateShopifyStoreMutation, UpdateShopifyStoreMutationVariables>(UpdateShopifyStoreDocument, options);
      }
export type UpdateShopifyStoreMutationHookResult = ReturnType<typeof useUpdateShopifyStoreMutation>;
export type UpdateShopifyStoreMutationResult = Apollo.MutationResult<UpdateShopifyStoreMutation>;
export type UpdateShopifyStoreMutationOptions = Apollo.BaseMutationOptions<UpdateShopifyStoreMutation, UpdateShopifyStoreMutationVariables>;
export const GetAssetVideoUrlByHtmlDocument = gql`
    mutation GetAssetVideoUrlByHtml($url: String!, $type: String!) {
  getAssetVideoUrlByHtml(url: $url, type: $type) {
    status
    url
  }
}
    `;
export type GetAssetVideoUrlByHtmlMutationFn = Apollo.MutationFunction<GetAssetVideoUrlByHtmlMutation, GetAssetVideoUrlByHtmlMutationVariables>;

/**
 * __useGetAssetVideoUrlByHtmlMutation__
 *
 * To run a mutation, you first call `useGetAssetVideoUrlByHtmlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetAssetVideoUrlByHtmlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getAssetVideoUrlByHtmlMutation, { data, loading, error }] = useGetAssetVideoUrlByHtmlMutation({
 *   variables: {
 *      url: // value for 'url'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetAssetVideoUrlByHtmlMutation(baseOptions?: Apollo.MutationHookOptions<GetAssetVideoUrlByHtmlMutation, GetAssetVideoUrlByHtmlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetAssetVideoUrlByHtmlMutation, GetAssetVideoUrlByHtmlMutationVariables>(GetAssetVideoUrlByHtmlDocument, options);
      }
export type GetAssetVideoUrlByHtmlMutationHookResult = ReturnType<typeof useGetAssetVideoUrlByHtmlMutation>;
export type GetAssetVideoUrlByHtmlMutationResult = Apollo.MutationResult<GetAssetVideoUrlByHtmlMutation>;
export type GetAssetVideoUrlByHtmlMutationOptions = Apollo.BaseMutationOptions<GetAssetVideoUrlByHtmlMutation, GetAssetVideoUrlByHtmlMutationVariables>;
export const FindAliasSceneDocument = gql`
    query FindAliasScene($where: FindAliasSceneInput!) {
  findAliasScene(where: $where) {
    exists
    sceneId
  }
}
    `;

/**
 * __useFindAliasSceneQuery__
 *
 * To run a query within a React component, call `useFindAliasSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAliasSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAliasSceneQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindAliasSceneQuery(baseOptions: Apollo.QueryHookOptions<FindAliasSceneQuery, FindAliasSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAliasSceneQuery, FindAliasSceneQueryVariables>(FindAliasSceneDocument, options);
      }
export function useFindAliasSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAliasSceneQuery, FindAliasSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAliasSceneQuery, FindAliasSceneQueryVariables>(FindAliasSceneDocument, options);
        }
export type FindAliasSceneQueryHookResult = ReturnType<typeof useFindAliasSceneQuery>;
export type FindAliasSceneLazyQueryHookResult = ReturnType<typeof useFindAliasSceneLazyQuery>;
export type FindAliasSceneQueryResult = Apollo.QueryResult<FindAliasSceneQuery, FindAliasSceneQueryVariables>;
export const UpdateAliasSceneDocument = gql`
    mutation UpdateAliasScene($where: UpdateAliasSceneInput!) {
  updateAliasScene(where: $where) {
    errorMessage
    status
  }
}
    `;
export type UpdateAliasSceneMutationFn = Apollo.MutationFunction<UpdateAliasSceneMutation, UpdateAliasSceneMutationVariables>;

/**
 * __useUpdateAliasSceneMutation__
 *
 * To run a mutation, you first call `useUpdateAliasSceneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAliasSceneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAliasSceneMutation, { data, loading, error }] = useUpdateAliasSceneMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateAliasSceneMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAliasSceneMutation, UpdateAliasSceneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAliasSceneMutation, UpdateAliasSceneMutationVariables>(UpdateAliasSceneDocument, options);
      }
export type UpdateAliasSceneMutationHookResult = ReturnType<typeof useUpdateAliasSceneMutation>;
export type UpdateAliasSceneMutationResult = Apollo.MutationResult<UpdateAliasSceneMutation>;
export type UpdateAliasSceneMutationOptions = Apollo.BaseMutationOptions<UpdateAliasSceneMutation, UpdateAliasSceneMutationVariables>;
export const GetSceneAliasDocument = gql`
    query GetSceneAlias($where: GetSceneAliasInput!) {
  getSceneAlias(where: $where) {
    alias
    message
  }
}
    `;

/**
 * __useGetSceneAliasQuery__
 *
 * To run a query within a React component, call `useGetSceneAliasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSceneAliasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSceneAliasQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetSceneAliasQuery(baseOptions: Apollo.QueryHookOptions<GetSceneAliasQuery, GetSceneAliasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSceneAliasQuery, GetSceneAliasQueryVariables>(GetSceneAliasDocument, options);
      }
export function useGetSceneAliasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSceneAliasQuery, GetSceneAliasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSceneAliasQuery, GetSceneAliasQueryVariables>(GetSceneAliasDocument, options);
        }
export type GetSceneAliasQueryHookResult = ReturnType<typeof useGetSceneAliasQuery>;
export type GetSceneAliasLazyQueryHookResult = ReturnType<typeof useGetSceneAliasLazyQuery>;
export type GetSceneAliasQueryResult = Apollo.QueryResult<GetSceneAliasQuery, GetSceneAliasQueryVariables>;
export const SetConversationSidDocument = gql`
    mutation SetConversationSid($where: SetConversationSidInput!) {
  setConversationSid(where: $where) {
    status
  }
}
    `;
export type SetConversationSidMutationFn = Apollo.MutationFunction<SetConversationSidMutation, SetConversationSidMutationVariables>;

/**
 * __useSetConversationSidMutation__
 *
 * To run a mutation, you first call `useSetConversationSidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetConversationSidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setConversationSidMutation, { data, loading, error }] = useSetConversationSidMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSetConversationSidMutation(baseOptions?: Apollo.MutationHookOptions<SetConversationSidMutation, SetConversationSidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetConversationSidMutation, SetConversationSidMutationVariables>(SetConversationSidDocument, options);
      }
export type SetConversationSidMutationHookResult = ReturnType<typeof useSetConversationSidMutation>;
export type SetConversationSidMutationResult = Apollo.MutationResult<SetConversationSidMutation>;
export type SetConversationSidMutationOptions = Apollo.BaseMutationOptions<SetConversationSidMutation, SetConversationSidMutationVariables>;
export const GetConversationSidDocument = gql`
    query GetConversationSid($where: GetConversationSidInput!) {
  getConversationSid(where: $where) {
    sid
  }
}
    `;

/**
 * __useGetConversationSidQuery__
 *
 * To run a query within a React component, call `useGetConversationSidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationSidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationSidQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetConversationSidQuery(baseOptions: Apollo.QueryHookOptions<GetConversationSidQuery, GetConversationSidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConversationSidQuery, GetConversationSidQueryVariables>(GetConversationSidDocument, options);
      }
export function useGetConversationSidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConversationSidQuery, GetConversationSidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConversationSidQuery, GetConversationSidQueryVariables>(GetConversationSidDocument, options);
        }
export type GetConversationSidQueryHookResult = ReturnType<typeof useGetConversationSidQuery>;
export type GetConversationSidLazyQueryHookResult = ReturnType<typeof useGetConversationSidLazyQuery>;
export type GetConversationSidQueryResult = Apollo.QueryResult<GetConversationSidQuery, GetConversationSidQueryVariables>;
export const SetIsChatEnabledSceneDocument = gql`
    mutation SetIsChatEnabledScene($where: SetIsChatEnabledSceneInput!) {
  setIsChatEnabledScene(where: $where) {
    status
  }
}
    `;
export type SetIsChatEnabledSceneMutationFn = Apollo.MutationFunction<SetIsChatEnabledSceneMutation, SetIsChatEnabledSceneMutationVariables>;

/**
 * __useSetIsChatEnabledSceneMutation__
 *
 * To run a mutation, you first call `useSetIsChatEnabledSceneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetIsChatEnabledSceneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setIsChatEnabledSceneMutation, { data, loading, error }] = useSetIsChatEnabledSceneMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSetIsChatEnabledSceneMutation(baseOptions?: Apollo.MutationHookOptions<SetIsChatEnabledSceneMutation, SetIsChatEnabledSceneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetIsChatEnabledSceneMutation, SetIsChatEnabledSceneMutationVariables>(SetIsChatEnabledSceneDocument, options);
      }
export type SetIsChatEnabledSceneMutationHookResult = ReturnType<typeof useSetIsChatEnabledSceneMutation>;
export type SetIsChatEnabledSceneMutationResult = Apollo.MutationResult<SetIsChatEnabledSceneMutation>;
export type SetIsChatEnabledSceneMutationOptions = Apollo.BaseMutationOptions<SetIsChatEnabledSceneMutation, SetIsChatEnabledSceneMutationVariables>;
export const IsChatEnabledInSceneDocument = gql`
    query IsChatEnabledInScene($where: IsChatEnabledInSceneInput!) {
  isChatEnabledInScene(where: $where) {
    enabled
  }
}
    `;

/**
 * __useIsChatEnabledInSceneQuery__
 *
 * To run a query within a React component, call `useIsChatEnabledInSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsChatEnabledInSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsChatEnabledInSceneQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useIsChatEnabledInSceneQuery(baseOptions: Apollo.QueryHookOptions<IsChatEnabledInSceneQuery, IsChatEnabledInSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsChatEnabledInSceneQuery, IsChatEnabledInSceneQueryVariables>(IsChatEnabledInSceneDocument, options);
      }
export function useIsChatEnabledInSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsChatEnabledInSceneQuery, IsChatEnabledInSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsChatEnabledInSceneQuery, IsChatEnabledInSceneQueryVariables>(IsChatEnabledInSceneDocument, options);
        }
export type IsChatEnabledInSceneQueryHookResult = ReturnType<typeof useIsChatEnabledInSceneQuery>;
export type IsChatEnabledInSceneLazyQueryHookResult = ReturnType<typeof useIsChatEnabledInSceneLazyQuery>;
export type IsChatEnabledInSceneQueryResult = Apollo.QueryResult<IsChatEnabledInSceneQuery, IsChatEnabledInSceneQueryVariables>;
export const SetIsScanEnabledSceneDocument = gql`
    mutation SetIsScanEnabledScene($where: SetIsScanEnabledSceneInput!) {
  setIsScanEnabledScene(where: $where) {
    status
  }
}
    `;
export type SetIsScanEnabledSceneMutationFn = Apollo.MutationFunction<SetIsScanEnabledSceneMutation, SetIsScanEnabledSceneMutationVariables>;

/**
 * __useSetIsScanEnabledSceneMutation__
 *
 * To run a mutation, you first call `useSetIsScanEnabledSceneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetIsScanEnabledSceneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setIsScanEnabledSceneMutation, { data, loading, error }] = useSetIsScanEnabledSceneMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSetIsScanEnabledSceneMutation(baseOptions?: Apollo.MutationHookOptions<SetIsScanEnabledSceneMutation, SetIsScanEnabledSceneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetIsScanEnabledSceneMutation, SetIsScanEnabledSceneMutationVariables>(SetIsScanEnabledSceneDocument, options);
      }
export type SetIsScanEnabledSceneMutationHookResult = ReturnType<typeof useSetIsScanEnabledSceneMutation>;
export type SetIsScanEnabledSceneMutationResult = Apollo.MutationResult<SetIsScanEnabledSceneMutation>;
export type SetIsScanEnabledSceneMutationOptions = Apollo.BaseMutationOptions<SetIsScanEnabledSceneMutation, SetIsScanEnabledSceneMutationVariables>;
export const IsScanEnabledInSceneDocument = gql`
    query IsScanEnabledInScene($where: IsScanEnabledInSceneInput!) {
  isScanEnabledInScene(where: $where) {
    enabled
  }
}
    `;

/**
 * __useIsScanEnabledInSceneQuery__
 *
 * To run a query within a React component, call `useIsScanEnabledInSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsScanEnabledInSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsScanEnabledInSceneQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useIsScanEnabledInSceneQuery(baseOptions: Apollo.QueryHookOptions<IsScanEnabledInSceneQuery, IsScanEnabledInSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsScanEnabledInSceneQuery, IsScanEnabledInSceneQueryVariables>(IsScanEnabledInSceneDocument, options);
      }
export function useIsScanEnabledInSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsScanEnabledInSceneQuery, IsScanEnabledInSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsScanEnabledInSceneQuery, IsScanEnabledInSceneQueryVariables>(IsScanEnabledInSceneDocument, options);
        }
export type IsScanEnabledInSceneQueryHookResult = ReturnType<typeof useIsScanEnabledInSceneQuery>;
export type IsScanEnabledInSceneLazyQueryHookResult = ReturnType<typeof useIsScanEnabledInSceneLazyQuery>;
export type IsScanEnabledInSceneQueryResult = Apollo.QueryResult<IsScanEnabledInSceneQuery, IsScanEnabledInSceneQueryVariables>;
export const SetIsCameraThirdPersonSceneDocument = gql`
    mutation setIsCameraThirdPersonScene($where: SetIsCameraThirdPersonSceneInput!) {
  setIsCameraThirdPersonScene(where: $where) {
    status
  }
}
    `;
export type SetIsCameraThirdPersonSceneMutationFn = Apollo.MutationFunction<SetIsCameraThirdPersonSceneMutation, SetIsCameraThirdPersonSceneMutationVariables>;

/**
 * __useSetIsCameraThirdPersonSceneMutation__
 *
 * To run a mutation, you first call `useSetIsCameraThirdPersonSceneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetIsCameraThirdPersonSceneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setIsCameraThirdPersonSceneMutation, { data, loading, error }] = useSetIsCameraThirdPersonSceneMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSetIsCameraThirdPersonSceneMutation(baseOptions?: Apollo.MutationHookOptions<SetIsCameraThirdPersonSceneMutation, SetIsCameraThirdPersonSceneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetIsCameraThirdPersonSceneMutation, SetIsCameraThirdPersonSceneMutationVariables>(SetIsCameraThirdPersonSceneDocument, options);
      }
export type SetIsCameraThirdPersonSceneMutationHookResult = ReturnType<typeof useSetIsCameraThirdPersonSceneMutation>;
export type SetIsCameraThirdPersonSceneMutationResult = Apollo.MutationResult<SetIsCameraThirdPersonSceneMutation>;
export type SetIsCameraThirdPersonSceneMutationOptions = Apollo.BaseMutationOptions<SetIsCameraThirdPersonSceneMutation, SetIsCameraThirdPersonSceneMutationVariables>;
export const GetUnityPackageDocument = gql`
    mutation GetUnityPackage($data: GetUnityPackageInput!) {
  getUnityPackage(data: $data) {
    status
    fileURL
  }
}
    `;
export type GetUnityPackageMutationFn = Apollo.MutationFunction<GetUnityPackageMutation, GetUnityPackageMutationVariables>;

/**
 * __useGetUnityPackageMutation__
 *
 * To run a mutation, you first call `useGetUnityPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetUnityPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getUnityPackageMutation, { data, loading, error }] = useGetUnityPackageMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetUnityPackageMutation(baseOptions?: Apollo.MutationHookOptions<GetUnityPackageMutation, GetUnityPackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetUnityPackageMutation, GetUnityPackageMutationVariables>(GetUnityPackageDocument, options);
      }
export type GetUnityPackageMutationHookResult = ReturnType<typeof useGetUnityPackageMutation>;
export type GetUnityPackageMutationResult = Apollo.MutationResult<GetUnityPackageMutation>;
export type GetUnityPackageMutationOptions = Apollo.BaseMutationOptions<GetUnityPackageMutation, GetUnityPackageMutationVariables>;
export const CreateOneTourItemsDocument = gql`
    mutation CreateOneTourItems($data: TourItemsCreateInput!) {
  createOneTourItems(data: $data) {
    id
    name
    description
    userSceneId
    position
  }
}
    `;
export type CreateOneTourItemsMutationFn = Apollo.MutationFunction<CreateOneTourItemsMutation, CreateOneTourItemsMutationVariables>;

/**
 * __useCreateOneTourItemsMutation__
 *
 * To run a mutation, you first call `useCreateOneTourItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneTourItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneTourItemsMutation, { data, loading, error }] = useCreateOneTourItemsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneTourItemsMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneTourItemsMutation, CreateOneTourItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneTourItemsMutation, CreateOneTourItemsMutationVariables>(CreateOneTourItemsDocument, options);
      }
export type CreateOneTourItemsMutationHookResult = ReturnType<typeof useCreateOneTourItemsMutation>;
export type CreateOneTourItemsMutationResult = Apollo.MutationResult<CreateOneTourItemsMutation>;
export type CreateOneTourItemsMutationOptions = Apollo.BaseMutationOptions<CreateOneTourItemsMutation, CreateOneTourItemsMutationVariables>;
export const FindManyTourItemsDocument = gql`
    query FindManyTourItems($where: TourItemsWhereInput) {
  findManyTourItems(where: $where) {
    id
    name
    description
    userSceneId
    position
  }
}
    `;

/**
 * __useFindManyTourItemsQuery__
 *
 * To run a query within a React component, call `useFindManyTourItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyTourItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyTourItemsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindManyTourItemsQuery(baseOptions?: Apollo.QueryHookOptions<FindManyTourItemsQuery, FindManyTourItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindManyTourItemsQuery, FindManyTourItemsQueryVariables>(FindManyTourItemsDocument, options);
      }
export function useFindManyTourItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindManyTourItemsQuery, FindManyTourItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindManyTourItemsQuery, FindManyTourItemsQueryVariables>(FindManyTourItemsDocument, options);
        }
export type FindManyTourItemsQueryHookResult = ReturnType<typeof useFindManyTourItemsQuery>;
export type FindManyTourItemsLazyQueryHookResult = ReturnType<typeof useFindManyTourItemsLazyQuery>;
export type FindManyTourItemsQueryResult = Apollo.QueryResult<FindManyTourItemsQuery, FindManyTourItemsQueryVariables>;
export const DeleteOneTourItemsDocument = gql`
    mutation DeleteOneTourItems($where: TourItemsWhereUniqueInput!) {
  deleteOneTourItems(where: $where) {
    id
    name
    description
    userSceneId
    position
  }
}
    `;
export type DeleteOneTourItemsMutationFn = Apollo.MutationFunction<DeleteOneTourItemsMutation, DeleteOneTourItemsMutationVariables>;

/**
 * __useDeleteOneTourItemsMutation__
 *
 * To run a mutation, you first call `useDeleteOneTourItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneTourItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneTourItemsMutation, { data, loading, error }] = useDeleteOneTourItemsMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteOneTourItemsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneTourItemsMutation, DeleteOneTourItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneTourItemsMutation, DeleteOneTourItemsMutationVariables>(DeleteOneTourItemsDocument, options);
      }
export type DeleteOneTourItemsMutationHookResult = ReturnType<typeof useDeleteOneTourItemsMutation>;
export type DeleteOneTourItemsMutationResult = Apollo.MutationResult<DeleteOneTourItemsMutation>;
export type DeleteOneTourItemsMutationOptions = Apollo.BaseMutationOptions<DeleteOneTourItemsMutation, DeleteOneTourItemsMutationVariables>;
export const UpdateSceneAssetsDocument = gql`
    mutation UpdateSceneAssets($data: UpdateSceneAssetsInput!) {
  updateSceneAssets(data: $data) {
    status
    message
  }
}
    `;
export type UpdateSceneAssetsMutationFn = Apollo.MutationFunction<UpdateSceneAssetsMutation, UpdateSceneAssetsMutationVariables>;

/**
 * __useUpdateSceneAssetsMutation__
 *
 * To run a mutation, you first call `useUpdateSceneAssetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSceneAssetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSceneAssetsMutation, { data, loading, error }] = useUpdateSceneAssetsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSceneAssetsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSceneAssetsMutation, UpdateSceneAssetsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSceneAssetsMutation, UpdateSceneAssetsMutationVariables>(UpdateSceneAssetsDocument, options);
      }
export type UpdateSceneAssetsMutationHookResult = ReturnType<typeof useUpdateSceneAssetsMutation>;
export type UpdateSceneAssetsMutationResult = Apollo.MutationResult<UpdateSceneAssetsMutation>;
export type UpdateSceneAssetsMutationOptions = Apollo.BaseMutationOptions<UpdateSceneAssetsMutation, UpdateSceneAssetsMutationVariables>;
export const GetWalletBalanceDocument = gql`
    query GetWalletBalance($data: GetWalletBalanceInput!) {
  getWalletBalance(data: $data) {
    balance
    message
  }
}
    `;

/**
 * __useGetWalletBalanceQuery__
 *
 * To run a query within a React component, call `useGetWalletBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWalletBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWalletBalanceQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetWalletBalanceQuery(baseOptions: Apollo.QueryHookOptions<GetWalletBalanceQuery, GetWalletBalanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWalletBalanceQuery, GetWalletBalanceQueryVariables>(GetWalletBalanceDocument, options);
      }
export function useGetWalletBalanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWalletBalanceQuery, GetWalletBalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWalletBalanceQuery, GetWalletBalanceQueryVariables>(GetWalletBalanceDocument, options);
        }
export type GetWalletBalanceQueryHookResult = ReturnType<typeof useGetWalletBalanceQuery>;
export type GetWalletBalanceLazyQueryHookResult = ReturnType<typeof useGetWalletBalanceLazyQuery>;
export type GetWalletBalanceQueryResult = Apollo.QueryResult<GetWalletBalanceQuery, GetWalletBalanceQueryVariables>;
export const DepositFcDocument = gql`
    mutation DepositFC($data: DepositFCInput!) {
  depositFC(data: $data) {
    status
    message
  }
}
    `;
export type DepositFcMutationFn = Apollo.MutationFunction<DepositFcMutation, DepositFcMutationVariables>;

/**
 * __useDepositFcMutation__
 *
 * To run a mutation, you first call `useDepositFcMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDepositFcMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [depositFcMutation, { data, loading, error }] = useDepositFcMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDepositFcMutation(baseOptions?: Apollo.MutationHookOptions<DepositFcMutation, DepositFcMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DepositFcMutation, DepositFcMutationVariables>(DepositFcDocument, options);
      }
export type DepositFcMutationHookResult = ReturnType<typeof useDepositFcMutation>;
export type DepositFcMutationResult = Apollo.MutationResult<DepositFcMutation>;
export type DepositFcMutationOptions = Apollo.BaseMutationOptions<DepositFcMutation, DepositFcMutationVariables>;
export const ConvertOzoneToFcDocument = gql`
    mutation ConvertOzoneToFC($where: ConvertOzoneToFCInput!) {
  convertOzoneToFC(where: $where) {
    status
    convertedPrice
  }
}
    `;
export type ConvertOzoneToFcMutationFn = Apollo.MutationFunction<ConvertOzoneToFcMutation, ConvertOzoneToFcMutationVariables>;

/**
 * __useConvertOzoneToFcMutation__
 *
 * To run a mutation, you first call `useConvertOzoneToFcMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertOzoneToFcMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertOzoneToFcMutation, { data, loading, error }] = useConvertOzoneToFcMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useConvertOzoneToFcMutation(baseOptions?: Apollo.MutationHookOptions<ConvertOzoneToFcMutation, ConvertOzoneToFcMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConvertOzoneToFcMutation, ConvertOzoneToFcMutationVariables>(ConvertOzoneToFcDocument, options);
      }
export type ConvertOzoneToFcMutationHookResult = ReturnType<typeof useConvertOzoneToFcMutation>;
export type ConvertOzoneToFcMutationResult = Apollo.MutationResult<ConvertOzoneToFcMutation>;
export type ConvertOzoneToFcMutationOptions = Apollo.BaseMutationOptions<ConvertOzoneToFcMutation, ConvertOzoneToFcMutationVariables>;
export const FindMatchesByUserIdDocument = gql`
    query FindMatchesByUserId($where: FindMatchesByUserIdInput!) {
  findMatchesByUserId(where: $where) {
    matches {
      id
      player1
      player2
      winner
      createdAt
      reward
      status
    }
  }
}
    `;

/**
 * __useFindMatchesByUserIdQuery__
 *
 * To run a query within a React component, call `useFindMatchesByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMatchesByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMatchesByUserIdQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindMatchesByUserIdQuery(baseOptions: Apollo.QueryHookOptions<FindMatchesByUserIdQuery, FindMatchesByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindMatchesByUserIdQuery, FindMatchesByUserIdQueryVariables>(FindMatchesByUserIdDocument, options);
      }
export function useFindMatchesByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindMatchesByUserIdQuery, FindMatchesByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindMatchesByUserIdQuery, FindMatchesByUserIdQueryVariables>(FindMatchesByUserIdDocument, options);
        }
export type FindMatchesByUserIdQueryHookResult = ReturnType<typeof useFindMatchesByUserIdQuery>;
export type FindMatchesByUserIdLazyQueryHookResult = ReturnType<typeof useFindMatchesByUserIdLazyQuery>;
export type FindMatchesByUserIdQueryResult = Apollo.QueryResult<FindMatchesByUserIdQuery, FindMatchesByUserIdQueryVariables>;
export const BuyAssetWeb2FcDocument = gql`
    mutation BuyAssetWeb2FC($data: BuyAssetWeb2FCInput!) {
  buyAssetWeb2FC(data: $data) {
    status
    message
  }
}
    `;
export type BuyAssetWeb2FcMutationFn = Apollo.MutationFunction<BuyAssetWeb2FcMutation, BuyAssetWeb2FcMutationVariables>;

/**
 * __useBuyAssetWeb2FcMutation__
 *
 * To run a mutation, you first call `useBuyAssetWeb2FcMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuyAssetWeb2FcMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buyAssetWeb2FcMutation, { data, loading, error }] = useBuyAssetWeb2FcMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useBuyAssetWeb2FcMutation(baseOptions?: Apollo.MutationHookOptions<BuyAssetWeb2FcMutation, BuyAssetWeb2FcMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BuyAssetWeb2FcMutation, BuyAssetWeb2FcMutationVariables>(BuyAssetWeb2FcDocument, options);
      }
export type BuyAssetWeb2FcMutationHookResult = ReturnType<typeof useBuyAssetWeb2FcMutation>;
export type BuyAssetWeb2FcMutationResult = Apollo.MutationResult<BuyAssetWeb2FcMutation>;
export type BuyAssetWeb2FcMutationOptions = Apollo.BaseMutationOptions<BuyAssetWeb2FcMutation, BuyAssetWeb2FcMutationVariables>;
export const WearSkinDocument = gql`
    mutation WearSkin($where: WearSkinInput!) {
  wearSkin(where: $where) {
    status
    message
  }
}
    `;
export type WearSkinMutationFn = Apollo.MutationFunction<WearSkinMutation, WearSkinMutationVariables>;

/**
 * __useWearSkinMutation__
 *
 * To run a mutation, you first call `useWearSkinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWearSkinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wearSkinMutation, { data, loading, error }] = useWearSkinMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useWearSkinMutation(baseOptions?: Apollo.MutationHookOptions<WearSkinMutation, WearSkinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WearSkinMutation, WearSkinMutationVariables>(WearSkinDocument, options);
      }
export type WearSkinMutationHookResult = ReturnType<typeof useWearSkinMutation>;
export type WearSkinMutationResult = Apollo.MutationResult<WearSkinMutation>;
export type WearSkinMutationOptions = Apollo.BaseMutationOptions<WearSkinMutation, WearSkinMutationVariables>;
export const GetUserWardrobeDocument = gql`
    query GetUserWardrobe($where: GetUserWardrobeInput!) {
  getUserWardrobe(where: $where) {
    wardrobe {
      id
      userId
      skins
    }
  }
}
    `;

/**
 * __useGetUserWardrobeQuery__
 *
 * To run a query within a React component, call `useGetUserWardrobeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserWardrobeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserWardrobeQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUserWardrobeQuery(baseOptions: Apollo.QueryHookOptions<GetUserWardrobeQuery, GetUserWardrobeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserWardrobeQuery, GetUserWardrobeQueryVariables>(GetUserWardrobeDocument, options);
      }
export function useGetUserWardrobeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserWardrobeQuery, GetUserWardrobeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserWardrobeQuery, GetUserWardrobeQueryVariables>(GetUserWardrobeDocument, options);
        }
export type GetUserWardrobeQueryHookResult = ReturnType<typeof useGetUserWardrobeQuery>;
export type GetUserWardrobeLazyQueryHookResult = ReturnType<typeof useGetUserWardrobeLazyQuery>;
export type GetUserWardrobeQueryResult = Apollo.QueryResult<GetUserWardrobeQuery, GetUserWardrobeQueryVariables>;
export const GetProfileByUserIdDocument = gql`
    query GetProfileByUserId($where: GetProfileByUserIdInput!) {
  getProfileByUserId(where: $where) {
    status
    message
    profile {
      id
      timePlayed
      timeInLobby
      createdAt
      username
      totalGames
      wins
      loses
      draws
    }
  }
}
    `;

/**
 * __useGetProfileByUserIdQuery__
 *
 * To run a query within a React component, call `useGetProfileByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileByUserIdQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetProfileByUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetProfileByUserIdQuery, GetProfileByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileByUserIdQuery, GetProfileByUserIdQueryVariables>(GetProfileByUserIdDocument, options);
      }
export function useGetProfileByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileByUserIdQuery, GetProfileByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileByUserIdQuery, GetProfileByUserIdQueryVariables>(GetProfileByUserIdDocument, options);
        }
export type GetProfileByUserIdQueryHookResult = ReturnType<typeof useGetProfileByUserIdQuery>;
export type GetProfileByUserIdLazyQueryHookResult = ReturnType<typeof useGetProfileByUserIdLazyQuery>;
export type GetProfileByUserIdQueryResult = Apollo.QueryResult<GetProfileByUserIdQuery, GetProfileByUserIdQueryVariables>;
export const CreateUserProfileDocument = gql`
    mutation CreateUserProfile($data: CreateUserProfileInput!) {
  createUserProfile(data: $data) {
    status
    message
  }
}
    `;
export type CreateUserProfileMutationFn = Apollo.MutationFunction<CreateUserProfileMutation, CreateUserProfileMutationVariables>;

/**
 * __useCreateUserProfileMutation__
 *
 * To run a mutation, you first call `useCreateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserProfileMutation, { data, loading, error }] = useCreateUserProfileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserProfileMutation, CreateUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserProfileMutation, CreateUserProfileMutationVariables>(CreateUserProfileDocument, options);
      }
export type CreateUserProfileMutationHookResult = ReturnType<typeof useCreateUserProfileMutation>;
export type CreateUserProfileMutationResult = Apollo.MutationResult<CreateUserProfileMutation>;
export type CreateUserProfileMutationOptions = Apollo.BaseMutationOptions<CreateUserProfileMutation, CreateUserProfileMutationVariables>;
export const ChangeUsernameDocument = gql`
    mutation ChangeUsername($data: ChangeUsernameInput!) {
  changeUsername(data: $data) {
    status
    message
  }
}
    `;
export type ChangeUsernameMutationFn = Apollo.MutationFunction<ChangeUsernameMutation, ChangeUsernameMutationVariables>;

/**
 * __useChangeUsernameMutation__
 *
 * To run a mutation, you first call `useChangeUsernameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUsernameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUsernameMutation, { data, loading, error }] = useChangeUsernameMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChangeUsernameMutation(baseOptions?: Apollo.MutationHookOptions<ChangeUsernameMutation, ChangeUsernameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeUsernameMutation, ChangeUsernameMutationVariables>(ChangeUsernameDocument, options);
      }
export type ChangeUsernameMutationHookResult = ReturnType<typeof useChangeUsernameMutation>;
export type ChangeUsernameMutationResult = Apollo.MutationResult<ChangeUsernameMutation>;
export type ChangeUsernameMutationOptions = Apollo.BaseMutationOptions<ChangeUsernameMutation, ChangeUsernameMutationVariables>;
export const FindFirstHideAndSeekDocument = gql`
    query FindFirstHideAndSeek($where: HideAndSeekWhereInput) {
  findFirstHideAndSeek(where: $where) {
    finishPrompt
    id
    startPrompt
    searchTime
  }
}
    `;

/**
 * __useFindFirstHideAndSeekQuery__
 *
 * To run a query within a React component, call `useFindFirstHideAndSeekQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindFirstHideAndSeekQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindFirstHideAndSeekQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindFirstHideAndSeekQuery(baseOptions?: Apollo.QueryHookOptions<FindFirstHideAndSeekQuery, FindFirstHideAndSeekQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindFirstHideAndSeekQuery, FindFirstHideAndSeekQueryVariables>(FindFirstHideAndSeekDocument, options);
      }
export function useFindFirstHideAndSeekLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindFirstHideAndSeekQuery, FindFirstHideAndSeekQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindFirstHideAndSeekQuery, FindFirstHideAndSeekQueryVariables>(FindFirstHideAndSeekDocument, options);
        }
export type FindFirstHideAndSeekQueryHookResult = ReturnType<typeof useFindFirstHideAndSeekQuery>;
export type FindFirstHideAndSeekLazyQueryHookResult = ReturnType<typeof useFindFirstHideAndSeekLazyQuery>;
export type FindFirstHideAndSeekQueryResult = Apollo.QueryResult<FindFirstHideAndSeekQuery, FindFirstHideAndSeekQueryVariables>;
export const HideAndSeekNpcsDocument = gql`
    query HideAndSeekNPCS($where: HideAndSeekNPCWhereInput) {
  hideAndSeekNPCS(where: $where) {
    hideAndSeekId
    id
    propUrl
    assetUrl
  }
}
    `;

/**
 * __useHideAndSeekNpcsQuery__
 *
 * To run a query within a React component, call `useHideAndSeekNpcsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHideAndSeekNpcsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHideAndSeekNpcsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useHideAndSeekNpcsQuery(baseOptions?: Apollo.QueryHookOptions<HideAndSeekNpcsQuery, HideAndSeekNpcsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HideAndSeekNpcsQuery, HideAndSeekNpcsQueryVariables>(HideAndSeekNpcsDocument, options);
      }
export function useHideAndSeekNpcsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HideAndSeekNpcsQuery, HideAndSeekNpcsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HideAndSeekNpcsQuery, HideAndSeekNpcsQueryVariables>(HideAndSeekNpcsDocument, options);
        }
export type HideAndSeekNpcsQueryHookResult = ReturnType<typeof useHideAndSeekNpcsQuery>;
export type HideAndSeekNpcsLazyQueryHookResult = ReturnType<typeof useHideAndSeekNpcsLazyQuery>;
export type HideAndSeekNpcsQueryResult = Apollo.QueryResult<HideAndSeekNpcsQuery, HideAndSeekNpcsQueryVariables>;
export const CreateOneHideAndSeekDocument = gql`
    mutation CreateOneHideAndSeek($data: HideAndSeekCreateInput!) {
  createOneHideAndSeek(data: $data) {
    startPrompt
    finishPrompt
    id
  }
}
    `;
export type CreateOneHideAndSeekMutationFn = Apollo.MutationFunction<CreateOneHideAndSeekMutation, CreateOneHideAndSeekMutationVariables>;

/**
 * __useCreateOneHideAndSeekMutation__
 *
 * To run a mutation, you first call `useCreateOneHideAndSeekMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneHideAndSeekMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneHideAndSeekMutation, { data, loading, error }] = useCreateOneHideAndSeekMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneHideAndSeekMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneHideAndSeekMutation, CreateOneHideAndSeekMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneHideAndSeekMutation, CreateOneHideAndSeekMutationVariables>(CreateOneHideAndSeekDocument, options);
      }
export type CreateOneHideAndSeekMutationHookResult = ReturnType<typeof useCreateOneHideAndSeekMutation>;
export type CreateOneHideAndSeekMutationResult = Apollo.MutationResult<CreateOneHideAndSeekMutation>;
export type CreateOneHideAndSeekMutationOptions = Apollo.BaseMutationOptions<CreateOneHideAndSeekMutation, CreateOneHideAndSeekMutationVariables>;
export const UpdateOneHideAndSeekDocument = gql`
    mutation UpdateOneHideAndSeek($data: HideAndSeekUpdateInput!, $where: HideAndSeekWhereUniqueInput!) {
  updateOneHideAndSeek(data: $data, where: $where) {
    finishPrompt
    id
    startPrompt
  }
}
    `;
export type UpdateOneHideAndSeekMutationFn = Apollo.MutationFunction<UpdateOneHideAndSeekMutation, UpdateOneHideAndSeekMutationVariables>;

/**
 * __useUpdateOneHideAndSeekMutation__
 *
 * To run a mutation, you first call `useUpdateOneHideAndSeekMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneHideAndSeekMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneHideAndSeekMutation, { data, loading, error }] = useUpdateOneHideAndSeekMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneHideAndSeekMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneHideAndSeekMutation, UpdateOneHideAndSeekMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneHideAndSeekMutation, UpdateOneHideAndSeekMutationVariables>(UpdateOneHideAndSeekDocument, options);
      }
export type UpdateOneHideAndSeekMutationHookResult = ReturnType<typeof useUpdateOneHideAndSeekMutation>;
export type UpdateOneHideAndSeekMutationResult = Apollo.MutationResult<UpdateOneHideAndSeekMutation>;
export type UpdateOneHideAndSeekMutationOptions = Apollo.BaseMutationOptions<UpdateOneHideAndSeekMutation, UpdateOneHideAndSeekMutationVariables>;
export const DeleteManyHideAndSeekNpcDocument = gql`
    mutation DeleteManyHideAndSeekNPC($where: HideAndSeekNPCWhereInput) {
  deleteManyHideAndSeekNPC(where: $where) {
    count
  }
}
    `;
export type DeleteManyHideAndSeekNpcMutationFn = Apollo.MutationFunction<DeleteManyHideAndSeekNpcMutation, DeleteManyHideAndSeekNpcMutationVariables>;

/**
 * __useDeleteManyHideAndSeekNpcMutation__
 *
 * To run a mutation, you first call `useDeleteManyHideAndSeekNpcMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyHideAndSeekNpcMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyHideAndSeekNpcMutation, { data, loading, error }] = useDeleteManyHideAndSeekNpcMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyHideAndSeekNpcMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyHideAndSeekNpcMutation, DeleteManyHideAndSeekNpcMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyHideAndSeekNpcMutation, DeleteManyHideAndSeekNpcMutationVariables>(DeleteManyHideAndSeekNpcDocument, options);
      }
export type DeleteManyHideAndSeekNpcMutationHookResult = ReturnType<typeof useDeleteManyHideAndSeekNpcMutation>;
export type DeleteManyHideAndSeekNpcMutationResult = Apollo.MutationResult<DeleteManyHideAndSeekNpcMutation>;
export type DeleteManyHideAndSeekNpcMutationOptions = Apollo.BaseMutationOptions<DeleteManyHideAndSeekNpcMutation, DeleteManyHideAndSeekNpcMutationVariables>;
export const UpdateOneGraphicsSettingsDocument = gql`
    mutation UpdateOneGraphicsSettings($data: GraphicsSettingsUpdateInput!, $where: GraphicsSettingsWhereUniqueInput!) {
  updateOneGraphicsSettings(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateOneGraphicsSettingsMutationFn = Apollo.MutationFunction<UpdateOneGraphicsSettingsMutation, UpdateOneGraphicsSettingsMutationVariables>;

/**
 * __useUpdateOneGraphicsSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateOneGraphicsSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneGraphicsSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneGraphicsSettingsMutation, { data, loading, error }] = useUpdateOneGraphicsSettingsMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneGraphicsSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneGraphicsSettingsMutation, UpdateOneGraphicsSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneGraphicsSettingsMutation, UpdateOneGraphicsSettingsMutationVariables>(UpdateOneGraphicsSettingsDocument, options);
      }
export type UpdateOneGraphicsSettingsMutationHookResult = ReturnType<typeof useUpdateOneGraphicsSettingsMutation>;
export type UpdateOneGraphicsSettingsMutationResult = Apollo.MutationResult<UpdateOneGraphicsSettingsMutation>;
export type UpdateOneGraphicsSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateOneGraphicsSettingsMutation, UpdateOneGraphicsSettingsMutationVariables>;
export const UpdateOnePostProcessingSettingsDocument = gql`
    mutation UpdateOnePostProcessingSettings($data: PostProcessingSettingsUpdateInput!, $where: PostProcessingSettingsWhereUniqueInput!) {
  updateOnePostProcessingSettings(data: $data, where: $where) {
    antialiasing
    bloom
    id
    ssr
    ssao
  }
}
    `;
export type UpdateOnePostProcessingSettingsMutationFn = Apollo.MutationFunction<UpdateOnePostProcessingSettingsMutation, UpdateOnePostProcessingSettingsMutationVariables>;

/**
 * __useUpdateOnePostProcessingSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateOnePostProcessingSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnePostProcessingSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnePostProcessingSettingsMutation, { data, loading, error }] = useUpdateOnePostProcessingSettingsMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOnePostProcessingSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOnePostProcessingSettingsMutation, UpdateOnePostProcessingSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOnePostProcessingSettingsMutation, UpdateOnePostProcessingSettingsMutationVariables>(UpdateOnePostProcessingSettingsDocument, options);
      }
export type UpdateOnePostProcessingSettingsMutationHookResult = ReturnType<typeof useUpdateOnePostProcessingSettingsMutation>;
export type UpdateOnePostProcessingSettingsMutationResult = Apollo.MutationResult<UpdateOnePostProcessingSettingsMutation>;
export type UpdateOnePostProcessingSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateOnePostProcessingSettingsMutation, UpdateOnePostProcessingSettingsMutationVariables>;
export const FindFirstPostProcessingSettingsDocument = gql`
    query FindFirstPostProcessingSettings($where: PostProcessingSettingsWhereInput) {
  findFirstPostProcessingSettings(where: $where) {
    antialiasing
    bloom
    id
    ssr
    ssao
  }
}
    `;

/**
 * __useFindFirstPostProcessingSettingsQuery__
 *
 * To run a query within a React component, call `useFindFirstPostProcessingSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindFirstPostProcessingSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindFirstPostProcessingSettingsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindFirstPostProcessingSettingsQuery(baseOptions?: Apollo.QueryHookOptions<FindFirstPostProcessingSettingsQuery, FindFirstPostProcessingSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindFirstPostProcessingSettingsQuery, FindFirstPostProcessingSettingsQueryVariables>(FindFirstPostProcessingSettingsDocument, options);
      }
export function useFindFirstPostProcessingSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindFirstPostProcessingSettingsQuery, FindFirstPostProcessingSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindFirstPostProcessingSettingsQuery, FindFirstPostProcessingSettingsQueryVariables>(FindFirstPostProcessingSettingsDocument, options);
        }
export type FindFirstPostProcessingSettingsQueryHookResult = ReturnType<typeof useFindFirstPostProcessingSettingsQuery>;
export type FindFirstPostProcessingSettingsLazyQueryHookResult = ReturnType<typeof useFindFirstPostProcessingSettingsLazyQuery>;
export type FindFirstPostProcessingSettingsQueryResult = Apollo.QueryResult<FindFirstPostProcessingSettingsQuery, FindFirstPostProcessingSettingsQueryVariables>;
export const FindFirstGraphicsSettingsDocument = gql`
    query FindFirstGraphicsSettings($where: GraphicsSettingsWhereInput) {
  findFirstGraphicsSettings(where: $where) {
    id
    postProcessingSettingsId
    preset
    physicsEngine
  }
}
    `;

/**
 * __useFindFirstGraphicsSettingsQuery__
 *
 * To run a query within a React component, call `useFindFirstGraphicsSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindFirstGraphicsSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindFirstGraphicsSettingsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindFirstGraphicsSettingsQuery(baseOptions?: Apollo.QueryHookOptions<FindFirstGraphicsSettingsQuery, FindFirstGraphicsSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindFirstGraphicsSettingsQuery, FindFirstGraphicsSettingsQueryVariables>(FindFirstGraphicsSettingsDocument, options);
      }
export function useFindFirstGraphicsSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindFirstGraphicsSettingsQuery, FindFirstGraphicsSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindFirstGraphicsSettingsQuery, FindFirstGraphicsSettingsQueryVariables>(FindFirstGraphicsSettingsDocument, options);
        }
export type FindFirstGraphicsSettingsQueryHookResult = ReturnType<typeof useFindFirstGraphicsSettingsQuery>;
export type FindFirstGraphicsSettingsLazyQueryHookResult = ReturnType<typeof useFindFirstGraphicsSettingsLazyQuery>;
export type FindFirstGraphicsSettingsQueryResult = Apollo.QueryResult<FindFirstGraphicsSettingsQuery, FindFirstGraphicsSettingsQueryVariables>;
export const CreateOneGraphicsSettingsDocument = gql`
    mutation CreateOneGraphicsSettings($data: GraphicsSettingsCreateInput) {
  createOneGraphicsSettings(data: $data) {
    preset
    postProcessingSettingsId
    id
    physicsEngine
  }
}
    `;
export type CreateOneGraphicsSettingsMutationFn = Apollo.MutationFunction<CreateOneGraphicsSettingsMutation, CreateOneGraphicsSettingsMutationVariables>;

/**
 * __useCreateOneGraphicsSettingsMutation__
 *
 * To run a mutation, you first call `useCreateOneGraphicsSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneGraphicsSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneGraphicsSettingsMutation, { data, loading, error }] = useCreateOneGraphicsSettingsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneGraphicsSettingsMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneGraphicsSettingsMutation, CreateOneGraphicsSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneGraphicsSettingsMutation, CreateOneGraphicsSettingsMutationVariables>(CreateOneGraphicsSettingsDocument, options);
      }
export type CreateOneGraphicsSettingsMutationHookResult = ReturnType<typeof useCreateOneGraphicsSettingsMutation>;
export type CreateOneGraphicsSettingsMutationResult = Apollo.MutationResult<CreateOneGraphicsSettingsMutation>;
export type CreateOneGraphicsSettingsMutationOptions = Apollo.BaseMutationOptions<CreateOneGraphicsSettingsMutation, CreateOneGraphicsSettingsMutationVariables>;
export const SwitchEnableQuestsDocument = gql`
    mutation SwitchEnableQuests($data: SwitchEnableQuestsInput!) {
  switchEnableQuests(data: $data) {
    status
  }
}
    `;
export type SwitchEnableQuestsMutationFn = Apollo.MutationFunction<SwitchEnableQuestsMutation, SwitchEnableQuestsMutationVariables>;

/**
 * __useSwitchEnableQuestsMutation__
 *
 * To run a mutation, you first call `useSwitchEnableQuestsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchEnableQuestsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchEnableQuestsMutation, { data, loading, error }] = useSwitchEnableQuestsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSwitchEnableQuestsMutation(baseOptions?: Apollo.MutationHookOptions<SwitchEnableQuestsMutation, SwitchEnableQuestsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SwitchEnableQuestsMutation, SwitchEnableQuestsMutationVariables>(SwitchEnableQuestsDocument, options);
      }
export type SwitchEnableQuestsMutationHookResult = ReturnType<typeof useSwitchEnableQuestsMutation>;
export type SwitchEnableQuestsMutationResult = Apollo.MutationResult<SwitchEnableQuestsMutation>;
export type SwitchEnableQuestsMutationOptions = Apollo.BaseMutationOptions<SwitchEnableQuestsMutation, SwitchEnableQuestsMutationVariables>;
export const UserFinishedQuestDocument = gql`
    mutation UserFinishedQuest($data: UserFinishedQuestInput!) {
  userFinishedQuest(data: $data) {
    status
  }
}
    `;
export type UserFinishedQuestMutationFn = Apollo.MutationFunction<UserFinishedQuestMutation, UserFinishedQuestMutationVariables>;

/**
 * __useUserFinishedQuestMutation__
 *
 * To run a mutation, you first call `useUserFinishedQuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserFinishedQuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userFinishedQuestMutation, { data, loading, error }] = useUserFinishedQuestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserFinishedQuestMutation(baseOptions?: Apollo.MutationHookOptions<UserFinishedQuestMutation, UserFinishedQuestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserFinishedQuestMutation, UserFinishedQuestMutationVariables>(UserFinishedQuestDocument, options);
      }
export type UserFinishedQuestMutationHookResult = ReturnType<typeof useUserFinishedQuestMutation>;
export type UserFinishedQuestMutationResult = Apollo.MutationResult<UserFinishedQuestMutation>;
export type UserFinishedQuestMutationOptions = Apollo.BaseMutationOptions<UserFinishedQuestMutation, UserFinishedQuestMutationVariables>;
export const UpdateQuestDocument = gql`
    mutation UpdateQuest($data: UpdateQuestInput!) {
  updateQuest(data: $data) {
    status
  }
}
    `;
export type UpdateQuestMutationFn = Apollo.MutationFunction<UpdateQuestMutation, UpdateQuestMutationVariables>;

/**
 * __useUpdateQuestMutation__
 *
 * To run a mutation, you first call `useUpdateQuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestMutation, { data, loading, error }] = useUpdateQuestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateQuestMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuestMutation, UpdateQuestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuestMutation, UpdateQuestMutationVariables>(UpdateQuestDocument, options);
      }
export type UpdateQuestMutationHookResult = ReturnType<typeof useUpdateQuestMutation>;
export type UpdateQuestMutationResult = Apollo.MutationResult<UpdateQuestMutation>;
export type UpdateQuestMutationOptions = Apollo.BaseMutationOptions<UpdateQuestMutation, UpdateQuestMutationVariables>;
export const GetAssetsWeb2Document = gql`
    query GetAssetsWeb2($where: GetAssetsWeb2Input!) {
  getAssetsWeb2(where: $where) {
    assets {
      id
      name
      description
      assetURL
      fileType
      previewImage
      price
      currency
      createdAt
      viewsCount
      creatorEmail
      collectionId
      tag
    }
    totalCount
  }
}
    `;

/**
 * __useGetAssetsWeb2Query__
 *
 * To run a query within a React component, call `useGetAssetsWeb2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetsWeb2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetsWeb2Query({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAssetsWeb2Query(baseOptions: Apollo.QueryHookOptions<GetAssetsWeb2Query, GetAssetsWeb2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssetsWeb2Query, GetAssetsWeb2QueryVariables>(GetAssetsWeb2Document, options);
      }
export function useGetAssetsWeb2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssetsWeb2Query, GetAssetsWeb2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssetsWeb2Query, GetAssetsWeb2QueryVariables>(GetAssetsWeb2Document, options);
        }
export type GetAssetsWeb2QueryHookResult = ReturnType<typeof useGetAssetsWeb2Query>;
export type GetAssetsWeb2LazyQueryHookResult = ReturnType<typeof useGetAssetsWeb2LazyQuery>;
export type GetAssetsWeb2QueryResult = Apollo.QueryResult<GetAssetsWeb2Query, GetAssetsWeb2QueryVariables>;
export const GetBoughtAssetsByUserDocument = gql`
    query getBoughtAssetsByUser($where: GetBoughtAssetsByUserInput!) {
  getBoughtAssetsByUser(where: $where) {
    assets {
      id
      name
      description
      assetURL
      fileType
      previewImage
      createdAt
      price
      currency
      viewsCount
      creatorEmail
      collectionId
      tag
    }
    totalCount
  }
}
    `;

/**
 * __useGetBoughtAssetsByUserQuery__
 *
 * To run a query within a React component, call `useGetBoughtAssetsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBoughtAssetsByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBoughtAssetsByUserQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetBoughtAssetsByUserQuery(baseOptions: Apollo.QueryHookOptions<GetBoughtAssetsByUserQuery, GetBoughtAssetsByUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBoughtAssetsByUserQuery, GetBoughtAssetsByUserQueryVariables>(GetBoughtAssetsByUserDocument, options);
      }
export function useGetBoughtAssetsByUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBoughtAssetsByUserQuery, GetBoughtAssetsByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBoughtAssetsByUserQuery, GetBoughtAssetsByUserQueryVariables>(GetBoughtAssetsByUserDocument, options);
        }
export type GetBoughtAssetsByUserQueryHookResult = ReturnType<typeof useGetBoughtAssetsByUserQuery>;
export type GetBoughtAssetsByUserLazyQueryHookResult = ReturnType<typeof useGetBoughtAssetsByUserLazyQuery>;
export type GetBoughtAssetsByUserQueryResult = Apollo.QueryResult<GetBoughtAssetsByUserQuery, GetBoughtAssetsByUserQueryVariables>;
export const LoginDocument = gql`
    query login($data: LoginInput!) {
  login(data: $data) {
    isLoggedIn
  }
}
    `;

/**
 * __useLoginQuery__
 *
 * To run a query within a React component, call `useLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLoginQuery(baseOptions: Apollo.QueryHookOptions<LoginQuery, LoginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
      }
export function useLoginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoginQuery, LoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
        }
export type LoginQueryHookResult = ReturnType<typeof useLoginQuery>;
export type LoginLazyQueryHookResult = ReturnType<typeof useLoginLazyQuery>;
export type LoginQueryResult = Apollo.QueryResult<LoginQuery, LoginQueryVariables>;
export const GetUserPublicDocument = gql`
    query getUserPublic($where: UsersWhereUniqueInput!) {
  getUserPublic(where: $where) {
    user {
      id
      role
      email
      avatarUrl
      bannerUrl
    }
  }
}
    `;

/**
 * __useGetUserPublicQuery__
 *
 * To run a query within a React component, call `useGetUserPublicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPublicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPublicQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUserPublicQuery(baseOptions: Apollo.QueryHookOptions<GetUserPublicQuery, GetUserPublicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserPublicQuery, GetUserPublicQueryVariables>(GetUserPublicDocument, options);
      }
export function useGetUserPublicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserPublicQuery, GetUserPublicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserPublicQuery, GetUserPublicQueryVariables>(GetUserPublicDocument, options);
        }
export type GetUserPublicQueryHookResult = ReturnType<typeof useGetUserPublicQuery>;
export type GetUserPublicLazyQueryHookResult = ReturnType<typeof useGetUserPublicLazyQuery>;
export type GetUserPublicQueryResult = Apollo.QueryResult<GetUserPublicQuery, GetUserPublicQueryVariables>;
export const SignUpDocument = gql`
    mutation signUp($data: SignUpInput!) {
  signUp(data: $data) {
    isSignedUp
  }
}
    `;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const VerifyAccountDocument = gql`
    mutation verifyAccount($data: VerifyAccountInput!) {
  verifyAccount(data: $data) {
    isVerified
  }
}
    `;
export type VerifyAccountMutationFn = Apollo.MutationFunction<VerifyAccountMutation, VerifyAccountMutationVariables>;

/**
 * __useVerifyAccountMutation__
 *
 * To run a mutation, you first call `useVerifyAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyAccountMutation, { data, loading, error }] = useVerifyAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useVerifyAccountMutation(baseOptions?: Apollo.MutationHookOptions<VerifyAccountMutation, VerifyAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyAccountMutation, VerifyAccountMutationVariables>(VerifyAccountDocument, options);
      }
export type VerifyAccountMutationHookResult = ReturnType<typeof useVerifyAccountMutation>;
export type VerifyAccountMutationResult = Apollo.MutationResult<VerifyAccountMutation>;
export type VerifyAccountMutationOptions = Apollo.BaseMutationOptions<VerifyAccountMutation, VerifyAccountMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($data: ForgotPasswordInput!) {
  forgotPassword(data: $data) {
    forgotPassword
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ChangeUserPasswordDocument = gql`
    mutation ChangeUserPassword($data: ChangeUserPasswordInput!, $where: UsersWhereUniqueInput!) {
  changeUserPassword(data: $data, where: $where) {
    changePassword
  }
}
    `;
export type ChangeUserPasswordMutationFn = Apollo.MutationFunction<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>;

/**
 * __useChangeUserPasswordMutation__
 *
 * To run a mutation, you first call `useChangeUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserPasswordMutation, { data, loading, error }] = useChangeUserPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useChangeUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>(ChangeUserPasswordDocument, options);
      }
export type ChangeUserPasswordMutationHookResult = ReturnType<typeof useChangeUserPasswordMutation>;
export type ChangeUserPasswordMutationResult = Apollo.MutationResult<ChangeUserPasswordMutation>;
export type ChangeUserPasswordMutationOptions = Apollo.BaseMutationOptions<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($where: UsersWhereUniqueInput!, $data: UserUpdateInput!) {
  updateUser(where: $where, data: $data) {
    user {
      id
      email
      role
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const GoogleLoginDocument = gql`
    query GoogleLogin($email: String!) {
  googleLogin(email: $email) {
    status
    user {
      id
      email
      verificationToken
      password
      isVerified
      subscribed
      changePasswordToken
      avatarUrl
      bannerUrl
      role
      firstName
      lastName
      registrationOrigin
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGoogleLoginQuery__
 *
 * To run a query within a React component, call `useGoogleLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoogleLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoogleLoginQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGoogleLoginQuery(baseOptions: Apollo.QueryHookOptions<GoogleLoginQuery, GoogleLoginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GoogleLoginQuery, GoogleLoginQueryVariables>(GoogleLoginDocument, options);
      }
export function useGoogleLoginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GoogleLoginQuery, GoogleLoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GoogleLoginQuery, GoogleLoginQueryVariables>(GoogleLoginDocument, options);
        }
export type GoogleLoginQueryHookResult = ReturnType<typeof useGoogleLoginQuery>;
export type GoogleLoginLazyQueryHookResult = ReturnType<typeof useGoogleLoginLazyQuery>;
export type GoogleLoginQueryResult = Apollo.QueryResult<GoogleLoginQuery, GoogleLoginQueryVariables>;